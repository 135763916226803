import React, { useEffect, useState } from "react";
import {
  getFormattedTime,
  getStatusIcon,
  getFileIcon,
  dateTimeComparision,
  InitalRender,
  getCurrentTimeHomePage,
} from "./../common/Helper";
import { GetSingleTicketsService, CloseTicketService, readTicketIndicator } from "../../services/TicketService";
import TicketComments from "./NewTicketComments";
import { AddTicketThreadService } from "../../services/TicketService";
// import { download } from "../../utils/FileDownload";
import ReactHtmlParser from "react-html-parser";
import { Link, useHistory } from "react-router-dom";
import { TrimText } from "../common/TrimText";
import moment from "moment";
import { BASE_URL } from "../../utils/Constants";
import StatusRender, { CategoryRender, DepartmentRender } from "./StatusRender";
export default function NewTicketView(props) {
  const { selectedTicket, setSelectedTicket } = props;
  const [ticket, setTicket] = useState([]);
  const [status, setStatus] = useState("");
  const [ticketThread, setTicketThread] = useState([]);
  const [ticketCategory, setTicketCategory] = useState("");
  const [ticketDepartment, setTicketDepartment] = useState("");
  const history = useHistory();
  const [copyText, setCopyText] = useState(0);
  useEffect(() => {
    let componentMounted = true
    GetSingleTicketsService(selectedTicket)
      .then((res) => {
        if (componentMounted) {
          setTicket(res.data.ticket);
          setStatus(res.data.ticket.status)
          setTicketDepartment(res.data.ticket.department);
          setTicketCategory(res.data.ticket.category);
          if (res.data.threadList) setTicketThread(res.data.threadList);
          else setTicketThread({});
        }
        readTicketIndicator({ 'id_ticket': res.data.ticket.id_ticket }).then((res) => {
        });
      })
      .catch((error) => {
        // setDataState("error");
      });

    return function cleanup() {
      componentMounted = false
    }
  }, [selectedTicket]);

  const handleTicketThread = (value) => {
    AddTicketThreadService(value).then((res) => {
      let updatedChat = res.data[0];
      setTicketThread((ticketThread) => [...ticketThread, updatedChat]);
      setStatus(res.data[0].status)
    });
  };

  const downloadFile = async (absolutePath, fileName, index) => {
    // let value = await download(absolutePath, fileName);
    // setDownloadLoader(index);

    setTimeout(() => {
      // if (value) setDownloadLoader("0");
    }, 1000);
  };
  const handleCloseTicket = () => {
    CloseTicketService(selectedTicket)
      .then((res) => {
        setTicket({ ...ticket, status: 'Closed' });
      })
      .catch((error) => {
        // setDataState("error");
      });
  }
  const handleOpenForm = (value) => {

    history.push('/mytickets');
    props.setOpenForm(value);
    props.setSelectedTicket(undefined);
  }

  const copyEmailText = (email) => {
    setCopyText(1);
    navigator.clipboard.writeText(email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  return (
    <>
      {ticket ? (
        <div className="Tickets-right-main">
          <div className="Tickets-detail">
            <div className="Tickets-detail-head d-flex align-items-center mb-0">
              <div className="my-tickets-heading-blk flex-direction justify-content-start mb-4">
                <div className="name-w-head d-flex align-items-center ">
                  <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Tickets <span>Details</span>
                  </h4>
                  <div className="Tickets-head-rgt-new ticket-status-blk">
                    <h3 className="m-0">{TrimText(ticket.subject)}</h3>
                    {ticket.status ? <StatusRender status={ticket.status} /> : null}
                  </div>
                </div>
                <div className="text-md-right action2-blk">
                  <div className="ticket-view-btn d-flex align-items-center">

                    {ticket.status != "Closed" && ticket.status != undefined && (
                      <div
                        className="add-new-ticket-bx"
                        onClick={() => handleCloseTicket()}
                      >
                        <a title="Close Ticket" className="btn btn-primary">
                          <i className="fal fa-times"></i>Close Ticket
                        </a>
                      </div>
                    )}
                    <div
                      className="add-new-ticket-bx ml-3"
                      onClick={() => setSelectedTicket()}
                    >
                      <a
                        title="Back"
                        className="btn btn-white-bordered"
                        onClick={props.onBack}
                      >
                        <i className="fal fa-angle-left"></i>Back
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <div className="text-md-right ml-auto">
                <p className="">
                  {" "}
                  {getCurrentTimeHomePage(Date().toLocaleString())}{" "}
                </p>
              </div>

              {/* <div className="ticket-view-title d-flex align-items-center">
                <div className="Tickets-head-lft">
                  <div className="icon">{`${ticketDepartment.charAt(
                    0
                  )}${ticketCategory.charAt(0)}`}</div>
                </div>
                <div className="Tickets-head-rgt">
                  <h3>{ticket.subject}</h3>
                  <p>#{ticket.id_ticket}</p>
                </div>
              </div> */}
            </div>
            <div className="Tickets-detail-body row">
              <div className="ticket-details col-md-3">
                <div className="ac-white-card p-0">
                  <div className="p-4">
                    <div className="row mb-3 align-items-center">
                      <div className="col-md-6">
                        <h2 className="ticket-id">#{ticket.id_ticket}</h2>
                      </div>
                      <div className="col-md-6">
                        <label className="lb-black">
                          <b>Assigned To</b>
                        </label>
                        <div className="assigned-title-blk assigned-title-right">
                          <div className="assigned-title-itm">
                            <span className="name-icon">
                              {ticket.assign_to_photo ? <img src={BASE_URL + "/" + ticket.assign_to_photo.replace("/home/myaie/public_html/", "")} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.closest('.name-icon').append(InitalRender(ticket.assign_to ? ticket.assign_to : 'NA'));
                                currentTarget.remove();
                              }} /> : InitalRender(ticket.assign_to ? ticket.assign_to : 'NA')}
                              {ticket?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                            </span>
                            {TrimText(ticket.assign_to ? ticket.assign_to : 'NA', 20)}
                          </div>
                          <div className="assigned-title-info online_newpoints01">
                            <article>
                              <figure>{ticket.assign_to_photo ? <img src={BASE_URL + "/" + ticket.assign_to_photo.replace("/home/myaie/public_html/", "")} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.closest('figure').append(InitalRender(ticket.assign_to ? ticket.assign_to : 'NA'));
                                currentTarget.remove();
                              }} /> : InitalRender(ticket.assign_to ? ticket.assign_to : 'NA')}
                                 {ticket?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                              </figure>
                              <figcaption>
                                <h4>{TrimText(ticket.assign_to ? ticket.assign_to : 'NA', 20)}</h4>
                                <p>
                                  {/* <a href={"mailto:" + ticket.assign_to_email} target="_blank"> */}
                                  <i class="fal fa-envelope"></i>
                                  {ticket.assign_to_email ?
                                    <>
                                      <a class="hover-color-blue" onClick={() => copyEmailText(ticket.assign_to_email)}>
                                        {TrimText(ticket.assign_to_email ? ticket.assign_to_email : "NA", 20)}
                                      </a>
                                      {copyText ? (
                                        <i class="fas fa-check-circle cat-greenhex"></i>
                                      ) : (
                                        ticket.assign_to_email && <i
                                          className="fal fa-copy hover-color-blue"
                                          onClick={() => copyEmailText(ticket.assign_to_email)}
                                        ></i>
                                      )}</> : "N/A"}

                                </p>
                              </figcaption>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="lb-black">
                          <b>Date Modified</b>
                        </label>
                        <p>
                          {moment(ticket.last_update).format(
                            "D MMM YYYY HH:mm"
                          )}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label className="lb-black">
                          <b>Date Created</b>
                        </label>
                        <p>
                          {moment(ticket.date_add).format("D MMM YYYY HH:mm")}
                        </p>
                      </div>

                      <div className="col-md-6">
                        <label className="lb-black">
                          <b>Department</b>
                        </label>
                        <p>{ticket.department ? <DepartmentRender status={ticket.department} /> : ""}</p>
                      </div>
                      <div className="col-md-6">
                        <label className="lb-black">
                          <b>Category</b>
                        </label>
                        <CategoryRender status={ticket.category} />
                        {/* <p className="cat cat-dark-red ">
                          <i className="fas fa-circle"></i> {ticket.category
                            ? ticket.category.charAt(0).toUpperCase() + ticket.category.slice(1)
                            : ""}
                        </p> */}
                      </div>

                      {ticket &&
                        ticket.attachments &&
                        ticket.attachments.length > 0 && (
                          <div className="col-md-12">
                            <label className="lb-black">
                              <b>Attachments</b>
                            </label>
                            <div className="doc-list">
                              {ticket.attachments.map((i) => (
                                <div className="doc-list-in d-flex relative-class mb-2">
                                  <div className="icon-w-box">
                                    <i className="fal fa-paperclip"></i>
                                  </div>
                                  <div className="doc-list-info">
                                    <p className="as-text-blue mb-0">
                                      <a
                                        target="_blank"
                                        download
                                        href={i.attachment}
                                      >
                                        {i.originalName}
                                      </a>
                                    </p>
                                    <span className=" attch-text-font">
                                      {i.extentsion}
                                    </span>
                                  </div>
                                </div>
                              ))}
                              {/* <div className="doc-list-in d-flex">
                              <div className="icon-w-box">
                                <i class="fal fa-paperclip"></i>
                              </div>
                              <div className="doc-list-info">
                                <p class="as-text-blue mb-0">
                                  <a href="#">Thabang Test</a>
                                </p>
                                <span>1mb</span>
                              </div>
                            </div>

                            <div className="doc-list-in d-flex">
                              <div className="icon-w-box">
                                <i class="fal fa-paperclip"></i>
                              </div>
                              <div className="doc-list-info">
                                <p class="as-text-blue mb-0">
                                  <a href="#">Thabang Test</a>
                                </p>
                                <span>1mb</span>
                              </div>
                            </div>
                            <div className="doc-list-in d-flex">
                              <div className="icon-w-box">
                                <i class="fal fa-paperclip"></i>
                              </div>
                              <div className="doc-list-info">
                                <p class="as-text-blue mb-0">
                                  <a href="#">Thabang Test</a>
                                </p>
                                <span>1mb</span>
                              </div>
                            </div> */}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="description p-4">
                    <label className="lb-black d-block">
                      <b>Message</b>
                    </label>
                    <p>{ticket.campus_name ? `Campus: ${ticket.campus_name}` : ""}</p>
                    {ticket.message ? ReactHtmlParser(ticket.message) : ""}
                  </div>
                  {/* {ticket &&
                    ticket.attachments &&
                    ticket.attachments.length > 0 ? (
                    <div className="attachment-wrap">
                      <hr />
                      <strong>Attachment</strong>
                      <div className="attachment-row d-flex flex-wrap">
                        {ticket.attachments.map((attachment, index) => {
                          let keyValue = `key_${index}`;
                          return (
                            <div className="attachment-col" key={index}>
                              <div className="attach-bx">
                                <div className="attach-icon">
                                  <i
                                    className={`fal ${getFileIcon(
                                      attachment.extentsion.toLowerCase()
                                    )}`}
                                  ></i>
                                </div>
                                <div className="attach-detail">
                                  <p>{attachment.originalName}</p>
                                  <a
                                    key={index}
                                    href=""
                                    className="btn btn-white-bordered"
                                    onClick={() =>
                                      downloadFile(
                                        attachment.attachment,
                                        attachment.originalName,
                                        keyValue
                                      )
                                    }
                                  >
                                    <i className="fal fa-arrow-to-bottom"></i>
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </div>
              <TicketComments
                ticketData={ticket}
                ticketThread={ticketThread}
                id_ticket={ticket.id_ticket}
                parentHandleTicketThread={handleTicketThread}
                scroll_down_bottom_parent={props.scroll_down_bottom_parent}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
