// import React,{ useEffect, useRef } from "react";
// import TextField from "@material-ui/core/TextField";
// import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
// import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
// import DatePicker from "@material-ui/lab/DatePicker";
// import moment from "moment-timezone";

// export default function Test() {
//   const videoRef = useRef(null);
//   const photoRef = useRef(null);
//   const stripRef = useRef(null);

//   useEffect(() => {
//     getVideo();
//   }, [videoRef]);

//   const getVideo = () => {
//     navigator.mediaDevices
//       .getUserMedia({ video: { width: 300 } })
//       .then(stream => {
//         let video = videoRef.current;
//         video.srcObject = stream;
//         video.play();
//       })
//       .catch(err => {
//         console.error("error:", err);
//       });
//   };

//   const paintToCanvas = () => {
//     let video = videoRef.current;
//     let photo = photoRef.current;
//     let ctx = photo.getContext("2d");

//     const width = 320;
//     const height = 240;
//     photo.width = width;
//     photo.height = height;

//     return setInterval(() => {
//       ctx.drawImage(video, 0, 0, width, height);
//     }, 200);
//   };

//   const takePhoto = () => {
//     let photo = photoRef.current;
//     let strip = stripRef.current;

//     console.warn(strip);

//     const data = photo.toDataURL("image/jpeg");

//     console.warn(data);
//     const link = document.createElement("a");
//     link.href = data;
//     link.setAttribute("download", "myWebcam");
//     link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
//     strip.insertBefore(link, strip.firstChild);
//   };

//   return (
//     <div>
//       <button onClick={() => takePhoto()}>Take a photo</button>
//       <video onCanPlay={() => paintToCanvas()} ref={videoRef} />
//       <canvas ref={photoRef} />
//       <div>
//         <div ref={stripRef} />
//       </div>
//     </div>
//   );
// }

// import React, { useEffect, useState, useRef } from "react";
// import ObjectDetection from './object-detection/object-detection';
// import './object-detection/styles.css';
// // import { SOCKET_URL } from "../utils/Constants";
// // import io from "socket.io-client";

// export default function Test() {
//   // const socket = useRef();
//   const [hasCamera, setHasCamera] = useState();
//   const [hasMicrophone, setHasMicrophone] = useState();

//   /*************************** ***************************/
//   // useEffect(() => {
//   //   socket.current = io.connect(SOCKET_URL);

//   //   socket.current.on("NewClient", (id) => {
//   //     // setYourID(id);
//   //     console.log(id);
//   //   });
//   //   socket.current.on("allUsers", (users) => {
//   //     console.log(users);
//   //     // setUsers(users);
//   //   });

//   //   socket.current.on("hey", (data) => {
//   //     setReceivingCall(true);
//   //     ringtoneSound.play();
//   //     setCaller(data.from);
//   //     setCallerSignal(data.signal);
//   //   })
//   // }, []);
//   /*************************** ***************************/

//   function detectWebcam(callback) {
//     let md = navigator.mediaDevices;
//     if (!md || !md.enumerateDevices) return callback(false);
//     md.enumerateDevices().then((devices) => {
//       callback(devices.some((device) => "videoinput" === device.kind));
//     });
//   }

//   function detectAudio() {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         // Code for success
//         console.log('stream');
//         setHasMicrophone("yes");
//       })
//       .catch((err) => {
//         console.log(err);
//         // if (err.includes("NotFoundError: Requested device not found"))
//         //   alert("Mic not detected");
//         // else alert("Error recording audio");
//         setHasMicrophone("no");
//       });
//   }

//   /**************************** ****************************/
//   useEffect(() => {
//     let start = document.getElementById("start"),
//       stop = document.getElementById("stop"),
//       mediaRecorder;

//     start.addEventListener("click", async function () {
//       let stream = await recordScreen();
//       let mimeType = "video/webm";
//       mediaRecorder = createRecorder(stream, mimeType);
//       let node = document.createElement("p");
//       node.textContent = "Started recording";
//       document.body.appendChild(node);
//     });

//     stop.addEventListener("click", function () {
//       console.log('mediaRecorder value would be ', mediaRecorder && mediaRecorder.stream && mediaRecorder.stream)
//       mediaRecorder && mediaRecorder.stream.getTracks()
//         .forEach(track => track.stop())

//       // mediaRecorder.stop();
//       let node = document.createElement("p");
//       node.textContent = "Stopped recording";
//       document.body.appendChild(node);
//     });

//     // start.click();
//   }, []);

//   async function recordScreen() {
//     return await navigator.mediaDevices.getDisplayMedia({
//       audio: true,
//       video: { mediaSource: "screen" },
//     });
//   }

//   function createRecorder(stream, mimeType) {
//     // the stream data is stored in this array
//     let recordedChunks = [];

//     const mediaRecorder = new MediaRecorder(stream);

//     mediaRecorder.ondataavailable = function (e) {
//       if (e.data.size > 0) {
//         recordedChunks.push(e.data);
//       }
//     };
//     mediaRecorder.onstop = function () {
//       saveFile(recordedChunks);
//       recordedChunks = [];
//     };
//     mediaRecorder.start(200); // For every 200ms the stream data will be stored in a separate chunk.
//     return mediaRecorder;
//   }

//   function saveFile(recordedChunks) {
//     const blob = new Blob(recordedChunks, {
//       type: "video/webm",
//     });
//     let filename = window.prompt("Enter file name"),
//       downloadLink = document.createElement("a");
//     downloadLink.href = URL.createObjectURL(blob);
//     downloadLink.download = `${filename}.webm`;

//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     URL.revokeObjectURL(blob); // clear from memory
//     document.body.removeChild(downloadLink);
//   }
//   /**************************** ****************************/

//   useEffect(() => {
//     detectWebcam(function (hasWebcam) {
//       console.log("Webcam: " + (hasWebcam ? "yes" : "no"));
//       setHasCamera(hasWebcam ? "yes" : "no");
//     });
//     detectAudio();
//   }, []);

//   useEffect(() => {
//     var script = document.createElement("script");
//     script.async = true;
//     script.src = "/site.js";
//     document.head.appendChild(script);
//   }, []);

//   return (
//     <>
//       <style jsx>
//         {`
//           figure {
//             // display: inline-block;
//             margin: 10px;
//             vertical-align: top;
//           }

//           figcaption {
//             // text-align: left;
//             font-weight: bold;
//           }

//           video.video{
//             width: 480px;
//             height: 360px;
//             background-color: #999;
//           }
//           canvas.motion {
//             width: 80px;
//             height: 50px;
//             background-color: #999;
//           }

//           canvas.motion {
//             image-rendering: pixelated;
//           }
//         `}
//       </style>
//       Has camera: {hasCamera}<br /> Has microphone: {hasMicrophone}
//       <figure>
//         <video id="video" className="video"></video>
//         <figcaption>Live Video</figcaption>
//       </figure>
//       <figure>
//         <canvas id="motion" className="motion"></canvas>
//         <figcaption>
//           Total movements: <span id="score">0</span>
//         </figcaption>
//       </figure>
//       <button id="start">Start screen sharing</button>
//       <button id="stop">Stop</button>
//       <ObjectDetection />
//     </>
//   );
// }

import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import moment from "moment-timezone";



export default function Test() {


  var constraints = { audio: true, video: false };

  navigator.mediaDevices.getUserMedia(constraints).then(


    function (stream) {
      var max_level_L = 0;
      var old_level_L = 0;
      var cnvs = document.getElementById("test");
      var cnvs_cntxt = cnvs.getContext("2d");

      var audioContext = new AudioContext();
      var microphone = audioContext.createMediaStreamSource(stream);
      var javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);

      microphone.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
      javascriptNode.onaudioprocess = function (event) {

        var inpt_L = event.inputBuffer.getChannelData(0);
        var instant_L = 0.0;

        var sum_L = 0.0;
        for (var i = 0; i < inpt_L.length; ++i) {
          sum_L += inpt_L[i] * inpt_L[i];
        }
        instant_L = Math.sqrt(sum_L / inpt_L.length);
        max_level_L = Math.max(max_level_L, instant_L);
        instant_L = Math.max(instant_L, old_level_L - 0.008);
        old_level_L = instant_L;

        cnvs_cntxt && cnvs_cntxt.clearRect(0, 0, cnvs.width, cnvs.height);
        cnvs_cntxt && (cnvs_cntxt.fillStyle = '#00ff00');
        cnvs_cntxt && cnvs_cntxt.fillRect(10, 10, (cnvs.width - 20) * (instant_L / max_level_L), (cnvs.height - 20)); // x,y,w,h

      }
    }).catch(function (err) {
      console.log(err);
    });
  return (
    <div>
      <canvas width="320px" height="30px" id="test" style={{backgroundColor: 'black'}}></canvas>
    </div>
  );
}