import React,{useEffect,useState} from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Chart } from 'react-charts'
import {
  getClassAttendance,
} from "../services/GeneralService";
import moment from "moment-timezone";
// moment.tz.setDefault("Africa/Johannesburg");

function ClassAttendance(props) {
  const [param,setParam]= useState("sid");
  const [paramVal,setParamVal]= useState(props.match.params.sid);
  const [chartData,setChartData]= useState([]);
  if(!paramVal){
    setParam('qid');
    setParamVal(props.match.params.qid);
  }
    useEffect(() => {
        getClassAttendance({paramVal:paramVal,param:param})
        .then((res) => {
            setChartData(res.data);
          })
          .catch((error) => {
          });
    }, [])


    const data = React.useMemo(
        () => [
          {
            data: chartData
          },
        //   {
        //     label: 'Series 2',
        //     data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
        //   }
        ],
        [chartData]
      )
      const axes = React.useMemo(
        () => [
          { primary: true, type: 'ordinal', position: 'left' },
          { position: 'bottom', type: 'linear', stacked: true }
        ],
        []
      )
      const series = React.useMemo(
        () => ({
          type: 'bar'
        }),
        []
      )

    return (
        <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
            <SideBar></SideBar>
            <div className="sidebar-right">
            <div class="sec-help-center">
              <div class="card-simple-header d-flex">
                <span class="ttl-left">Attendance</span>
              </div>
              <div class="card-simple" >
                <div class="card-simple-body">	
                <div>                
                  <table>
                        <tr>
                          <th>Attendance Status</th>
                          <th>Count of Attendance Status</th>
                        </tr>
                        {
                          chartData.length>0 && chartData.map(subItem=>{
                            return(
                            <tr>
                            <td>{subItem[0]}</td>
                            <td>{subItem[1]}</td>
                          </tr>)
                          })
                        }
                      </table>
                      </div>
                      <br/><br/>
                    <div
                    style={{
                      width: '400px',
                      height: '300px'
                    }}
                    >
                    <Chart data={data} series={series} axes={axes} />
                    </div>
                </div>
              </div>
              </div>
           </div>
        </div>
      </>
    )
}

export default ClassAttendance

