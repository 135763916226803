import React from "react";

const AssignmentStatus = (props) => {
    switch (props.status) {
      case "Open":
        return (
          <>
          <div className="assessment-03">
            <p className="as-blue-bg  as-widget">{/*<i className="fal fa-check"></i>*/}Open</p>
          </div>
          </>
        );
        break;
      case "Draft":
        return (
          <>
            <div className="assessment-03">
              <p className="as-green-bg as-widget">{/*<i className="fal fa-check"></i>*/}Draft</p>
            </div>
          </>
        );
        break;
      case "Submitted":
        return (
          <>
            <div className="assessment-03">
              <p className="as-green-bg as-widget">{/*<i className="fal fa-check"></i>*/}Submitted</p>
            </div>
          </>
        );
        break;
      case "Closed-Submitted":
        return (
          <>
            <div className="assessment-03">
              <p className="as-orange-bg as-widget">{/*<i className="fal fa-check"></i>*/}Closed-Submitted</p>
            </div>
          </>
        );
        break;

      case "Pending":
        return (
          <>
            <div className="assessment-03">
              <p className="as-orange-bg as-widget">{/*<i className="fal fa-hourglass-half"></i>*/}Pending</p>
            </div>
          </>
        );
        break;
      case "Closed-Marked":
        return (
          <>
          <div className="assessment-03">
            <p className="as-blue-bg as-widget">{/*<i className="fal fa-check"></i>*/}Closed-Marked</p>
          </div>
          </>
        );
        break;
      case "Closed Not-Submitted":
        return (
          <>
          <div className="assessment-03">
            <p className="as-red-bg as-widget">{/*<i className="fal fa-check"></i>*/}Closed Not-Submitted</p>
          </div>
          </>
        );
        break;
      default:
        return (
          <>
            <div className="assessment-03">
              <p className="as-red-bg as-widget">{/*<i className="fal fa-times"></i>*/}Unknown Status</p>
            </div>
          </>
        );
        break;
    }
}
export default AssignmentStatus;
