import React from "react";
import { connect } from "react-redux";
import { setUnreadNotificationCount, setWidget, unsetWidget } from "../store/actions";
import { shortenText } from '../utils/commonFunction.js'
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ViewBookingModal from "../modals/ViewBookingModal.js";
import $ from 'jquery';
import GlobalCalendar from "../components/course/GlobalCalendar";
import { ListBookings } from "../services/BookingServices.js";
import SekletonSubjectListCalendar from "../loaders/SekletonSubjectListCalendar";
import {
  GetSubject, UpdateStudentTimeZone
} from "../services/CalendarService";
import { Scrollbar } from "react-scrollbars-custom";
import BookingModal from "../modals/BookingModal.js";
const stc = require('string-to-color');
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUnreadNotificationCount: (payload) => dispatch(setUnreadNotificationCount(payload)),
    setWidget: (payload) => dispatch(setWidget(payload)),
    unsetWidget: (payload) => dispatch(unsetWidget())
  };
}

/** map state */
const mapStateToProps = (state) => ({ notification: state.notification, user: state.user, widget: state.widget });

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      subjectId: [],
      //showAll:true,
      isLoading: false,
      subjectListLoading: false,
      showModal: false,
      showBookingModal : false,
      bookingData: {},
      calendarView: 'week',
      bookingDetails: {},
      userId: props.user.id
    };
    this.updateSubject = this.updateSubject.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    

  }

  componentDidMount() {
    this.setState({ subjectListLoading: true });
    GetSubject()
      .then((res) => {
        this.setState({ subjects: res.data, subjectListLoading: false });
        this.updateAllSubject();
      })
      .catch((error) => {
        this.setState({ subjectListLoading: false });
      });

    UpdateStudentTimeZone({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).then((res) => {
      if (res.status === 200) {
      }
    }).catch((err) => {
      console.log(err);
    })

    const { user } = this.props;
    const userId = user.id;
    ListBookings(userId, this.calendarView)
      .then((res) => {
        //console.log('Bookings:', res.data);
        this.setState({ bookingData: res.data })
        this.setState({ isLoading: true })
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
      });

  }
  updateSubject = (e) => {
    let sid = Number(e.currentTarget.value);
    if (this.state.subjectId.includes(sid)) {
      let index = this.state.subjectId.indexOf(sid);
      if (index > -1) {
        this.state.subjectId.splice(index, 1);
      }
    } else {
      this.state.subjectId.push(sid);
    }
    this.setState({ subjectId: this.state.subjectId })

  };

  updateAllSubject = () => {
    if (this.state.subjectId.length > 0) {
      this.state.subjectId = [];
    } else {
      this.state.subjects.length > 0 && this.state.subjects.map((row) => {
        let sid = Number(row.id);
        if (!this.state.subjectId.includes(sid)) {
          this.state.subjectId.push(sid);
        }
      })
    }

    this.setState({ subjectId: this.state.subjectId });

  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  handleCalendarView = (day) => {
    this.state.calendarView = day;
  }

  handleBookingDetail = (bookingDetail, showBookingModal) => {
    this.setState({ bookingDetails: bookingDetail, showBookingModal: showBookingModal });
  }

  toggleModal(){
    this.setState({showModal : true});
  }
  closeModal() {
    this.setState({ showModal: false })
    this.setState({ showBookingModal: false})
  }


  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="calendar-wrap">
              <div className="event-calendar-main d-flex h-100">
                <div className="event-calendar-left bg-white">
                  <button className="close-btn-sidebar" onClick={this.handleSideBar}>
                    <i className="close-bar close-bar-1"></i>
                    <i className="close-bar close-bar-2"></i>
                    <i className="close-bar close-bar-3"></i>
                  </button>
                  <div className="calendar-panel-main">
                    <div className="calendar-panel-title">
                      <div className="calendar-panel-left">
                        <span><a href="">Show/Hide Subjects</a></span>
                      </div>
                      <div className="calendar-panel-right">
                        <div className="custom-control custom-checkbox text-left box-tm">
                          <label class="toggleSwitch Switchcalendar">
                            <div class="show-all">Show All</div>
                            <input type="checkbox" className="custom-control-input" id="customCheck1" value='all' onClick={() => this.updateAllSubject()} checked={this.state.subjectId.length == this.state.subjects.length ? 'checked' : ''} />
                            <span>
                              <span><i class="fal fa-eye-slash"></i></span>
                              <span><i class="fal fa-eye"></i></span>
                            </span>
                            <a></a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="calendar-panel-body">
                      <Scrollbar>
                        {this.state.subjectListLoading && <SekletonSubjectListCalendar />}
                        {!this.state.subjectListLoading && this.state.subjects.length > 0 && this.state.subjects.map((row) => {

                          let stylesBorder = {
                            'color': stc(row.name)
                          };
                          let styles = {
                            'background-color': stc(row.name)
                          };
                          return (

                            <div class="calendar-cart-item">
                              <div class="calendar-cart-item-inner" style={stylesBorder}>
                                <button>{shortenText(row.name.trim(), 30)} </button>
                                <span style={styles} className="calendar-checkbox">
                                  <input value={row.id} onClick={(e) => this.updateSubject(e)} type="checkbox" checked={(this.state.subjectId.includes(row.id) ? 'checked' : '')} />
                                </span>
                              </div>
                            </div>
                          )
                        })}

                      </Scrollbar>

                      <div className="btn-booking">

                        <button
                          className="btn btn-primary btn-req"
                          title="Request Booking"
                          // data-toggle="modal"
                          // data-target="#resource-modal"
                          onClick={this.toggleModal}
                        >
                          <i className="fal fa-calendar-alt"></i>Request Booking
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-calendar-right">
                  {this.state.subjectId.length < 1 && !this.state.subjectListLoading ? (
                    <div className="subject-no-content">
                      <div className="no-img">
                        {/* <img src={not_yet_published_icon} /> */}
                      </div>
                      <div className="no-content">
                        <h4>No Subject Selected</h4>
                        <p>Please select any subject</p>
                      </div>
                    </div>
                  ) : (
                    this.state.isLoading &&
                      <GlobalCalendar subjectId={this.state.subjectId}
                          bookingData={this.state.bookingData.bookings}
                          onCalendarView={(day) => this.handleCalendarView(day)}
                          onBookingDetail={(bookingDetail, showBookingModal) => this.handleBookingDetail(bookingDetail, showBookingModal)}
                          userId = {this.state.userId}>
                      </GlobalCalendar>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingModal
          show={this.state.showModal}
          onClose={() => this.closeModal()}
        />
        <ViewBookingModal
          show={this.state.showBookingModal}
          onClose={() => this.closeModal()}
          bookingDetails={this.state.bookingDetails} />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
