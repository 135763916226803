import userReducer from "./reducers/user";
import payerReducer from "./reducers/payer";
import subjectReducer from "./reducers/subject";
import homeTabReducer from "./reducers/homeTab";
import chatGroups from "./reducers/chatGroups";
import selectedGroupChatReducer from "./reducers/selectedGroupChatReducer";
import { combineReducers } from "redux";
import fileReducer from "./reducers/file";
import quizReducer from "./reducers/quiz";
import notificationReducer from "./reducers/notification";
import ticketNotificationReducer from "./reducers/ticketNotification";
import chatNotificationReducer from "./reducers/chatNotification";
import widgetReducer from "./reducers/widget";
import modalStatus from "./reducers/modalStatus";
import brandReducer from "./reducers/brand";
import courseCount from "./reducers/courseCount";
import hoverUserReducer from "./reducers/hoverUser";
import missingDetailReducer from "./reducers/usermissingDetail";
import favouritesCountReducer from "./reducers/favourites";
import reRegistrationCountReducer from "./reducers/reRegistrationNotifications";
import isReRegReducer from "./reducers/rereg";
import isWitheldReducer from "./reducers/withheld";
import examLaunched from "./reducers/examLaunched";
import buttonState from "./reducers/buttonStates";
import isExamRegReducer from "./reducers/examreg";
import ReRegistrationReducer from "./reducers/reRegistrations";
import isArticulationReducer from "./reducers/articulation";

const rootReducer = combineReducers({
  user: userReducer,
  payer: payerReducer,
  tabs: homeTabReducer,
  selectedGroup: selectedGroupChatReducer,
  subject: subjectReducer,
  chat: chatGroups,
  file: fileReducer,
  quiz: quizReducer,
  notification: notificationReducer,
  ticketNotification: ticketNotificationReducer,
  chatNotification: chatNotificationReducer,
  widget: widgetReducer,
  modalStatusVideo:modalStatus,
  brand: brandReducer,
  course: courseCount,
  hoverUser: hoverUserReducer,
  missingDetail: missingDetailReducer,
  favourites: favouritesCountReducer,
  reRegistrationCount: reRegistrationCountReducer,
  isReReg: isReRegReducer,
  isWithHeld: isWitheldReducer,
  examLaunched: examLaunched,
  buttonStates : buttonState,
  isExamReg : isExamRegReducer,
  registrations:ReRegistrationReducer,
  isArticulation:isArticulationReducer
});
export default rootReducer;
