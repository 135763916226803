import React, { Component } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { getMyExam } from "../services/ExamService";
import ExamInProgress from "../components/exam/ExamInProgress";
import ExamCompleted from "../components/exam/ExamCompleted";

class ExamSummeryPage extends React.Component {
  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="exam-list-wrap">
              <div class="my-tickets-heading-blk flex-direction mb-4">
                <h4 class="text-left-align landing-heading heading_color_dynamic">My <span>Exam Registrations</span></h4>
                <div class="text-md-right"><p class=""></p></div>
              </div>

              {/* <h4 className="my-md-2 my-0 landing-heading">
                My <span>Exam Registrations</span>
              </h4> */}
              <ExamInProgress />
              {/* Show Completed */}
              <ExamCompleted />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ExamSummeryPage;
