import React from "react";
import { zipURL } from "../../utils/Constants";
import { FetchFileIcon } from "../../utils/commonFunction";

export const RenderAttachList = (props) => {
    const downloadFile = (filePath, fileName) => {
        // console.log('the url received is ', filePath)
        // window.open(filePath, '_blank')

        const url = filePath;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();

    }

    return (<div className="col-md-4 mb-4">
        <div className="uploded-files-item feed-pad">
            <div className="uploded-files-item-txt curser"
                data-toggle="tooltip"
                data-placement="right"
                onClick={() => downloadFile(props.filePath, props.fileName)}
                title={props.fileName}/*onClick={() => downloadFile(props.filePath, props.fileName)}*/>
                <figure>
                    {/* <i className="fas fa-file"></i> */}
                    {props?.fileName !== undefined && props?.fileName ? <i className={FetchFileIcon(props?.fileName)}></i> : null}
                </figure>
                <figcaption >
                    <h4 className="oneline-ellipsis">{props.fileName}</h4>
                    <p>{props.size}</p>
                </figcaption>
            </div>

            {props.showClose ?
                <button onClick={() => { props.removeF(props) }} type="button" className="btn file-remove-btn"><i className="fal fa-times hold-show"></i></button>
                :
                <a href={zipURL + props?.filePath?.replace("null/","")} target='_blank' className="btn file-remove-btn rm-box-shadow download-icon"><i className="fal fa-download download-icon"></i></a>
            }
        </div>
    </div>
    )
}