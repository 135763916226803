import React, { useState } from "react";



import LoginFromAdmin from "../components/LoginFromAdmin"

class ViewStudent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { match } = this.props;
    //console.log('Password',match.params.password);
    //console.log('State',this.state.email,this.state.password);
    //const email = match.params.email;
    //const password = match.params.password;

    // viewStudent(match.params)
    // .then((res) => {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Success",
    //       text: res.data.message,
    //       timer: 3000,
    //       onOpen: function () {
    //         Swal.showLoading();
    //       },
    //     }).then(function (result) {
    //       if (result.dismiss === "timer") {
       
    //       }
    //     });
    //   })
    //   .catch(function (err) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Login Failed",
    //       text: err.response.data.message,
    //     });
    //     return false;
    //   });
  }
  render() {
    return (
      <>
        <div
          className="portal-login portal-public-wrap d-flex align-items-center"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card portal-login-card text-center">
                  <div className="card-header">
                    <div className="card-header-img">
                    </div>
                  </div>
                   <LoginFromAdmin params={this.props.match}/>         
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewStudent
