import axiosInstance from "../utils/axios";

export const GetQuizByIdService = async (values) => {
  return await axiosInstance().get("/getQuizById", { params: values });
};

export const GetQuestionsService = async (values) => {
  return await axiosInstance().get("/getQuestions", { params: values });
};

export const GetQuizQuestionsService = async (values) => {
  return await axiosInstance().get("/getQuizQuestions", { params: values });
};

export const SubmitQuizService = async (values) => {
  return await axiosInstance().post("submitQuiz", values);
};

export const AssessmentSetToFinalService = async (values) => {
  return await axiosInstance().post("assessmentSetToFinal", values);
};

export const SubmitSingleAnswerService = async (values) => {
  return await axiosInstance().post("submitSingleAnswer", values);
};

export const SubmitSingleAnswersService = async (values) => {
  return await axiosInstance().post("submitSingleAnswers", values);
};

export const quizResultWithTime = async (values) => {
  return await axiosInstance().post("/quizQuestion", values);
};

export const longAndShortAns = async (values) => {
  return await axiosInstance().post("/longAndShortAns", values);
};

export const GetQuizAttemptsService = async (values) => {
  return await axiosInstance().get("/getQuizAttempts", { params: values });
};

export const UploadDocument = async (values)=>{
  return await axiosInstance().post("/uploadDocument", values);
}

export const GetQuestionsPreview = async (values) => {
  return await axiosInstance().get("/getQuestionsPreview", { params: values });
};