import React, { Component } from "react";
import { connect } from "react-redux";
import { setUnreadNotificationCount } from "../store/actions";

import $ from "jquery";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import NotificationList from "../components/notification/NotificationList";
import NotificationLeftBox from "../components/notification/NotificationLeftBox";
import NotificationRightBox from "../components/notification/NotificationRightBox";
import NotificationFilter from "../components/notification/NotificationFilter";
import { GetMyNotificationsService, UpdateNotificationRead } from "../services/NotificationService";
import {
  GetNotificationContentService,
  GetFilteredNotificationService,
  GetMyNotificationOfAvaibality,
} from "../services/NotificationService";
import ReactHtmlParser from "react-html-parser";
import SkeletonLeftBoxNotification from "../loaders/SkeletonLeftBoxNotification";

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUnreadNotificationCount: (payload) => dispatch(setUnreadNotificationCount(payload)),
  };
}

/** map state */
const mapStateToProps = (state) => ({ user: state.user, notification: state.notification });

class NotificationPage extends React.Component {
  mounted = true
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Allvisibility: [],
      Unreadvisibility: [],
      Importantvisibility: [],
      NotificationContent: [],
      NotificationAttachments: [],
      notificationTabDef: "all",
      nId: 0,
      searchFilterText: "",
    };
  }

  handleSearchFilterText = (text) => {
    this.setState({ searchFilterText: text })
  }

  checkAvailabilityOfNotification = (notificationTab = "all") => {
    this.setState({ loading: true });
    try {	
      if (this.props.match.params.id) {	
        this.setState({ nId: this.props.match.params.id });	
        this.getNotificationContentMethod(this.props.match.params.id);	
      }	
    } catch (error) {}	
    
    GetMyNotificationOfAvaibality(notificationTab).then((res) => {
      if (this.mounted) {
        localStorage.removeItem("unreadNotificationCount");
        localStorage.setItem("unreadNotificationCount", res.data.notificationsCount ? res.data.notificationsCount.unreadNotificationCount : 0);
        this.props.setUnreadNotificationCount(res.data.notificationsCount ? res.data.notificationsCount.unreadNotificationCount : 0);
        if (notificationTab == "all") {
          this.setState({ Allvisibility: res.data.notificationsCount });
        } else if (notificationTab == "unread") {
          this.setState({ Unreadvisibility: res.data.notificationsCount });
        } else {
          this.setState({ Importantvisibility: res.data.notificationsCount });
        }

        this.setState({ loading: false, notificationTabDef: notificationTab });
      }
    });
  };

  componentDidMount() {
    this.checkAvailabilityOfNotification(this.state.notificationTabDef);
  }

  getNotificationContentMethod = (notificationId) => {
    GetNotificationContentService({ notificationId , uId: this.props.user.id })
      .then((res) => {
        this.setState({
          NotificationContent:
            res.data.NotificationContents.NotificationContent,
        });

        this.setState({
          NotificationAttachments: res.data.NotificationContents.attachments,
        });
      })
      .catch((error) => {});

    UpdateNotificationRead({ notificationId , uId: this.props.user.id })
      .then((res) => {
        localStorage.removeItem("unreadNotificationCount");
        localStorage.setItem("unreadNotificationCount", res.data.NotificationContents.NotificationContent.readNotificationDecrement === true ? this.props.notification.notificationcount - 1 : this.props.notification.notificationcount);
        this.props.setUnreadNotificationCount(res.data.NotificationContents.NotificationContent.readNotificationDecrement === true ? this.props.notification.notificationcount - 1 : this.props.notification.notificationcount);
      })
      .catch((error) => {});
  };

  // filterMethod = (unread = "1", imp = "0", nextPage = 1) => {
  //   GetFilteredNotificationService(unread, imp, nextPage).then((res) => {
  //     // this.setState({ filteredNotification: res.data.notifications });

  //     this.setState({
  //       filteredNotification: [
  //         ...this.state.filteredNotification,
  //         res.data.notifications,
  //       ],
  //     });
  //   });
  // };

  componentWillUnmount() {
    this.mounted = false
  }

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="notification-main-wrap">
              <div className="notfication-left-wrap bg-white">
                <button
                  className="close-btn-sidebar"
                  onClick={this.handleSideBar}
                >
                  <i className="close-bar close-bar-1"></i>
                  <i className="close-bar close-bar-2"></i>
                  <i className="close-bar close-bar-3"></i>
                </button>

                <div className="notfication-panel">
                  <NotificationFilter
                    searchFilterText={this.state.searchFilterText}
                    handleSearchFilterText={this.handleSearchFilterText}
                    readStatusRowMethod={this.readStatusMethod}
                    filterRowMethod={this.filterMethod}
                    checkNotificationAvailabilityMethod={
                      this.checkAvailabilityOfNotification
                    }
                  />

                  {this.state.loading && <SkeletonLeftBoxNotification />}

                  {!this.state.loading && (
                    <NotificationLeftBox
                      // Available tab
                      AllvisibilityTab={this.state.Allvisibility}
                      UnreadvisibilityTab={this.state.Unreadvisibility}
                      ImportantvisibilityTab={this.state.Importantvisibility}
                      notificationTabDef={this.state.notificationTabDef}
                      //Content method
                      searchFilterText={this.state.searchFilterText}
                      handleSearchFilterText={this.handleSearchFilterText}
                      parentGetNotificationContent={
                        this.getNotificationContentMethod
                      }
                      parentGetMyNotification={this.getMyNotification}
                      filteredNotificationRow={this.state.filteredNotification}
                      filterRowMethod={this.filterMethod}
                      nId={this.state.nId}
                    />
                  )}
                </div>
              </div>
              <div className="notfication-right-wrap">
                <NotificationRightBox
                  notificationContent={this.state.NotificationContent}
                  notificationAttachment={this.state.NotificationAttachments}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
