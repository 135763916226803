import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFile, setUser } from "../../../store/actions";
import { UpdateResponsible } from "../../../services/ProfileService";
import Swal from "sweetalert2";
import { setPayer } from "../../../store/actions";

const ResponsibleDetails = () => {
  const [me, setMe] = useState(0);

  const user = useSelector((state) => state.user);
  const file = useSelector((state) => state.file);

  const dispatch = useDispatch();

  useEffect(() => {
    setMe(user.payer_same);
  }, []);

  const handleChange = (e) => {
    const ne = me == 0 ? 1 : 0;

    UpdateResponsible({ value: ne })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => {
          setMe(ne);
          user.payer_same = ne;
          dispatch(setUser(user));
          dispatch(setPayer(res.data.payer_details));
          dispatch(setFile(!file));
        });
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="customCheck1"
          defaultChecked={user.payer_same}
          onChange={(e) => handleChange(e)}
        />
        <label className="custom-control-label" htmlFor="customCheck1">
          I am responsible for the payment of the account.
        </label>
      </div>
    </>
  );
};

export default ResponsibleDetails;
