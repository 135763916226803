import React, { useState, useEffect } from "react";
import ProgressForm from "./re_registration_sub/ProgressForm";
import ReSubmission from "./re_registration_sub/ReSubmission";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../services/ReRegistrationService";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReRegistrationsReload } from "../../store/actions";

function SchoolOfArchitecture(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg } = props;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [continueChecked, setContinueChecked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const reload = useSelector(state => state.registrations.reload);

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([
    { subject: "" },
  ]);

  const [parentSubjectReSubLength, setparentSubjectReSubLength] = useState([
    { subject: "" },
  ]);

  const handleCheckContinue = (e) => {
    setContinueChecked(e.target.checked);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectProgress_: parentSubjectProgLength,
      subjectReSub_: parentSubjectReSubLength,
      term_condn: checked,
      // term_continue: continueChecked,
      rereg_academy: props.registration.academy,
      rereg_id: props.registration.id,
      next_year_option: '',
      late_reg : lateReg
    },
    validationSchema: Yup.object({
      // subjectProgress_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      // subjectReSub_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Select at least one"),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      // term_continue: Yup.bool().oneOf([true], "Required"),
      // next_year_option: Yup.string().required("Please select option")
    }),
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
          if(isReReg.isReReg===true){
            dispatch(setReRegistrationsReload(!reload));
            history.push("/reregStatus");
          }
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
            <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5"> Re-register Modules:</h5>
                <label>

                <b>NOTE:</b> It is mandatory to re-register for EVERY module you have failed. Failure to do so will result in a delay in your academic progress. <br/><br/>
                
                <b>Modules you did not pass and must re-register for:</b>
                </label>
                <ReSubmission
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLength}
                  parentSubjectReSubLength={parentSubjectReSubLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
              <h5 className="textdanger heading-5"> Foundation Programme Progression:</h5>
                <p>Should you have successfully completed the Foundation Program, articulation options will be available to you when the articulation period starts. </p>
              </div>              
              <div className="form-group col-sm-12 inputfield">
              {/*  <h5 className="textdanger heading-5">Progress:</h5>
                {/* <label>
                  <b>Architecture:</b> In order to be eligible to progress into
                  the second year of the National Diploma (NQF6), you need to
                  achieve 50% or more for the following core subjects:
                </label>

                <ul className="list-unstyled unstyledlist-custom">
                  <li>Architectural Design.</li>
                  <li>Construction, Technology and Detailing.</li>
                  <li>Architectural Representation and Draughting.</li>
                  <li>Computer Applications.</li>
                </ul> 

                <label>
                  <b>
                    SSD / Management of Civil Engineering Construction
                    Processes:
                  </b>{" "}
                  In order to be eligible to progress into the second year of
                  SSD or Management of Civil Engineering Construction Processes,
                  please ensure you have 65% across all your modules.
                </label>
              </div>

              <div className="col-12 form-group inputfield select-width">
                 <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_continue &&
                        formik.errors.term_continue
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_continue"
                    onChange={(e) => handleCheckContinue(e)}
                    onBlur={formik.handleBlur}
                    checked={continueChecked}
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I would like to progress to next level.
                  </label>
                  {formik.touched.term_continue &&
                    formik.errors.term_continue ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_continue}
                    </div>
                  ) : null}
                </div>
                <br /> 
                {/* <div className="">
                {/* <label>Please select an option*</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.next_year_option && formik.touched.next_year_option
                        ? " is-invalid"
                        : "")
                    }
                    name="next_year_option"
                    value={formik.values.next_year_option}
                    onChange={(value) => {
                      formik.setFieldValue("next_year_option", value.value)
                      formik.setFieldValue("next_year_option_label", value.label);
                    }}
                    onBlur={formik.handleBlur}
                    maxMenuHeight={175}
                    placeholder={formik.values.next_year_option_label ? formik.values.next_year_option_label : "Please select"}
                    options={[
                      { value: 'Progress to Civil Management Processes 2nd Year', label: 'Progress to Civil Management Processes 2nd Year' },
                      { value: 'Progress to SSD 2nd Year', label: 'Progress to SSD 2nd Year' },
                      // { value: 'Progress to Architectural Technology NQF6', label: 'Progress to Architectural Technology NQF6' },
                      // { value: 'Progress to (NEW) Higher Certificate in Architectural Technology', label: 'Progress to (NEW) Higher Certificate in Architectural Technology' }
                    ]}
                  />

                 
                </div> */}
                {(formik.errors.next_year_option &&
                  formik.touched.next_year_option) ? (
                  <span className="">
                    <p className="errorMsg">{formik.errors.next_year_option}</p>
                  </span>
                ) : null}

                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => formik.setFieldValue('term_condn', e.target.checked)}
                    onBlur={formik.handleBlur}
                    checked={formik.values.term_condn}
                    id="customCheck2"
                  />
                  <label
                    className="custom-control-label color-red"
                    htmlFor="customCheck2"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submit"
              className="btn btn-submit btn-main btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submit
            </button>
            <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfArchitecture;
