import React, { useEffect, useState } from 'react'
import ReRegLaunchForm from './ReRegLaunchForm'
import { ArticulationRegistrationList, GetReRegNotSubmitReasonsList, GetReRegistrationList } from '../../services/ReRegistrationService';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setIsReReg } from '../../store/actions';
import { useHistory } from 'react-router-dom';

const SoftAndHardLaunch = () => {
    // const [examsList, setExamsList] = useState([]);
    const [reasonDropdownData, setReasonDropdownData] = useState([]);
    const [update, setUpdate] = useState(false);    
    const dispatch = useDispatch();
    const history = useHistory();
    const examsList = useSelector(state => state.registrations.data);    
    const reload = useSelector(state => state.registrations.reload);

    useEffect(() => {
        // ArticulationRegistrationList().then(res=>{
            // console.log("______Articulation______",res);
            // const artiStatus = res?.data?.open.filter(item=>(item?.nonRegData?.status !== "Declined" && (item?.remind !== 0)));
            // if(!artiStatus.length){
                // GetReRegistrationList().then(res => {
                    // console.log("res",res?.data?.open);
                    // const respData = res?.data?.open.filter(item=>((item.reRegistrationStatus !== "Declined" && item.status!=="Submitted" && (item.remind !==0)) || item?.late_reg == 1));
                    
                    if(examsList.length){
                        const status = examsList.length > 0 ;
                        dispatch(setIsReReg(status));
                        // setExamsList(respData);
                    }else{
                        dispatch(setIsReReg(false));
                        history.push('/home')
                    }
                // })
                // .catch(error => {
                //     console.error(error);
                // });
        //     }
        // }).catch(error=>{
        //     console.error(error);
        // })
        
        GetReRegNotSubmitReasonsList()
            .then(res => {
                setReasonDropdownData(res?.data?.data.map(item=>({value:item?.id.toString(), label: item?.reason})));
            })
            .catch(error => {
                console.error(error);
            });
    }, [reload]);
    return (
        <div className="launch__wrappper border-0">
            {/* <h3>
                <i className='fal fa-clock mr-1'></i>
                <strong>Early bird discount: Get 5% off if you re-register before {examsList.length ? moment.unix(examsList[0].early_bird_end_date).format("DD MMM YY") : ""}</strong>
            </h3> */}
            {examsList.length ? examsList.map((item,ind)=><ReRegLaunchForm key={ind} data={item} reasonDropdownData={reasonDropdownData}/>) : ""}
        </div>
    )
}

export default SoftAndHardLaunch