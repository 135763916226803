import React, { useState } from 'react'
import AssignmentType from '../AssignmentType'
import AssignmentStatus from '../AssignmentStatus'
import { SystemIcons } from '../../common/SystemIcons'
import { RenderAttachList } from '../../common/RenderAttachList';
import ReactHtmlParser from "react-html-parser";
import { Link, useParams } from 'react-router-dom';

export default function AssessmentDetailTab({ data, quizId, maxAttempt, step, toolTip, user }) {
    const [open, setOpen] = useState(true);
    const [showFeedback, setShowFeedback] = useState(true);
    const [showFile, setShowFile] = useState(true);
    const steps = [6, 7];
    const { qid } = useParams()

    return (
        <div className="assessment__details__wrapper">
            <div className="custom__accordion__box">
                <div className="accordion__head d-flex align-items-center justify-content-between">
                    <div className="head__bar">
                    {data?.timeLimitActive === 1 ? 
                        <i className="fal fa-clock"></i>
                        : <i className="fal fa-ballot-check"></i>}
                        {data?.timeLimitActive === 1 ? <div className="timer">Time Limit: <span className="as-text-red">
                            {`${data?.timeHour ? (data.timeHour > 9 ? data.timeHour : "0" + data.timeHour) : "00"}:${data?.timeMin ? (data.timeMin > 9 ? data.timeMin : "0" + data.timeMin) : "00"}`}
                        </span>
                        </div> : "Assessment Information" }
                    </div>
                    <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary">
                        <i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i>
                    </button>
                </div>
                <div className={"textseries-content-blk white-brd-blk mb-4 " + (open ? "show" : "")}>
                    <div class={`row ${data.camera ? "" : "camhidden"}`}>
                        <div class="col-md-6">
                            <div class="textseries-info-details">
                                <ul>
                                    <li>
                                        <span class="heading">Type:</span>
                                        <span class="content">
                                            {data?.ass_type &&
                                                <AssignmentType ass_type={data?.ass_type ? data.ass_type : ""} />                                                
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <span class="heading">Status:</span>
                                        <span class="content">
                                            {data?.status &&
                                                <AssignmentStatus status={data?.status ? (data?.status == "Closed-Marked" && data?.notify_assessed == 0 ? "Closed-Submitted" : data?.status) : ""} />
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <span class="heading">Condition:</span>
                                        <span class="content">
                                            <span class="ts-info-act">
                                                <SystemIcons data={data} />
                                                {(step === 6 || step === 7) && <button
                                                    type="button"
                                                    class="btn btn-secondary"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title={toolTip}
                                                >
                                                    <i class="fal fa-info-circle"></i>
                                                </button>}
                                            </span>
                                        </span>
                                    </li>
                                    {data.assigendSickAssessment > 0 && <li>
                                        <span className="heading">Appeal Outcome: &nbsp;</span>
                                            <span className="content">
                                                Sick Test Issued
                                            </span>
                                    </li>}
                                    {data.assigendSickAssessment > 0 && <li>
                                        <span class="heading">Sick Test Link:</span>
                                        <span class="content">
                                            <Link type='button' className='btn btn-primary' to={`/course/${qid}/subject/${data.intake}/assessment/${data.assigendSickAssessment}`}>
                                                <i class="fas fa-eye"></i>
                                                View
                                            </Link>
                                        </span>
                                    </li>} 
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="textseries-info-details">
                                <ul>
                                    <li>
                                        <span class="heading">Open Date:</span>
                                        <span class="content">
                                            {data?.open_date_time
                                                ? data.open_date_time
                                                : ""}
                                        </span>
                                    </li>
                                    <li>
                                        <span class="heading">Close Date:</span>
                                        <span class="content">
                                            {data?.close_date_time
                                                ? data.close_date_time
                                                : ""}
                                        </span>
                                    </li>
                                    {data?.timeLimitActive === 1 && <li>
                                        <span class="heading">Time Limit:</span>
                                        <span class="content">
                                                <div class="as-text-red">
                                                    {data?.timeHour ? data.timeHour : "0"}{" "}
                                                    hours{" "}
                                                    {data?.timeMin ? data.timeMin : "0"} min
                                                </div>
                                        </span>
                                    </li>}                                                                       
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 mt-15">
                            <div className="textseries-info-details">
                                <ul>
                                    {steps.includes(step) && <li>
                                        <span className="heading">Feedback:</span>
                                        {(data.feedbackFiles && (data.feedbackFiles.length > 0)) ?
                                            <span onClick={() => setShowFeedback(!showFeedback)} className="content">
                                                List of files <i class={`fal ${showFeedback ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                                            </span>
                                            :
                                            <span className="content">
                                                -
                                            </span>
                                        }
                                    </li>}
                                    {steps.includes(step) && showFeedback && (data.feedbackFiles && (data.feedbackFiles.length > 0)) ?
                                        <div className="uploded-files-block">
                                            <div className="row">
                                                {/* {(data.returnFile !== '' && data.returnFile != null) ?
                                                    RenderAttachList({ fileName: data.returnFileName, size: data.subReturnSize, filePath: data.returnFile, showClose: false })
                                                    :
                                                    null} */}
                                                    {data.feedbackFiles && (data.feedbackFiles.length > 0) ?
                                                    data.feedbackFiles.map(i => {
                                                        i.showClose = false
                                                        return (
                                                            RenderAttachList(i)
                                                        )
                                                    }) : null}
                                            </div>
                                        </div>
                                        : null}
                                    {steps.includes(step) && <li>
                                        <span className="heading">Submission:</span>
                                        {((data.Files && (data.Files.length > 0)) || data.fileName !== "") ?
                                            <span onClick={() => setShowFile(!showFile)} className="content">
                                                List of files <i class={`fal ${showFile ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                                            </span>
                                            :
                                            <span className="content">
                                                {quizId ? 'Completed' : 'No attempt'}
                                            </span>
                                        }
                                    </li>}                                    
                                    {steps.includes(step) && showFile && ((data.Files && (data.Files.length > 0)) || data.fileName !== "") ?
                                        <div className="uploded-files-block">
                                            <div className="row">
                                                {data.Files && (data.Files.length > 0) ?
                                                    data.Files.map(i => {
                                                        i.showClose = false
                                                        return (
                                                            RenderAttachList(i)
                                                        )
                                                    })
                                                    :
                                                    data.fileName !== "" ?
                                                        RenderAttachList({ fileName: data.fileName, size: data.subFileSize, filePath: data.filePath, showClose: false })
                                                        :
                                                        null}
                                            </div>
                                        </div>
                                        : null}
                                    {quizId && <li>
                                        <span class="heading">Attempts:</span>
                                        <span class="content">
                                            <span class="ts-info-act">
                                                {maxAttempt}
                                            </span>
                                        </span>
                                    </li>}
                                    {steps.includes(step) && data?.subDetails ? <li>
                                        <span className="heading right-space">Marker's Comment: </span>
                                        <span className="content">
                                            {ReactHtmlParser(data.subDetails)}
                                        </span>
                                    </li>
                                        : null}
                                    {/* {(step === 7 && user.status === "Results Witheld") && <div className="result-ticket">
                                        <div className="result-ticket-inner d-flex justify-content-between">
                                            <div className="result-ticket-L">
                                                <h5>Result have been witheld</h5>
                                                <p>Your marks are being witheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket below.</p>
                                            </div>
                                            <div className="result-ticket-R">
                                                <b>Your Result: WITHELD</b>
                                            </div>
                                        </div>
                                        <Link to="/addnewticket" className="btn-white-bordered-ticket"><i class="far fa-ticket-alt"></i> Log A Ticket</Link>
                                    </div>} */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 mt-15">
                            <div className="textseries-info-details">
                                <ul>
                                    {data.ass_type === "Supplementary Exam" && <li>
                                        <span className="heading">Maximum Mark Achievable: &nbsp;</span>
                                        {data.maximum_grade_achievable ?
                                            <span className="content">
                                                {data.maximum_grade_achievable}
                                            </span>
                                            :
                                            <span className="content">
                                                -
                                            </span>
                                        }
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
