import React, { useState, useEffect } from "react";
import {
  getExamCenterList,
  getExamCenterDetails,
} from "../../services/ExamService";
import ReactToPdf from "react-to-pdf";
import HeaderImage from "../../../src/assets/images/header_pdf.jpg";
import FooterImage from "../../../src/assets/images/AIE-Letterhead-Footer.jpg";
import { examPdfDateFormate } from "../common/Helper";

export default function ExamCentersReadOnly(props) {
  const [examCenterDetailsPopup, setexamCenterDetailsPopup] = useState(true);
  const [examCenterDetails, setExamCenterDetails] = useState([]);
  const [examCenter, setExamCenter] = useState(false);
  const [examPeriod, setExamPeriod] = useState("");

  const { formik, examStudentReg, examdetails } = props;

  const ref = React.createRef();

  const examCenterVisiblity = (examCenterDetailsPopup) => {
    setexamCenterDetailsPopup(!examCenterDetailsPopup);
  };

  useEffect(() => {
    getExamCenterList().then((res) => {
      setExamCenter(res.data);
    });
  }, []);

  useEffect(() => {
    if(props.examCenterId){
      console.log(props.examCenterId)
      getExamCenterDetails(props.examCenterId).then((res) => {
        setExamCenterDetails(res.data);
      });

    }

    const period = "Exam_Period_" + examdetails.period.replace(" ", "_");

    setExamPeriod(period);
  }, [props.examCenterId]);

  const examCenterDetailsHandler = (e) => {
    const center = e.target.value;
    if (center !== "") {
      getExamCenterDetails(center).then((res) => {
        setExamCenterDetails(res.data);
      });
    }
  };

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };

  return (
    <>
      <div className="exam-center-wrap theme-form">
        <div className="heading-blue">Exam Centre</div>

        <div className="row align-items-end">
          <div className="col-12">
            <p>
              <small>Please choose your exam centre.</small>
            </p>
          </div>

          <div className="form-group col-10 col-sm-4 col-lg-5 m-0">
            {/* <label>EC - Buffalo City (East London)</label> */}
            <select
              onChange={(e) => examCenterDetailsHandler(e)}
              className={
                "form-control " +
                (formik.touched.centre && formik.errors.centre
                  ? " is-invalid"
                  : "")
              }
              name="centre"
              value={formik.values.centre}
              disabled="disabled"
            >
              <option value="">---</option>
              {examCenter && examCenter.length
                ? examCenter.map((element, index) => {
                    return (
                      <option key={index} value={element.id}>
                        {element.name}
                      </option>
                    );
                  })
                : null}
            </select>

            {formik.touched.centre && formik.errors.centre ? (
              <div className="invalid-feedback">{formik.errors.centre}</div>
            ) : null}
          </div>

          <div className="col-2 col-sm-1 info-circle">
            <i className="fal fa-info-circle"></i>
          </div>

          <div className="col-xl-4 col-sm-6">
            {/* <button
              type="button"
              onClick={() => examCenterVisiblity(examCenterDetailsPopup)}
              className="btn btn-success text-center btn-block exam-center-btn mt-2 mt-sm-0"
              disabled="disabled"
            >
              {examCenterDetails &&
              examCenterDetails.length &&
              examCenterDetailsPopup === true
                ? "Hide Exam Centre Details"
                : "View Exam Centre Details"}
            </button> */}
          </div>
        </div>

        {examCenterDetails &&
        examCenterDetails.length &&
        examCenterDetailsPopup === true ? (
          <div className="view-exam-center-details">
            <h4 className="small-ttl">Exam Centre Details</h4>
            <ul className="exam-centerList">
              <li className="row exam-detail-item">
                <label className="col-md-3 control-label">Centre Name :</label>
                <div className="col-md-9">
                  <p>{examCenterDetails[0].name}</p>
                </div>
              </li>
              {/* End exam-detail-item */}
              <li className="row exam-detail-item align-items-center">
                <label className="col-md-3 control-label">
                  Centre Physical Address :{" "}
                </label>
                <div className="col-md-9">
                  <p>{examCenterDetails[0].center_physical_address}</p>
                </div>
              </li>
              {/* End exam-detail-item */}
              <li className="row exam-detail-item align-items-center">
                <label className="col-md-3 control-label">
                  Contact Person:{" "}
                </label>
                <div className="col-md-9">
                  <p>{examCenterDetails[0].contact_person}</p>
                </div>
              </li>
              {/* End exam-detail-item */}
              <li className="row exam-detail-item align-items-center">
                <label className="col-md-3 control-label">
                  Contact Number:{" "}
                </label>
                <div className="col-md-9">
                  <p>{examCenterDetails[0].contact_number}</p>
                </div>
              </li>
              {/* End exam-detail-item */}
              <li className="row exam-detail-item align-items-center">
                <label className="col-md-3 control-label">
                  Centre Number:{" "}
                </label>
                <div className="col-md-9">
                  <p>{examCenterDetails[0].centre_number}</p>
                </div>
              </li>
              {/* End exam-detail-item */}
            </ul>
            <div className="exam-center-print text-md-left text-center">
              {/* PDF CODE */}
              <div
                // style={{ position: "absolute", left: "-1000px", top: 0 }}
                style={{
                  position: "absolute",
                  left: "-1000px",
                  top: 0,
                  width: "800px",
                }}
                options={options}
                ref={ref}
              >
                <table
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  width="100%"
                  style={{
                    "font-size": "12px",
                    "font-family": "Arial,sans-serif",
                    "max-width": "795px",
                  }}
                >
                  <thead>
                    <tr>
                      <td>
                        <img
                          src={HeaderImage}
                          alt=""
                          width="100%"
                          style={{ width: "100%", "max-width": "100%" }}
                        />
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          border="0"
                          width="100%"
                          style={{
                            width: "100%",
                            "max-width": "90%",
                            margin: "0 auto",
                          }}
                        >
                          <tr>
                            <td style={{ height: "20px" }}>&nbsp;</td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                align="right"
                                style={{ margin: "0", padding: "0" }}
                              >
                                {examPdfDateFormate()}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ height: "40px" }}>&nbsp;</td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                align="left"
                                style={{ margin: "0", padding: "0" }}
                              >
                                Exam Period | {examdetails.period}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ height: "40px" }}>&nbsp;</td>
                          </tr>

                          <tr>
                            <td>
                              <p
                                align="left"
                                style={{ margin: "0", padding: "0" }}
                              >
                                <b>Exam Centre Details</b>
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ height: "5px" }}>&nbsp;</td>
                          </tr>

                          <tr>
                            <td
                              style={{ height: "1px", background: "#eee" }}
                            ></td>
                          </tr>

                          <tr>
                            <td style={{ height: "20px" }}>&nbsp;</td>
                          </tr>

                          <tr>
                            <td>
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                                width="100%"
                                style={{ width: "100%", "max-width": "100%" }}
                              >
                                <tr>
                                  <td width="35%" style={{ width: "35%" }}>
                                    <p
                                      align="left"
                                      style={{ margin: "0", padding: "0" }}
                                    >
                                      <b>Centre Name :</b>
                                    </p>
                                  </td>
                                  <td width="65%" style={{ width: "65%" }}>
                                    <p style={{ margin: "0", padding: "0" }}>
                                      {examCenterDetails[0].name}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ height: "10px" }}
                                  ></td>
                                </tr>

                                <tr>
                                  <td width="35%" style={{ width: "35%" }}>
                                    <p
                                      align="left"
                                      style={{ margin: "0", padding: "0" }}
                                    >
                                      <b>Centre Physical Address:</b>
                                    </p>
                                  </td>
                                  <td width="65%" style={{ width: "65%" }}>
                                    <p style={{ margin: "0", padding: "0" }}>
                                      {
                                        examCenterDetails[0]
                                          .center_physical_address
                                      }
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ height: "10px" }}
                                  ></td>
                                </tr>

                                <tr>
                                  <td width="35%" style={{ width: "35%" }}>
                                    <p
                                      align="left"
                                      style={{ margin: "0", padding: "0" }}
                                    >
                                      <b>Contact Person:</b>
                                    </p>
                                  </td>
                                  <td width="65%" style={{ width: "65%" }}>
                                    <p style={{ margin: "0", padding: "0" }}>
                                      {examCenterDetails[0].contact_person}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ height: "10px" }}
                                  ></td>
                                </tr>

                                <tr>
                                  <td width="35%" style={{ width: "35%" }}>
                                    <p
                                      align="left"
                                      style={{ margin: "0", padding: "0" }}
                                    >
                                      <b>Contact Number: </b>
                                    </p>
                                  </td>
                                  <td width="65%" style={{ width: "65%" }}>
                                    <p style={{ margin: "0", padding: "0" }}>
                                      {examCenterDetails[0].contact_number}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ height: "10px" }}
                                  ></td>
                                </tr>

                                <tr>
                                  <td width="35%" style={{ width: "35%" }}>
                                    <p
                                      align="left"
                                      style={{ margin: "0", padding: "0" }}
                                    >
                                      <b>Centre Number:</b>
                                    </p>
                                  </td>
                                  <td width="65%" style={{ width: "65%" }}>
                                    <p style={{ margin: "0", padding: "0" }}>
                                      {examCenterDetails[0].centre_number}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{ height: "5px" }}
                                  ></td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ height: "40px" }}>&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot id="stickToFooter">
                    <tr>
                      <td>
                        <img
                          src={FooterImage}
                          alt=""
                          style={{ width: "100%", "max-width": "100%" }}
                          width="100%"
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {/* PDF CODE */}

              <ReactToPdf targetRef={ref} filename={examPeriod}>
                {({ toPdf }) => (
                  // <button onClick={toPdf}>Generate pdf</button>
                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={toPdf}
                  >
                    <i className="fal fa-print"></i> Print
                  </button>
                )}
              </ReactToPdf>
            </div>
          </div>
        ) : null}
        {/* End search-exam-center */}
      </div>
    </>
  );
}
