import React, { useEffect, useState, useRef } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import NewTicketForm from "../components/ticket/NewTicketForm";
import NewTicketList from "../components/ticket/NewTicketList";
import TicketView from "../components/ticket/TicketView";
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

const TicketPage = () => {
  const [selectedTicket, setSelectedTicket] = useState();
  const [ticketDetails, setTicketDetails] = useState({});
  const [view, setView] = useState("All");
  const [newTicket, setNewTicket] = useState(false);
  const scroll_down_bottom_parent = useRef();
  const [openForm, setOpenForm] = useState(true);

  const history = useHistory();
  const selectorState = useSelector((state) => state);
  const { user } = selectorState
  useEffect(() => {
  }, [openForm, selectedTicket])
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="wrap-box-right">
            {selectedTicket == undefined && (
              <NewTicketForm
                setNewTicket={setNewTicket}
                setSelectedTicket={setSelectedTicket}
                openForm={openForm}
                setOpenForm={setOpenForm}
                onCancel={() => history.push("/mytickets")}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketPage;
