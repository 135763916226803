import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ReactHtmlParser from "react-html-parser";

export default function ViewTextResource({show, close, data}) {
  return (

    <Modal isOpen={show} toggle={close} centered>
        <ModalHeader toggle={close}>{data?.title}</ModalHeader>
        <ModalBody>
        {ReactHtmlParser(data?.textBody)}
        </ModalBody>
    </Modal>
  )
}
