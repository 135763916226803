import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Select from "react-select";
import * as Yup from "yup";
import { GetReRegNotSubmitReasonsList, ReRegistrationRemindMeLater } from '../../services/ReRegistrationService';
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ReRegAvatar from './ReRegAvatar';
import { setReRegistrationsReload } from '../../store/actions';

function ReRegLaunchForm({data,reasonDropdownData, update, setUpdate}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const isReReg = useSelector(state=>state.isReReg);
    const [isLoading, setIsLoading] = useState(false);
    const [isRemindMeLoading, setIsRemindMeLoading] = useState(false);
    const reload = useSelector(state => state.registrations.reload);


    const initialValue = {
        reReg: true,
        reasonType: "",
        reason: "",
    }

    const ReRegLaunchSchema = Yup.object({
        reReg: Yup.boolean().required("Please check Yes/No"),
        reasonType: Yup.string().when("reReg", {
            is: false,
            then: Yup.string().required("Please select any reason type"),
        }),
        reason: Yup.string().when(["reReg", "reasonType"], {
            is: (reReg,reasonType)=>{
                return (reReg === false && (reasonType === "8" || reasonType === "4"))
            },
            then: Yup.string().required("Reason is required"),
        }),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValue,
        validationSchema: ReRegLaunchSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            if(values.reReg === true){
                history.push(`/reregistration/${data.id}`);
                setIsLoading(false);
            } else {
                const params = {
                    rereg_course_id: data.id,
                    intake_year: data.intake_year,
                    intake_number: data.intake_number,
                    academy_type: data.academy_type,
                    remind: 0,
                    submit_reason: 1,
                    not_submit_reason: values.reasonType,
                    not_submit_reason_description: values.reason,
                    rereg_id: data.reregistration_id
                };
                ReRegistrationRemindMeLater(params).then(res=>{
                    formik.resetForm();
                    // console.log("after sumbit response",res);
                    setIsLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Re-Registration reason set successfully",
                    }).then((result)=>{
                        if(result.isConfirmed){                            
                            dispatch(setReRegistrationsReload(!reload));
                            // window.location.href = "/reregStatus";
                            // history.push('/home');
                            // if(!isReReg.isReReg){
                                // history.push('/reregStatus');
                            
                        }
                    });
                }).catch(error=>{
                    console.error(error);
                    setIsLoading(false);
                });
            }
        }
    });

    const handleRemindMeLater = () => {
        setIsRemindMeLoading(true);
        let params = {
            rereg_course_id: data.id,
            intake_year: data.intake_year,
            intake_number: data.intake_number,
            academy_type: data.academy_type,
            remind: 1,
            submit_reason: 0,
        };
        if(data?.reregistration_id){
            params = {...params,rereg_id: data.reregistration_id}
        }
        ReRegistrationRemindMeLater(params).then(res=>{
            formik.resetForm();
            // console.log("after sumbit response",res);
            setIsRemindMeLoading(false);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Remind me later set successfully",
            }).then((result)=>{
                if(result.isConfirmed){
                    dispatch(setReRegistrationsReload(!reload));
                    // window.location.href = "/reregStatus";
                    // history.push('/home');
                    // if(!isReReg.isReReg){
                        // history.push('/reregStatus');
                    
                }
            });
        }).catch(error=>{
            console.error(error);
            setIsRemindMeLoading(false);
        });
    }

    const { handleBlur, handleSubmit, values, errors, touched, setFieldValue } = formik;

    return (
        <>
            <div className='launch__form'>
                <div className='card card-profile-info-card mb-4'>
                    <div className='card-body re-reg-card'>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-4">
                                <figure>
                                    <ReRegAvatar />
                                </figure>
                            </div>
                            <div className="col-md-6">
                                <form className='d-flex flex-column' noValidate onSubmit={handleSubmit}>
                                    <h3 className='mt-0'>
                                    
                                    {data?.late_reg == 1 ? 
                                    <>
                                    <i className='fal fa-calendar-exclamation mr-1'></i>
                                    <b>LATE RE-REGISTRATION IS OPEN</b>
                                    </>
                                    : (data?.early_bird_end_date && moment.unix(data?.early_bird_end_date).format("YY-MM-DD") >= moment().format("YY-MM-DD")) && 
                                    <>
                                    <i className='fal fa-clock mr-1'></i>
                                    <b>EARLY BIRD:</b> Get 5% discount if you re-register before {moment.unix(data?.early_bird_end_date).format("DD MMM YY")}
                                    </>
                                    }
                                    </h3>
                                    {data?.late_reg == 1 ? 
                                    <h4>Late re-registration is open for {data?.academy} Intake {data?.intake_number}, {data?.intake_year}</h4>                                    
                                    :
                                    <h4>Re-registration is {(!data?.hard_launch_date || moment.unix(data?.hard_launch_date).format("YY-MM-DD") > moment().format("YY-MM-DD")) ? "open" : "closing soon"} for {data?.academy} Intake {data?.intake_number}, {data?.intake_year}</h4>
                                    }
                                    
                                    <p>Please indicate your intention to re-register.</p>
                                    <span className='d-flex align-items-center'>
                                        <input id={"reReg1"+data.id} type='radio' name='reReg' checked={formik.values.reReg} onChange={(e) => setFieldValue(`reReg`, true)} />
                                        <label htmlFor={"reReg1"+data.id} className='ml-2 mb-0'>Yes, I want to re-register</label>
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <input id={"reReg2"+data.id} type='radio' name='reReg' checked={!formik.values.reReg} onChange={(e) => setFieldValue(`reReg`, false)} />
                                        <label htmlFor={"reReg2"+data.id} className='ml-2 mb-0'>No, I don't want to re-register</label>
                                    </span>
                                    {values.reReg === false && <div className="form-icon-group mb-2">
                                        <Select
                                            className={"form-control custom-select-box" + (errors.reasonType && touched.reasonType ? " is-invalid" : "")
                                            }
                                            name="reasonType"
                                            value={reasonDropdownData.filter(item => item.value === values.reasonType)}
                                            onChange={(value) => { setFieldValue("reasonType", value.value) }}
                                            onBlur={handleBlur}
                                            maxMenuHeight={175}
                                            placeholder="Select"
                                            options={reasonDropdownData}
                                        />
                                    </div>}
                                    {((values.reasonType === "8" || values.reasonType === "4") && values.reReg === false) && <div className="form-group-blk mb-2">
                                        <label>
                                            {values.reasonType === "8" ? "Reason *" : "What are you looking to study *"}
                                        </label>
                                        <textarea 
                                            rows="2" 
                                            name="reason" 
                                            value={values.reason} 
                                            onChange={(e)=>setFieldValue("reason",e.target.value)} 
                                            className={"form-control" + (errors.reason && touched.reason ? " is-invalid" : "")} 
                                            placeholder={values.reasonType === "8" ? "Explain your other reason why you chose not to re-register." : "Explain what it is you are looking to study."} 
                                        ></textarea>
                                    </div>}
                                    {/* display form errors */}
                                    {(Object.keys(errors).length && Object.keys(touched).length) ? Object.keys(errors).map(item => {
                                        return <span className="text-danger mt-1 mb-0" key={item}>{errors[item]}</span>
                                    }) : ""}
                                    <div className='d-flex align-items-center mt-3'>
                                        <button type="submit" className='btn btn-primary' disabled={isLoading}>{isLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>} Submit</button>
                                        {data?.late_reg == 0 && (!data?.hard_launch_date || moment.unix(data?.hard_launch_date).format("YY-MM-DD") > moment().format("YY-MM-DD")) && 
                                            <button type="button" className='btn btn-primary additional-btn ml-2' onClick={handleRemindMeLater} disabled={isRemindMeLoading}>
                                                {isRemindMeLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-bell-slash"></i>} 
                                                Remind Me Later
                                            </button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReRegLaunchForm;