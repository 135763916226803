import React, { useState, useEffect } from "react";
import { getExamSubjectList } from "../../services/ExamService";

export default function ExamSubjectAppend(props) {
  const [appendedSubject, setAppendedSubject] = useState(1);
  //const [items,setItems] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectLength, setSubjectLength] = useState([]);
  //const[appendSubjectNumber,setAppendSubjectNumber]=useState(0);
  const { formik } = props;

  const [fields, setFields] = useState([{ subject: "", first: "" }]);

  function handleAdd() {
    const values = [...fields];
    values.push({ subject: "", first: "" });
    setFields(values);
    props.setParentSubjectLength(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    props.setParentSubjectLength(values);
  }

  function handleSubjectChange(i, event) {
    const values = [...fields];
    values[i].subject = event.target.value;
    setFields(values);
    props.setParentSubjectLength(values);
  }

  function handleFirstChange(i, event) {
    const values = [...fields];
    values[i].first = event.target.value;
    setFields(values);
    props.setParentSubjectLength(values);
  }

  useEffect(() => {
    getExamSubjectList(props.examId).then((res) => {
      setSubjectList(res.data);
    });
  }, []);

  return (
    <>
      <div className="table-responsive instructions-table">
        <div className="d-flex add-row-head w-100">
          <div className="form-group cust_tbl_col_1 col-8 m-0">
            <label>
              <strong>Subject:</strong>
            </label>
          </div>
          <div className="form-group cust_tbl_col_2 col-3 m-0">
            <label>
              <strong>Is this your first attempt?</strong>
            </label>
          </div>

          <div className="form-group cust_tbl_col_4 col-1 text-right m-0">
            <label>&nbsp;</label>
          </div>
        </div>

        <div className="d-flex add-row-repeat w-100">
          <div className="form-group">
            <label className="color-red">
              <strong>
                Important: Term mark for selected Intake will be used
              </strong>
            </label>
          </div>
        </div>

        {/* <!-- ./ End add-row-head --> */}

        {formik.values.subject_.map((item, idx) => (
          <div className="d-flex add-row-repeat w-100" key={idx}>
            {subjectList && subjectList.length ? (
              <div className="form-group col-8 cust_tbl_col_1">
                <select
                  className="form-control"
                  //className={"form-control "+(formik.touched.subject_ && formik.errors.subject_ && formik.touched.subject_[idx].subject && formik.errors.subject_[idx].subject ?" is-invalid":"")}
                  name={`subject_[${idx}].subject`}
                  onChange={(e) => handleSubjectChange(idx, e)}
                  onBlur={formik.handleBlur}
                  key={idx}
                  value={formik.values.subject_[idx].subject}
                >
                  <option value="">---</option>
                  {subjectList.map((element, index2) => {
                    return (
                      <option
                        key={index2}
                        value={element.intake_id}
                      >{`${element.subject_name}-Intake ${element.intake_number}, ${element.intake_year}`}</option>
                    );
                  })}
                </select>
                {/* {formik.touched.subject_ && formik.errors.subject_ && formik.touched.subject_[idx].subject && formik.errors.subject_[idx].subject ?(<div className="invalid-feedback">{formik.errors.subject_[idx].subject}</div>):null} */}
                {formik.touched.subject_ &&
                formik.errors.subject_ &&
                formik.touched.subject_[idx] &&
                formik.errors.subject_[idx] &&
                formik.touched.subject_[idx].subject &&
                formik.errors.subject_[idx].subject ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {formik.errors.subject_[idx].subject}
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="form-group col-3 cust_tbl_col_2">
              <select
                //className={"form-control "+(formik.touched.subject_ && formik.errors.subject_ && formik.touched.subject_[idx].first && formik.errors.subject_[idx].first ?" is-invalid":"")}
                className="form-control"
                name={`subject_[${idx}].first`}
                onChange={(e) => handleFirstChange(idx, e)}
                onBlur={formik.handleBlur}
                key={idx}
                value={formik.values.subject_[idx].first}
              >
                <option key={`${idx}_0`} value="">
                  ---
                </option>
                <option key={`${idx}_1`} value="1">
                  Yes
                </option>
                <option key={`${idx}_2`} value="0">
                  No
                </option>
              </select>

              {/* {formik.touched.subject_ && formik.errors.subject_ && formik.touched.subject_[idx].first && formik.errors.subject_[idx].first ?(<div className="invalid-feedback">{formik.errors.subject_[idx].first}</div>):null} */}
              {formik.touched.subject_ &&
              formik.errors.subject_ &&
              formik.touched.subject_[idx] &&
              formik.errors.subject_[idx] &&
              formik.touched.subject_[idx].first &&
              formik.errors.subject_[idx].first ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subject_[idx].first}
                </div>
              ) : null}
            </div>

            <div className="form-group col-1 text-right cust_tbl_col_4">
              <a onClick={() => handleRemove(idx)} className="btn btn-danger">
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        ))}
        {/* <!-- ./ End  add-row-repeat --> */}
      </div>

      <div className="form-group col-12">
        <a
          className="btn btn-link p-0 btn-additional"
          onClick={() => handleAdd()}
        >
          <i className="fal fa-plus-circle"></i> Add additional line item
        </a>
      </div>
    </>
  );
}
