const initialState = {
    subject:{}
};

const subjectReducer = (state = initialState ,action) => {

    switch(action.type) {
        case 'SET_SUBJECT' :
            return {...state.subject,...action.payload}
        default :
            return state;
    }

}

export default subjectReducer;