import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HighSchooldDetailService } from "../../../services/ProfileService";
import Swal from "sweetalert2";

const HighSchoolDetails = () => {
  const [edit, setEdit] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: Yup.object({
      high_year: Yup.string().required("Matriculated year is required"),
      high_name: Yup.string().required("School name is required"),
    }),
    onSubmit: (values) => {
      HighSchooldDetailService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setUser(res.data));
              setEdit(true);
            }
          });
        })
        .catch((error) => {});
    },
  });

  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header">High School</div>
        <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit?"Edit":"Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>

          {edit ? (
            <div className="card-body-inr card-body-info">
              <div className="form-group">
                <label>Year Matriculated</label>
                <div className="label-selected form-control">
                  {user.high_year}
                </div>
              </div>
              <div className="form-group">
                <label>School Name</label>
                <div className="label-selected form-control">
                  {user.high_name}
                </div>
              </div>
            </div>
          ) : (
            <div className="card-body-inr">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <label>Year Matriculated *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.high_year && formik.touched.high_year
                        ? " is-invalid"
                        : "")
                    }
                    name="high_year"
                    value={formik.values.high_year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.high_year && formik.touched.high_year ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>

                <div className="form-group">
                  <label>School Name *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.high_name && formik.touched.high_name
                        ? " is-invalid"
                        : "")
                    }
                    name="high_name"
                    value={formik.values.high_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.high_name && formik.touched.high_name ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>

                <div className="form-group form-group-save-cancel">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                    Save
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    onClick={() => setEdit(true)}
                    title="Cancel"
                  >
                  <i className="fal fa-times"></i>
                    Cancel
                  </button>
                  {formik.touched.high_year && formik.errors.high_year ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.high_year}
                    </div>
                  ) : null}
                  {formik.touched.high_name && formik.errors.high_name ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.high_name}
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HighSchoolDetails;
