import React,{useEffect,useState} from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import {
  getArticle
} from "../services/StudentHubService.js";
import ReactHtmlParser from "react-html-parser";

function Article(props) {
  const [article,setArticle]= useState({});
    useEffect(() => {
      getArticle({id:props.match.params.id})
        .then((res) => {
            setArticle(res.data);
          })
          .catch((error) => {
          });
    }, [])


    useEffect(() => {
    }, [article])
    
  return (
    <>
    <Header></Header>
    <div className="content-wrapper d-flex flex-wrap">
      <SideBar></SideBar>
      <div className="sidebar-right">
        <div className="home-list-wrap">
          <div className="wlcome-user-info">
              <div className="row align-items-center">
                  <div className="col-md-6">
                  <h4 className="my-md-2 my-0">
                   {article.title}
                  </h4>
                </div>
                <div className="col-md-6 text-md-right">
                  <p className="my-2">
                    {getCurrentTimeHomePage(Date().toLocaleString())}
                  </p>
                </div>
                
                </div>
                <div className="settings-body">
                {ReactHtmlParser(article.textBody)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
              
  )
}

export default Article

