import React, { useEffect, useRef, useState } from 'react'
import { CKEditor } from 'ckeditor4-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone-uploader';
import HtmlParser from 'react-html-parser'
import { MathJax } from 'better-react-mathjax';
import { RenderAttachList } from '../../common/RenderAttachListFeed';
import { BASE_URL } from '../../../utils/Constants';
import { getRandomOrder } from '../../../utils/commonFunction';

// Match the Column
// const OptionListMatch = ({ item, index, setLeftSideColumnHeight, finalHeightArr }) => {
//     const elementRef = useRef(null);
//     useEffect(() => {
//         if (elementRef.current) {
//             const height = elementRef?.current?.offsetHeight;
//             console.log(`---------- ref ----------`, height);
//             setLeftSideColumnHeight(old=>[...old,height]);
//         }
//     }, []);
//     return item?.q_option !== "" ? (
//         <li className="d-flex list-option" ref={elementRef} style={{minHeight:`${finalHeightArr[index]}px`,alignItems:"flex-start"}}>
//             <span>
//                 {HtmlParser(
//                     `<span>${item.q_option}</span>`
//                 )}
//             </span>
//         </li>
//     ) : null;
// }

const ShowPopupQues = (props) => {
    const [choice, setchoice] = useState([]);
    const [answer, setanswer] = useState([]);
    const [singleanswer, setsingleanswer] = useState("");
    const [typeanswer, settypeanswer] = useState("");
    const [refresh, setrefresh] = useState(false);
    const [itemst, setItemst] = useState([]);
    const quesData = { ...props.data };
    const data = { ...quesData.ques };
    const removeHTMLTagsRegex = /(<([^>]+)>|&nbsp;)/gi;
    var timer = null;
    const rightInnerRefs = useRef([]);
    const leftInnerRefs = useRef([]);
    const [finalHeightArr, setFinalHeightArr] = useState([]);
    const [isRender, setIsRender] = useState(false);

    // Order List & Match the Column
    useEffect(() => {
        if (data?.question_type === "Match the Column" || data?.question_type === "Order List") {
            let tempArr = JSON.parse(JSON.stringify(data))
            let tempOption = tempArr?.options ? tempArr.options : []
            let newOptionTemp = []
            if (data.selected.length > 0) {
                data.selected.map(i => {
                    data.options.map(j => {
                        if (j.oid == i) {
                            newOptionTemp.push(j)
                        }
                    })
                })
            }
            if (newOptionTemp.length > 0) {
                tempArr.options = newOptionTemp
            }
            else {
                if (tempOption && data?.question_type === "Order List") {
                    shuffleArray(tempOption)
                }
                if (tempOption) {
                    tempArr.option = tempOption
                }
            }
            setItemst(tempArr)
            setTimeout(()=>{
                setIsRender(!isRender);
            },1000);
        }
    }, [props.data]);

    useEffect(() => {
        try {
            clearTimeout(timer);
            timer = null;
            // setanswer(question.selected);
            timer = setInterval(() => {
                if (data.qid != null) {
                    localStorage.setItem(
                        `@qa${data.qid}qa`,
                        parseInt(localStorage.getItem(`@qa${data.qid}qa`) || 0) + 1
                    );
                }
            }, 1000);
        } catch (error) {
            // setanswer(new Array());
        }

        try {
            setsingleanswer(data.selected);
        } catch (error) {
            setsingleanswer("");
        }

        try {
            settypeanswer(data.selected);
        } catch (error) {
            settypeanswer("");
        }

        try {
            setchoice(data.choice);
        } catch (error) {
            setchoice(new Array());
        }

        return () => clearInterval(timer);
    }, []);

    const refreshprops = () => {
        setrefresh(!refresh);
    };

    // Document Upload
    const DocumentUpload = ({ question }) => {
        const [filess, setFiless] = useState(question.docs);

        const getFileName = (url) => {
            const name = url?.split("/").reverse()[0];
            return name;
        }
        return (
            <div className="profile-doc-upload-blk">
                <div className="row">
                    {(filess && filess.length > 0) ? filess.map((f, i) => {
                        return (
                            <div className="col-md-4 mb-4" key={i}>
                              {RenderAttachList({ fileName: getFileName(f?.filePath), size: f.size, filePath: BASE_URL + "/" + (f?.filePath).replaceAll("/home/myaie/public_html/","").replace("public/",""), showClose: false, i })}
                            </div>
                        )                        
                    }) : null}
                </div>
                <div className="profile-doc-upload">
                    <Dropzone
                        disabled
                        classNames={{ submitButton: 'btn btn-primary', submitButtonContainer: ' btn-upload' }}
                        styles={{ dropzone: { /*minHeight: 225*/ } }}
                        accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf, .xlsx"
                        inputContent={
                            <lable>
                                <i className="fas fa-file-upload"></i>
                                <h4>
                                    To submit question, Drop your file here, or <span>browse</span>
                                </h4>
                                <p>Supports: PDF, DOCX and more</p>
                            </lable>
                        }
                        maxSizeBytes="1000MB"
                    />
                </div>
            </div>
        );
    }

    // Choice Matrix
    const ChoiceMatrix = ({ question }) => {
        const columns = question.options.length ? JSON.parse(question.options[0].q_option).map(item => item.titleValue) : [];
        const rows = question.options.length ? question.options.map(item => ({ ...item, q_option: JSON.parse(item.q_option) })) : [];
        const colWidth = { width: `${100 / (columns.length + 1)}%` };

        return (
            <table className="choice__matrix__wrapper">
                <thead>
                    <tr>
                        <th style={colWidth}></th>
                        {columns.map((col, colInd) => (<th key={colInd} style={colWidth}>{col}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rInd) => (
                        <tr key={rInd}>
                            <td style={colWidth}>{row?.titleValue}</td>
                            {columns.map((col, colInd) => (<td key={colInd} style={colWidth}>
                                <input type="radio" name={row?.oid} checked={question.selected.length ? question.selected[rInd]?.q_option[colInd]?.titleValue === question.selected[rInd]?.q_option_match?.titleValue ? true : false : false} />
                            </td>))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    // Order List & Match the Column
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = getRandomOrder(i);
            [array[i], array[j]] = [array[j], array[i]];
        }
    } 
    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 2,
        margin: `0 0 8`,
        // change background colour if dragging
        background: isDragging ? "transparent" : "transparent",
        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        padding: '0px 8px ',
    });
    // Order List & Match the Column
    const onDragEnd = (result, Arr) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(Arr,result.source.index,result.destination.index);
        let temp = { ...itemst }
        temp.options = [...items]
        setItemst({ ...temp })
    }

    // Fill in the blanks
    const FillInTheBlanks = ({ question }) => {
        const inputString = question.question.replace(removeHTMLTagsRegex, "").replaceAll("&#39;", "'");
        const numInputs = inputString.split('___').length - 1;
        const [inputValues, setInputValues] = useState(Array(numInputs).fill(''));

        const parts = inputString.split('___');

        const inputBoxes = parts.flatMap((part, index) => {
            const inputIndex = index % inputValues.length;
            if (index === parts.length - 1) {
                return <span key={index}>{part}</span>;
            } else {
                return [
                    <span key={`part-${index}`}>{part}</span>,
                    <input id={`input-${index}`} key={`input-${index}`} className="form-control" type="text" value={question.selected?.length ? question.selected[inputIndex]?.q_option_match : inputValues[inputIndex]} disabled/>
                ];
            }
        });

        const inputStringWithValues = parts.reduce((result, part, index) => {
            const inputIndex = index % inputValues.length;
            if (index === parts.length - 1) {
                return result + part;
            } else {
                return result + part + inputValues[inputIndex];
            }
        }, '');

        return (
            <div className="text-input-list">
                {inputBoxes}
                <br />
            </div>
        );
    }

    // Long Answer
    const OptionLongAnswer = () => {
        return (
            <div class="form-group">                
                <textarea
                    type="text"
                    class="form-control"
                    placeholder="Long Answer"
                    value={quesData.UserAnswer}
                    disabled
                ></textarea>
            </div>
        );
    }

    // Short Answer
    const OptionShortAnswer = () => {
        return (
            <div class="form-group">
                <textarea
                    type="text"
                    class="form-control"
                    placeholder="Short Answer"
                    value={quesData.UserAnswer}
                    disabled
                ></textarea>
            </div>
        );
    }

    // Multiple Choice
    const OptionListCheckbox = ({ item, index, question }) => {
        const data = question.selected.indexOf(item.oid+"");
        return (
            <div class="custom-control custom-checkbox mb-3">
                <input
                    class="custom-control-input"
                    name="example"
                    type="checkbox"
                    id={`customCheck_${index}`}
                    disabled
                    checked={(data !== undefined && data !== "" && data != null && data !== -1) || data === 0 ? true : false}
                />
                <label class="custom-control-label m-choice-option col-md-12" for={`customCheck_${index}`}>
                    {HtmlParser(`${item.q_option}`.replace(/<\/?[^>]+>/gi, ""))}
                </label>
            </div>
        );
    }

    // Single Choice
    const OptionList = ({item,index,question ,answer,setanswer}) => {
        var data = "";
        try {
            data = question.selected;
        } catch (error) {
            data = "";
        }
        return (
            <div class="custom-control custom-radio">
                <input
                    type="radio"
                    class="custom-control-input"
                    name="example"
                    checked={data == item.oid}
                    id={`customRadio_${index}`}
                    value={item.oid}
                    disabled
                />
                <label class="custom-control-label" for={`customRadio_${index}`}>
                    <span className={"quiz__single__ans__active__dot " + (data == item.oid ? 'active' : '')}></span>
                    <li
                        className={(data == item.oid) ? 'active-choice' : ''}
                        id={`customRadio_${index}`}
                        value={item.oid}
                    >
                        {HtmlParser(`${item.q_option}`)}
                    </li>
                </label>
            </div>
        );
    }

    useEffect(() => {
        let lInnerSidearr = [];
        if(leftInnerRefs.current.length){
            leftInnerRefs.current.forEach((ref, index) => {
                if (ref.current) {
                    const height = ref.current.offsetHeight;
                    lInnerSidearr.push(height);
                }
            });
        }

        let rInnerSidearr = [];
        if(rightInnerRefs.current.length){
            rightInnerRefs.current.forEach((ref, index) => {
                if (ref.current) {
                    const height = ref.current.offsetHeight;
                    rInnerSidearr.push(height);
                }
            });
        }
        // console.log("____________________L INNER", lInnerSidearr);
        // console.log("____________________R INNER", rInnerSidearr);
        if(lInnerSidearr.length && rInnerSidearr.length){
            setFinalHeightArr(compareArrays(lInnerSidearr, rInnerSidearr));
        }
    }, [itemst,isRender]);

    const compareArrays = (arr1, arr2) => {
        const result = [];
        for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
            const val1 = arr1[i] || Number.NEGATIVE_INFINITY;
            const val2 = arr2[i] || Number.NEGATIVE_INFINITY;
            result.push(Math.max(val1, val2));
        }
        return result;
    }

    // const removeLastBRTag = (text) => {
    //     if (text) {
    //         let splitedQues = text?.toString().split("<p>");
    //         let improvedText = "";
    //         if (splitedQues && splitedQues[splitedQues?.length - 1] === "<br />\r\n&nbsp;</p>\r\n") {
    //             splitedQues.pop()
    //             improvedText = splitedQues.map(item => {
    //                 if (item !== undefined && item !== null && item !== "") {
    //                     return "<p>" + item
    //                 }
    //             }).join("");
    //             return improvedText;
    //         } else {
    //             improvedText = splitedQues.map(item => {
    //                 if (item !== undefined && item !== null && item !== "") {
    //                     return "<p>" + item
    //                 }
    //             }).join("");
    //             return improvedText;
    //         }
    //     }
    // }

    return (
        <div>
            <div class='quiz-ques'>
                <MathJax inline dynamic>
                    {data.question_type === "Fill in the blank" ? <p>Fill in the Blanks</p> : HtmlParser((data?.question))}
                </MathJax>
            </div>
            <div class='quiz-desc'>
                <MathJax inline dynamic>
                    {HtmlParser((data?.description))}
                </MathJax>
            </div>
            <div class="radio-group-custom radion-group-center">

                {/* Document Upload */}
                {data.question_type === "Document Upload" &&
                    <DocumentUpload
                        question={data}
                        refresh={refresh}
                        setrefresh={setrefresh}
                        refreshprops={refreshprops}
                        answer={answer}
                        setanswer={setanswer}
                    />
                }

                {/* Choice Matrix */}
                {data.question_type === "Choice Matrix" &&
                    <MathJax inline dynamic>
                        <ChoiceMatrix
                            question={data}
                            refresh={refresh}
                            setrefresh={setrefresh}
                            refreshprops={refreshprops}
                            answer={answer}
                            setanswer={setanswer}
                        />
                    </MathJax>}

                {/* Order List */}
                {data.question_type === "Order List" &&
                    <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                        <Droppable droppableId={"droppable"}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="order__list__wrapper"
                                >
                                    {itemst?.options?.map((item, index) => (
                                        <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} isDragDisabled={true}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='order__list__options'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <MathJax inline dynamic>
                                                        {HtmlParser(item.q_option)}
                                                    </MathJax>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }

                {/* Fill In The Blank */}
                {data.question_type === "Fill in the blank" &&
                    <MathJax inline dynamic>
                        <FillInTheBlanks
                            question={data}
                            answer={answer}
                            setanswer={setanswer}
                            refresh={refresh}
                            setrefresh={setrefresh}
                            refreshprops={refreshprops}
                        />
                    </MathJax>
                }

                {/* Long Answer */}
                {data.question_type === "Long Answer" && (
                    <MathJax inline dynamic>
                        <OptionLongAnswer
                            question={data}
                            refreshprops={refreshprops}
                            setanswer={settypeanswer}
                            answer={typeanswer}
                        />
                    </MathJax>
                )}

                {/* Short Answer */}
                {data.question_type === "Short Answer" &&
                    data.options.map((item, index) => (
                        <MathJax inline dynamic>
                            <OptionShortAnswer
                                item={item}
                                index={index}
                                question={data}
                                refreshprops={refreshprops}
                                setanswer={setsingleanswer}
                                answer={singleanswer}
                            />
                        </MathJax>
                    ))}

                {/* Match the Column */}
                {data.question_type === "Match the Column" && (
                    <div class="select-list-inline">
                        <ul class="list-unstyled d-flex">
                            <div className='col-md-6'>
                                {data.options.map((item, index) => {
                                    leftInnerRefs.current[index] = React.createRef();
                                    return (
                                    // <OptionListMatch
                                    //     item={item}
                                    //     index={index}
                                    //     question={data}
                                    //     refreshprops={refreshprops}
                                    //     setanswer={setanswer}
                                    //     answer={answer}
                                    //     choice={choice}
                                    //     setchoice={setchoice}
                                    //     setLeftSideColumnHeight={setLeftSideColumnHeight}
                                    //     finalHeightArr={finalHeightArr}
                                    // />
                                        item?.q_option !== "" ? (
                                            <li class="d-flex list-option p-0" style={{ height: `${finalHeightArr[index]}px`, alignItems: "flex-start" }}>
                                                <span ref={leftInnerRefs.current[index]} style={{ padding: "2px 8px" }}>
                                                    <MathJax inline dynamic>
                                                        {HtmlParser(
                                                            `<span>${item.q_option}</span>`
                                                        )}
                                                    </MathJax>
                                                </span>
                                            </li>
                                        ) : null
                                )})}
                            </div>

                            <div className='col-md-6'>
                                <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                                    <Droppable droppableId={"droppable"}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {itemst?.options?.map((item, index) => (
                                                    <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} isDragDisabled={true}>
                                                        {(provided, snapshot) => {
                                                            rightInnerRefs.current[index] = React.createRef();
                                                            return (
                                                                <div>
                                                                    <div
                                                                        className='match-option'
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            ), height: `${finalHeightArr[index]}px`
                                                                        }}
                                                                    >
                                                                        <span ref={rightInnerRefs.current[index]} style={{display:"block",padding:"2px 8px"}}>
                                                                            <MathJax inline dynamic>
                                                                                {itemst.choice[index].split("___")[1]}
                                                                            </MathJax>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </ul>
                    </div>
                )}

                {/* Multiple Choice */}
                <div className="result__preview__ques">
                    {data.question_type === "Multiple Choice Multiple Answer" &&
                        data.options.map((item, index) => (
                            <MathJax inline dynamic>
                                <OptionListCheckbox
                                    item={item}
                                    index={item.oid}
                                    question={data}
                                    refreshprops={refreshprops}
                                    answer={answer}
                                    setanswer={setanswer}
                                />
                            </MathJax>
                        ))
                    }
                </div>

                {/* Single Choice */}
                <ul className="questions-quiz-option mb-0 result__preview__ques">
                    {data.question_type === "Multiple Choice Single Answer" &&
                        data.options.map((item, index) => (
                            <MathJax inline dynamic>
                                <OptionList
                                    key={index}
                                    item={item}
                                    index={item.oid}
                                    question={data}
                                    refresh={refresh}
                                    setrefresh={setrefresh}
                                    refreshprops={refreshprops}
                                    answer={singleanswer}
                                    setanswer={setsingleanswer}
                                />
                            </MathJax>
                        ))}
                </ul>
            </div>
        </div>
    )
}
export default React.memo(ShowPopupQues)