import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SaveSettings } from "../services/GeneralService";
import Swal from "sweetalert2";
import { setUserSettings,getUserSettings } from "../utils/Auth";
import { Settings } from "../utils/CustomModels";
const SettingForm = (props) => {
    let defaulView='';
    if(Settings.hasOwnProperty("calendar")){
        defaulView=Settings.calendar.defaultView;
    }
    let userSettings=getUserSettings();
    if(userSettings!==undefined && userSettings!==null){    
        if(Object.keys(userSettings).length!==0){ 
            userSettings=JSON.parse(userSettings);
            if(userSettings.hasOwnProperty("calendar")){
                defaulView=userSettings.calendar.defaultView;
            }
        }
    }
    const formik = useFormik({
      initialValues: {
        defaultView: defaulView,
    },
    validationSchema: Yup.object({
        defaultView: Yup.string().required("Password Required"),
    }),
    onSubmit: (values) => {
        let data={'calendar':{'defaultView':values.defaultView}};
        SaveSettings({data:data})
        .then((res) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Settings Saved',
              //text:'To Finalise your submission please set the status to Final.',
              showConfirmButton: true
            }).then((result) => {
                setUserSettings(JSON.stringify(data));

            });
        })
        .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Error while saving settings.",
            }).then((result) => {});
          });
    },
});

return (
    <form onSubmit={formik.handleSubmit}>
        <div className="settings-body">
            {/* <div className="form-item">
                <div className="item-label">Notification</div>
                <div className="form-item d-flex align-items-center">
                    <div className="secondary-label">Push Notification</div>
                    <div className="form-item-control ml-auto toggle-swh">
                        <label className="toggleSwitch">
                        <input type="checkbox" name="pushNotification" />
                        <span>
                            <span>Off</span>
                            <span>On</span>
                        </span>
                        <a></a>
                    </label></div>
                </div>
            </div> */}
            <div className="form-item">
                <div className="item-label">Calendar</div>
                    <div className="form-item d-flex align-items-center">
                       <div className="secondary-label">Default View</div>
                        <div className="form-item-control ml-auto">
                            <select className={
                                    "form-control selectcontrol" +
                                    (formik.errors.defaultView && formik.touched.defaultView
                                    ? " is-invalid"
                                    : "")
                                }
                                name="defaultView"
                                value={formik.values.defaultView}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}>
                                <option value="list">List View</option>
                                <option value="calendar">Calendar View</option>
                            </select>
                            {formik.touched.defaultView && formik.errors.defaultView ? (
                            <div className="invalid-feedback">
                                {formik.errors.defaultView}
                            </div>
                            ) : null}
                        </div>
                </div>
            </div>
            <div className="form-item save-setting">
                <div className="text-md-right d-flex justify-content-center justify-content-md-end">
                    <button className="btn btn-save btn-primary" title="Save" type="submit">Save</button>
                </div>
            </div>
        </div>
    </form>
        
  );
};
export default SettingForm;
