import React, { useEffect, useState } from "react";

export default function NotificationFilter(props) {
  const [filterBtnStatus, setFilterBtnStatus] = useState(0);

  const handleReadStatus = (notificationFilter, important = "0") => {
    // props.readStatusRowMethod(readStatus, important);

    props.checkNotificationAvailabilityMethod(notificationFilter);
  };

  const handleFilterNotification = (
    unread = "1",
    imp = "0",
    filterBtnStatus
  ) => {
    if (filterBtnStatus == 0) {
      setFilterBtnStatus(1);
      props.filterRowMethod(unread, imp);
    }
  };

  const handleFilterPagination = (unread = "1", imp = "0", page = "1") => {
    props.filterRowMethod(unread, imp, page);
  };

  return (
    <>
      <div className="notfication-panel-head d-flex justify-content-between align-items-center">
        <ul className="d-flex list-unstyled m-0 read-unread-tab-pill nav nav-tabs border-0">
          <li>
            <a
              data-toggle="pill"
              className="active"
              href="#all"
              onClick={() => {
                props.handleSearchFilterText("");
                handleReadStatus("all", "today");
              }}
            >
              All
            </a>
          </li>
          <li>
            <a
              data-toggle="pill"
              href="#unread"
              onClick={() => {
                props.handleSearchFilterText("");
                handleReadStatus("unread", "today");
              }}
            >
              Unread
            </a>
          </li>
          <li>
            <a
              data-toggle="pill"
              href="#important"
              onClick={() => {
                props.handleSearchFilterText("");
                handleReadStatus("important", "today");
              }}
            >
              Important
            </a>
          </li>
        </ul>
        <div class="sec-search-notfication">
          <div class="fa-ic-search">
            <i class="fal fa-search"></i>
          </div>
          <input
            value={props.searchFilterText}
            onChange={(e) => props.handleSearchFilterText(e.target.value)}
            type="search"
            class="form-control"
            placeholder="Search Subject"
          />
        </div>
        {/* <div className="sort-by">
          <div className="form-inline">
            <label>By</label>
            <select className="form-control">
              <option>Date</option>
            </select>
          </div>
        </div> */}
      </div>
    </>
  );
}
