import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function SkeletonExamLeftBox() {
  return (
    <div className="exam-detail-lft bg-white">
      <button className="close-btn-sidebar">
        <i className="close-bar close-bar-1"></i>
        <i className="close-bar close-bar-2"></i>
        <i className="close-bar close-bar-3"></i>
      </button>
      <div className="exam-lft-head">
        <div className="all-exam">
          <Link to="/myexam">
            <i className="fal fa-angle-left"></i>All Exam
          </Link>
        </div>

        <div className="exam-bx-info-top d-flex">
          <div className="icon">EP</div>
          <div className="exam-bx-info-right"></div>
        </div>
      </div>

      <div className="exam-lft-body">
        <div className="exam-list-opt">
          {Array(4)
            .fill()
            .map((item, index) => (
              <Skeleton width={280} height={60} key={index}/>
            ))}
        </div>
      </div>
    </div>
  );
}
