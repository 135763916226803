import React, { useEffect, useState } from "react";
import DataTable, { createThem } from "react-data-table-component";
import { GetMyLicensesService } from "../../services/ProfileService";
import DataTableExtensions from "react-data-table-component-extensions";

const columns = [
  {
    name: "Licenses Type",
    selector: "license_type",
    sortable: true,
  },
  {
    name: "Valid From",
    selector: "valid_from",
    sortable: true,
  },
  {
    name: "Valid Until",
    selector: "valid_until",
    sortable: true,
  },
  {
    name: "Details",
    selector: "userPrincipalName",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
  {
    name: "Expires in",
    selector: "expiredTime",
    sortable: true,
  },
];

const Licenses = (props) => {
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    GetMyLicensesService()
      .then((res) => {
        setLicenses(res.data.licenses_data);
      })
      .catch((error) => {});
  }, []);

  const tableData = {
    columns,
    data: licenses,
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-profile-info-card">
            <div className="card-header">Licenses</div>
            <div className="card-body">
              <div className="card-body-inr card-body-info">
                <div className="card card-profile-licenses-table card-table-custom">
                  <DataTableExtensions
                    {...tableData}
                    export={false}
                    print={false}
                  >
                    <DataTable
                      title=""
                      columns={columns}
                      data={licenses}
                      pagination={true}
                    />
                  </DataTableExtensions>
                </div>
                {/* <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>Licenses Type</th>
                        <th>Valid From </th>
                        <th>Valid Until </th>
                        <th>Details</th>
                        <th>Action </th>
                        <th>Expires in</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Microsoft O365 Email</td>
                        <td>13-03-2020</td>
                        <td>18-03-2020</td>
                        <td>97668@myaie.ac</td>
                        <td>
                          <i className="far fa-toggle-off active"></i>
                        </td>
                        <td>Expired</td>
                      </tr>

                      <tr>
                        <td>Adobe Suit</td>
                        <td>13-03-2020</td>
                        <td>18-03-2020</td>
                        <td>97668@myaie.ac</td>
                        <td>
                          <i className="far fa-toggle-off"></i>
                        </td>
                        <td>Expired</td>
                      </tr>
                    </tbody>
                  </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Licenses;
