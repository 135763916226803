import { WS_URL } from "../utils/Constants";
import Ws from "@adonisjs/websocket-client";
import { getToken } from "../utils/Auth";
import { reject, result } from "underscore";
export class SocketConnection {

  async connect() {  
    this.ws = await Ws(WS_URL).withApiToken(getToken()).connect();
    this.ws.on("open", () => {
      // console.log("Connection initialized");
    });
    this.ws.on("close", () => {
      // console.log("Connection closed");
    });
    return this;
  }

  subscribe(channel, handler) {

    return new Promise((resolve , reject) => {
      if (!this.ws) {
        setTimeout(() => resolve(this.subscribe(channel)), 1000);
      } else {
        let result = null;
        result = this.ws.getSubscription(channel);
        if (!result) {
          result = this.ws.subscribe(channel);
        }
        //return result;
        resolve(result)
      }
      
    })
   
  }
  close() {
    if (this.ws) {
      this.ws.close();
    }
  }
}

export default new SocketConnection();
