import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPayer } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  PayerAddressDetailService,
  countryList,
} from "../../../services/ProfileService";
import Swal from "sweetalert2";
import Select from "react-select";

const provinceOptions = [
  { value: 'Eastern-Cape', label: 'Eastern-Cape' },
  { value: 'Free-State', label: 'Free-State' },
  { value: 'Gauteng', label: 'Gauteng' },
  { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
  { value: 'Limpopo', label: 'Limpopo' },
  { value: 'Mpumalanga', label: 'Mpumalanga' },
  { value: 'Northern-Cape', label: 'Northern-Cape' },
  { value: 'North-West', label: 'North-West' },
  { value: 'Western-Cape', label: 'Western-Cape' },
]

const PayerAddressDetails = () => {
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const payer = useSelector((state) => state.payer);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  const tempPayer = {
    ph_street: "",
    ph_city: "",
    ph_province: "",
    ph_country: "",
    ph_zip: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(payer).length > 0 ? payer : tempPayer,
    // payer
    validationSchema: Yup.object({
      ph_street: Yup.string().required("Street address is required"),
      ph_city: Yup.string().required("City is required"),
      ph_province: Yup.string().required("Province is required"),
      ph_country: Yup.string().required("Country is required"),
      ph_zip: Yup.string().required("Postal Code is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      PayerAddressDetailService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setPayer(res.data));
              setEdit(true);
            }
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    countryList()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((error) => {
        setCountries([]);
      });
  }, [edit]);

  return (
    <div className="card card-profile-info-card">
      <div className="card-header">Address Details</div>
      <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
        {!user.payer_same ? (
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit ? "Edit" : "Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>
        ) : null}
        {edit ? (
          <div className="card-body-inr card-body-info">
            <div className="form-group">
              <label>Street Address</label>
              <div className="label-selected form-control">
                {payer.ph_street}{" "}
              </div>
            </div>
            <div className="form-group">
              <label>City</label>
              <div className="label-selected form-control">
                {" "}
                {payer.ph_city}
              </div>
            </div>
            <div className="form-group">
              <label>Province</label>
              <div className="label-selected form-control">
                {" "}
                {payer.ph_province}
              </div>
            </div>
            <div className="form-group">
              <label>Country</label>
              <div className="label-selected form-control">
                {payer.ph_country}
              </div>
            </div>
            <div className="form-group">
              <label>Postal Code</label>
              <div className="label-selected form-control">{payer.ph_zip}</div>
            </div>
          </div>
        ) : (
          <div className="card-body-inr">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label>Street Address *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.ph_street && formik.touched.ph_street
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_street"
                  value={formik.values.ph_street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.ph_street && formik.touched.ph_street ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label>City *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.ph_city && formik.touched.ph_city
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_city"
                  value={formik.values.ph_city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.ph_city && formik.touched.ph_city ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label>Province *</label>
                <div className="form-icon-group">
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.ph_province && formik.touched.ph_province ? " is-invalid" : "")}
                    name="ph_province"
                    value={formik.values.ph_province}
                    onChange={(value) => formik.setFieldValue("ph_province", value.value)}
                    onBlur={formik.handleBlur}
                    options={provinceOptions}
                    maxMenuHeight={175}
                    placeholder={formik.values.ph_province ? formik.values.ph_province : "Province"}
                  />
                  {formik.errors.ph_province && formik.touched.ph_province ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label>Country *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.ph_country && formik.touched.ph_country
                        ? " is-invalid"
                        : "")
                    }
                    name="ph_country"
                    value={formik.values.ph_country}
                    onChange={(value) => formik.setFieldValue("ph_country", value.value)}
                    onBlur={formik.handleBlur}
                    maxMenuHeight={175}
                    placeholder={formik.values.ph_country ? formik.values.ph_country : "Country"}
                    options={countries.map((item, key) => {
                      return (
                        { value: item.value, label: item.value }
                      );
                    })}
                  />
                  {formik.errors.ph_country && formik.touched.ph_country ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label>Postal Code *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.ph_zip && formik.touched.ph_zip
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_zip"
                  value={formik.values.ph_zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.ph_zip && formik.touched.ph_zip ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                  Save
                </button>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  onClick={() => setEdit(true)}
                  title="Cancel"
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>

                {formik.touched.ph_street && formik.errors.ph_street ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.ph_street}
                  </div>
                ) : null}

                {formik.touched.ph_city && formik.errors.ph_city ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.ph_city}
                  </div>
                ) : null}

                {formik.touched.ph_province && formik.errors.ph_province ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.ph_province}
                  </div>
                ) : null}

                {formik.touched.ph_country && formik.errors.ph_country ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.ph_country}
                  </div>
                ) : null}

                {formik.touched.ph_zip && formik.errors.ph_zip ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.ph_zip}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayerAddressDetails;
