import React, { useState, useEffect } from "react";
import ExamSummeryList from "./ExamSummeryList";
import { getMyAllExam } from "../../services/ExamService";
import SkeletonExam from "../../loaders/SkeletonExam";

export default function ExamInProgress(props) {
  const [examListsRow, setExamListRow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true
    getMyAllExam()
      .then((res) => {
        if (mounted) {
        setExamListRow(res.data.examList);
        setLoading(false);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
      }
      })
      .catch((error) => {
        console.log(error);
      });

      return function cleanup() {
        setExamListRow([]);
        setLoading(true)
        mounted = false
    }
  }, []);
  return (
    <>
      {loading && <SkeletonExam />}
      {!loading && examListsRow && examListsRow.length ? (
        <ul className="exam-ul-list list-unstyled">
          {examListsRow.map((examDetails, index) => {
            if (!examDetails.showCompleted) {
              return <ExamSummeryList key={index} exam={examDetails} />;
            } else {
              return null;
            }
          })}
        </ul>
      ) : null}
    </>
  );
}
