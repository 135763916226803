import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { submitReRegistrationService } from '../../../services/ReRegistrationService';

export default function SchoolOfGreensideReadOnly(props) {
  // reRegSubject={reRegSubject}
  //               registration={registration}
  //               subjectProgressReadOnly={subjectProgressReadOnly}
  //               subjectReWriteReadOnly={subjectReWriteReadOnly}
  //               subjectReDoReadOnly={subjectReDoReadOnly}
  //               subjectReSubReadOnly={subjectReSubReadOnly}
  const { reRegSubject, registration,regForReadOnly,repeatReadOnly, setReadOnly, setStudentNewRegId } = props;
  const [loading, setLoading] = useState(false);  
  const ReRegisterSchema = Yup.object({
    term_condn: Yup.bool().oneOf([true], "Accept Terms & Conditions is required")
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rereg_academy: registration.academy,
      rereg_id: registration.id,
      registerFor: regForReadOnly,
      repeat: repeatReadOnly?.length?repeatReadOnly.split(","):[""],
      term_condn: true
    },
    validationSchema: ReRegisterSchema,
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
        });
      });
    }
  });

  const { values, errors, touched, handleSubmit, handleBlur, setValues } = formik;

  const handleCheck = (e) => {
    setValues({
      ...values,
      term_condn: e.target.checked
    })
  }

  /* Multiple  Register For Field  start  */

  const handleAddRegFor = () => {
    const regList = [...values.registerFor];
    regList.push("");
    setValues({ ...values, registerFor: [...regList] });
  }

  const handleChangeRegisterFor = (e, idx) => {
    let RegList = [...values.registerFor];
    RegList[idx] = e.target.value;
    setValues({
      ...values,
      registerFor: [...RegList]
    });
  }

  const handleDeleteRegFor = (index) => {
    const regList = [...values.registerFor];
    regList.splice(index, 1);
    setValues({ ...values, registerFor: [...regList] });
  }

  /* Multiple  Register For Field  end  */


  /* Multiple  Repeat Field  start  */
  const handleAddRepeat = () => {
    const repeatList = [...values.repeat];
    repeatList.push("");
    setValues({ ...values, repeat: [...repeatList] });
  }

  const handleChangeRepeat = (e, idx) => {
    let RepeatList = [...values.repeat];
    RepeatList[idx] = e.target.value;
    setValues({
      ...values,
      repeat: [...RepeatList]
    });
  }

  const handleDeleteRepeat = (index) => {
    const repeatList = [...values.repeat];
    repeatList.splice(index, 1);
    setValues({ ...values, repeat: [...repeatList] });
  }
  /* Multiple  Repeat Field  end  */


  return (
    <div className="card-body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Register For:</h5>
                <label>
                Should you have passed your current level, as per your transcript results, please choose the programme you would like to register for/register for the next level of your programme.
                </label>
                <div className="form-group inputfield col-6">
                <select
                          className={"form-control " + (touched.registerFor && errors.registerFor ? " is-invalid" : "")}
                          name="register_for"
                          id="registerFor"
                          value={values.registerFor}
                          disabled
                          // onChange={(e) => { handleChangeRegisterFor(e, idx) }}
                        >
                          <option value="">---</option>
                          <option value="1" >First Year - Bachelor ofArts Degree in Interior Design</option>
                          <option value="2" >First Year - Bachelor of Arts Degree in Graphics Design</option>
                          <option value="3" >First Year - Bachelor of Arts Degree in Multimedia Design</option>
                          <option value="4">Second Year - BA Graphic Design</option>
                          <option value="5">Second Year - BA Multimedia Design</option>
                          <option value="6">Second Year - BA Interior Design</option>
                          <option value="7">Third year-Graphic Design</option>
                          <option value="8">Third year-Interior Design</option>
                          <option value="9">Third year Multimedia Design</option>
                          <option value="10">BA Honours-Interior Design</option>
                          <option value="11">BA Honours Multimedia Design</option>
                          <option value="12">BA Honours-Visual Communication</option>
                        </select>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat:</h5>
                <label>
                  Refer to your transcript to determine which modules you will need to repeat. Select all appropriate modules.
                </label>
                <div className="form-group inputfield col-12">
                  {Array.isArray(values.repeat) && values.repeat.map((element, idx) => (
                    <div className="row">
                      <div className="col-6">
                        <select
                          className={"form-control " + (touched.repeat && errors.repeat ? " is-invalid" : "")}
                          name="register_for"
                          id="repeat"
                          value={element}
                          disabled
                          // onChange={(e) => { handleChangeRepeat(e, idx) }}
                        >
                          <option value="">---</option>
                          <option value="4" >Critical Studies</option>
                          <option value="5" >Design Studio, Visual Communication and Technology and Professional Practice</option>
                          <option value="6">Minor Module</option>
                          <option value="7">Major Module</option>
                        </select>
                      </div>
                      {/* {values.repeat.length > 1 && <div className="col-2">
                        <button onClick={() => { handleDeleteRepeat(idx) }} type="button" class="btn btn-danger rounded-circle" title="Delete"><i class="fal fa-trash-alt"></i></button>
                      </div>} */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (touched.term_condn && errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={handleBlur}
                    checked={values.term_condn}
                    id="customCheck1"
                    disabled="disabled"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://designcenter.co.za/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button> */}
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}
