import React,{ useState, useEffect } from "react";
import Open from "../../assets/images/Open.png";
import Close from "../../assets/images/closed.png";
import { Link } from "react-router-dom";
import { getFormattedTimeForList, slashDateFormate } from "../common/Helper";
import $ from "jquery";
import ReactHtmlParser from "react-html-parser";

function ReRegistrationLeftBox(props) {
  const { registration, studentStatus, progressBar } = props;

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  return (
    <>
      <div className="re-registration-detail-lft bg-white">
        <button className="close-btn-sidebar" onClick={handleSideBar}>
          <i className="close-bar close-bar-1"></i>
          <i className="close-bar close-bar-2"></i>
          <i className="close-bar close-bar-3"></i>
        </button>
        <div className="re-registration-lft-head">
          <div className="all-exam">
            <Link to="/reregistration">
              <i className="fal fa-angle-left"></i>All Re-Registrations
            </Link>
          </div>

          <div className="re-registration-bx-info-top d-flex">
            <div className="icon">SA</div>
            <div className="re-registration-bx-info-right">
              <h3>
                {registration.academy2}
                <br />
                Intake {registration.intake_number} {registration.intake_year}
              </h3>
              <ul className="fa-ul">
                <li>
                  <i className="fa-li fal fa-calendar-alt"></i>
                  {registration.start_date} to {registration.end_date}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="re-registration-lft-body">
          <div className="re-registration-list-opt">
            <div className="re-registration-list-opt-card card">
              <h3>Your Status</h3>
              <ul className="fa-ul list-unstyled">
                <li>
                  {registration.per || studentStatus ? (
                    <React.Fragment>
                      <i className="fal fa-user-check"></i>Registration
                      Submitted
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fa-li fal fa-user-times"></i>Not Registered
                    </React.Fragment>
                  )}
                </li>
              </ul>
            </div>

            {registration.per || progressBar ? (
              <div className="re-registration-list-opt-card card card-progress">
                <h3>
                  Processing status:{" "}
                  {registration.status
                    ? ReactHtmlParser(registration.status)
                    : "Submitted"}
                </h3>
                <div className="progress">
                  {progressBar ? (
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: registration.per + "%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    >
                      10%
                    </div>
                  ) : (
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: registration.per }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="90"
                    >
                      {registration.per}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              // <div className="re-registration-list-opt-card card card-progress">
              //   <h3>Processing status</h3>
              //   <div className="progress">
              //     <div
              //       className="progress-bar progress-bar-striped progress-bar-animated"
              //       role="progressbar"
              //       aria-valuenow="25"
              //       aria-valuemin="0"
              //       aria-valuemax="100"
              //     ></div>
              //   </div>
              // </div>
              <div className="re-registration-list-opt-card card">
                <h3>Processing status</h3>
                <ul className="fa-ul list-unstyled">
                  <li>
                    {" "}
                    <i className="fa-li fal fa-history"></i>Pending Submission
                  </li>
                </ul>
              </div>
            )}

            <div className="re-registration-list-opt-card card">
              <h3>Registration Status</h3>
              <ul className="fa-ul list-unstyled">
                <li>
                  {registration.reg_status == "Closed" ? (
                    <React.Fragment>
                      <i className="fa-li">
                        <img src={Close} />
                      </i>
                      CLOSED
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fa-li">
                        <img src={Open} />
                      </i>
                      OPEN
                    </React.Fragment>
                  )}
                </li>
              </ul>
            </div>

            <div className="re-registration-list-opt-card card">
              <h3>Re-Registration Close Date</h3>
              <ul className="fa-ul list-unstyled">
                <li>
                  <i className="fa-li fal fa-calendar-alt"></i>
                  {/* {slashDateFormate(registration.end_date)} */
                   registration.end_date}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReRegistrationLeftBox;
