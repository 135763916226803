import React, { useState, useEffect } from "react";
import { image_url } from "../../utils/ImageUrl";

export default function TeamProfile(props) {
  const { team, profile } = props;
  
  return (
    <>
      <div className="col support-team-col">
        <div className="support-team-box">
          <div className="support-team-icon">
            <img src={image_url(team?.picture)} />
          </div>
          <div className="support-team-detail">
            <h3 className="support-team-name">
              {team && team.name && team.name}
            </h3>
            {profile == "coach" ? (
              <p className="support-team-subject">Programme Lead</p>
            ) : (
              ""
            )}
            {profile == "lecturer" ? (
              <p className="support-team-subject">Subject Lecturer</p>
            ) : (
              ""
            )}
            {profile == "support" ? (
              <p className="support-team-subject">Programme Support</p>
            ) : (
              ""
            )}
            <p className="support-team-email">
              {team && team.email && team.email ? (<a href={"mailto:"+ team.email}>{team.email}</a>) : ("")}
            </p>
            <p className="support-team-detail">
              {team && team.about && team.about}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
