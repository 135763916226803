import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { GetMyNotificationsService } from "../../../services/NotificationService";
import NotificationList from "../NotificationList";
import SkeletonLeftBoxNotification from "../../../loaders/SkeletonLeftBoxNotification";

export default function NotificationUnreadTodayFilterTabs(props) {
  const [
    todayUnreadNotificationdata,
    settodayUnreadNotificationdata,
  ] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loader, setLoader] = useState(true);
  // All

  //1 to open a tab as default
  const { defaultTab, notificationTabDef } = props;

  const [tempData, setTempData] = useState([]);
  const { activeNotification, functionalParentCompneneLvl1 } = props.functionalParentCompneneLvl2;
  const { searchFilterText, handleSearchFilterText } = functionalParentCompneneLvl1;

  const handleSelectNotification = (e, notificationId, readNotificationId) => {
    props.handleSelectNotification(e, notificationId, readNotificationId);
  };

  const getMyNotifications = async (notificationFilter, day, page = 1) => {
    setLoader(true);
    setTimeout(
      function() {
        if($('.nu-collapsed-custom-today').attr('aria-expanded') == 'true') {
          commonNotificationFunction(notificationFilter, day, page);
        }
      }.bind(this),
    500);
  };

  function commonNotificationFunction(
    notificationFilter,
    day,
    page,
    status = false
  ) {
    GetMyNotificationsService(notificationFilter, day, page).then((res) => {
      if (status) {
        settodayUnreadNotificationdata([
          ...tempData,
          res.data.notifications.rows,
        ]);
        setTempData([
          ...tempData,
          res.data.notifications.rows,
        ]);
      } else {
        settodayUnreadNotificationdata([res.data.notifications.rows]);
        setTempData([res.data.notifications.rows]);
        setLoader(false);
      }

      setCurrentPage(res.data.notifications.page);
      setLastPage(res.data.notifications.lastPage);
    });
  }

  const getMyNotificationPagination = (notificationFilter, day, page) => {
    page = page + 1;
    commonNotificationFunction(notificationFilter, day, page, true);
  };

  const filterNotifications = () => {
    var arr=[];
    tempData.map(item=>{
      item.map(i=>{
        if(i.subject && i.subject.toLowerCase().includes(searchFilterText.toLowerCase())){
          arr.push(i)
        }
      })      
    })
    settodayUnreadNotificationdata([arr]);
  }

  useEffect(() => {
    filterNotifications()
  },[searchFilterText])

  useEffect(() => {
    if (defaultTab && notificationTabDef == 'unread') {
      commonNotificationFunction("unread", "today", 1);
    }
  }, []);

  return (
    <>
      <div className="card border-0 bg-white rounded-0">
        <div className="card-header p-0  bg-white rounded-0  border-0">
          <button
            aria-expanded={defaultTab === 1 ? `true` : `false`}
            type="button"
            className="btn btn-block btn-day text-left nu-collapsed-custom-today"
            data-toggle="collapse"
            data-target="#TodayUnread"
            onClick={() => getMyNotifications("unread", "today")}
            title="Today"
          >
            Today
          </button>
        </div>
        <div className="card-body p-0  bg-white rounded-0">
          <div
            id="TodayUnread"
            className={`collapse ${defaultTab === 1 ? `show` : ``}`}
          >
            {!loader &&
            todayUnreadNotificationdata &&
            todayUnreadNotificationdata.length ? (
              <ul className="notification-list-ul list-unstyled m-0">
                {todayUnreadNotificationdata.map((notifications, index) => {
                  return notifications.map((notification, index) => {
                    return (
                      <NotificationList
                        active={activeNotification}
                        notification={notification}
                        index={notification.id}
                        handleSelectNotification={handleSelectNotification}
                        key={notification.id}
                      />
                    );
                  });
                })}

                {currentPage > lastPage - 1 ? null : (
                  <li className="see-more-li">
                    <button
                      title="See More..."
                      onClick={() =>{
                        getMyNotificationPagination(
                          "unread",
                          "today",
                          currentPage
                        )
                        handleSearchFilterText("");
                      }}
                      className="see-more-btn btn"
                    >
                      See More...
                    </button>
                  </li>
                )}
              </ul>
            ) : null}

            {loader && <SkeletonLeftBoxNotification />}
          </div>
        </div>
      </div>
    </>
  );
}
