import axiosInstance from "../utils/axios";
export const getHubResources = async (values) => {
  return await axiosInstance().get(`/getResources1`, { params: values });
};

export const getHubResourcesNew = async (values) => {
  return await axiosInstance().get(`/getResourcesNew`, { params: values });
};

// for student hub tabs
export const getStudentHubTabs = async (values) => {
  return await axiosInstance().get(`/getStudentHubTabs1`, { params: values });
};

// for accandemic resources
export const getAccademicResources = async (values) => {
  return await axiosInstance().get(`/getAcademicResources1`, { params: values });
};

export const getArticle = async (values) => {
  return await axiosInstance().get("/getArticle/", { params: values });
};

export const getAssistanceRequired = async () => {
  return await axiosInstance().post("/assistanceRequired");
};

export const getAllCampus = async () => {
  return await axiosInstance().post("/getAllCampus");
};

export const getClosestCampus = async (data) => {
  return await axiosInstance().post("/getClosetCampus",data);
};

export const addAssistanceRequest = async (data) => {
  return await axiosInstance().post("/addAssistanceRequest",data);
};

export const getAllResource = async (data) => {
  return await axiosInstance().post("/getAllResource",data);
};


export const getResourceDetails = async (param) => {
  return await axiosInstance().get("/getResourceDetails",{params:param});
};

export const addToMyLibrary = async (data) => {
  return await axiosInstance().post("/addToMyLibrary",data);
};

export const removeFromFavorite = async (data) => {
  return await axiosInstance().post("/removeFromFavorite",data);
};

export const listSupportTeamList = async (param) => {
  return await axiosInstance().get("/listSupportTeamList",{params:param});
};

export const getAssignStudentResource = async (data) => {
  return await axiosInstance().post("/getAssignStudentResource",data);
};

export const OpenedResource = async (data) => {
  return await axiosInstance().post("/openedResource",data);
};