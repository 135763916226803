import React, { useEffect, useState } from "react";
import { useHistory, Route, Redirect } from "react-router-dom";
import { getToken } from "../utils/Auth";
import { LoginCheckService } from "../services/LoginService";
import { useSelector, useDispatch } from "react-redux";
import { setIsArticulation, setIsExamReg, setIsReReg, setIsWithheld, setReRegistrations, setReRegistrationsLoading, setUser, setWidget, unsetWidget } from "../store/actions/index";
import {InActiveStudentAcess, ReRegAccess} from "../utils/CustomModels"
import { ArticulationRegistrationList, GetReRegistrationList } from "../services/ReRegistrationService";
import { GetMyOpenCloseExam } from "../services/ExamService";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const location = useHistory();
  const user = useSelector((state) => state.user);
  const registrationsReload = useSelector((state) => state.registrations.reload);
  const isWithHeldStatus = localStorage.getItem("isResultWitheld");
  useEffect(() => {
    LoginCheckService()
      .then((res) => {
        dispatch(unsetWidget());
        dispatch(setWidget({ status: 1 }));
        dispatch(setUser(res.data));
        if(res?.data?.status == "Results Witheld"){
          localStorage.setItem('resultWithHeld',JSON.stringify(true));
        } else {
          localStorage.setItem('resultWithHeld',JSON.stringify(false));
        }
        let flag=false;
        for (let i=0;i<InActiveStudentAcess.length;i++){
          if(window.location.href.includes(InActiveStudentAcess[i])){
            flag=true;
          }
        }
        if((user.status=='On Hold') && !flag) {
          location.push("/status");
        }
        if(user.status !== 'On Hold' && (res?.data?.status != "Results Witheld" || (res?.data?.status == "Results Witheld" && res?.data?.remind_me_status ==true))){
          // getting reregistration's list 
          dispatch(setReRegistrationsLoading());
          ArticulationRegistrationList().then(res1=>{
            // console.log("private______Articulation______",res);
            const artiStatus = res1?.data?.open.filter(item=>(item?.nonRegData?.status !== "Declined" && (item?.remind !== 0)));
            // console.log(">>>>>>>>>>>status>>>>>>>>>>>>>>>>>",artiStatus);
            if(artiStatus.length){
              localStorage.setItem('reRegStatus',JSON.stringify(true));
              dispatch(setIsReReg(true));
              dispatch(setReRegistrations(artiStatus))
              dispatch(setIsArticulation(true))
              location.push("/reregStatus");
            } else {              
              GetReRegistrationList()
              .then(res => {
                  // let status = false;
                  // console.log(res?.data?.open.filter(item=>(item.reRegistrationStatus !== "Declined" && item.status!=="Submitted" && (item.remind !==0))))
                  let status = res?.data?.open.filter(item=>((item.status !== "Preliminary" &&  item.status !== "In-Active" && item.status !== "Completed" && item.status !== "RCO" && item.status !== "Review" && item.status !== "Admin" && item.status !== "Cancelled" && item.status !== "Accounts" && item.status !== "Sales" && item.reRegistrationStatus != "Re-Registration Rejected" && item.reRegistrationStatus !== "Declined" && item.status!=="Submitted" && (item.remind !==0)) || item?.late_reg == 1)).length > 0 ? true : false;
                  let regData = res?.data?.open.filter(item=>((item.status !== "Preliminary" &&  item.status !== "In-Active" && item.status !== "Completed" && item.status !== "RCO" && item.status !== "Review" && item.status !== "Admin" && item.status !== "Cancelled" && item.status !== "Accounts" && item.status !== "Sales" && item.reRegistrationStatus != "Re-Registration Rejected" && item.reRegistrationStatus !== "Declined" && item.status!=="Submitted" && (item.remind !==0)) || item?.late_reg == 1));
                  dispatch(setReRegistrations(regData))
                  if(status){
                    dispatch(setIsReReg(status));                    
                    localStorage.setItem('reRegStatus',JSON.stringify(status));
                    let reRegFlag = false;
        
                    for (let i = 0; i < ReRegAccess.length; i++) {
                      if (window.location.href.includes(ReRegAccess[i])) {
                        reRegFlag = true;
                      }
                    }
                    if ((user.status !== 'On Hold' ) && status && !reRegFlag) {
                      location.push("/reregStatus");
                    }
                  }else{
                    GetMyOpenCloseExam().then((response)=>{
                      const examStatus = response?.data?.open.filter(item => item.registrationStudentStatus === "Not Registered" && (item.remind !==0)).length > 0 ? true : false;
                      if(examStatus){
                        dispatch(setIsReReg(false));
                        dispatch(setIsExamReg(examStatus))
                        localStorage.setItem('reRegStatus',JSON.stringify(false));
                        localStorage.setItem('examRegStatus',JSON.stringify(examStatus));
                        location.push("/examregistration")
                      }else{
                        dispatch(setIsReReg(false));
                        dispatch(setIsExamReg(false))                        
                      }

                    }).catch((error)=>{console.log(error)})
                  }
              })
              .catch(error => {
                console.error(error);
              }).finally(()=>{
                dispatch(setIsArticulation(false))
              });  
            }
          }).catch(error=>{
            console.error(error);
          })
        }
        if(res?.data?.status == "Results Witheld" && res?.data?.remind_me_status == false){          
          localStorage.setItem("isResultWitheld", true)
          dispatch(setIsWithheld(true))
          isWithHeldStatus && location.push("/ResultWithheld");   
        } else {
          dispatch(setIsWithheld(false))
          localStorage.setItem("isResultWitheld", false)
          // location.push("/home")  
        }
      })
      .catch((err) => {
        let response = err.response;
        if (response.status === 401) {
          location.push("/");
        }
      });

    return (()=>{
      dispatch(setReRegistrations([]))
    })
  }, [isWithHeldStatus, registrationsReload]);

  return (
    <>
      <main>
        <Route
          {...rest}
          render={(props) =>
            getToken() ? (
             <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            )
          }
        />
      </main>
    </>
  );
};

export default PrivateRoute;
