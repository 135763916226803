import React, { useState } from "react";
import { Link, } from "react-router-dom";
import BackgroundImage from "../assets/images/login-bg.jpg";
import HeaderImage from "../assets/images/card-header-public.png";
import checkBadge from "../assets/images/Successful.svg";
import uncheckBadge from "../assets/images/Unsuccessful.svg";
import { getStudentDetails } from "../services/GeneralService.js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  capitalize,
  nationalityList,
  disabilityList,
  languageList,
} from "../utils/commonFunction";

const StudentVerify = (props) => {
  const [student, setStudent] = useState({})
  const [nationalityList1, setNationalityList1] = useState(nationalityList())

  const brandConfig = useSelector((state) => state.brand);

  useEffect(() => {
    getStudentDetails(props.match.params)
      .then((res) => {
        setStudent(res.data);
      })
      .catch((error) => { });

  }, [])

  useEffect(() => {
    document.body.classList.add("std-card-body");
    return () => {
      document.body.classList.remove("std-card-body");
    };
  });

  return (
    <>
      <div className="student-card-main-block">
        <div className="container">
          <div className="student-card-info-block">
            <div className="student-card-header">
              <img
                src={brandConfig.configuration && brandConfig.configuration.login_header ? brandConfig.configuration.login_header : "javascript:void(0)"}
                alt="Background"
              />
            </div>
            <div className="student-card-detail-blk">
              <div className="student-card-detail-head">
                <h4>
                  {student.status == "Active" ? "Student Card Verification Successful " : "Student Card Verification Unsuccessful "}
                  {student.status == "Active" ? (
                    <span class="std-status check-badge">
                      <img src={checkBadge} />
                    </span>
                  ) : (
                    <span class="std-status uncheck-badge">
                      <img src={uncheckBadge} />
                    </span>
                  )
                  }
                </h4>
                {student.status == "Active" ? (
                  <p><strong>{student.title} {student.fullname}</strong> is a student of {brandConfig.configuration && brandConfig.configuration.brand_name ? brandConfig.configuration.brand_name : ""}</p>
                ) : (
                  <p><strong>{student.title} {student.fullname}</strong> is no longer a student of {brandConfig.configuration && brandConfig.configuration.brand_name ? brandConfig.configuration.brand_name : ""}</p>
                )}
              </div>
              <div className="student-card-detail-content">
                <div className="student-card-detail-infos">
                  <div className="student-card-image-blk-mobile">
                    <div className="student-card-image-blk">
                      <img src={student.photo} />
                    </div>
                    <h6>
                      The Student Card should reflect the following details
                    </h6>
                  </div>

                  <div className="student-card-image-blk">
                    <img src={student.photo && student.photo ? student.photo : ""} />
                  </div>

                  <div className="student-card-infos">
                    <h6>
                      The Student Card should reflect the following details
                    </h6>
                    <div className="student-card-info-list">
                      {/* <ul>
                        <li>
                          <h4>Title</h4>
                          <p>{student.title} </p>
                        </li>
                        <li>
                          <h4>Full Name</h4>
                          <p>{student.fullname} </p>
                        </li>
                        </ul>
                        <ul>
                        <li>
                          <h4>ID Number</h4>
                          <p>{student.number}</p>
                        </li>
                        <li>
                          <h4>Race</h4>
                          <p>{capitalize(student.race)}</p>
                        </li>
                        </ul>
                        <ul>
                        <li>
                          <h4>Gender</h4>
                          <p>{capitalize(student.gender)} </p>
                        </li>
                        <li>
                          <h4>Home Language</h4>
                          <p>{capitalize(student.language)} </p>
                        </li>
                        </ul>
                        <ul>
                        <li>
                          <h4>Date Of Birth</h4>
                          <p>{student.bday_day}{student.bday_month ? "/" : ""}{student.bday_month}{student.bday_year ? "/" : ""}{student.bday_year} </p>
                        </li>
                        <li>
                          <h4>Nationality</h4>
                          <p>
                            {`${student.nationality}` in nationalityList1 == true ? nationalityList1[student.nationality] : ''}
                          </p>
                        </li>
                      </ul> */}

                      <table className="desktop-view">
                       <tr>
                         <td>
                         <h4>Title</h4>
                          <p>{student.title} </p>
                         </td>
                         <td>
                          <h4>Full Name</h4>
                          <p>{student.fullname} </p>
                         </td>
                         <td>
                          <h4>ID Number</h4>
                          <p>{student.number}</p>
                         </td>
                         <td>
                          <h4>Race</h4>
                          <p>{capitalize(student.race)}</p>
                         </td>
                       </tr>
                       <tr>
                         <td>
                          <h4>Gender</h4>
                          <p>{capitalize(student.gender)} </p>
                         </td>
                         <td>
                          <h4>Home Language</h4>
                          <p>{capitalize(student.language)} </p>
                         </td>
                         <td>
                          <h4>Date Of Birth</h4>
                          <p>{student.bday_day}{student.bday_month ? "/" : ""}{student.bday_month}{student.bday_year ? "/" : ""}{student.bday_year} </p>
                         </td>
                         <td>
                          <h4>Nationality</h4>
                          <p>
                            {`${student.nationality}` in nationalityList1 == true ? nationalityList1[student.nationality] : ''}
                          </p>
                         </td>
                       </tr>
                      </table>

                      <table className="mobile-view">
                       <tr>
                         <td>
                         <h4>Title</h4>
                          <p>{student.title} </p>
                         </td>
                         <td>
                          <h4>Full Name</h4>
                          <p>{student.fullname} </p>
                         </td>
                         <td>
                          <h4>ID Number</h4>
                          <p>{student.number}</p>
                         </td>
                       </tr>
                       <tr>
                         <td>
                          <h4>Race</h4>
                          <p>{capitalize(student.race)}</p>
                         </td>
                         <td>
                          <h4>Gender</h4>
                          <p>{capitalize(student.gender)} </p>
                         </td>
                         <td>
                          <h4>Home Language</h4>
                          <p>{capitalize(student.language)} </p>
                         </td>
                       </tr>
                       <tr>
                         <td>
                          <h4>Date Of Birth</h4>
                          <p>{student.bday_day}{student.bday_month ? "/" : ""}{student.bday_month}{student.bday_year ? "/" : ""}{student.bday_year} </p>
                         </td>
                         <td>
                          <h4>Nationality</h4>
                          <p>
                            {`${student.nationality}` in nationalityList1 == true ? nationalityList1[student.nationality] : ''}
                          </p>
                         </td>
                       </tr>
                      </table>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default StudentVerify;