import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import _ from "underscore";
import FeedAttachments from "../feed/FeedAttachments";
import "../../assets/css/comment.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getFormattedTime,
  getStatusIcon,
  getCurrentTime,
} from "../common/Helper";
import moment from "moment";
import { TABLE_DATE_TIME_FORMAT } from "../../utils/Constants";
import Str from "../common/Str";

const FeedComments = (props) => {
  const user = useSelector((state) => state.user);

  const [showReply, setShowReply] = useState(false);
  return (
    <>
      <div
        className={
          "other-reply" +
          (props.comments.length == 1 ? " hide-comment-cnt " : "")
        }
        onClick={() => setShowReply(!showReply)}
      >
        <i
          className={
            "fal " + (showReply ? "fa-angle-double-up" : "fa-angle-double-down")
          }
        ></i>
        {props.comments.length - 1} replies from other classmates
      </div>

      {props.comments.map((item, index) => {
        return (
          <div
            className={
              index < props.comments.length - 1 && !showReply
                ? " hide-reply"
                : `show-reply ${
                    user.id == item.studentId ? "currntUser" : "otherUser"
                  }`
            }
            // className={
            //   index < props.comments.length - 1 && !showReply
            //     ? `show-reply ${
            //         user.id == item.studentId ? "currntUser" : "otherUser"
            //       }`
            //     : `show-reply ${
            //         user.id == item.studentId ? "currntUser" : "otherUser"
            //       }`
            // }
            key={item.id}
          >
            <div
              className={
                "card-reply" + (item.id === props.id ? " mycomment" : "")
              }
            >
              <div className="card-left">
                <div className="card-left-by-img 123123123123">
                  <img src={item.photo} alt={item.fullname} />
                </div>
                <div className="card-left-status">
                  <i
                    className={
                      "fas fa-check" +
                      (_.contains(props.online, item.id) ? "" : " offline")
                    }
                  ></i>
                </div>
              </div>
              <div className="card-right">
                <h5>
                  <a href="#">{item.first_name + " " + item.last_name}</a>
                  <span className="post-by-time">
                    {moment(getFormattedTime(item.created_at)).format(Str.TABLE_DATE_TIME_FORMAT)}
                  </span>
                </h5>
                <p>{ReactHtmlParser(item.message)}</p>
                {item.files && item.files.length ? (
                  <FeedAttachments attachments={item.files} />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default FeedComments;
