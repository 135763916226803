import React,{useState,useEffect} from 'react';
import ReactHtmlParser from "react-html-parser";
import SkeletonExamRightBox from '../../loaders/SkeletonExamRightBox';

export default function ExamRightBoxInformation(props){

    const [loading,setLoading]=useState(true);

    setTimeout(()=>{
        setLoading(false);
    },1000)
    const {content} = props;

    return(<>
        {loading && <SkeletonExamRightBox />}
        {!loading && (
            <div className="exam-detail-rgt">
            <div className="exam-detail-top d-flex justify-content-between">
                    <div className="exam-detail-top-left">
                        <div className="exam-bx-info  d-flex">
                                <div className="icon">
                                        ME
                                </div>
                                <div className="exam-bx-info-rgt">
                                        <h3>External Exam Registration Form</h3>
                                        <p>Register here for your DoE National Exams. Please ensure that you have completed all the required fields</p>
                                </div>
                        </div>
                    </div>
                    {/* <div className="exam-detail-top-right">
                        <a href="exam-list.html">Back</a>
                    </div>		 */}
            </div>

            <div className="exam-register-bx">
            <div className="card card-form-bx">
				<div className="card-header">
					Application Comments
				</div>

                <div className="card-body">
					<div className="theme-form application-form">
						<div className="row">
                            <div className="col-12">
                            {content !== ''?ReactHtmlParser(content):''}      
                            </div>                     
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
        )}
    </>);
}