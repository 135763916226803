const initialState = {
  quiz: new Object(),
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUIZ":
      if(action.payload[2]){
        state.quiz[action.payload[0]][action.payload[1]] = action.payload[2]
      } else {
        state.quiz[action.payload[0]] = action.payload[1]
      }
      // state.quiz[action.payload[0]] = action.payload[1];
      return { quiz: state.quiz };
    case "SET_QUIZ_COMPLETE":
      state.quiz = action.payload;
      return { quiz: state.quiz };
    case "CLEAR_QUIZ":
      state.quiz = new Object();
      return { quiz: state.quiz };
    default:
      return state;
  }
};

export default quizReducer;
