import React, { createRef, useEffect, useRef, useState } from "react";
import FeedMessage from "./FeedMessage";
import $ from "jquery";
import ReactDynamicModal from "react-draggable-resizable-modal";
import SkeletonMessageFeed from "../../loaders/SkeletonMessageFeed";
import SkeletonMessage from "../../loaders/SkeletonMessage";
import moment from "moment";
import ami_logo from "../../assets/images/ami-logo-icon.svg";
import { GetSubjectEventPageWise } from "../../services/CalendarService";
import ReactHtmlParser from "react-html-parser";
import { GetSupportTeamService, readAllCommentMessages } from "../../services/QualificationService";
import { useSelector } from "react-redux";

const FeedMessages = (props) => {
  const [videoArr, setVideoArr] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [dateArr, setDateArr] = useState([]);
  const [noOfLoader, setNoOfLoader] = useState(2);
  const [hideList, setHideList] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showListLoading, setShowListLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [classesKey, setClassesKey] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [chatFeedArr, setChatFeedArr] = useState([]);
  const [team, setTeam] = useState([]);
  const [teamObj, setTeamObj] = useState([]);

  const user = useSelector((state) => state.user);
  
  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideo("");
  };

  useEffect(() => {
    setLoader(true);
    dateMethod();
    const t = setTimeout(() => {
      setLoader(false);
    }, 1000);

    return () => {clearTimeout(t)}
    // if (props.feeds.length > 0) {
    //   setChatFeedArr([
    //     ...props.feeds
    //   ]);
    // }
  }, [props.feeds.length]);

  useEffect(() => {
    let mounted = true
    GetSupportTeamService({ sid: props.subjectId })
      .then((res) => {
        if (mounted) {
          setTeam(res.data.team);
          if (res.data.team) {
            let tempObj = Object.keys(res.data.team).map(item => {
              return {
                ...res.data.team[item], role: item
              }
            });
          setTeamObj(tempObj)
          }
        }
      })
      .catch((error) => { });

    return function cleanup() {
      mounted = false
    }
  }, []);


  const dateMethod = () => {
    let tempArr = [];
    props.feeds.map((item) => {
      let dateOnly = moment(item.updated_at, "Do MMMM YYYY, h:mm:ss a").format(
        "Do MMMM YYYY"
      );
      if (!tempArr.includes(dateOnly)) {
        tempArr.push(dateOnly);
      } else {
        tempArr.push("");
      }
    });
    setDateArr(tempArr);
  };

  const childRef = useRef(props.feeds.map((feed)=>createRef(feed.id)));
  let openId;
  const clickOutsideHandle = (event) =>{            
    const clickedElemClass = event.target.classList[0];
    let id;
    if (clickedElemClass === "reply-static") {
      id = event.target.classList[1];           
    }
    if(id!==null && id!==undefined){
      childRef.current[id].handleClickOutside(event);
      // setOpenId(id);
      openId = id;
    }else
    if(openId!==null && openId!==undefined){
      childRef.current[openId].handleClickOutside(event);
    }
  }
  useEffect(() => {
    $(document).on("click", clickOutsideHandle);
    return () => {
      $(document).off("click", clickOutsideHandle);
    };
  }, []);

  return (
    <>    
      <div className="ps-fd-card-body chat-space-lt" id="contenttwo">
        {(loader || props.loadMore) && <SkeletonMessageFeed count={noOfLoader} />}
        {props.feeds.map((feed, index) => {
          if (!feed["user"]) props.feeds[index]["user"] = feed["adminuser"];
          return (
            <FeedMessage
              ind={index}
              islastIndex={props.feeds.length - 1 == index}
              key={`${feed.id}-${feed.room_id}`}
              feed={feed}
              showDate={dateArr[index] ? true : false}
              online={props.online}
              taggingUsers={props.taggingUsers}
              setCurrentVideo={(link) => {
                setCurrentVideo(link);
                setModalOpen(true);
              }}
              updateMsg={props.updateMsg}
              setOpenReplyId={props.setOpenReplyId}
              openReplyId={props.openReplyId}
              setShowHideEdit={props.setShowHideEdit}
              showHideEditArr={props.showHideEditArr}
              setShowHidePostEdit={props.setShowHidePostEdit}
              showHidePostEdit={props.showHidePostEdit}
              resetEditor={props.resetEditor}
              deleteHandle={props.deleteHandle}
              likeDislikeHandle={props.likeDislikeHandle}
              updatePostImportant={props.updatePostImportant}
              sendClick={props.sendClick}
              deletedFile={props.deletedFile}
              reduceCount={props.reduceCount}
              hideList={hideList}
              setHideList={setHideList}
              updateReadMessages={props.updateReadMessages}
              subjectId={props.subjectId}
              teamObj={teamObj}
              ref={elem => (childRef.current[index]=elem)}
            />
          );
        })}
        <div id="content" />
      </div>

      <div className="modal-update iframe_modal_full">
        <ReactDynamicModal
          data-backdrop="static"
          initWidth={640}
          initHeight={440}
          onRequestClose={closeModal}
          isOpen={modalOpen}
          data={
            <div className="body">
              {currentVideo != "" ? (
                <iframe
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  src={`https://${currentVideo}`}
                  frameborder="0"
                ></iframe>
              ) : null}
            </div>
          }
        />
      </div>
      {props.showIcon && !props.feeds.length &&
        <div className="blank-chat-con">
          <div className="blank-chat-icon">
            <i class="fad fa-comments-alt"></i>
            <i class="fad fa-comments chat-icon-1"></i>
            <i class="fad fa-comment-dots chat-icon-2"></i>
          </div>
          <div className="blank-chat-text">
            <p>Nothing to chat about</p>
            <p>Why not start a conversation <i class="fad fa-smile-wink"></i></p>
          </div>
        </div>
      }
    </>
  );
};

export default React.memo(FeedMessages);
