import React, { useState, useEffect, Component, useMemo } from "react";
//import addNotification from "react-push-notification";
import SocketConnection from "../../utils/SocketConnection";
import { useSelector, useDispatch } from "react-redux";
import { setNotificationCount, setUnreadNotificationCount } from "../../store/actions";
import { BASE_URL, WS_URL } from "../../utils/Constants";
// const ws = require('adonis-websocket-client')
// const io = ws("http://127.0.0.1:3333", { secure: true, reconnection: true, rejectUnauthorized: false })

// let connection, typeEvent, subscribe;

export default function NotificationBadge() {
  var interval = null;
  const [subscribe, setSubscription] = useState(null);
  const dispatch = useDispatch();
  //var connection = SocketConnection.connect().then((res) => {connection = res});

  let user;

  if (JSON.parse(localStorage.getItem("user")) != null) {
    user = JSON.parse(localStorage.getItem("user")).id;
  }

  /*useEffect(() => {
    let mounted = true;
    try {
      if (JSON.parse(localStorage.getItem("user")) != null) {
        if (connection) {
          SocketConnection.subscribe(
            `notificationBadge:${user}`,
            "test1111"
          ).then((res) => {
            if (mounted) {
              setSubscription(res);
            }
          });
        }
      }
    } catch (error) {}
  }, []);*/

  useEffect(() => {
    try {
      if (subscribe) {
        subscribe.on("message", (message) => {
          //dispatch(setNotificationCount(message.totalcount));
          dispatch(setUnreadNotificationCount(message.totalcount));
          //CODE REMOVED - PUSH NOTIFICATION
          // message.notification && message.notification.length > 0 &&
          //   message.notification.map((item, index) => {
          //     setTimeout(() => {
          //       addNotification({
          //         title: item.subject,
          //         subtitle: "This is a subtitle",
          //         message: item.smallContent,
          //         theme: "darkblue",
          //         //closeButton: "Go away",
          //         onClick: (e) =>
          //           (window.location.href = `${BASE_URL}/mynotifications/${item.id}`),
          //         native: true, // when using native, your OS will handle theming.
          //       });
          //     }, index + "000");
          //   });
        });
        interval = setInterval(() => {
          if (subscribe.state === "open") {
            subscribe.emit("message", "hello world");
          } else {
            /*connection = SocketConnection.connect().then((res) => {
              SocketConnection.subscribe(
                `notificationBadge:${user}`,
                "test1111"
              ).then((res) => {
                setSubscription(res);
                // subscribe = res
              });
            });*/
            // if (connection) {
            //   SocketConnection.subscribe(
            //     `notificationBadge:${user}`,
            //     "test1111"
            //   ).then((res) => {
            //     setSubscription(res);
            //     // subscribe = res
            //   });
            // }
          }
        }, 60000);
        subscribe.emit("message", "hello world");
      }
    } catch (error) {
      //clearInterval(interval);
      //connection = SocketConnection.connect().then((res) => {connection = res});
    }
    return () => {
      //console.log("unmount");
      clearInterval(interval);
    };
  }, [subscribe]);

  return null;
}
