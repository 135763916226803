import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SubjectDetailService,
  GetSubjectDetailService,
} from "../../../services/ProfileService";
import Swal from "sweetalert2";

const SubjectDetails = () => {
  const [edit, setEdit] = useState(true);
  const [inputList, setInputList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    SubjectDetailService(inputList)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => {
          if (result.value) {
            const len = 10;
            const emptyList = new Array();
            setInputList([{}]);
            for (let index = 0; index < len - res.data.length; index++) {
              //setInputList({subject:"",mark:""})
              //console.log(index)
              emptyList.push({ subject: "", mark: "" });
            }

            const list = [...res.data, ...emptyList];
            setInputList(list);
            setSubjectList(res.data);
            setEdit(true);
          }
        });
      })
      .catch((error) => {});
  };
  useEffect(() => {
    GetSubjectDetailService()
      .then((res) => {
        const len = 10;
        const emptyList = new Array();
        for (let index = 0; index < len - res.data.length; index++) {
          //setInputList({subject:"",mark:""})
          emptyList.push({ subject: "", mark: "" });
        }

        const list = [...inputList, ...res.data, ...emptyList];
        setInputList(list);
        setSubjectList(res.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header">Subject Details</div>
        <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit?"Edit":"Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>
          {edit ? (
            <div className="card-body-inr card-body-info">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th style={{ width: "50%" }}>Subject</th>
                      <th style={{ width: "50%" }}>Symbol(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjectList.map((sub) => {
                      return (
                        <tr key={sub.id}>
                          <td>
                            <div className="form-group m-0">
                              <div className="label-selected form-control">
                                {sub.subject}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="form-group m-0">
                              <div className="label-selected form-control">
                                {sub.mark}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="card-body-inr">
              <form onSubmit={handleSubmit}>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th style={{ width: "50%" }}>Subject</th>
                        <th style={{ width: "50%" }}>Symbol(%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="form-group m-0">
                                <input
                                  type="text"
                                  name="subject"
                                  className="form-control"
                                  value={x.subject}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group m-0">
                                <input
                                  type="text"
                                  name="mark"
                                  className="form-control"
                                  value={x.mark}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="form-group form-group-save-cancel">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                    Save
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    onClick={() => setEdit(true)}
                    title="Cancel"
                  >
                    <i className="fal fa-times"></i>
                   Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubjectDetails;
