import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResponsibleDetails from "./blocks/ResponsibleDetails";
import PayerAboutDetails from "./blocks/PayerAboutDetails";
import PayerAddressDetails from "./blocks/PayerAddressDetails";
import PayerContactDetails from "./blocks/PayerContactDetails";
import { GetPayerDetailService } from "../../services/ProfileService";
import { setPayer } from "../../store/actions";
import PersonalDocuments from "./blocks/PersonalDocuments";
const SponsorPayer = () => {
  const payer = useSelector((state) => state.payer);
  const dispatch = useDispatch();

  useEffect(() => {
    GetPayerDetailService()
      .then((res) => {
        dispatch(setPayer(res.data[0]));
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 mt-4">
          <ResponsibleDetails />
        </div>
        <div className="col-lg-4">
          <PayerAboutDetails />
        </div>
        <div className="col-lg-4">
          <PayerAddressDetails />
          <PersonalDocuments
            docType={payer.payer_same ? "personal_doc" : "personal_doc_payer"}
            heading={"Your Personal Documents"}
          />
        </div>
        <div className="col-lg-4">
          <PayerContactDetails />
        </div>
      </div>
    </>
  );
};

export default SponsorPayer;
