const initialState = {
    reRegCount: 0,
  };
  
  const reRegistrationCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_RE_REGISTRATION_COUNT":
        state.reRegCount = action.payload;
        return {
            reRegCount: state.reRegCount,
        };
    
      default:
        return state;
    }
  };
  
  export default reRegistrationCountReducer;