import React, { useState, useEffect } from "react";
import ProgressForm from "./re_registration_sub_read_only/ProgressFormReadOnly";
import RedoSubjectReadOnly from "./re_registration_sub_read_only/RedoSubjectReadOnly";
import ReWriteExamReadOnly from "./re_registration_sub_read_only/ReWriteExamReadOnly";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../../services/ReRegistrationService";
function SchoolOfDraughting(props) {
  const {
    reRegSubject,
    registration,
    subjectProgressReadOnly,
    subjectReWriteReadOnly,
    subjectReDoReadOnly,
    subjectReSubReadOnly,
    delivery_methode
  } = props;

  const [parentSubjectRedoLength, setParentSubjectRedoLength] = useState([
    { subject: "" },
  ]);
  const [parentSubjectReWriteLength, setparentSubjectReWriteLength] = useState([
    { subject: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [continueChecked, setContinueChecked] = useState(true);

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleCheckContinue = (e) => {
    setContinueChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectRedo_: subjectReDoReadOnly,
      subjectReWrite_: subjectReWriteReadOnly,
      term_condn: checked,
      term_continue: continueChecked,
      next_year_option: registration.next_year_option,
    },
    validationSchema: Yup.object({
      subjectRedo_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      subjectReWrite_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      term_continue: Yup.bool().oneOf([true], "Required"),
    }),
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You'r Registration have been submited succefully",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Rewrite Internal Exam Only (optional, if applicable):
                </h5>
                <label>
                  Some students may re-write internal exams. This is advised if
                  the assessments were passed, but the year mark reflects below
                  50% due to a failed exam. Select subjects for internal exams
                  to be rewritten:
                </label>

                <ReWriteExamReadOnly
                  formik={formik}
                  setparentSubjectReWriteLength={setparentSubjectReWriteLength}
                  parentSubjectReWriteLength={parentSubjectReWriteLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Redo (optional, if applicable):
                </h5>
                <label>
                  Select subjects you want to repeat, as term mark is either
                  below 50% or student would like to attempt it again. If you do
                  not need/want to repeat any subjects you may leave this field
                  blank.
                </label>

                <RedoSubjectReadOnly
                  formik={formik}
                  setParentSubjectRedoLength={setParentSubjectRedoLength}
                  parentSubjectRedoLength={parentSubjectRedoLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Progress:
                </h5>
                <label>
                <b>SSD</b> In order to be eligible to progress into the second year of SSD please ensure you have 65% across all your modules
                </label>
                <div className="row">
                  <div className="col-sm-6 form-group inputfield">
                    <select
                      className={
                        "form-control "
                      }
                      name="next_year_option"                      
                      value={formik.values.next_year_option}
                      disabled
                    >
                      <option value="">---</option>
                      <option value="Progress to SSD 2nd Year">Progress to SSD 2nd Year</option>                    
                    </select>
                    {formik.touched.next_year_option &&
                        formik.errors.next_year_option ? (
                          <div className="invalid-feedback">
                            {formik.errors.next_year_option}
                          </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Learning Method (only applies to Redo subjects)</h5>
                <label>
                Select the relevant learning method that you would like to proceed with for the Redo subjects only.
Kindly note that the internal rewrites are offered strictly via the Online learning method.
                </label>
              </div> 
              <div className="col-6 form-group inputfield">
                <select
                  className="form-control"
                  name={delivery_methode}
                  disabled="disabled"
                >
                  <option value={delivery_methode}>
                    {(() => {
  
  switch (delivery_methode) {
     case 'online':
         return (
          'Online'
         )
     case 'full-time':
         return (
           'Full Time'
         )
     case 'fulltimeonline':
         return (
           'Full Time, Online'
         )
      case 'fulltimecontact':
      return (
        'Full Time, Contact'
      )
      case 'parttimeonline':
        return (
          'Part-Time, Online'
        )
     default:
         return (
           'Full Time'
         )
  }

})()}

                    </option>

                </select>
              </div>
              {/* <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Progress From:</h5>
              </div> */}

              <div className="col-12 form-group inputfield">
                {/* <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_continue &&
                      formik.errors.term_continue
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_continue"
                    onChange={(e) => handleCheckContinue(e)}
                    onBlur={formik.handleBlur}
                    checked={continueChecked}
                    id="customCheck1"
                    disabled="disabled"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I would like to continue to MDDOP N5.
                  </label>

                  {formik.touched.term_continue &&
                  formik.errors.term_continue ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_continue}
                    </div>
                  ) : null}
                </div>
                <br /> */}
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck2"
                    disabled="disabled"
                  />
                  <label
                    className="custom-control-label color-red"
                    htmlFor="customCheck2"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button
              className="btn btn-submit btn-main btn-danger ml-2"
              disabled="disabled"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfDraughting;
