import React from "react";
import HighSchoolDetails from "./blocks/HighSchoolDetails";
import SubjectDetails from "./blocks/SubjectDetails";
import QualificationDetails from "./blocks/QualificationDetails";
import PersonalDocuments from "./blocks/PersonalDocuments";

const EducationalDetails = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <HighSchoolDetails />
        </div>
        <div className="col-lg-4">
          <SubjectDetails />
        </div>
        <div className="col-lg-4">
          <PersonalDocuments
            docType={"metric_certificate"}
            heading={"Your Matric Certificate"}
          />
        </div>

        <div className="col-12">
          <QualificationDetails />
        </div>
      </div>
    </>
  );
};

export default EducationalDetails;
