import React, { useEffect, useState } from "react";
import $ from "jquery";
import DataTable, { createTheme } from "react-data-table-component";
import { GetMyTicketsService } from "../../services/TicketService";
import { useHistory } from "react-router-dom";
import { getFormattedTime, getStatusIcon } from "./../common/Helper";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Scrollbar } from "react-scrollbars-custom";
import ReactTooltip from "react-tooltip";
import { Link,useParams } from "react-router-dom";

const StyledLi = styled.li`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  &.selected {
    border-bottom: 1px blue solid;
  }
`;

export default function TicketList(props) {
  //   const [tickets, setTickets] = useState([]);
  const [apiStatus, setApiStatus] = useState("loading");
  const [page, setPage] = useState({
    All: 1,
    pendingAcademy: 1,
    pendingStudent: 1,
  });
  // const [ticketDetailed, setTicketDetailed] = useState([]);
  const [lastPage, setLastPage] = useState({});
  const [pageClicked, setPageClicked] = useState(true);
  const [ticketState, setTicketState] = useState("All");

  const {
    selectedTicket,
    setSelectedTicket,
    ticketDetails,
    setTicketDetails,
  } = props;
  const history = useHistory();

  const params = useParams();
  
  useEffect(() => {

   let componentMounted = true
  setSelectedTicket(params.ticketId);

    if (
      ticketDetails[ticketState] == undefined ||
      pageClicked ||
      props.newTicket
    ) {
      setPageClicked(false);
      if (props.newTicket) {
        setPage((pages) => ({
          ...pages,
          [ticketState]: 1,
        }));
      }
      let pageNumber = props.newTicket ? 1 : page[ticketState];
      GetMyTicketsService(history, ticketState, pageNumber)
        .then((res) => {
          if (componentMounted) {
          const ticks = res.data.tickets.ticketsList;
          if (ticketDetails[ticketState] == undefined || props.newTicket) {
            setTicketDetails((tickets) => ({
              ...tickets,
              [ticketState]: ticks,
            }));
            setLastPage((pages) => ({
              ...pages,
              [ticketState]: res.data.tickets.lastPage,
            }));
          } else
            setTicketDetails((tickets) => ({
              ...tickets,
              [ticketState]: [...tickets[ticketState], ...ticks],
            }));
          setApiStatus("success");
          }
        })
        .catch((error) => {
          setApiStatus("error");
        });

      props.setNewTicket(false);



    return function cleanup() {
      componentMounted = false
  }
    }
  }, [ticketState, page, props.newTicket]);

  const handleTicketState = (state) => {
    setTicketState(state);
    ticketDetails[state] == undefined && setApiStatus("loading");
  };

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  const handleTicketAllState=(state) => {
    setTicketState(state);
    ticketDetails[state] == undefined && setApiStatus("loading");
  };

  return (
    <div className="Tickets-main-wrap">
      <div className="Tickets-left-wrap bg-white">
        <button className="close-btn-sidebar" onClick={() => handleSideBar()}>
          <i className="close-bar close-bar-1"></i>
          <i className="close-bar close-bar-2"></i>
          <i className="close-bar close-bar-3"></i>
        </button>
        {apiStatus == "success" ? (
          <div className="Tickets-panel">
            <div className="Tickets-panel-head d-flex justify-content-between align-items-center">
              <ul className="d-flex list-unstyled m-0 read-unread-tab-pill nav nav-tabs border-0">
                <StyledLi
                  className={ticketState == "All" ? "selected" : ""}
                  onClick={() => handleTicketAllState("All")}
                >
                  All
                </StyledLi>
                <StyledLi
                  className={ticketState == "pendingStudent" ? "selected" : ""}
                  onClick={() => handleTicketState("pendingStudent")}
                >
                  Pending Student
                </StyledLi>
                <StyledLi
                  className={ticketState == "pendingAcademy" ? "selected" : ""}
                  onClick={() => handleTicketState("pendingAcademy")}
                >
                  Pending Academy
                </StyledLi>


               
              </ul>
            </div>
            <div className="Tickets-body">
              <Scrollbar>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <ul className="Tickets-list-ul list-unstyled m-0">
                      {ticketDetails[ticketState].map((tick,indexKey) => {
                        let category_id = "category_" + tick.id_ticket;
                        let department_id = "department_" + tick.id_ticket;
                        let attachment_id = "attachment_" + tick.id_ticket;
                        return (
                         
                          <li
                            key={indexKey}
                            onClick={() => setSelectedTicket(tick.id_ticket)}
                          >
                            <div
                              className={`card Tickets-card Tickets-card-v-2 ${
                                tick.id_ticket == selectedTicket
                                  ? "backgroundColor Tickets-active"
                                  : "Tickets-read"
                              }`}
                            ><Link
                            className="tickets-view-link"
                            to={`/mytickets/${tick.id_ticket}`}
                          ></Link>
                              <div className="card-left">
                                <h4>{tick.subject}</h4>
                                <div className="last-update">
                                  <div className="TCAI-bx">
                                    <div className="TCAI-bx-lft">
                                      <i className="fal fa-calendar-alt"></i>
                                    </div>
                                    <div className="TCAI-bx-right">
                                      <p>{getFormattedTime(tick.date_add)}</p>
                                    </div>
                                  </div>
                                </div>
                                <p>{ReactHtmlParser(tick.message.replace(/<\/?[^>]+(>|$)/g, " "))}</p>
                              </div>
                              <div className="card-right">
                                <ul className=" d-flex list-unstyled  Tickets-include-items">
                                  <li className="ticket-number">
                                    <div className="TCAI-bx">
                                      <div className="TCAI-bx-right">
                                        <p>#{tick.id_ticket}</p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="ticket-card-extra-info w-100 d-flex align-items-center">
                                <div className="ticket-status align-items-center mr-auto">
                                  <div className="TCAI-bx">
                                    <div className="TCAI-bx-lft">
                                      <i className="">
                                        <img
                                          src={getStatusIcon(tick.status)}
                                          className="mCS_img_loaded"
                                        />
                                      </i>
                                    </div>
                                    <div className="TCAI-bx-right">
                                      <p>{tick.status}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="ticket-Department">
                                  <a data-tip data-for={department_id}>
                                    <i className="fal fa-user"></i>
                                  </a>
                                  <ReactTooltip
                                    id={department_id}
                                    place="top"
                                    effect="solid"
                                  >
                                    {tick.department}
                                  </ReactTooltip>
                                </div>
                                <div className="ticket-Category">
                                  <a data-tip data-for={category_id}>
                                    <i className="fal fa-th-large"></i>
                                  </a>
                                  <ReactTooltip
                                    id={category_id}
                                    place="top"
                                    effect="solid"
                                  >
                                    {tick.category}
                                  </ReactTooltip>
                                </div>

                                {tick.filename > 0 ? (
                                  <div className="attachment">
                                    <a data-tip data-for={attachment_id}>
                                      <i className="fal fa-paperclip"></i>
                                    </a>
                                    <ReactTooltip
                                      id={attachment_id}
                                      place="top"
                                      effect="solid"
                                    >
                                      Attachment
                                    </ReactTooltip>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                      {page[ticketState] < lastPage[ticketState] && (
                        <li className="see-more-li">
                          <button
                            className="see-more-btn btn"
                            onClick={() => {
                              setPage((pages) => ({
                                ...pages,
                                [ticketState]: pages[ticketState] + 1,
                              }));
                              setPageClicked(true);
                            }}
                          >
                            See More...
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>
        ) : (
          <SkeletonTicketList />
        )}
      </div>
    </div>
  
  );
}
