const initialState = {
    isArticulation: false,
  };
  
  const isArticulationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_IS_ARTICULATION":        
        return { 
          ...state,
          isArticulation: action.payload
        };
      default:
        return state;
    }
  };
  
  export default isArticulationReducer;
  