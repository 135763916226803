import React, { useState, useEffect } from "react";
import {
  QualificationDetailService,
  GetQualificaitonDetailService,
  RemoveMyHighQualificationService,
  RemoveMyHighQualificationCertificateService,
  moveFileToDbService,
  ProfileCertificateService,
} from "../../../services/ProfileService";
import Swal from "sweetalert2";
import DownloadCertificate from "./DownloadCertificate";

const QualificationDetails = () => {
  const [edit, setEdit] = useState(true);
  const [inputList, setInputList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [uploadFileName, setUploadFileName] = useState([]);

  // let uploadFileName=[];
  useEffect(() => {
    service();
  }, []);

  const checkFileExistsOrNot = (qualificationData) => {
    if (qualificationData != undefined) {
      if (qualificationData.files.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  const handleFileChange = (e, id) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("certificate", e.target.files[0]);
      formInputs.append("rowId", id);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileCertificateService(formInputs)
        .then((res) => {
          Swal.close();

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            const len = 4;
            const emptyList = new Array();
            // setInputList([])
            for (let index = 0; index < len - res.data.length; index++) {
              emptyList.push({
                id: 0,
                qualification: "",
                place: "",
                year: "",
                files: [],
              });
            }
            const list = [...res.data, ...emptyList];
            setInputList(list);
            setQualificationList(res.data);
            setFileName("");
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => {});
        });
    }
  };

  const service = () => {
    GetQualificaitonDetailService()
      .then((res) => {
        const len = 4;
        inputList.length = 0;
        const emptyList = new Array();
        for (let index = 0; index < len - res.data.length; index++) {
          emptyList.push({
            id: 0,
            qualification: "",
            place: "",
            year: "",
            certificate: "",
            files: [],
          });
        }

        const list = [...inputList, ...res.data, ...emptyList];
        setInputList(list);
        setQualificationList(res.data);
      })
      .catch((error) => {});
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const list = [...inputList];
    if (e.target.files) {
      const { name } = e.target;
      const value = e.target.files[0];
      list[index][name] = value;
      const formInputs = new FormData();
      formInputs.append("certificate", value);
      formInputs.append("index_value", index);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      moveFileToDbService(formInputs)
        .then((res) => {
          setTimeout(() => {
            Swal.close();

            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            }).then((result) => {
              if (uploadFileName[index] == undefined) {
                uploadFileName[res.data.indexValue.index_value] = res.data.data;
                setUploadFileName(uploadFileName);
              } else {
                uploadFileName.splice(index, 1, res.data.data);
                uploadFileName[res.data.indexValue.index_value] = res.data.data;
                setUploadFileName(uploadFileName);
              }
            });
          }, 500);
        })
        .catch((error) => {});
    } else {
      const { name, value } = e.target;
      list[index][name] = value;
    }

    setInputList(list);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    inputList.map((inpt, index) => {
      data.append("qualification[]", inpt["qualification"]);
      data.append("place[]", inpt["place"]);
      data.append("year[]", inpt["year"]);
      data.append("id[]", inpt["id"]);
      data.append("certificate[]", uploadFileName[index]);
    });

    // return false;
    QualificationDetailService(data).then((res) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated successfully",
      }).then((result) => {
        if (result.value) {
          const len = 4;
          uploadFileName.length = 0;
          const emptyList = new Array();
          for (let index = 0; index < len - res.data.length; index++) {
            emptyList.push({
              id: 0,
              qualification: "",
              place: "",
              year: "",
              certificate: "",
              files: [],
            });
          }
          const list = [...res.data, ...emptyList];
          setQualificationList(res.data);
          setEdit(true);
          setFileName("");
          service();
        }
      });
    });
  };

  const handleRemove = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveMyHighQualificationService({ rowId })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Certificate removed successfully",
            }).then((result) => {
              if (result.value) {
                const len = 4;
                const emptyList = new Array();
                for (let index = 0; index < len - res.data.length; index++) {
                  emptyList.push({
                    id: 0,
                    qualification: "",
                    place: "",
                    year: "",
                    certificate: "",
                    files: [],
                  });
                }
                const list = [...res.data, ...emptyList];
                setInputList(list);
                setQualificationList(res.data);
                setFileName("");
                //setEdit(true);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const handleRemoveFile = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveMyHighQualificationCertificateService({ rowId })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Certificate removed successfully",
            }).then((result) => {
              if (result.value) {
                const len = 4;
                const emptyList = new Array();
                for (let index = 0; index < len - res.data.length; index++) {
                  emptyList.push({
                    id: 0,
                    qualification: "",
                    place: "",
                    year: "",
                    certificate: "",
                    files: [],
                  });
                }
                const list = [...res.data, ...emptyList];
                setInputList(list);
                setQualificationList(res.data);
                setFileName("");
                setEdit(true);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header">Highest Qualifications</div>
        <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit?"Edit":"Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>
          {edit ? (
            <div className="card-body-inr card-body-info">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>Qualification</th>
                      <th>Place of Study</th>
                      <th>Year Graduated</th>
                      <th>Your Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualificationList.map((qul, index) => {
                      return (
                        <tr key={`${qul.id}-${index}`}>
                          <td>
                            <div className="form-group m-0">
                              <div className="label-selected form-control">
                                {qul.qualification}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="form-group m-0">
                              <div className="label-selected form-control">
                                {qul.place}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="form-group m-0">
                              <div className="label-selected form-control">
                                {qul.year}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="form-group atttach-file m-0">
                              <ul className="list-unstyled attached-file-ul m-0">
                                {qul.files.map((item) => {
                                  return (
                                    <li key={item.id}>
                                      <DownloadCertificate
                                        item={item}
                                        canRemove={false}
                                      ></DownloadCertificate>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="card-body-inr">
              <form onSubmit={handleSubmit}>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>Qualification</th>
                        <th>Place of Study</th>
                        <th>Year Graduated</th>
                        <th>Your Certificate</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputList.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="form-group m-0">
                                <input
                                  type="text"
                                  name="qualification"
                                  className="form-control"
                                  value={x.qualification || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group m-0">
                                <input
                                  type="text"
                                  name="place"
                                  className="form-control"
                                  value={x.place || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group m-0">
                                <input
                                  name="year"
                                  className="form-control"
                                  value={x.year || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  type="text"
                                  pattern="\d*"
                                  maxLength="4"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group m-0">
                                {" "}
                                {qualificationList.length != 0 &&
                                qualificationList[qualificationList.length - 1]
                                  .id &&
                                checkFileExistsOrNot(qualificationList[i]) &&
                                qualificationList[qualificationList.length - 1]
                                  .id &&
                                x.id &&
                                qualificationList[qualificationList.length - 1]
                                  .files.length == 0 ? (
                                  <div>
                                    <ul className="list-unstyled attached-file-ul m-0">
                                      {x.files.map((item) => {
                                        return (
                                          <li key={item.id}>
                                            <DownloadCertificate
                                              item={item}
                                              canRemove={true}
                                              handleRemoveFile={
                                                handleRemoveFile
                                              }
                                            ></DownloadCertificate>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : x.id ? (
                                  <div className="form-group atttach-file m-0">
                                    {x.files.length == 0 ? (
                                      <label>
                                        <i className="fal fa-paperclip"></i>
                                        <span>Add Attachment</span>
                                        <input
                                          type="file"
                                          className="form-control  form-control-aatch-file"
                                          onChange={(e) =>
                                            handleFileChange(e, x.id)
                                          }
                                          name="certificate"
                                        />
                                      </label>
                                    ) : null}

                                    <ul className="list-unstyled attached-file-ul m-0">
                                      {x.files.map((item) => {
                                        return (
                                          <li key={item.id}>
                                            <DownloadCertificate
                                              item={item}
                                              canRemove={true}
                                              handleRemoveFile={
                                                handleRemoveFile
                                              }
                                            ></DownloadCertificate>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="form-group atttach-file m-0">
                                    {x.files.length == 0 ? (
                                      <div>
                                        <label>
                                          <i className="fal fa-paperclip"></i>
                                          <span>Add Attachment</span>
                                          <input
                                            name="files123"
                                            className="form-control  form-control-aatch-file"
                                            onChange={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            type="file"
                                          />
                                        </label>
                                        <br />
                                        <br />
                                        {uploadFileName && uploadFileName[i] && (
                                          <div>
                                            <i className="fal fa-file"></i>{" "}
                                            &nbsp;
                                            {
                                              uploadFileName[i].split("/")[
                                                uploadFileName[i].split("/")
                                                  .length - 1
                                              ]
                                            }
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <ul className="list-unstyled attached-file-ul m-0">
                                        {x.files.map((item) => {
                                          return (
                                            <li key={item.id}>
                                              <DownloadCertificate
                                                item={item}
                                                canRemove={true}
                                                handleRemoveFile={
                                                  handleRemoveFile
                                                }
                                              ></DownloadCertificate>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {x.id ? (
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => handleRemove(x.id)}
                                  title="Delete"
                                >
                                  <i className="fal fa-trash-alt"></i>
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="form-group form-group-save-cancel">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                    Save
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    onClick={() => setEdit(true)}
                    title="Cancel"
                  >
                    <i className="fal fa-times"></i>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QualificationDetails;
