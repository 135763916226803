import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import StudentSupport from "./Library/StudentSupport";
import SearchAssistance from "./Library/SearchAssistance";
import ResearchAssistance from "./Library/ResearchAssistance";
import AdvanceSearch from "./Library/AdvanceSearch";
import Home from "./Library/Home";
import CampusLibraries from "./Library/CampusLibraries";
import StudentHubMyLibrary from "./StudentHubMyLibrary";

export default function StudentHubLibrary({ tabState, paramObj }) {
  const brand = useSelector((state) => state.brand);
  const [search, setSearch] = useState("");
  const [searchBtn, setSearchBtn] = useState(false);
  const [resources, setResources] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState("AdvancedSearch");
  const currentTab = tabState;
  const currentId = paramObj?.id;
  const history = useHistory();
  const { tab, page, id } = useParams();

  const searchResources = () => {
    setSearchBtn(!searchBtn)
    history.push("/student-hub/advance-search/table");
  }


  return (
    <div className="lib-con">
      <div className="student-hub-main-blk mb-4 bgImg-head" >
        <div className="student-hub-search-blk">
          <h2>Welcome to Library @{brand?.configuration?.brand_name}</h2>
          <p>Not sure what to search for? Look through the categories below.</p>
          <div className="student-hub-search-form">
            <input type="text" value={search} name="search" className="form-control" placeholder="Search Resource" title="Search Resource" onChange={(e) => setSearch(e.target.value)} />
            <button title="Search" className="btn btn-white" type="button" onClick={() => searchResources()}><span>Search <i className="fal fa-search"></i></span></button>
          </div>
        </div>
      </div>
      {(currentTab === 'home' || currentTab === undefined) &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Library <span>Home</span></h4>
          </div>
          <p className='mb-4'>Welcome to LIBRARY @{brand?.configuration?.brand_name} . Here you can search for any academic information regarding your projects, class discussions or any other interesting topic. The library consists of books found in our campus libraries as well as online resource such as links, videos, online documents and articles.</p>
        </div>
      }
      {(currentTab === 'advance-search') &&
        <div>
          {tab === 'advance-search' && page === "query" &&
            <div className="lib-head-con">
              <h4 className="landing-heading heading_color_dynamic">Advanced <span>Search</span></h4>
            </div>
          }
          {tab === 'advance-search' && page === "table" &&
            <div className="lib-head-con">
              <h4 className="landing-heading heading_color_dynamic">Search <span>Result</span></h4>
              <>
                <div className='separator-line' />
                <button type="button" onClick={() => history.push(`/student-hub/advance-search/query`)} className="btn btn-primary" title="Back"><i className="fal fa-chevron-left"></i> Back</button>
              </>
            </div>
          }
          {tab === 'advance-search' && page === "detail" &&
            <div className="lib-head-con">
              <h4 className="landing-heading heading_color_dynamic">Search <span>Result</span></h4>
              <>
                <div className='separator-line' />
                <button type="button" onClick={() => history.push(`/student-hub/${tab}/table`)} className="btn btn-primary" title="Back"><i className="fal fa-chevron-left"></i> Back</button>
              </>
            </div>
          }

          {tab === 'advance-search' && page === "query" &&
            <p className='mb-4'>Your result set still too big? Use advanced Search to filter the results according to the Resource Type, Title, Keywords or Description or any variation as you need.</p>
          }
          {tab === 'advance-search' && (page === "detail" || page == "table") &&
            <p className='mb-4'>Did you get what you were looking for? Remember you can ask for help by clicking on Research Assistance and using the links provided there to find what you are looking for or click on Search Assistance so we can help you.</p>
          }
        </div>
      }
      {(currentTab === 'research-assistance') &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Research <span>Assistance</span></h4>
          </div>
          <p className='mb-4'>Here you will find Research Resources that we have curated and partnered with where possible to help you with your research.</p>
        </div>
      }
      {(currentTab === 'search-assistance') &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Search <span>Assistance</span></h4>
          </div>
          <p className='mb-4'>If you need help finding academic information regarding your project or class discussion fill in the form and we will contact you with the relevant information.</p>
        </div>
      }
      {(currentTab === 'campus-library') &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Campus <span>Libraries</span></h4>
          </div>
          <p className='mb-4'>Need to get to the closest Library at one of our campuses. Here is a list of the closest campuses.</p>
        </div>
      }
      {(currentTab === 'student-support') &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Student <span>Support</span></h4>
          </div>
          <p className='mb-4'>Unsure of what to do if you can't get the information you need?</p>
        </div>
      }
      {(currentTab === 'my-library') &&
        <div>
          <div className="lib-head-con">
            <h4 className="landing-heading heading_color_dynamic">Favourites</h4>
          </div>
          {page == "table" && <p className='mb-4'>This is list of all Resources you have favoured.</p>}
        </div>
      }
      <div className="studenthub-content-blk">
        {(currentTab !== 'my-library') &&
          <div className="studenthub-sidebar">
            <ul className="nav-tabs-list-blk">
              <li className={(currentTab == 'home' || currentTab == undefined) ? 'active' : ''} title="Library Home">
                <Link to={"/student-hub/home"} className="nav-tabs-item"><i className="fal fa-home"></i> Library Home </Link>
              </li>
              <li className={currentTab == 'advance-search' ? 'active' : ''} title="Advanced Search">
                <Link to={"/student-hub/advance-search/query"} className="nav-tabs-item"><i className="fal fa-search-plus"></i> Advanced Search </Link>
              </li>
              <li className={currentTab == 'research-assistance' ? 'active' : ''} title="Research Assistance">
                <Link to={"/student-hub/research-assistance"} className="nav-tabs-item"><i className="fal fa-print-search"></i> Research Assistance </Link>
              </li>
              <li className={currentTab == 'search-assistance' ? 'active' : ''} title="Search Assistance">
                <Link to={"/student-hub/search-assistance"} className="nav-tabs-item"><i className="fal fa-hands-helping"></i> Search Assistance </Link>
              </li>
              <li className={currentTab == 'campus-library' ? 'active' : ''} title="Campus Libraries">
                <Link to={"/student-hub/campus-library"} className="nav-tabs-item"><i className="fal fa-university"></i>Campus Libraries</Link>
              </li>
              <li className={currentTab == 'student-support' ? 'active' : ''} title="Student Support">
                <Link to={"/student-hub/student-support"} className="nav-tabs-item"><i className="fal fa-headset"></i>Student Support</Link>
              </li>
            </ul>
          </div>
        }
        <div className={`studenthub-right-blk ${currentTab === 'my-library' ? 'isFavBox' : ''}`}>

          {(currentTab === 'home' || currentTab === undefined) && <Home />}

          {currentTab === 'advance-search' && <AdvanceSearch searchBtn={searchBtn} globalSearch={search} resetGlobalSearch={() => { setSearch("") }} />}

          {currentTab === 'research-assistance' && <ResearchAssistance />}

          {currentTab === 'search-assistance' && <SearchAssistance />}

          {currentTab === 'campus-library' && <CampusLibraries />}

          {currentTab === 'student-support' && <StudentSupport />}

          {currentTab === 'my-library' && <StudentHubMyLibrary />}
        </div>
      </div>
    </div>
  )
}

