import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function SkeletonExam() {
  return (
    <div className="subject-list">
      <div className="exam-list-wrap">
        <ul className="exam-ul-list list-unstyled">
          {Array(4)
            .fill()
            .map((item, index) => (
              <li key={index}>
                <Skeleton width={252} height={252} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
