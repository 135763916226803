import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { ratingFormSubmitService } from "../../services/GeneralService";

export default function FeedBackForm(props) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  let history = useHistory();
  //const phoneRegExp = /^[6-9]\d{9}$/;
  const matchName = /^[a-zA-Z-\s]+$/;
  //const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;
  const phoneRegExp = /^(\+|\d)[0-9]{7,16}$/;
  const formik = useFormik({
    initialValues: {
      full_name: "",
      contact_number: "",
      email: "",
      comment: "",
      rating_type: props.rating_type,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      full_name: Yup.string()
        .matches(matchName, "Name should be A-Z or a-z")
        .required("Full name is required"),
      contact_number: Yup.string()
        .matches(
          phoneRegExp,
          "Phone number should be 0-9 of 10 digits with country code"
        )
        .required("Contact number is required"),
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.rating_type = props.rating_type;

      ratingFormSubmitService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "Thanks, we have received your feedback.",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          resetForm();
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4 card-group mb-3">
            <label>Name & Surname: </label>

            <input
              type="text"
              className={
                "form-control" +
                (formik.errors.full_name && formik.touched.full_name
                  ? " is-invalid"
                  : "")
              }
              placeholder=""
              name="full_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.full_name}
            />
          </div>
          <div className="col-md-4 card-group mb-3">
            <label>Contact Number:</label>
            <input
              type="text"
              className={
                "form-control" +
                (formik.errors.contact_number && formik.touched.contact_number
                  ? " is-invalid"
                  : "")
              }
              placeholder=""
              name="contact_number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contact_number}
            />
          </div>
          <div className="col-md-4 card-group mb-3">
            <label>Email Address:</label>
            <input
              type="email"
              className={
                "form-control" +
                (formik.errors.email && formik.touched.email
                  ? " is-invalid"
                  : "")
              }
              placeholder=""
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div className="col-md-12 card-group mb-3">
            <label>
              Is there anything else you want us to know about your experience?
            </label>
            <textarea
              rows="10"
              className={
                "form-control" +
                (formik.errors.comment && formik.touched.comment
                  ? " is-invalid"
                  : "")
              }
              placeholder=""
              name="comment"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
            ></textarea>
          </div>
          <div className="col-md-12 card-group">
            <button
              className="btn btn-submit btn-main btn-primary"
              disabled={loading}
              title="Submit Query"
            >
              {loading ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-check"></i>
              )}
              Submit Query
            </button>
            {formik.touched.full_name && formik.errors.full_name ? (
              <div className="invalid-feedback d-block">{formik.errors.full_name}</div>
            ) : null}
            {formik.touched.contact_number && formik.errors.contact_number ? (
              <div className="invalid-feedback d-block">
                {formik.errors.contact_number}
              </div>
            ) : null}
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback d-block">{formik.errors.email}</div>
            ) : null}
            {formik.touched.comment && formik.errors.comment ? (
              <div className="invalid-feedback d-block">{formik.errors.comment}</div>
            ) : null}
          </div>
        </div>
      </form>
    </>
  );
}
