import React, { useEffect, useState, useMemo } from "react";
import $ from "jquery";
import { GetMyTicketsService, GetMyTicketsNewService, readTicketIndicator } from "../../services/TicketService";
import { useHistory } from "react-router-dom";
import { getFormattedTime, getStatusIcon, getCurrentTimeHomePage, InitalRender } from "./../common/Helper";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Scrollbar } from "react-scrollbars-custom";
import ReactTooltip from "react-tooltip";
import { Link, useParams } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import StatusRender, { CatColorRender, CategoryRender, ColorRender, DepartmentRender } from "./StatusRender";
import moment from "moment";
import { TrimText } from "../common/TrimText";
import {
  GetDepartmentsService,
  AddTicketService,
} from "../../services/TicketService";
import { BASE_URL } from "./../../utils/Constants";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Str from "../common/Str";

const StyledLi = styled.li`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  &.selected {
    border-bottom: 1px blue solid;
  }
`;

export default function TicketList(props) {
  const [apiStatus, setApiStatus] = useState("loading");
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [noDataComponent, setNoDataComponent] = useState([]);
  const [page, setPage] = useState({
    All: 1,
    pendingAcademy: 1,
    pendingStudent: 1,
  });
  const [lastPage, setLastPage] = useState({});
  const [pageClicked, setPageClicked] = useState(true);
  const [ticketState, setTicketState] = useState("All");
  const [status, setStatus] = useState([])
  const [searchStatusCheck, setSearchStatusCheck] = useState({})
  const [update, setUpdate] = useState([])
  const [searchUpdateCheck, setSearchUpdateCheck] = useState({})
  const [searchDepartment, setSearchDepartment] = useState([])
  const [searchDepartmentCheck, setSearchDepartmentCheck] = useState({})
  const [searchCat, setSearchCat] = useState([])
  const [searchCatCheck, setSearchCatCheck] = useState({})
  const [searchType, setSearchType] = useState([])
  const { selectedTicket, setSelectedTicket, ticketDetails, setTicketDetails } = props;
  const history = useHistory();
  const params = useParams();
  const [search, setSearch] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [tickData, setTickData] = useState({
    lastPage: 0,
    page: 1,
    perPage: 10,
    ticketsList: [],
    total: 0,
    ticketsList: []
  })
  const [ticketPage, setticketPage] = useState(1)
  const [copyText, setCopyText] = useState(0);
  const [renderTable, setRenderTable] = useState(false);

  // const absolutePath = BASE_URL + '/' + absolutePath.replace("/home/myaie/public_html/", "");

  const copyEmailText = (email) => {
    setCopyText(1);
    navigator.clipboard.writeText(email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  useEffect(() => {
    setLoading(true)
    GetMyTicketsNewService(history, 'All', ticketPage)
      .then((res) => {
        setTickData(res.data.tickets)
        if (res && res.data && res.data.tickets && res.data.tickets.length == 0) {
          setNoDataComponent(Str.noRecord)
        }
        setRenderTable(true)
      })
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, [ticketPage]);

  useEffect(() => {
    GetDepartmentsService(history)
      .then((res) => {
        setDepartments(res.data.departments);
      })
      .catch((error) => { });
  }, []);


  const columns = useMemo(() => [
    {
      name: "ID",
      selector: "id_ticket",
      sortable: true,
      // maxWidth: "110px",
      cell: (row, index) => (
        <div className="ticket-id">
          <p>
            <i data-toggle="tooltip" title="Unread"
              className={
                row.notification_indicator_status ? "fal fa-comment-lines" : ""
              }
            ></i>{" "}
            <span className="overflow-ellipsis2"><b>#{row.id_ticket}</b></span>
          </p>
        </div>
      ),
    },
    {
      name: "Subject",
      selector: "subject",
      // wrap: true,
      sortable: true,
      cell: (row) => (
        <Link
          to={`myticketsdetail/${row.id_ticket}`}
          className={
            row.notification_indicator_status
              ? "as-text-blue bold-text plink overflow-ellipsis2"
              : "as-text-blue plink overflow-ellipsis2"
          }
        >
          {row.subject}
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      // wrap: true,
      sortable: true,
      cell: (row) => <StatusRender status={row.status} />,
    },
    {
      name: "Department",
      selector: "department",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <p className="oneline-ellipsis">
          {row.department
            ? <DepartmentRender status={row.department} />
            : "NA"}
        </p>
      ),
    },
    {
      name: "Created",
      selector: "date_add",
      // wrap: true,
      sortable: true,
      cell: (row) => <div className="dateTime"><p className="right-space">{moment(row.date_add).format(`D MMM 'YY`)}</p><p>{moment(row.date_add).format(`HH:mm a`)}</p></div>,
    },
    {
      name: "Modified",
      selector: "last_update",
      // wrap: true,
      sortable: true,
      cell: (row) => (
        <div className="dateTime"><p className="right-space">{moment(row.last_update).format("D MMM 'YY")}</p><p>{moment(row.last_update).format("HH:mm a")}</p></div>
      ),
    },
    {
      name: "Assigned To",
      selector: "assign_to",
      // wrap: true,
      sortable: true,
      cell: (row) => (
        <div className="user-profile assigned-title-blk">
          {/* {console.log("row ------> ", row)} */}
          <div className="assigned-title-itm">
            <span
              className={
                "name-icon cat-dark-red " +
                `${row.assign_to_photo ? 'transparentBg':ColorRender(row.category)}` +
                " user-drop-down-img"
              }
            >
              {row.assign_to_photo ? (
                <img
                  src={
                    BASE_URL +
                    "/" +
                    row.assign_to_photo.replace("/home/myaie/public_html/", "")
                  }
                  
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null; // prevents looping
                //   currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(row.assign_to));
                //   currentTarget.remove();
                // }}
                />
                
              ) : (
                InitalRender(row.assign_to)
              )}
              {row?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
            </span>
            <span className="overflow-ellipsis2">{TrimText(row.assign_to ? row.assign_to.split(" ")[0] : "NA", 9)}</span>
          </div>
          <div className="assigned-title-info ticket-table-tiltle online_newpoints01">
            <article>
              <figure className={`${row.assign_to_photo ? 'transparentBg':ColorRender(row.category)}`}>
                {row.assign_to_photo ? (
                  <img
                    src={
                      BASE_URL +
                      "/" +
                      row.assign_to_photo.replace(
                        "/home/myaie/public_html/",
                        ""
                      )
                    }
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.closest('figure').append(InitalRender(row.assign_to));
                  //   currentTarget.remove();
                  // }}
                  />
                ) : (
                  InitalRender(row.assign_to)
                )}
                  {row?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
              </figure>
              <figcaption>
                <h4>{TrimText(row.assign_to ? row.assign_to : "NA", 20)}</h4>
                <p>
                  <i class="fal fa-envelope"></i>
                  {row.assign_to ? (
                    // <a href="javascript:void(0);" className="hover-color-blue" onClick={() => copyEmailText(row.assign_to_email)} >
                    <>
                      <a href="javascript:void(0);" className='hove-active-item' onClick={() => navigator.clipboard.writeText(row.assign_to_email)}>
                        {TrimText(
                          row.assign_to_email ? row.assign_to_email : "NA",
                          20
                        )}

                      </a>
                      {copyText ? (
                        <i class="fas fa-check-circle cat-greenhex"></i>
                      ) : (
                        row.assign_to_email && <i className="fal fa-copy hover-color-blue hove-active-item-icon"
                          onClick={() => navigator.clipboard.writeText(row.assign_to_email)}
                        // onClick={() => copyEmailText(row.assign_to_email)}
                        >
                        </i>
                      )}
                    </>
                  ) : (
                    "N/A"
                  )}


                </p>
              </figcaption>
            </article>
          </div>
        </div>
      ),
    },
    {
      name: "Category",
      selector: "category",
      wrap: true,
      sortable: true,
      // maxWidth: "100%",
      // minWidth: "150px",
      // cell: (row) => <CategoryRender status={row.category} />,
      cell: (row) => row.category ? <span className={"cat " + CatColorRender(row.category)} ><i className="fas fa-circle"></i> {row.category}</span> : "NA",
    },
    {
      name: "Action",
      // width: "100%",
      // maxWidth: "90px",
      // minWidth: "90px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <a href={`myticketsdetail/${row.id_ticket}`}>
              {/* <button className="btn btn-primary">
                <i className="fal fa-folder-open"></i> Open
              </button> */}
              <button
                title="Open"
                className="btn btn-primary rounded-circle"
              >
                <i className="fal fa-folder-open"></i>
              </button>
            </a>
          </div>
        </div>
      ),
      // button: true,
    },
  ]);

  const dataToRender = () => {
    let updatedData = []
    let allData = tickData.ticketsList
    if (search.length) {
      let tempId = allData.filter(item => {
        let includes = item.id_ticket.toString().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempSearch = allData.filter(item => {
        let includes = item.subject && item.subject.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempStatus = allData.filter(item => {
        let includes = item.status && item.status.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempDateAdd = allData.filter(item => {
        let includes = item.date_add && (moment(item.date_add).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
        if (includes) {
          return includes
        } else return null
      })
      let tempDateModified = allData.filter(item => {
        let includes = item.last_update && (moment(item.last_update).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
        if (includes) {
          return includes
        } else return null
      })
      let tempDepartment = allData.filter(item => {
        let includes = item.department && item.department.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempAssignTo = allData.filter(item => {
        let includes = item.assign_to && item.assign_to.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })
      let tempAssignToNA = allData.filter(item => {
        let includes = (search.toLowerCase() == 'na') && (item.assign_to == null)
        if (includes) {
          return includes
        } else return null
      })
      let tempCategory = allData.filter(item => {
        let includes = item.category && item.category.toLowerCase().includes(search.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })

      let data = [...tempId, ...tempSearch, ...tempStatus, ...tempDateAdd, ...tempDateModified, ...tempDepartment, ...tempAssignTo, ...tempAssignToNA, ...tempCategory]
      let unique = [...new Set(data)]

      updatedData = unique
    } else {
      updatedData = allData
    }
    if (update.length) {
      let tempUpdate = updatedData
      let tempSearch = tempUpdate.filter(item => {
        const startsWith = update.find(function (post, index) {
          // console.log('the post and item.category is ', post, item.update, post.toLowerCase() == item.update.toLowerCase())
          if ((post == 'modified') && item.notification_indicator_status)
            return item;
          else if ((post == 'viewed') && !item.notification_indicator_status)
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })

      updatedData = tempSearch
    }
    if (status.length) {
      let tempStatus = updatedData
      let tempSearch = tempStatus.filter(item => {
        const startsWith = status.find(function (post, index) {
          // console.log('the post and item.category is ', post, item.status, post.toLowerCase() == item.status.toLowerCase())
          if (post.toLowerCase() == (item.status ? item.status.toLowerCase() : ''))
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })

      updatedData = tempSearch
    }
    if (searchDepartment.length) {
      let tempStatus = updatedData
      let tempSearch = tempStatus.filter(item => {
        let tempItem = item.department ? item.department.toLowerCase() : ''
        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
        const startsWith = searchDepartment.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : post
          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
          if (tempPostNew == tempItemNew)
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempSearch
    }
    if (searchCat.length) {
      let tempStatus = updatedData
      let tempSearch = tempStatus.filter(item => {
        let tempItem = item.category ? item.category.toLowerCase() : ''
        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
        const startsWith = searchCat.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : post
          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
          if (tempPostNew == tempItemNew)
            return item;
        });
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempSearch
    }
    if (update.length || status.length || search.length || searchDepartment.length || searchCat.length) {
      return updatedData.length > 0 ? updatedData : []
    } else {
      return tickData.ticketsList
    }
  }

  // ** Function to handle type filter  
  const handleSearchFilter = e => {
    const value = e.target.value
    setSearch(value);
  }

  const handleUpdateFilter = e => {
    const value = e.target.value
    let arr = update;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setUpdate(arr)
    let id = e.target.id;
    setSearchUpdateCheck({ ...searchUpdateCheck, [id]: !searchUpdateCheck[id] });
  }

  const handleStatusFilter = e => {
    const value = e.target.value
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr)
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  }

  const handleDepartmentFilter = e => {
    const value = e.target.value
    let arr = searchDepartment;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSearchDepartment(arr)
    let id = e.target.id;
    setSearchDepartmentCheck({ ...searchDepartmentCheck, [id]: !searchDepartmentCheck[id] });
    setSearchCat([])
    setSearchCatCheck([])
  }

  const handleCatFilter = e => {
    const value = e.target.value
    // console.log('the value of the filter is ', value)
    let arr = searchCat;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSearchCat(arr)
    let id = e.target.id;
    setSearchCatCheck({ ...searchCatCheck, [id]: !searchCatCheck[id] });
  }

  const resetFilter = () => {
    setUpdate([]);
    setSearchUpdateCheck({});
    setStatus([]);
    setSearchStatusCheck({});
    setSearchDepartment([]);
    setSearchDepartmentCheck({});
    setSearchCat([])
    setSearchCatCheck({})
    setSearch('')
  };

  const renderDepartmentFilterUI = () => {
    let tempDept = departments
    return (tempDept.map((item, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={item.title}
            value={item.title}
            onClick={handleDepartmentFilter}
            checked={searchDepartmentCheck[item.title]}
          />
          <label for={item.title}>
            {/* {i.title} */}
            <DepartmentRender status={item.title} />
          </label>
        </li>
      )
    }))
  }

  const renderCatFilterUI = useMemo(() => {
    let tempDept = departments
    let tempCat = []
    let unique = []
    let arr = searchDepartment;
    if (arr.length == 0) {
      tempDept.map(i => {
        tempCat = [...tempCat, ...i.categories]
      })
    }
    else {
      tempDept.map(i => {
        if (arr.includes(i.title)) {
          tempCat = [...tempCat, ...i.categories]
        }
      })
    }
    unique = [...new Set(tempCat.map(item => item.title))]
    return (unique.map((item, index) => {
      return (
        <li className={"cat cat-orange"} key={index}>
          <input
            type="checkbox"
            id={item}
            value={item}
            onClick={handleCatFilter}
            checked={searchCatCheck[item]}
          />
          <label for={item}>
            <span className={"cat " + CatColorRender(item)} ><i className="fas fa-circle m-0"></i> {item}</span>
          </label>
        </li>
      )
    }))
  })

  return (
    <>
      <div className="my-tickets-heading-blk flex-direction mb-4">
        <h4 className="text-left-align landing-heading heading_color_dynamic">
          My <span>Tickets</span>
        </h4>
        <div className="text-md-right">
          <p className="">
            {" "}
            {getCurrentTimeHomePage(Date().toLocaleString())}{" "}
          </p>
        </div>
      </div>

      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="filter-search-bar-blk">
          <div className="filter-button-group">
            <div className="filter-eff filter-data-btn">
              <button className="filter-buttons">
                <i className="fal fa-filter"></i>
              </button>
            </div>
            <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${update.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Reply"
                  >
                    <span>
                      Reply <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      <li >
                        <input
                          type="checkbox"
                          id="modified"
                          value="modified"
                          onClick={handleUpdateFilter}
                          checked={searchUpdateCheck.modified}
                        />
                        <label for="modified">
                          <span >Unread</span>
                        </label>
                      </li>
                      <li >
                        <input
                          type="checkbox"
                          id="viewed"
                          value="viewed"
                          onClick={handleUpdateFilter}
                          checked={searchUpdateCheck.viewed}
                        />
                        <label for="viewed">
                          <span >Read</span>
                        </label>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect `}>

                  <button
                    className={`btn btn-default dropdown-toggle ${status.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Status"
                  >
                    <span>
                      Status <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      <li className="item-text-green">
                        <input
                          type="checkbox"
                          id="action13"
                          value="Open"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action13}
                        />
                        <label for="action13">
                          <span className="as-yellow-bg as-widget">OPEN</span>
                        </label>
                      </li>
                      <li className="item-text-green">
                        <input
                          type="checkbox"
                          id="action14"
                          value="New"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action14}
                        />
                        <label for="action14">
                          <span className="as-skyblue-bg as-widget">NEW</span>
                        </label>
                      </li>
                      <li className="item-text-green">
                        <input
                          type="checkbox"
                          id="action1"
                          value="Closed"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action1}
                        />
                        <label for="action1">
                          <span className="as-green-bg as-widget">CLOSED</span>
                        </label>
                      </li>
                      <li className="item-text-red">
                        <input
                          type="checkbox"
                          id="action15"
                          value="Pending-Investigation"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action15}
                        />
                        <label for="action15">
                          <span className="as-yellowshade-bg as-widget">PENDING-INVESTIGATION</span>
                        </label>
                      </li>
                      <li className="item-text-red">
                        <input
                          type="checkbox"
                          id="action2"
                          value="Pending-Student"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action2}
                        />
                        <label for="action2">
                          <span className="as-red-bg as-widget">PENDING-STUDENT</span>
                        </label>
                      </li>
                      <li className="item-text-blue">
                        <input
                          type="checkbox"
                          id="action3"
                          value="Pending-Academy"
                          onClick={handleStatusFilter}
                          checked={searchStatusCheck.action3}
                        />
                        <label for="action3">
                          <span className="as-blue-bg as-widget">PENDING-ACADEMY</span>
                        </label>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="assessment-table-status">
              <div className="btn-type-Status dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${searchDepartment.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Department"
                  >
                    <span>
                      Department <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {renderDepartmentFilterUI()}
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="assessment-table-status">
              <div className="btn-type-Status dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${searchCat.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Category"
                  >
                    <span>
                      Category <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {renderCatFilterUI}
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="button-reset">
              <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>
          </div>
          <div className="add-ticket-blk button-reset">
            <Link to={`/addnewticket`}>
              <button className="btn btn-primary" title="New Ticket">
                <i className="fal fa-plus"></i>New Ticket
              </button>
            </Link>
          </div>
        </div>
        <div className="data-table-extensions">
          <div className="data-table-extensions-filter">
            <label for="filterDataTable" className="icon"></label>
            <input
              type="text"
              name="filterDataTable"
              className="filter-text"
              placeholder="Search"
              onChange={handleSearchFilter}
              value={search}
            />
          </div>
          <div className="data-table-extensions-action"></div>
        </div>
        {!renderTable && <SkeletonTicketList />}
        {renderTable &&
          <DataTable
            columns={columns}
            data={(dataToRender()).length > 0 ? dataToRender() : setTimeout(() => {
              setNoDataComponent(Str.noRecord)
            }, 1000)}
            pagination={true}
            noDataComponent={noDataComponent}
            defaultSortField="last_update"
            defaultSortAsc={false}
          />
        }
      </div>
    </>
  );
}
