import React, { useEffect, useState } from "react";
import SchoolOfEngineering from "./SchoolOfEngineering";
import SchoolOfBusiness from "./SchoolOfBusiness";
import SchoolOfDraughting from "./SchoolOfDraughting";
import SchoolOfArchitecture from "./SchoolOfArchitecture";
import SchoolOfGreenside from "./SchoolOfGreenside";

import SchoolOfEngineeringReadOnly from "./read_only_school/SchoolOfEngineeringReadOnly";
import SchoolOfBusinessReadOnly from "./read_only_school/SchoolOfBusinessReadOnly";
import SchoolOfDraughtingReadOnly from "./read_only_school/SchoolOfDraughtingReadOnly";
import SchoolOfArchitectureReadOnly from "./read_only_school/SchoolOfArchitectureReadOnly";
import SchoolOfGreensideReadOnly from "./read_only_school/SchoolOfGreensideReadOnly"

import {
  getReRegistrationSubjectService,
  getReRegistrationReadOnlySubjectService,
} from "../../services/ReRegistrationService";
import { Link } from "react-router-dom";
import SchoolOfEmendy from "./SchoolOfEmendy";
import SchoolOfEmendyReadOnly from "./read_only_school/SchoolOfEmendyReadOnly";
function ReRegistrationRightBox(props) {
  const { registration } = props;
  const [reRegSubject, setReRegSubject] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [studentNewRegId, setStudentNewRegId] = useState(0);
  const [lateReg, setLateReg] = useState(0);

  const [subjectProgressReadOnly, setSubjectProgressReadOnly] = useState([]);
  const [subjectReWriteReadOnly, setSubjectReWriteReadOnly] = useState([]);
  const [subjectReDoReadOnly, setSubjectReDoReadOnly] = useState([]);
  const [subjectReSubReadOnly, setSubjectReSubReadOnly] = useState([]);
  const [regForReadOnly, setRegForReadOnly] = useState([]);
  const [repeatReadOnly, setRepeatReadOnly] = useState([]);
  const [electiveReadOnly, setElectiveReadOnly] = useState([]);
  const [delivery_methode, setDelivery_methode] = useState('');

  useEffect(() => {
    getReRegistrationSubjectService(props.registration.id).then((res) => {
      setReRegSubject(res.data.result);
      setLateReg(res.data.late_reg)
    });
  }, []);

  useEffect(() => {
    if (
      readOnly ||
      props.registration.readOnly ||
      props.registration.studentNewRegId > 0
    ) {
      let regId = props.registration.studentNewRegId
        ? props.registration.studentNewRegId
        : studentNewRegId;
      getReRegistrationReadOnlySubjectService(regId).then((res) => {
        setSubjectProgressReadOnly(res.data.subjectProgress_);
        setSubjectReWriteReadOnly(res.data.subjectRewrite_);
        setSubjectReDoReadOnly(res.data.subjectRedo_);
        setDelivery_methode(res.data.delivery_methode);
        setRegForReadOnly(res.data.registerFor);
        setRepeatReadOnly(res.data.repeat);
        setElectiveReadOnly(res.data.elective);
      });
      setReadOnly(true);
    }
  }, [readOnly]);
  return (
    <>
      <div className="re-registration-detail-rgt">
        <div className="re-registration-detail-top d-flex justify-content-between">
          <div className="re-registration-detail-top-left">
            <div className="re-registration-bx-info  d-flex">
              <div className="icon">SA</div>
              <div className="re-registration-bx-info-rgt">
                <h3>
                  {registration.academy2}, Intake {registration.intake_number} ,{" "}
                  {registration.intake_year}
                </h3>
                <p>
                  <i className="fal fa-calendar-alt"></i>{" "}
                  {registration.start_date} to {registration.end_date}
                </p>
              </div>
            </div>
          </div>
          <div className="re-registration-detail-top-right">
            <a title="Back" class="btn btn-white-bordered" href="/reregistration"><i class="fal fa-angle-left"></i>Back</a>
          </div>
        </div>

        <div className="re-registration-register-bx">
          <div className="card card-form-bx">
            <div className="card-header">
              {/* What would you like to Re-Register for next term? */}
            </div>

            {!readOnly &&
              registration &&
              registration.academy == "draughting" ? (
              <SchoolOfDraughting
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly &&
              registration &&
              registration.academy == "engineering" ? (
              <SchoolOfEngineering
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly && registration && registration.academy == "business" ? (
              <SchoolOfBusiness
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly &&
              registration &&
              registration.academy == "architecture" ? (
              <SchoolOfArchitecture
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
                nextOption={props.registration.next_year_option}
              />
            ) : null}
            {!readOnly &&
              registration &&
              registration.academy == "gdc" ? (
              <SchoolOfGreenside
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly &&
              registration &&
              (registration.academy == "fst" || registration.academy == "fg") ? (
              <SchoolOfEmendy
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {/* ReadOnly ReRegistration */}
            {readOnly &&
              registration &&
              registration.academy == "draughting" ? (
              <SchoolOfDraughtingReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
                delivery_methode={delivery_methode}
              />
            ) : null}
            {readOnly &&
              registration &&
              registration.academy == "engineering" ? (
              <SchoolOfEngineeringReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
              />
            ) : null}
            {readOnly && registration && registration.academy == "business" ? (
              <SchoolOfBusinessReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
              />
            ) : null}
            {readOnly &&
              registration &&
              registration.academy == "architecture" ? (
              <SchoolOfArchitectureReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
                nextOption={props.registration.next_year_option}
              />
            ) : null}
            {readOnly &&
              registration &&
              registration.academy == "gdc" ? (
              <SchoolOfGreensideReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                regForReadOnly={regForReadOnly}
                repeatReadOnly={repeatReadOnly}
              />
            ) : null}
            {readOnly &&
              registration &&
              (registration.academy == "fst" || registration.academy == "fg") ? (
              <SchoolOfEmendyReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                regForReadOnly={regForReadOnly}
                repeatReadOnly={repeatReadOnly}
                electiveReadOnly={electiveReadOnly}
              />
            ) : null}
            {/* <SchoolOfEngineering reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfBusiness reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfDraughting reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfArchitecture reRegSubject={reRegSubject} /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReRegistrationRightBox;
