import React, { useState } from "react";
import { Bar } from "@reactchartjs/react-chart.js";
import ReactHtmlParser from "react-html-parser";

const QuizBar = (props) => {
  let labelAll = [];
  let color = [];
  let timeArrRed = [];
  let timeArrGreen = [];
  props.dataAns.map((item, index) => {
    // console.log("_________item:",item)
    // labelAll.push(
    //   `Question ${index + 1}\n${
    //     item.attemptedstatus ? "Attempted" : "Unattempted"
    //   }`
    // );

    if (item.attemptedstatus) {
      if(item.questionType === "Document Upload" || item.questionType === "Long Answer"){
        color.push("#FFAB2D");
        labelAll.push(`Question ${index + 1}\n Manually Marked`)
      } else if (props.scoreArray[index] === "0") {
          // timeArrGreen.push(0);
          // timeArrRed.push(props.timeArr[index]);
          color.push("#ef0303");
          labelAll.push(`Question ${index + 1}\n Incorrrect`)
      } else if (
        item.score == props.scoreArray[index] &&
        item.questionType != "Long Answer"
      ) {
        // timeArrGreen.push(props.timeArr[index]);
        // timeArrRed.push(0);
        color.push("#5cb85c");
        labelAll.push(`Question ${index + 1}\n Correct`)
      } else {
        // timeArrGreen.push(0);
        // timeArrRed.push(props.timeArr[index]);
        color.push("#1899D2");
        labelAll.push(`Question ${index + 1}\n Partially Correct`)
      }
    } else {
      // timeArrGreen.push(0);
      // timeArrRed.push(props.timeArr[index]);
      color.push("#ef0303");
      labelAll.push(`Question ${index + 1}\n Unattempted`)
    }
  });

  const dataAll = {
    labels: labelAll,
    datasets: [
      {
        label: "# of Question",
        data: props.timeArr,
        backgroundColor: color,
        borderWidth: 1,
      },
    ],
  };

  // const dataRed = {
  //   labels: labelAll,
  //   datasets: [
  //     {
  //       label: "# of Question",
  //       data: timeArrRed,
  //       backgroundColor: color,
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const dataGreen = {
  //   labels: labelAll,
  //   datasets: [
  //     {
  //       label: "# of Question",
  //       data: timeArrGreen,
  //       backgroundColor: color,
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      position: "right",
    },
  };

  const [status, setstatus] = useState(dataAll);

  // console.log(dataAll);
  return (
    <>
      {/* <button onClick={() => setstatus(dataRed)}>Show Red</button>
      <button onClick={() => setstatus(dataGreen)}>Show Green</button>
      <button onClick={() => setstatus(dataAll)}>All Data</button> */}
      <Bar data={dataAll} options={options} width={10} height={7} />
    </>
  );
};

export default QuizBar;
