const initialState = {
  selectedGroup: [],
};

const selectedGroupChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_GROUPS":
      return { ...state, ...action.payload };
      break;
    case "UNSET_SELECTED_GROUPS":
      return { ...state, selectedGroup: [] };
      break;

    default:
      return state;
      break;
  }
};

export default selectedGroupChatReducer;
