import React, { useState, useEffect } from "react";
import VideoPlayer from "./VidoePlayer";
import QuizeImage from "../../assets/images/ic-1.png";
import {
  updateVideoTimeService,
  getVideoTimeService,
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  updateWatchCountService,
} from "../../services/AssembliesService";
import { download } from "../../utils/FileDownload";
import SubAssembliesItems from "./SubAssembliesItems";
import { image_url } from "../../utils/ImageUrl";
import { useParams } from "react-router-dom";
import { ADMIN_PASSWORD } from "../../utils/Constants";

let obj = {};

export default function AssembliesItems(props) {
  const {
    assemblyItem,
    lesson,
    currentVideoId,
    buttonCheckedStatus,
    documentId,
    setQuizId,
    setshow,
    setrId,
    rId,
    getSubContentMethod,
    lessonindex,
    isAdmin,
    refreshProgress
  } = props;
  const {password} = useParams();
  const [videoId, setVideoId] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [watchedVideo, setWatchedVideo] = useState([]);
  const [watchTime, setWatchTime] = useState(assemblyItem.watch_in_m_s);
  const [watchPercentage, setWatchPercentage] = useState(
    assemblyItem.watch_percentage
  );
  const [isWatchedVideo, setIsWatchedVideo] = useState(
    assemblyItem.is_video_watched
  );
  const [moduleId, setModuleId] = useState(0);
  const [alreadyWatched, setAlreadyWatched] = useState(
    assemblyItem.alreadyWatched ? true : false
  );
  const [subassembliesItems, setsubAssembliesItems] = useState([]);
  const [buttonCheckedStatus1, setButtonCheckedStatus1] = useState(0);
  const [documentId1, setDocumentId1] = useState([]);
  const [currentVideoId1, setCurrentVideoId1] = useState(0);
  const [playerSeek, setPlayerSeek] = useState(0);
  const [playerPipMode, setPlayerPipMode] = useState(false);
  const [resumeBtn, setResumeBtn] = useState(
    assemblyItem.watch_percentage > 0 && assemblyItem.watch_percentage < 100 ? true : false
  );

  const handleVideoTogglePIP = (player, assemblyItem, videoId, action) => {
    setPlayerPipMode(action);
  };

  const handleVideoStart = (player, assemblyItem, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //set current time of video to 0 on start video
    const watchedVd = 0;
    const duration = Math.round(player.getDuration());
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(assemblyItem, watchedVd, true, duration).then(
        (res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
          // setWatchPercentage(res.data[0].watch_percentage);
          if (parseFloat(res.data[0].watch_percentage) >= 95) {
            updateWatchCountService(assemblyItem)
              .then((res) => {
                props.parentGetLearningMaterialData(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
            setResumeBtn(false);
          }
  
          //Set resume time of video
          player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const setCustomeCurrentVideoId = (videoId) => {
    setCurrentVideoId1(videoId);
  };

  const handleFileTracking = (itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus1(true);
      setDocumentId1([...documentId1, itemId]);
      props.parentGetLearningMaterialData(props.parentSubId);
      props.refreshProgress(props.lesson.module_id);
    });
    getSubContentMethod();
  };

  const handleVideoResume = (player, assemblyItem, videoId, action) => {
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());

    const duration = Math.round(player.getDuration());

    if (action == false) {
      getVideoTimeService(assemblyItem).then((res) => {
        // setWatchTime(res.data[0].watchedTimeSecond);
        // setWatchPercentage(res.data[0].watch_percentage);

        //Set resume time of video
        //player.seekTo(res.data[0].watchedTimeInSecond);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
        if (parseFloat(res.data[0].watch_percentage) >= 95) {
          updateWatchCountService(assemblyItem)
            .then((res) => {
              props.parentGetLearningMaterialData(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
          setResumeBtn(false);
        }
      });
    } else {
      let updateVideoTime = true;
      if(password!=ADMIN_PASSWORD){
        updateVideoTimeService(
          assemblyItem,
          watchedVd,
          updateVideoTime,
          duration
        ).then((res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
          // setWatchPercentage(res.data[0].watch_percentage);
          if (parseFloat(res.data[0].watch_percentage) >= 95) {
            updateWatchCountService(assemblyItem)
              .then((res) => {
                props.parentGetLearningMaterialData(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          //Set resume time of video
          // player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        });
      }
    }
    setPlaying(action);

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const handleVideoInProgress = (player, assemblyItem, videoId) => {
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        assemblyItem,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0].watch_percentage);
        if (parseFloat(res.data[0].watch_percentage) >= 95) {
          updateWatchCountService(assemblyItem)
            .then((res) => {
              props.parentGetLearningMaterialData(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        props.parentGetLearningMaterialData(props.parentSubId);
        props.refreshProgress(props.lesson.module_id);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
      });
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const handleVideoRewind = (player, assemblyItem, videoId) => {
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        assemblyItem,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0].watch_percentage);
        if (parseFloat(res.data[0].watch_percentage) >= 95) {
          updateWatchCountService(assemblyItem)
            .then((res) => {
              props.parentGetLearningMaterialData(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        props.parentGetLearningMaterialData(props.parentSubId);
        props.refreshProgress(props.lesson.module_id);
      });
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const handleVideoReady = (player, assemblyItem, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);
    const watchedVd = 0;
    let updateVideoTime = false;
    setAlreadyWatched(true);
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(assemblyItem, watchedVd, updateVideoTime).then(
        (res) => {
          setWatchTime(res.data[0].watchedTimeSecond);
          setWatchPercentage(res.data[0].watch_percentage);
          //  player.seekTo(res.data[0].watchedTimeInSecond);
          if (parseFloat(res.data[0].watch_percentage) >= 95) {
            updateWatchCountService(assemblyItem)
              .then((res) => {
                props.parentGetLearningMaterialData(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0].watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          props.parentGetLearningMaterialData(props.parentSubId);
          props.refreshProgress(props.lesson.module_id);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }

    props.parentSetCurrentVideoId(videoId);
  };

  const downloadFile = async (absolutePath, fileName, itemId) => {
    if (isAdmin != 1) {
      props.parentFileTracking(itemId);
    }
    let value = await download(absolutePath, fileName);
  };

  const handleViewItem = async (itemId) => {
    if (isAdmin != 1) {
      props.parentFileTracking(itemId);
    }    
  };

  const handleItem = (item) => {
    if (props.isAdmin == 1) {
      getAssembliesItemAdmin(item).then((res) => {
        setsubAssembliesItems(res.data);
        setModuleId(item);
      });
    } else {
      getAssembliesItem(item).then((res) => {
        setsubAssembliesItems(res.data);
        setModuleId(item);
      });

    }

  };

  const fetchIcon = (path) => {
    let ext = path.split('.')
    switch (ext[ext.length - 1]) {
      case 'docx':
        return 'fal fa-file-word';
        break;
      case 'zip':
        return 'fal fa-file-archive';
        break;
      case 'pdf':
        return 'fal fa-file-pdf';
        break;
      case 'xlsx':
        return 'fal fa-file-excel';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'fal fa-file-image';
        break;
      case 'txt':
        return 'fal fa-file-alt';
        break;
      case 'pptx':
        return 'fal fa-file-powerpoint';
        break;
      default:
        return 'fal fa-file';
        break;
    }
  }

  return (
    <>
      <li key={assemblyItem.item_id}>
        <div className={"sub-body" + (assemblyItem.item_type === 2 ? ' video-sec' : '')}>
          {/* {assemblyItem.item_type === 2 ?(
             <div className="card-list-inner d-flex">
             <div className="card-list-left">
                <div className="card-list-ic d-flex">
                   <div className="card-media card-blue-1">
                      <i className="fal fa-video"></i>
                   </div>
                   <div className="card-media-text">
                      <p>Watch: interoduction</p>
                      <span><i className="fal fa-clock"></i> 10m 58s </span>
                   </div>
                </div>
             </div>
             <div className="card-list-right ml-auto">
                <ul className="learningright-inner d-flex align-items-center">
                   <li className="border-left item-progress">
                      <div className="progress border-left">
                         <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: '100%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">100%</div>
                      </div>
                   </li>
                   <li className="border-left item-action">
                      <div className="media-gp">
                         <a href="#" className="btn btn-white-bordered"><i className="fal fa-play"></i> Watch Video</a>
                      </div>
                   </li>
                   <li className="item-status">
                      <div className="header-status ">
                         <div className="status-gol bg-success">
                            <i className="fal fa-check"></i>
                         </div>
                      </div>
                   </li>
                </ul>
             </div>
          </div>

          ):null} */}

          {assemblyItem.item_type === 2 ? (
            <VideoPlayer
              isAdmin={isAdmin}
              ParentResumeBtn={resumeBtn}
              ParentHandleVideoStart={handleVideoStart}
              playing={playing}
              videoId={videoId}
              lessonindex={lessonindex}
              loopId={assemblyItem.item_id}
              assemblyName={assemblyItem.item_name}
              assemblyDescription={assemblyItem.item_name}
              videoType={assemblyItem.videoType}
              url={assemblyItem.youtube_url}
              item_url={assemblyItem.item_url}
              parentRefId={`${lesson.module_id}_${assemblyItem.item_id}`}
              watchedVideo={watchedVideo}
              video_duration={assemblyItem.video_duration}
              ParentHandleVideoResume={handleVideoResume}
              ParentHandleVideoInProgress={handleVideoInProgress}
              ParentHandleVideoReady={handleVideoReady}
              ParentHandleVideoRewind={handleVideoRewind}
              watch_in_m_s={watchTime}
              watchPercentage={watchPercentage}
              isWatchedVideo={isWatchedVideo}
              alreadyWatched={alreadyWatched}
              currentVideoId={currentVideoId}
              playerSeek={playerSeek}
              ParentPlayerPipMode={playerPipMode}
              ParentHandleVideoTogglePIP={handleVideoTogglePIP}
            />
          ) : null}

          {/* {assemblyItem.item_type === 3 ? (
            <div
              className="hd-main d-flex flex-wrap"
              data-toggle="collapse"
              data-parent="#accordion"
              onClick={() => handleItem(assemblyItem.item_id)}
              href={"#accordionBodyOne" + assemblyItem.item_id}
              key={assemblyItem.item_id}
            >
              <div className="sub-header">
                <h3>{assemblyItem.item_name}</h3>
                <p>{assemblyItem.item_desc}</p>
              </div>
            </div>
          ) : null} */}

          {assemblyItem.item_type === 3 ? (
            <div
              className="learning-material-card"
              key={"learning-material-card" + assemblyItem.item_id}
            >
              <div
                className="hd-main d-flex flex-wrap"
                data-toggle="collapse"
                data-parent="#accordion"
                onClick={() => handleItem(assemblyItem.item_id)}
                href={"#accordionBodyOne" + assemblyItem.item_id}
                key={assemblyItem.item_id}
              >
                <div className="learning-material-head borderprimary">
                  <div className="learning-left ">
                    <h3>
                      {lessonindex} {assemblyItem.item_name}
                    </h3>
                    <span className="txt-smll">{assemblyItem.item_desc}</span>
                  </div>
                  <div className="learning-right ml-auto">
                    <ul className="learningright-inner d-flex align-items-center">
                      {/* <li className="bar-line">
                        <div className="barImg">
                          <i className="fal fa-tasks"></i>
                        </div>
                      </li> */}

                      <li className="material-info deep-text">
                        <i title="Videos" className="fal fa-video" />  {assemblyItem.assembliesVidoesFile} 
                        <i title="Documents" className="fal fa-file ml-2" />  {assemblyItem.assembliesDocumentFile} 
                        <i title="Quiz" className="fal fa-clipboard-list ml-2" />  {assemblyItem.assembliesQuiz}                                
                        <i title="Link" className="fal fa-external-link ml-2" />  {assemblyItem.assembliesLinkFile}                                
                      </li>
                      {assemblyItem.progressPercentag &&
                        assemblyItem.progressPercentag != null ? (
                        <li className="item-progress">
                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              style={{
                                width: assemblyItem.progressPercentag + "%",
                              }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {assemblyItem.progressPercentag}%
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
                <div className="header-status">
                  <div className="status-gol card-blue-1">
                    <i className="fal fa-chevron-down"></i>
                  </div>
                </div>
              </div>
              <div
                className="learning-material-body collapse"
                id={"accordionBodyOne" + assemblyItem.item_id}
              >
                <ul className="sub-ul">
                  {subassembliesItems.length
                    ? subassembliesItems.map((assemblyItem1, index1) => {
                      return (
                        <SubAssembliesItems
                          isAdmin={isAdmin}
                          key={index1}
                          parentSubId={assemblyItem.item_id}
                          parentGetLearningMaterialData={
                            handleItem
                          }
                          assemblyItem={assemblyItem1}
                          setQuizId={setQuizId}
                          lessonindex={
                            lessonindex != ""
                              ? lessonindex + "." + parseInt(index1 + 1)
                              : ""
                          }
                          setshow={setshow}
                          setrId={setrId}
                          lesson={lesson}
                          parentSetCurrentVideoId={setCustomeCurrentVideoId}
                          parentFileTracking={handleFileTracking}
                          currentVideoId={currentVideoId1}
                          getSubContentMethod={getSubContentMethod}
                          buttonCheckedStatus={buttonCheckedStatus1}
                          documentId={documentId1}
                          refreshProgress={refreshProgress}
                          refreshParentProgress={()=>{props.parentGetLearningMaterialData(props.parentSubId)}}
                        />
                      );
                    })
                    : null}
                </ul>
                {/* ----------------ul-------------------- */}
              </div>
            </div>
          ) : null}
          {assemblyItem.item_type === 4 ? (
            <div className="card-list-inner d-flex ">
              <div className="card-list-left">
                <p className="lead-text-sm m-0">
                  <i className={fetchIcon(assemblyItem.absolutePath)}></i>
                  {lessonindex} {assemblyItem.item_name}
                </p>
              </div>
              <div className="card-list-right ml-auto">
                <ul className="learningright-inner d-flex align-items-center">
                  <li className="border-left item-action">
                    <div className="media-gp">
                      <a
                        title="Download"
                        onClick={() => {
                          downloadFile(
                            assemblyItem?.upload_type === "library" ? image_url(assemblyItem?.upload_link) : assemblyItem.absolutePath,
                            // assemblyItem.document,
                            assemblyItem.item_name+"."+(assemblyItem?.upload_type === "library" ? assemblyItem?.upload_link?.split(".").reverse()[0] : assemblyItem.document.split(".").reverse()[0]),
                            assemblyItem.item_id
                          );
                        }}
                        className="btn btn-white-bordered"
                      >
                        <i className="fal fa-download"></i> Download
                      </a>
                    </div>
                  </li>
                  {assemblyItem.buttonCheckedStatus ||
                    (buttonCheckedStatus &&
                      documentId.includes(assemblyItem.item_id)) ? (
                    <li className="item-status">
                      <div className="header-status ">
                        <div className="status-gol bg-success">
                          <i className="fal fa-check"></i>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          ) : null}

          {/* Information */}
          {assemblyItem.item_type === 7 ? (
            <div className="card-list-inner d-flex ">
              <div className="sub-header">
                <h3>
                  {lessonindex} {assemblyItem.item_name}
                </h3>
                <p>{assemblyItem.item_desc}</p>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 5 ? (
            <div className="card-list-inner d-flex ">
              <div className="card-list-left">
                <p className="lead-text-sm m-0">
                  <i className="fal fa-external-link"></i>
                  {lessonindex} {assemblyItem.item_name}
                </p>
              </div>
              <div className="card-list-right ml-auto">
                <ul className="learningright-inner d-flex align-items-center">
                  <li title="View" className="border-left item-action">
                    <div className="media-gp">
                      <a
                        title="View"
                        onClick={()=>{handleViewItem(assemblyItem.item_id)}}
                        href={assemblyItem.link_url}
                        target="_blank"
                        className="btn btn-white-bordered"
                      >
                        <i className="fal fa-eye"></i> View
                      </a>
                    </div>
                  </li>
                  {assemblyItem.buttonCheckedStatus ||
                    (buttonCheckedStatus &&
                      documentId.includes(assemblyItem.item_id)) ? (
                    <li className="item-status">
                      <div className="header-status ">
                        <div className="status-gol bg-success">
                          <i className="fal fa-check"></i>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          ) : null}

          {assemblyItem.item_type === 6 ? (
            <div className="card-list-inner d-flex">
              <div className="card-list-left">
                <div className="card-list-ic d-flex">
                  <div className="card-media card-red-1">
                    <i>
                      <img src={QuizeImage} alt="" />
                    </i>
                  </div>
                  <div className="card-media-text">
                    <p>
                      {lessonindex} {assemblyItem.item_name}
                    </p>
                    {/* <span>50 Points </span> */}
                  </div>
                </div>
              </div>
              <div className="card-list-right ml-auto">
                <ul className="learningright-inner d-flex align-items-center">
                  <li className="border-left item-progress">
                    {assemblyItem.quiz_percentage > 0 && (
                      <div className="progress border-left">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: assemblyItem.quiz_percentage + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {assemblyItem.quiz_percentage}%
                        </div>
                      </div>
                    )}
                  </li>
                  <li className="border-left">
                    <div className="media-gp">
                      {assemblyItem.button_status && (
                        <a
                          title="Result"
                          onClick={() => {
                            if (assemblyItem.button_status) {
                              setQuizId(assemblyItem.quiz_id);
                              setshow({ attempt: true });
                            }
                          }}
                          className="btn btn-white-bordered"
                          style={{
                            cursor: assemblyItem.button_status
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          <i className="fal fa-sign-in-alt"></i> Result{" "}
                        </a>
                      )}
                      {isAdmin == 1 ? (<button
                        title="Attempt"
                        disabled="disabled"
                        className="btn btn-white-bordered ml-2">
                        <i className="fal fa-sign-in-alt"></i> Attempt{" "}
                      </button>) : (
                        <a
                          title="Attempt"
                          onClick={() => {
                            if (!assemblyItem.maximum_attempts) {
                              setQuizId(assemblyItem.quiz_id);
                              setshow({ desc: true });
                              props.parentFileTracking(assemblyItem.item_id);
                            }
                          }}
                          className="btn btn-white-bordered ml-2"
                          style={
                            assemblyItem.maximum_attempts
                              ? {
                                cursor: "not-allowed",
                                backgroundColor: "#d7dee8",
                              }
                              : {
                                cursor: "pointer",
                              }
                          }
                        >
                          <i className="fal fa-sign-in-alt"></i> Attempt{" "}
                        </a>)}
                    </div>
                  </li>
                  {assemblyItem.buttonCheckedStatus ? (
                    <li className="item-status">
                      <div className="header-status ">
                        <div className="status-gol bg-success">
                          <i className="fal fa-check"></i>
                        </div>
                      </div>
                    </li>
                  ) : (
                      <li>
                        <div className="header-status">
                          <div
                            className="status-gol bg-danger"
                          // data-toggle="dropdown"
                          >
                            <i className="fal fa-ellipsis-h"></i>
                          </div>
                          <div className="dropdown-menu dropdown-menu-right">
                            <button title="Action" className="dropdown-item" type="button">
                              Action
                            </button>
                            <button title="Another action" className="dropdown-item" type="button">
                              Another action
                            </button>
                            <button title="Something else here" className="dropdown-item" type="button">
                              Something else here
                            </button>
                          </div>
                        </div>
                      </li>
                  )}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
}
