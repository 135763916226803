import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setQuiz } from "../../../store/actions";
import ReactHtmlParser from "react-html-parser";
import HtmlParser from "react-html-parser";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CKEditor } from 'ckeditor4-react';
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  RichUtils,
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { UploadDocument } from "../../../services/QuizService";
import { BASE_URL } from "../../../utils/Constants";
import { FetchFileIcon, GetFileSize, getRandomOrder } from "../../../utils/commonFunction";
import { RenderAttachList } from "../../common/RenderAttachListFeed";

const removeHTMLTagsRegex = /(<([^>]+)>|&nbsp;)/gi;

function OptionList({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  // const onChange = (e, score) => {
  //   var arr = e;
  //   setanswer(arr);
  //   dispatch(setQuiz([item.qid, { ...question, selected: arr, score }]));
  //   refreshprops();
  // };
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${item.qid}qa`, { ...question, selected: arr }]));
    }
    refreshprops();
  };
  return (
    // <li
    //   onClick={(event) => onChange(item.oid, item.score)}
    //   className={(data == item.oid) ? 'active-choice' : ''}
    //   id={`customRadio_${index}`}
    //   value={item.oid}
    // >
    //   {HtmlParser(`${item.q_option}`)}
    // </li>
    <div class="custom-control custom-radio">
      <input
        type="radio"
        class="custom-control-input"
        name="example"
        onChange={(event) => onChange(event)}
        // defaultChecked={data == item.oid}
        checked={data == item.oid}
        id={`customRadio_${index}`}
        value={item.oid}
      />
      <label class="custom-control-label" htmlFor={`customRadio_${index}`}>
        <span className={"quiz__single__ans__active__dot " + (data == item.oid ? 'active' : '')}></span>
        <li
          // onClick={(event) => onChange(item.oid, item.score)}
          className={(data == item.oid) ? 'active-choice' : ''}
          id={`customRadio_${index}`}
          value={item.oid}
        >
          {HtmlParser(`${item.q_option}`)}
        </li>
      </label>
    </div>
  );
}

function OptionListCheckbox({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  var data = "";
  try {
    // data = answer[index];
    if (Array.isArray(answer)) {
      data = answer.indexOf(item.oid + "");
    } else {
      data = "";
    }
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var checked = 0;
    // const correctanswer = question.options.filter((itm, i) => itm.score != 0);
    if (e.target.checked) {
      var arr = answer.map((item) => item);
      // arr[index] = e.target.value;
      arr.push(e.target.value);
      setanswer(arr);

      arr.map((data) => {
        if (data != "" && data != null) {
          checked++;
        }
      });
     
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${item.qid}qa`,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      }
      
    } else {
      var arr = answer.map((item) => item);
      // arr[index] = "";
      const index = arr.indexOf(e.target.value + "");
      if (index > -1) {
        arr.splice(index, 1);
      }
      setanswer(arr);
      arr.map((data) => {
        if (data != "" && data != null) {
          checked++;
        }
      });
      // question.options.map((itm, i) => {
      //   if(arr.includes(itm.oid.toString())){
      //     score = score + itm.score;
      //   }
      //   // const isExist = arr.indexOf(itm.oid + "") != -1 ? true : false;
      //   // // if (itm.oid == arr[i]) {
      //   // if (isExist) {
      //   //   // score += itm.score;
      //   //   score = itm.score > 0 ? score + itm.score : 0;
      //   // }
      //   // correctscore += itm.score;
      // });
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${item.qid}qa`,
            {
              ...question,
              selected: arr,
              // score: score == correctscore ? correctscore : score,
              // score: score,
            },
          ])
        );
      }
    }
    refreshprops();
  };
  return (
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        name="example"
        type="checkbox"
        value={item.oid}
        id={`customCheck_${index}`}
        // checked={data == item.oid}
        // defaultChecked={data == item.oid}
        checked={
          (data != undefined && data != "" && data != null && data != -1) ||
            data == 0
            ? true
            : false
        }
        // defaultChecked={
        //   (data != undefined && data != "" && data != null && data != -1) ||
        //     data == 0
        //     ? true
        //     : false
        // }
        onChange={(event) => onChange(event)}
      />

      <label class="custom-control-label m-choice-option col-md-12" for={`customCheck_${index}`}>
        {ReactHtmlParser(
          `${item.q_option}`.replace(/<\/?[^>]+>/gi, "")
        )}
      </label>
    </div>
  );
}

// function OptionListMatch({
//   grpId,
//   qInd,
//   item,
//   index,
//   question,
//   refreshprops,
//   answer,
//   setanswer,
//   choice,
//   setchoice,
//   setLeftInnerHeight,
//   finalHeightArr,
//   itemst
// }) {
//   var data = "";
//   try {
//     data = answer[index];
//   } catch (error) {
//     data = "";
//   }
//   const innerRef = useRef(null);
//   const dispatch = useDispatch();
//   const onChange = (e) => {
//     var arr = answer.map((item) => item);
//     arr[index] = e.target.value;
//     setanswer(arr);
//     var ch = choice.map((itm) => itm);
//     var ind = e.nativeEvent.target.selectedIndex;
//     if (e.nativeEvent.target[ind].text != "-Select-") {
//       ch[index] = item.q_option + "___" + e.nativeEvent.target[ind].text;
//     } else {
//       ch[index] = "";
//     }
//     setchoice(ch);
//     var score = 0;
//     var scoreArr = [];
//     question.options.map((itm, i) => {
//       if (itm.oid == arr[i]) {
//         scoreArr[i] = itm.score;
//         score += itm.score;
//       } else {
//         scoreArr[i] = 0;
//       }
//     });
//     scoreArr.map((item, index) => {
//       if (item > 0) {
//       } else {
//         score = 0;
//       }
//     });
//     if(grpId){
//       dispatch(
//         setQuiz([
//           `qa${grpId}qa`, qInd, 
//           { ...question, selected: arr, choice: ch, score, scoreArr },
//         ])
//       );
//     } else {
//       dispatch(
//         setQuiz([
//           `qa${item.qid}qa`,
//           { ...question, selected: arr, choice: ch, score, scoreArr },
//         ])
//       );
//     }
//     refreshprops();
//   };

//   useEffect(() => {
//     if(innerRef.current){
//       const height = innerRef.current.offsetHeight;
//       setLeftInnerHeight(old=>[...old,height]);
//     }
//   }, []);

//   return item?.q_option !== "" ? (
//     <li class="d-flex list-option p-0" style={{minHeight:`${finalHeightArr[index]}px`,alignItems:"flex-start"}}>
//       <span ref={innerRef} style={{padding:"2px 8px"}}>
//         {ReactHtmlParser(
//           `<span>${item.q_option}</span>`
//         )}
//       </span>
//       {/* <select class="form-control" value={data} onChange={onChange}>
//         <option value={""}>-Select-</option>
//         {question.options.map((itm) => {
//           return (
//             <option value={itm.oid}>
//               {itm.q_option_match.replace(/<\/?[^>]+>/gi, "")}
//             </option>
//           );
//         })}
//       </select> */}
//     </li>
//   ) : null;
// }

function OptionShortAnswer({
  grpId, 
  qInd,
  item,
  index,
  question,
  refreshprops,
  answer,
  setanswer,

}) {

  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    }
    refreshprops();
  };

  const ckOnChange = (editor) => {
    setanswer(editor);
    var score = editor == item.q_option ? item.score : 0;
    dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: editor, score }]));
    refreshprops();
  }

  return (
    <div class="form-group">
      <textarea
        type="text"
        value={data}
        onChange={onChange}
        class="form-control"
        placeholder="Short Answer"
      ></textarea>
      {/* <CKEditor
        initData={HtmlParser(data)}
        onChange={({ editor }) => {
          // setEditorState(editor.getData());
          ckOnChange(editor.getData())
          // console.log('the value of the current editor is ', editor)
        }}
        onInstanceReady={({ editor }) => {
          editor.setData(HtmlParser(data));
        }}
        config={{
          preset: 'full',
          // use these plugins if ckeditor preset is set to standard and not full
          extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles',
          // extraPlugins:'dialog',
          // toolbar: 'full',
          //below code is to run videos in ckeditor
          embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        }}
      /> */}
    </div>
  );
}

function OptionLongAnswer({ grpId, qInd, question, refreshprops, answer, setanswer }) {
  var data = "";
  try {
    data = answer;
  } catch (error) {
    data = "";
  }
  const dispatch = useDispatch();
  const onChange = (e) => {
    var arr = e.target.value;
    setanswer(arr);
    // dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: arr }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: arr }]));
    }
    // refreshprops();
  };
  // const ckOnChange = (editor) => {
  //   setanswer(editor);
  //   if(grpId){
  //     dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: editor }]));
  //   } else {
  //     dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: editor }]));
  //   }
  //   refreshprops();
  // }

  return (
    <div class="form-group">
      <textarea
        type="text"
        value={HtmlParser(data)}
        onChange={onChange}
        class="form-control"
        placeholder="Long Answer"
      ></textarea>
      {/* <CKEditor
        initData={HtmlParser(data)}
        onChange={({ editor }) => {
          // setEditorState(editor.getData());
          ckOnChange(editor.getData())
          // console.log('the value of the current editor is ', editor)
        }}
        onInstanceReady={({ editor }) => {
          editor.setData(HtmlParser(data));
        }}
        config={{
          preset: 'full',
          // use these plugins if ckeditor preset is set to standard and not full
          extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles',
          // extraPlugins:'dialog',
          // toolbar: 'full',
          //below code is to run videos in ckeditor
          embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        }}
      /> */}
    </div>
  );
}

function FillInTheBlanks({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  // console.log("_________Fill In The Blank Answer_____",answer);
  // const inputString = "I am ___ years old and my annual income is ___ and my favorite color is ___ and my favorite food is ___";
  const dispatch = useDispatch();
  const inputString = question.question.replace(removeHTMLTagsRegex, "").replaceAll("&#39;","'");

  const numInputs = inputString.split('___').length - 1;

  const [inputValues, setInputValues] = useState(Array(numInputs).fill(''));
  const [answers, setAnswers] = useState([]);
  useEffect(()=>{
    setInputValues(Array(numInputs).fill(''));
    if(Array.isArray(answer) && answer.length > 0){
      setAnswers(answer?.map(a=>typeof a?.q_option_match === "string" ? a?.q_option_match : ""));
    }
  },[answer]);

  function handleChange(event, index) {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    // console.log("new fill selected->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", newValues);

    setInputValues(newValues);

    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    // setAnswers(newAnswers);
    // setanswer(newAnswers);
    
    // console.log("_________new fill selected_________", newAnswers);
    const selected = newAnswers?.map((item, ind) => {

      return { ...question.options[ind], q_option_match: item};
    });
    setanswer(selected);
    // console.log("_________Selected_________", selected);


    question.options.map(item => {
      if(grpId){
        dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: selected}]));
      } else {
        dispatch(setQuiz([`qa${item.qid}qa`, { ...question, selected: selected}]));
      }
      refreshprops();
    })
  }
  const parts = inputString.split('___');

  const inputBoxes = parts.flatMap((part, index) => {
    const inputIndex = index % inputValues.length;
    // console.log("--------------------------->>>>>>>>",inputIndex,index);
    if (index === parts.length - 1) {
      return <span key={index}>{part}</span>;
    } else {
      return [
      <span key={`part-${index}`}>{part}</span>,
      <input id={`input-${index}`} key={`input-${index}`} className={"form-control"} type="text" onChange={(event) => handleChange(event, inputIndex)} value={answer?.length ? answer[inputIndex]?.q_option_match : inputValues[inputIndex]} />
      ];
    }
  });

  const inputStringWithValues = parts.reduce((result, part, index) => {
    const inputIndex = index % inputValues.length;
    if (index === parts.length - 1) {
      return result + part;
    } else {
      return result + part + inputValues[inputIndex];
    }
  }, '');

  return (
    <div className="text-input-list">
      {inputBoxes}
      <br />
      {/* <button onClick={() => setAnswers([])}>Clear answers</button>
      <ul>
        {answers.map((answer, index) => (
          <li key={index}>{answer}</li>
        ))}
      </ul> */}
    </div>
  );
}

// Choice Matrix
function ChoiceMatrix({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  // console.log(":::::::------answer-----:::::",answer);
  const dispatch = useDispatch();
  const columns = question.options.length ? JSON.parse(question.options[0].q_option).map(item=>item.titleValue) : [];
  const rows = question.options.length ? question.options.map(item => ({ ...item, q_option: JSON.parse(item.q_option) })) : [];
  // console.log(":::ROWS:::",rows);
  const colWidth = { width: `${100 / (columns.length + 1)}%` };

  const [inputValues, setInputValues] = useState(rows.map(item => item.q_option.map(() => (0))));

  const handleChange = (rInd, colInd, optId) => {
    let newValues = [...inputValues];
    newValues[rInd] = newValues[rInd].map((item, ind) => (ind === colInd ? 1 : 0));
    // console.log(":::::::::::::::", newValues);
    setInputValues(newValues);

    // matching user answer
    const matched = rows.map((row, ind) => {
      if (optId === row.oid) {
        let matchingOrder = false;
        for (let i = 0; i < row.q_option.length; i++) {
          const isSelected = row.q_option[colInd].isSelected === true ? 1 : 0;
          if (isSelected === newValues[rInd][colInd]) {
            matchingOrder = true;
            break;
          } else {
            matchingOrder = false;
          }
        }
        // console.log("::::matching->",matchingOrder);
        return { ...row, q_option_match: row.q_option[colInd], score: matchingOrder ? row.score : 0 };
      } else {
        if (answer?.length) {
          return { ...row, q_option_match: answer[ind].q_option_match, score: answer[ind].score };
        } else {
          return { ...row, q_option_match: "", score: 0 }
        }
      }
    });
    
    // console.log(":::::::::::Matched::::::::::>",matched);
    setanswer(matched);
    // score
    let score = 0;
    matched.map(item=>{score+=item.score;return null;});
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: matched, score: score}]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: matched, score: score}]));
    }
    refreshprops();
  }

  return (
    <table className="choice__matrix__wrapper">
      <thead>
        <tr>
          <th style={colWidth}></th>
          {columns.map((col, colInd) => (<th key={colInd} style={colWidth}>{col}</th>))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rInd) => (
          <tr key={rInd}>
            <td style={colWidth}>{row?.titleValue}</td>
            {columns.map((col, colInd) => (<td key={colInd} style={colWidth}>
              <input type="radio" name={row?.oid} checked={(answer && answer[rInd] && answer[rInd]?.q_option) ? (answer[rInd]?.q_option[colInd]?.titleValue === answer[rInd]?.q_option_match?.titleValue ? true : false) : (inputValues[rInd][colInd] === 1 ? true : false)} onChange={() => handleChange(rInd, colInd, row.oid)} />
            </td>))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
// Document Upload
function DocumentUpload({
  grpId,
  qInd,
  question,
  refreshprops,
  answer,
  setanswer,
}) {
  // console.log(":::::::------answer-----:::::",answer);
  const dispatch = useDispatch();
  const [filess, setFiless] = useState([]);

  useEffect(()=>{
    setFiless(answer && answer?.length > 0 ? answer : []);
  },[answer]);
  
  const handleSubmit = (files, allFiles) => { 
    // console.log("::::::FILES:::::::",files,allFiles); 
    // setanswer(files.map(f=>f.file));
    refreshprops();
    // let filesURL = [];
    const formData = new FormData();
    files.forEach((f,i) => {
      formData.append(`document[${i}]`, f.file);
    });
    UploadDocument(formData).then(res=>{
      // console.log("_____________ Uploaded FILES ______________",res.data);
      setanswer([...filess,...res.data]);
      // filesURL.push(res.data);
      if(grpId){
        dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: [...filess,...res.data] }]));
      } else {
        dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: [...filess,...res.data] }]));
      }
    }).catch(error=>{
      console.error(error);
    });
    
    allFiles.forEach((file) => file.remove());
  };
  // remove uploaded files
  const handleRemoveFile = (ind)=>{
    let file = filess;
    file.splice(ind.i,1);
    setFiless(file);
    if(grpId){
      dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: file }]));
    } else {
      dispatch(setQuiz([`qa${question.qid}qa`, { ...question, selected: file }]));
    }
  }

  const getFileName = (url) => {
    const name = url?.split("/").reverse()[0];
    return name;
  }

  return <div className="profile-doc-upload-blk">
    <div className="row">
      {/* show/preview uploaded files */}
      {(filess && filess?.length > 0) ? filess?.map((f, i) => {
        return (
          <div className="col-md-4 mb-4" key={i}>
            {RenderAttachList({ fileName: getFileName(f?.filePath), size: f.size, filePath: f?.filePath, handleRemoveFile: handleRemoveFile, showClose: true, i })}
          </div>
        )
      }) : null}
    </div>
    <div className="profile-doc-upload">
      {/* file uploader */}
      <Dropzone
        onSubmit={handleSubmit}
        classNames={{ submitButton: 'btn btn-primary', submitButtonContainer: ' btn-upload' }}
        styles={{ dropzone: { /*minHeight: 225*/ } }}
        accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf"
        inputContent={
          <lable>
            <i className="fas fa-file-upload"></i>
            <h4>
              To submit question, Drop your file here, or <span>browse</span>
            </h4>
            <p>Supports: PDF, DOCX and more</p>
          </lable>
        }
        maxSizeBytes="1000MB"
        submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
      />
    </div>
  </div>
}

function DisplayQuestion(props) {
  const { question, quesno, setquestion, grpId, qInd, showQuesType } = props;
  var timer = null;
  
  const [answer, setanswer] = useState([]);
  const [choice, setchoice] = useState([]);
  const [singleanswer, setsingleanswer] = useState("");
  const [typeanswer, settypeanswer] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [itemst, setItemst] = useState([]);
  const dispatch = useDispatch();
  // const [leftInnerHeight, setLeftInnerHeight] = useState([]);
  const rightInnerRefs = useRef([]);
  const leftInnerRefs = useRef([]);
  const [finalHeightArr, setFinalHeightArr] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [showDocument, setShowDocument] = useState([]);
  const [docsSize, setDocsSize] = useState([]);

  const refreshprops = () => {
    setrefresh(!refresh);
    props.setrefresh(!props.refresh);
  };

  useEffect(() => {
    if (question?.question_type === "Match the Column" || question?.question_type === "Order List") {
      let tempArr = JSON.parse(JSON.stringify(question))
      let tempOption = tempArr?.options ? tempArr.options : []
      let newOptionTemp = []
      if (question.selected.length > 0) {
        if(question?.question_type === "Order List"){
          question.selected.map(i => {
            question.options.map(j => {
              if (j.oid == i) {
                newOptionTemp.push(j)
              }
            })
          })
        }
        if(question?.question_type == "Match the Column"){
          question.selected.map((i, ind)=>{
            question.options.map((opt, index) => {
              if(ind == index){
                newOptionTemp.push({...opt})
              }
            })
          })
        }
        
      }
      if (newOptionTemp.length > 0) {
        tempArr.options = newOptionTemp
      }
      else {
        if (tempOption && question?.question_type === "Order List") {
          shuffleArray(tempOption)
        }
        if (tempOption) {
          tempArr.option = tempOption
        }
      }

      if((question?.question_type=="Order List" || question?.question_type=="Match the Column") && Array.isArray(question?.selected) && question?.selected.length==0){
        const selected = [];
        const selectedItems = [];
        const choice = [];

        tempOption.map((opt)=>{
          selected.push(opt.oid);
          selectedItems.push(opt.q_option);
        });
  
        question.options.map((item, i) =>{
          tempOption.map((opt, j)=>{
            if(i==j){
              if(question?.question_type == "Order List"){
                choice[i] = item.q_option + '___' + opt.q_option
              }else{
                choice[i] = item.q_option + '___' + opt.q_option_match
              }
            }
          })
        })

        if(grpId){
          dispatch(setQuiz([`qa${grpId}qa`, qInd, { ...question, selected: selected, choice: choice, selectedItems: selectedItems  }]))
        }else{
          dispatch(setQuiz([`qa${question.qid}qa`,{ ...question, selected: selected, choice: choice, selectedItems: selectedItems }])
          );
        }
        
      }
      setItemst(tempArr)
      let tId = setTimeout(()=>{
        setIsRender(!isRender);
      },1000);
      return ()=>{clearTimeout(tId)}
    }
  }, [question])

  useEffect(() => {
    try {
      clearTimeout(timer);
      timer = null;
      // setanswer(question.selected);
      timer = setInterval(() => {
        if (question.qid != null) {
          localStorage.setItem(
            `@qa${question.qid}qa`,
            parseInt(localStorage.getItem(`@qa${question.qid}qa`) || 0) + 1
          );
        }
      }, 1000);
    } catch (error) {
      // setanswer(new Array());
    }

    try {
      if(question.question_type=="Short Answer" && Array.isArray(question?.selected) && question?.selected.length){
        setsingleanswer(question?.selected[0]?.q_option);
      }else{
        setsingleanswer(question.selected);
      }
    } catch (error) {
      setsingleanswer("");
    }

    try {
      if(question.question_type=="Long Answer" && Array.isArray(question?.selected) && question?.selected.length){        
        settypeanswer(question?.selected[0]?.q_option);
      }else{
        settypeanswer(question.selected);
      }
    } catch (error) {
      settypeanswer("");
    }

    try {
      setchoice(question.choice);
    } catch (error) {
      setchoice(new Array());
    }

    return () => clearInterval(timer);
  }, [props.question]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = getRandomOrder(i);
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const clearAnswer = () => {
    setsingleanswer("");
    setchoice(new Array());
    setanswer(new Array());
    settypeanswer("");
    refreshprops();
  };

  useEffect(() => {
    // clearAnswer();
  }, [props.clear]);

  useEffect(() => {
    // localStorage.removeItem("totaltime");
    // var ttimer = setInterval(() => {
    //   localStorage.setItem(
    //     "totaltime",
    //     parseInt(localStorage.getItem("totaltime") || 0) + 1
    //   );
    //   refreshprops();
    // }, 1000);
    return () => {
      // clearInterval(ttimer);
      // ttimer = null;
      // localStorage.removeItem("totaltime");
      // for (let i = 0; i < localStorage.length; i++) {
      //   let value = localStorage.key(i);
      //   if (value.startsWith("@")) {
      //     localStorage.removeItem(value);
      //   }
      // }
      // setanswer(new Array());
      setsingleanswer("");
      settypeanswer("");
    };
  }, []);

  function onDragEnd(result, Arr) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      Arr,
      result.source.index,
      result.destination.index
    );
    let temp = {...itemst}
    // console.log("before>>",temp)
    temp.options = [...items]
    // console.log("after>>",temp)
    setItemst({...temp})
    
    const optionRearranged = question?.options.map((opt, ind) =>({...opt, q_option_match : items[ind].q_option_match}));

    let arr = [];
    let selectedItemArr = [];
    let ch = choice.map((item) => item);
    // console.log('the items are like here ', items)
    temp.options.map(i => {
      arr.push((i.oid).toString())
      selectedItemArr.push(i?.q_option);
    })
    question.options.map((i, indexi) => {
      items.map((j, indexj) => {
        if (indexi == indexj) {
          ch[indexi] = i.q_option + '___' + j.q_option_match
        }
      })
    })
    var score = 0;
    var scoreArr = [];
    question.options.map((itm, i) => {
      items.map((j, indexj) => {
        if (i == indexj) {
          if (itm.oid == j.oid) {
            scoreArr.push(j.score)
            score += itm.score;
          }
          else {
            scoreArr.push(0)
          }
        }
      })
    });
    // console.log('the XXXXXXX in the arr is ', score, scoreArr)
    // scoreArr.map((item, index) => {
    //   if (item > 0) {
    //   } else {
    //     score = 0;
    //   }
    // });
    // console.log('_____________ch', ch)
    // console.log('_____________score', score)
    // console.log('_____________scoreArr', scoreArr)
    if(question?.question_type == "Match the Column"){
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            { ...question, options : optionRearranged, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr  },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${items[0].qid}qa`,
            { ...question, options : optionRearranged, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr },
          ])
        );
      }
    }else{
      if(grpId){
        dispatch(
          setQuiz([
            `qa${grpId}qa`, qInd,
            { ...question, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr  },
          ])
        );
      } else {
        dispatch(
          setQuiz([
            `qa${items[0].qid}qa`,
            { ...question, selected: arr, choice: ch, score, scoreArr, selectedItems: selectedItemArr },
          ])
        );
      }
    }
    refreshprops();
  }

  useEffect(()=>{    
    setanswer(question.selected);
    setchoice(question.choice);
    if(question.question_type=="Long Answer" && Array.isArray(question?.selected) && question?.selected.length){      
      settypeanswer(question?.selected[0]?.q_option);
    }else{
      settypeanswer(question.selected);
    }
    if(question.question_type=="Short Answer" && Array.isArray(question?.selected) && question?.selected.length){
      setsingleanswer(question?.selected[0]?.q_option);
    }else{
      setsingleanswer(question.selected);
    }
    if (question?.document) {
      const docArray = JSON.parse(question?.document);
      const docFiles = docArray.map(item => {
        const fileUrl = BASE_URL + "/" + item.replaceAll("/home/myaie/public_html/","").replace("public/","");
        return { url: fileUrl, name: item.split("/").at(-1), size: "0" }
      })
      setShowDocument(docFiles);
      docFiles.map(async item => {
        const size = await GetFileSize(item.url);
        setDocsSize(old=>[...old,size])
        return size;
      })
      // console.log("_______FILE", docFiles);
    }

    return ()=>{
      setShowDocument([]);
      setDocsSize([]);
    }
  },[question])

  useEffect(()=>{
    let lInnerSidearr = [];
    if(leftInnerRefs.current.length){
      leftInnerRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const height = ref.current.clientHeight;
          lInnerSidearr.push(height);
        }
      });
    }

    let rInnerSidearr = [];
    if(rightInnerRefs.current.length){
      rightInnerRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const height = ref.current.clientHeight;
          rInnerSidearr.push(height);
        }
      });
    }
    // console.log("____________________L INNER", lInnerSidearr);
    // console.log("____________________R INNER", rInnerSidearr);
    if(lInnerSidearr.length && rInnerSidearr.length){
      setFinalHeightArr(compareArrays(lInnerSidearr, rInnerSidearr));
    }
  },[itemst,isRender]);

  const compareArrays = (arr1, arr2) => {
    const result = [];
    for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
      const val1 = arr1[i] || Number.NEGATIVE_INFINITY;
      const val2 = arr2[i] || Number.NEGATIVE_INFINITY;
      result.push(Math.max(val1, val2));
    }
    return result;
  }

  // const removeLastBRTag = (text)=>{
  //   if(text){
  //     let splitedQues = text?.toString().split("<p>");
  //     let improvedText = "";
  //     if(splitedQues && splitedQues[splitedQues?.length - 1] === "<br />\r\n&nbsp;</p>\r\n"){
  //       splitedQues.pop()
  //       improvedText = splitedQues.map(item=>{
  //         if(item !== undefined && item !== null && item !== ""){
  //           return "<p>"+item
  //         }
  //       }).join("");
  //       return improvedText;
  //     } else {
  //       improvedText = splitedQues.map(item=>{
  //         if(item !== undefined && item !== null && item !== ""){
  //           return "<p>"+item
  //         }
  //       }).join("");
  //       return improvedText;
  //     }
  //   }
  // }

  return (
    <div>
      {Object.keys(question).length > 0 && (
        // <div class="card card-quiz card-header-dark">
          // <div class="card-header">Question {quesno}</div>
          // <div class="card-body">
            <div class="">
              <label class="d-block mb-3">
                <MathJax inline dynamic>
                <span class='quiz-ques'>
                  <span>{question.question_type === "Fill in the blank" ? <p>Fill in the Blanks</p> : HtmlParser((question?.question))}</span>
                  {showQuesType}
                </span>
                {question?.description ? <span class='quiz-desc'>
                  {HtmlParser(
                    (question?.description)
                    // question.description //.replace(/<\/?[^>]+>/gi, "")
                  )}
                </span> : null}
                  <div className="row">
                    {(showDocument && showDocument.length > 0) ? showDocument.map((f, i) => {
                        return <div className="col-md-4 mb-4" key={i}>
                          {RenderAttachList({ fileName: f?.name, size: docsSize[i], filePath: f?.url })}
                        </div>
                    }) : null}
                  </div>
                </MathJax>
              </label>
              <div class="radio-group-custom radion-group-center">
                {/* Multiple Choice Single Answer */}
                <ul className="questions-quiz-option mb-0">
                  {question.question_type == "Multiple Choice Single Answer" &&
                    question.options.map((item, index) => (
                      <MathJax inline dynamic key={index}>
                      <OptionList
                        grpId={grpId}
                        qInd={qInd}
                        item={item}
                        index={item.oid}
                        question={question}
                        refresh={refresh}
                        setrefresh={setrefresh}
                        refreshprops={refreshprops}
                        answer={singleanswer}
                        setanswer={setsingleanswer}
                      />
                      </MathJax>
                    ))}
                </ul>
                {/* {question.question_type == "Multiple Choice Multiple Answer" &&
                  question.options.map((item, index) => (
                    <OptionList
                      item={item}
                      index={index}
                      question={question}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      refreshprops={refreshprops}
                      answer={singleanswer}
                      setanswer={setsingleanswer}
                    />
                  ))} */}
                {/* Multiple Choice Multiple Answer */}
                {question.question_type == "Multiple Choice Multiple Answer" &&
                  question.options.map((item, index) => (
                    <MathJax inline dynamic key={index}>
                    <OptionListCheckbox
                      grpId={grpId}
                      qInd={qInd}
                      item={item}
                      index={item.oid}
                      question={question}
                      refreshprops={refreshprops}
                      answer={answer}
                      setanswer={setanswer}
                    />
                    </MathJax>
                  ))}
                {/* Match the Column */}
                {question.question_type == "Match the Column" && (
                  <div class="select-list-inline">
                    <ul class="list-unstyled">
                      <div className='col-md-6'>
                        {question?.options && question?.options?.map((item, index) => {
                          leftInnerRefs.current[index] = React.createRef();
                          return (
                          <MathJax inline dynamic key={index}>
                          {/* <OptionListMatch
                            item={item}
                            index={index}
                            question={question}
                            refreshprops={refreshprops}
                            setanswer={setanswer}
                            answer={answer}
                            choice={choice}
                            setchoice={setchoice}
                            setLeftInnerHeight={setLeftInnerHeight}
                            finalHeightArr={finalHeightArr}
                            itemst={itemst}
                          /> */}
                              {item?.q_option !== "" ? (
                                <li class="d-flex list-option p-0" style={{ height: `${finalHeightArr[index]}px`, alignItems: "flex-start" }}>
                                  <span ref={leftInnerRefs.current[index]} style={{ padding: "2px 8px" }}>
                                    {ReactHtmlParser(
                                      `<span>${item.q_option}</span>`
                                    )}
                                  </span>
                                </li>
                              ) : null}
                          </MathJax>
                        )})}
                      </div>
                      
                      <div className='col-md-6'>
                        <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                          <Droppable droppableId={"droppable"+qInd}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{...getListStyle(snapshot.isDraggingOver),padding:"0 0 0 12px"}}
                              >
                                {itemst?.options?.map((item, index) => (
                                  <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} >
                                    {(provided, snapshot) => { 
                                      rightInnerRefs.current[index] = React.createRef();
                                      return (
                                        <div className="mb-12px">
                                          <div
                                            className='match-option'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{...getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            ), height:`${finalHeightArr[index]}px`}}
                                          >
                                           <span ref={rightInnerRefs.current[index]} style={{display:"block",padding:"2px 8px"}}>
                                              <MathJax inline dynamic>
                                                {item?.q_option_match}
                                              </MathJax>
                                            </span>
                                          </div>
                                      </div>
                                    )}}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </ul>
                  </div>
                )}
                {/* Short Answer */}
                {question.question_type == "Short Answer" &&
                  question.options.map((item, index) => (
                    <MathJax inline dynamic key={index}>
                    <OptionShortAnswer
                      grpId={grpId}
                      qInd={qInd}
                      item={item}
                      index={index}
                      question={question}
                      refreshprops={refreshprops}
                      setanswer={setsingleanswer}
                      answer={singleanswer}
                    // editorState={editorState}
                    // setEditorState={setEditorState}
                    />
                    </MathJax>
                  ))}
                {/* Long Answer */}
                {question.question_type == "Long Answer" && (
                  <MathJax inline dynamic>
                  <OptionLongAnswer
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refreshprops={refreshprops}
                    setanswer={settypeanswer}
                    answer={typeanswer}
                  />
                  </MathJax>
                )}
                {/* Fill In The Blank */}
                {question.question_type === "Fill in the blank" &&
                  <MathJax inline dynamic>
                    <FillInTheBlanks
                      grpId={grpId}
                      qInd={qInd}
                      question={question}
                      answer={answer}
                      setanswer={setanswer}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      refreshprops={refreshprops}
                    />
                  </MathJax>}
                {/* Order List */}
                {question.question_type === "Order List" &&
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, itemst.options)}>
                    <Droppable droppableId={"droppable" + qInd}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="order__list__wrapper"
                        >
                          {itemst?.options?.map((item, index) => (
                            <Draggable key={item.oid} draggableId={item.oid?.toString()} index={index} >
                              {(provided, snapshot) => (
                                <div
                                  className='order__list__options'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <MathJax inline dynamic>
                                    {HtmlParser(item.q_option)}
                                  </MathJax>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                }
                {/* Choice Matrix */}
                {question.question_type === "Choice Matrix" &&
                <MathJax inline dynamic>
                  <ChoiceMatrix
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    refreshprops={refreshprops}
                    answer={answer}
                    setanswer={setanswer}
                  />
                </MathJax>}
                {/* Document Upload */}
                {question.question_type === "Document Upload" &&
                  <DocumentUpload 
                    grpId={grpId}
                    qInd={qInd}
                    question={question}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    refreshprops={refreshprops}
                    answer={answer}
                    setanswer={setanswer}
                  />
                }
              </div>
            </div>
          // </div>
        // </div>
      )}
    </div>
  );
}

export default DisplayQuestion;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // console.log('the result of the drag is here ', result)
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 2,
  margin: `0 0 8`,
  // change background colour if dragging
  background: isDragging ? "transparent" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "transparent" : "transparent",
  padding: '0px 8px ',
});

