import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Player from "../components/common/Player";
import $ from "jquery";

class Video extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  componentWillMount() {
    document.body.style.backgroundColor = "black";
}

componentWillUnmount(){
    document.body.style.backgroundColor = null;
}


  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    
    return (
      <>
      
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <Player videoType={this.props.match.params.videoType} videoId={this.props.match.params.videoId}/>
        </div>
      </>
    );
  }
}

export default Video;
