import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import _ from "underscore";
import FeedAttachments from "../group_feed/FeedAttachments";
import FeedComments from "../group_feed/FeedComments";
export default function GroupFeedMessage(props) {
  const { feed } = props;
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="post-by">
        <div className="post-by-left">
          <div className="post-by-img">
            <img src="https://1.gravatar.com/avatar/ad516503a11cd5ca435acc9bb6523536?s=100" />
          </div>
          {/* <div className="post-by-status">
            <i
              className={
                "fas fa-check" +
                (_.contains(props.online, props.feed.id) ? "" : " offline")
              }
            ></i>
          </div> */}
        </div>
        <div className="post-by-right customeChatGroup">
          <div className="ps-by-card">
            <div className="ps-by-card-body">
              {feed && feed.length ? (
                <FeedComments
                  comments={feed}
                  user={user}
                  online={props.online}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
