import React, { useState, useEffect } from "react";
import moment from "moment";

export default function StudentDateBirth(props) {
  const [yearoptions, setYearoptions] = useState([]);
  const [daysoptions, setDaysoptions] = useState([]);

  const {
    bday_day,
    bday_month,
    bday_year,
    formik,
    setBdayDay,
    setBdayMonth,
    setBdayYear,
  } = props;

  const handleYears = () => {
    let options = [];
    for (let index = 1900; index <= moment().year(); index++) {
      options.push(index);
    }
    setYearoptions(options);
  };
  const handleDays = () => {
    let options = [];
    for (let index = 1; index <= 31; index++) {
      options.push(index);
    }
    setDaysoptions(options);
  };

  const handleBdayYear = (e) => {
    setBdayYear(e.target.value);
  };

  const handleBdayMonth = (e) => {
    setBdayMonth(e.target.value);
  };

  const handleBdayDay = (e) => {
    setBdayDay(e.target.value);
  };

  useEffect(() => {
    handleYears();
    handleDays();
  }, []);

  return (
    <>
      <div className="form-group date-form-group col-sm-6">
        <label>Date of Birth*</label>
        <div className="row">
          <div className="col-sm-4 mt-2 mb-2 mt-sm-0 mb-sm-0">
              <select
                className={
                  "form-control " +
                  (formik.touched.bday_year && formik.errors.bday_year
                    ? " is-invalid"
                    : null)
                }
                name="bday_year"
                value={formik.values.bday_year}
                onChange={(e) => handleBdayYear(e)}
                onBlur={formik.handleBlur}
              >
                <option value="">Year</option>
                {yearoptions.map((year) => {
                  return (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  );
                })}
              </select>

            {formik.touched.bday_year && formik.errors.bday_year ? (
              <div className="invalid-feedback">{formik.errors.bday_year}</div>
            ) : null}
          </div>
          <div className="col-sm-5 mt-2 mb-2 mt-sm-0 mb-sm-0">
            {}
            <select
              className={
                "form-control " +
                (formik.touched.bday_month && formik.errors.bday_month
                  ? " is-invalid"
                  : null)
              }
              value={formik.values.bday_month}
              name="bday_month"
              onChange={(e) => handleBdayMonth(e)}
              onBlur={formik.handleBlur}
            >
              <option value="">Month</option>
              {moment.months().map((month, index) => {
                return (
                  <option value={index + 1} key={month}>
                    {month}
                  </option>
                );
              })}
            </select>
            {formik.touched.bday_month && formik.errors.bday_month ? (
              <div className="invalid-feedback">{formik.errors.bday_month}</div>
            ) : null}
          </div>
          <div className="col-sm-3 mt-2 mb-2 mt-sm-0 mb-sm-0">
            <select
              className={
                "form-control " +
                (formik.touched.bday_day && formik.errors.bday_day
                  ? " is-invalid"
                  : null)
              }
              name="bday_day"
              id="bday_day"
              value={formik.values.bday_day}
              onChange={(e) => handleBdayDay(e)}
              onBlur={formik.handleBlur}
            >
              <option value="">Date</option>
              {daysoptions.map((day) => {
                return (
                  <option value={day} key={day}>
                    {day}
                  </option>
                );
              })}
            </select>
            {formik.touched.bday_day && formik.errors.bday_day ? (
              <div className="invalid-feedback">{formik.errors.bday_day}</div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
