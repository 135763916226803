import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { setUser, setUserMissingDetails } from "../store/actions";
import moment from "moment";
import { MOBILE_REGEX } from "../utils/Constants";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";
import { AboutService } from "../services/ProfileService";
import {
  capitalize,
  nationalityList,
  disabilityList,
  languageList,
  checkUserKeys,
} from "../utils/commonFunction";
import { withRouter } from "react-router-dom";

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUser: (payload) => dispatch(setUser(payload)),
    setMissingDetail: (payload) => dispatch(setUserMissingDetails(payload))
  };
}

/** map state */
const mapStateToProps = (state) => ({ user: state.user });


export class ForceAboutDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: true,
      yearoptions: [],
      daysoptions: [],
      nationalityList: nationalityList(),
      disabilityList: disabilityList(),
      languageList: languageList(),
    };
  }

  handleMonths = () => {};
  handleYears = () => {
    let options = [];
    for (let index = 1900; index < moment().year(); index++) {
      options.push(index);
    }
    this.setState({ yearoptions: options });
  };
  handleDays = () => {
    let options = [];
    for (let index = 1; index <= 31; index++) {
      options.push(index);
    }
    this.setState({ daysoptions: options });
  };
  
  checkUserDetails = () => {
    let isRedirecting = checkUserKeys.map(item => {
      if(!this.props.user[item]){
        return false
      } else {
        return true
      }
    })
    if (!isRedirecting.includes(false)) {
      this.props.history.push("/home")
    }
  };
  async componentDidMount() {
    this.handleYears();
    this.handleDays();
    this.checkUserDetails();
  }
  render() {
    var { user = {} } = this.props;
    user = {
      ...user,
      disability_label: user.disability
        ? this.state.disabilityList[user.disability]
        : "",
      nationality_label: user.nationality
        ? this.state.nationalityList[user.nationality]
        : "",
      bday_month_label: moment.months()[user.bday_month - 1],
    };
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="home-list-wrap">
              <div className="wlcome-user-info">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h4 className="my-md-2 my-0">
                      <p>
                        Welcome to the Student Portal! <br /> Please tell us a
                        bit about yourself.
                      </p>
                    </h4>
                  </div>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={user}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("First Name is required"),
                    last_name: Yup.string().required("Last Name is required"),
                    title: Yup.string().required("Title is required"),
                    number: Yup.string().required("ID Number is required"),
                    bday_year: Yup.string().required("Year is required"),
                    bday_month: Yup.string().required("Month is required"),
                    bday_day: Yup.string().required("Day is required"),
                    race: Yup.string().required("Race is required"),
                    nationality: Yup.string().required(
                      "Nationality is required"
                    ),
                    disability: Yup.string().required("Disablity is required"),
                    gender: Yup.string().required("Gender is required"),
                    language: Yup.string().required("Language is required"),
                  })}
                  onSubmit={(values) => {
                    AboutService(values)
                      .then((res) => {
                        Swal.fire({
                          icon: "success",
                          title: "Success",
                          text: "Updated successfully",
                        }).then((result) => {
                          if (result.value) {
                            this.props.setUser(res.data);
                            this.props.setMissingDetail(false)
                            this.props.history.push("/")
                          }
                        });
                      })
                      .catch((error) => {});
                  }}
                >
                  {({
                    values,
                    errors,
                    setFieldValue,
                    status,
                    touched,
                    handleChange,
                    handleBlur,
                  }) => (
                    <div className="card mt-2">
                   <Form>
                    <div className="card card-profile-info-card p-3">
                   <div className="">
                      
                   <div className="row align-items-center">
                        <div className="col-md-4">
                        <div className="form-group" title="First Name">
                        <label>First Name *</label>

                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.first_name && touched.first_name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="First Name"
                          value={values.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Title">
                        <label>Title *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.title && touched.title
                                ? " is-invalid"
                                : "")
                            }
                            name="title"
                            value={values.title}
                            onChange={(value) => { setFieldValue("title", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.title ? capitalize(values.title) : "Title"}
                            options={[
                              { value: 'Mr', label: 'Mr' },
                              { value: 'Mrs', label: 'Mrs' },
                              { value: 'Miss', label: 'Miss' },
                              { value: 'Ms', label: 'Ms' }
                            ]}
                          />
                          {errors.title && touched.title ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Nationality">
                        <label>Nationality *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.nationality && touched.nationality
                                ? " is-invalid"
                                : "")
                            }
                            name="nationality"
                            value={values.nationality}
                            onChange={(value) => {
                              setFieldValue("nationality", value.value)
                              setFieldValue("nationality_label", value.label);
                            }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.nationality_label ? capitalize(values.nationality_label) : "Nationality"}
                            options={Object.keys(this.state.nationalityList).map((key) => {
                              return (
                                { value: key, label: this.state.nationalityList[key] }
                              );
                            })}
                          />
                          {errors.nationality && touched.nationality ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                      </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-md-4">
                        <div className="form-group" title="Second Name">
                        <label>Second Name (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Second Name"
                          value={values.second_name}
                          name="second_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Id Number">
                        <label>Id Number *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.number && touched.number
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Id Number"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.number && touched.number ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Disability">
                        <label>Disability *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.disability && touched.disability
                                ? " is-invalid"
                                : "")
                            }
                            name="disability"
                            value={values.disability}
                            onChange={(value) => {
                              setFieldValue("disability", value.value)
                              setFieldValue("disability_label", value.label);
                            }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.disability_label ? capitalize(values.disability_label) : "Disability"}
                            options={Object.keys(this.state.disabilityList).map((key) => {
                              return (
                                { value: key, label: this.state.disabilityList[key] }
                              );
                            })}
                          />
                          {errors.disability && touched.disability ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-md-4">
                        <div className="form-group" title="Third Name">
                        <label>Third Name (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Third Name"
                          value={values.third_name != 'null' ? values.third_name : ""}
                          name="third_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group date-form-group" title="Date Of Birth">
                        <label>Date Of Birth *</label>
                        <div className="row form-icon-group">
                          <div className="col-4">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_year && touched.bday_year
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_year"
                              value={values.bday_year}
                              onChange={(value) => {
                                setFieldValue("bday_year", value.value)
                                setFieldValue("bday_year", value.label)
                              }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_year ? values.bday_year : ""}
                              options={this.state.yearoptions.map((year) => {
                                return (
                                  { value: year, label: year }
                                );
                              })}
                            />
                            {errors.bday_year && touched.bday_year ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_month && touched.bday_month
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_month"
                              value={values.bday_month_label}
                              onChange={(value) => {
                                setFieldValue("bday_month", value.value)
                                setFieldValue("bday_month_label", value.label)
                              }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_month_label ? values.bday_month_label : ""}
                              options={moment.months().map((month, index) => {
                                return (
                                  { value: index + 1, label: month }
                                );
                              })}
                            />
                            {errors.bday_month && touched.bday_month ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-3">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_day && touched.bday_day
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_day"
                              value={values.bday_day}
                              onChange={(value) => { setFieldValue("bday_day", value.value) }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_day ? values.bday_day : ""}
                              options={this.state.daysoptions.map((key) => {
                                return (
                                  { value: key, label: key }
                                );
                              })}
                            />
                            {errors.bday_day && touched.bday_day ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Gender">
                        <label>Gender *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.gender && touched.gender
                                ? " is-invalid"
                                : "")
                            }
                            name="gender"
                            value={values.gender}
                            onChange={(value) => { setFieldValue("gender", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.gender ? capitalize(values.gender) : "Gender"}
                            options={[
                              { value: 'male', label: 'Male' },
                              { value: 'female', label: 'Female' }
                            ]}
                          />
                          {errors.gender && touched.gender ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                      </div>


                      <div className="row align-items-center">
                        <div className="col-md-4">
                        <div className="form-group" title="Last Name">
                        <label>Last Name *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.last_name && touched.last_name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Last Name"
                          value={values.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Race">
                        <label>Race *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.race && touched.race
                                ? " is-invalid"
                                : "")
                            }
                            name="race"
                            value={values.race}
                            onChange={(value) => { setFieldValue("race", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.race ? capitalize(values.race) : "Race"}
                            options={[
                              { value: 'African', label: 'African' },
                              { value: 'Asian', label: 'Asian' },
                              { value: 'Coloured', label: 'Coloured' },
                              { value: 'Indian', label: 'Indian' },
                              { value: 'White', label: 'White' },
                              { value: 'Other', label: 'Other' }
                            ]}
                          />
                          {errors.race && touched.race ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-group" title="Home Language">
                        <label>Home Language *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.language && touched.language
                                ? " is-invalid"
                                : "")
                            }
                            name="language"
                            value={values.language}
                            onChange={(value) => { setFieldValue("language", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.language ? capitalize(values.language) : "Language"}
                            options={Object.keys(this.state.languageList).map((key) => {
                              return (
                                { value: key, label: this.state.languageList[key] }
                              );
                            })}
                          />
                          {errors.language && touched.language ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>
                        </div>
                      </div>



                      <div className="form-group form-group-save-cancel">
                        <button
                          className="btn btn-save btn-success"
                          type="submit"
                          title="Save"
                        >
                          <i className="fal fa-save"></i>
                          Save
                        </button>
                        <button
                          className="btn btn-close btn-danger"
                          type="button"
                          onClick={() => this.setState({ edit: true })}
                          title="Cancel"
                        >
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-feedback d-block"
                        />

                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="number"
                          component="div"
                          className="invalid-feedback invalid-number d-block"
                        />
                        <ErrorMessage
                          name="bday_year"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="bday_month"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="bday_day"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="race"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="nationality"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="disability"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </div>
                   </div>
                   </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceAboutDetails);

//export default AboutYou
const provinceOptions = [
  { value: "Eastern-Cape", label: "Eastern-Cape" },
  { value: "Free-State", label: "Free-State" },
  { value: "Gauteng", label: "Gauteng" },
  { value: "KwaZulu-Natal", label: "KwaZulu-Natal" },
  { value: "Limpopo", label: "Limpopo" },
  { value: "Mpumalanga", label: "Mpumalanga" },
  { value: "Northern-Cape", label: "Northern-Cape" },
  { value: "North-West", label: "North-West" },
  { value: "Western-Cape", label: "Western-Cape" },
];
