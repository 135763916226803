import React, {  useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import { subjectDuration, subjectDurationUnix } from "../../utils/commonFunction.js";
//import DatePicker from "react-datepicker";
import moment from "moment-timezone";
//import moment from 'moment';

import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";

import { StartModule } from "../../services/MentoredSubjectService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { toggleStartModule } from "../../store/actions/index.js";
// moment.tz.setDefault("Africa/Johannesburg");

const UpdateMentoredSubject = ({ row, updateSubject, shortCourse }) => {
  const dispatch = useDispatch();
  const startModuleButton = useSelector(state => state.buttonStates.startModule);
  let startDate = moment(row?.showStart, "Do MMM YY");
  let endDate = moment(row?.showEnd, "Do MMM YY");
  let days = endDate.diff(startDate, "days");
  let targetStart = "";
  let targetEnd = "";
  let submitButtonText = "";
  let activity = "";
  if (row?.target_start?.trim() == "" || row?.target_start == 0) {
    targetStart = new Date();
  } else {
    targetStart = new Date(moment.unix(row?.target_start).format("YYYY, MM, DD"));
  }

  if(shortCourse){
    targetEnd = endDate;
    submitButtonText = row?.student_intake_status === 0 ?  "Start Course" : "Update Course"
  }else{
    if (row?.target_end?.trim() == "" || row?.target_end == 0) {
      targetEnd = moment().add(days, "days").format("YYYY, MM, DD");
      submitButtonText = "Start Course";
      activity = "subject_started";
    } else {
      targetEnd = moment.unix(row.target_end).format("YYYY, MM, DD");
      submitButtonText = "Update Course";
      activity = "subject_updated";
    }
  }

  const [targetStartDate, setTargetStartDate] = useState(moment(targetStart).toDate());
  const [targetEndDate, setTargetEndDate] = useState(moment(targetEnd).toDate());

  const EndDate = ({ value, onClick }) => (
    <div className="input-group date-input-group">
      <input
        onClick={onClick}
        value={value}
        type="text"
        className="form-control"
        name="targetEndDate"
        id="targetEndDate"
        aria-label="End Date"
        aria-describedby="basic-addon1"
      />
      <div className="input-group-prepend">
        <span onClick={onClick} className="input-group-text" id="basic-addon1">
          <i className="fal fa-calendar-alt"></i>
        </span>
      </div>
    </div>
  );
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      targetStartDate: targetStartDate,
      targetEndDate: targetEndDate,
      note: "",
      subject_id: row?.id,
      activity: activity,
      student_intake_status: 1,
    },
    onSubmit: (values, { resetForm }) => {
      values.targetStartDate = moment
        .utc(targetStartDate, "YYYY-MM-DD HH")
        .local()
        .format("YYYY-MMM-DD");
      values.targetEndDate = moment
        .utc(targetEndDate, "YYYY-MM-DD HH")
        .local()
        .format("YYYY-MMM-DD");
      let checkRes = moment(targetEndDate).isBefore(targetStartDate);
      if(checkRes) {
          Swal.fire({
              icon: "error",
              title: "The end date must be equal or greater then start date.",
          }).then((result) => {});
      } else {
        StartModule(values)
          .then((res) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Successful",
              text: "Course Started Successfully",
              showConfirmButton: true,
            }).then((result) => {
              // setRow(res.data);
              resetForm({});
              updateSubject();
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Unknown Error while saving",
            }).then((result) => {});
          });
      }
    },
  });
  function changeHandler(e) {
    //e is a momentJS DateTime instance
  }
  return (
    <>
      <div className={"card collapse hide mb-3 course-card-custom " + (startModuleButton === true ? "show" : "")} id="startModule" data-parent="#accordionSubject">
        <div className="card-header upper-heading">{row?.student_intake_status === 0 ? "Course Details" : "Course Information"}</div>
        {row?.student_intake_status === 0 ? 
        <p className="header-subtext">
        You are welcome to start the course whenever you're ready, offering you flexibility. The course has a fixed duration that determines when it will finish. 
        If you complete it ahead of time, the ending date will adjust accordingly. Please feel free to share any notes with us. Staying involved will enhance your 
        learning experience. Our support team is available to assist if you have any questions.
        </p>
        :<p className="header-subtext">
        The course has a fixed duration that determines when it will finish. If you complete it ahead of time, the ending date will adjust accordingly. 
        Please feel free to share any notes with us. Staying involved will enhance your learning experience. Our support team is available to assist if you have any questions.
        </p>}
        <div className="card-body">
          
          <form onSubmit={formik.handleSubmit}>
            <div className="theme-form application-form">
              <div className="row">
                {/* <div className="form-group col-sm-6 form-group-date-picker">
                                <label>Start Date</label>
                                <DatePicker  customInput={<StartDate />}
                                    dateFormat='dd/MM/yyyy'
                                    selected={targetStartDate ? targetStartDate:null} 
                                    onChange={date => setTargetStartDate(date)} />
                            </div>
                            <div className="form-group col-sm-6 form-group-date-picker">
                                <label>End Date</label>
                                <DatePicker  customInput={<EndDate />}
                                    dateFormat='dd/MM/yyyy'
                                    selected={targetEndDate ? targetEndDate:null} 
                                    onChange={date => setTargetEndDate(date)}
                                    />
                            </div>  */}
                <div className="form-group col-sm-6 col-md-6 col-lg-3 form-group-date-picker">
                  <label>Start Date</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled
                      inputFormat="dd/MM/yyyy"
                      value={targetStartDate ? targetStartDate : null}
                      onChange={(date) => setTargetStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-3 form-group-date-picker">
                  <label>End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={shortCourse}
                      inputFormat="dd/MM/yyyy"
                      value={targetEndDate ? targetEndDate : null}
                      onChange={(date) => setTargetEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                {row?.mentored_check_box ? (
                  <div className="form-group col-sm-12 col-md-12 col-lg-6">
                    <label>
                      Message to Mentor
                    </label>
                    <textarea
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.note}
                      name="note"
                      id="note"
                      placeholder={shortCourse ? "Message" : "Message"}
                      rows={1}                      
                    ></textarea>
                  </div>
                ) : null}
                <div className="form-group col-12">
                  <i className="fal fa-calendar-alt mr-2"></i>
                  {shortCourse ? (
                    <>Course Duration: {row?.subject_duration} months</>
                  ) : (
                    row?.mentored_check_box ?
                    <>
                    Recommended Start &amp; End Date: {moment.unix(row?.start_recommended_date).format("Do MMM YY")} to {moment.unix(row?.end_recommended_date).format("Do MMM YY")} {subjectDurationUnix(row?.start_recommended_date, row?.end_recommended_date)}
                    </>
                    :<>
                      Recommended Start &amp; End Date: {row?.showStart} to {row?.showEnd} {subjectDuration(row?.showStart, row?.showEnd)}
                    </>
                  )}
                </div>                
                <div className="form-group col-12 m-0">
                  <input onChange={formik.handleChange} value={formik.values.subject_id} name="subject_id" id="subject_id" type="hidden" />
                  <button type="submit" title={submitButtonText} className="btn btn-submit btn-primary">
                    <i className="fal fa-play-circle"></i>
                    {submitButtonText}
                  </button>
                  <button onClick={() => {dispatch(toggleStartModule())}} title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default UpdateMentoredSubject;
