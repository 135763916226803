import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonSubject = () => {
  return (
    <div className="subject-list">
      {Array(1)
        .fill()
        .map((item, index) => (
          <div className="subject-list-card card" key={index}>
            <ul className="subject-info-list list-unstyled d-flex mb-0">
              <li key={index}>
                <Skeleton width={250} height={100} />
              </li>
            </ul>
          </div>
        ))}
    </div>
  );
};
export default SkeletonSubject;
