import React, { useEffect } from "react";
import AboutDetails from "./blocks/AboutDetails";
import LoginDetails from "../profile/blocks/LoginDetails";
import ContactDetails from "../profile/blocks/ContactDetails";
import AddressDetails from "../profile/blocks/AddressDetails";
import PersonalDocuments from "./blocks/PersonalDocuments";
import { useDispatch } from "react-redux";
import { GetPayerDetailService } from "../../services/ProfileService";
import { setPayer } from "../../store/actions";
const PersonalInformation = () => {
  const dispatch = useDispatch();

  let details;

  if (localStorage.getItem("user") != null) {
    details = JSON.parse(localStorage.getItem("user"));
    //console.log("details--- ", details.email);
  }

  useEffect(() => {
    GetPayerDetailService()
      .then((res) => {
        dispatch(setPayer(res.data[0]));
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <AboutDetails />
        </div>
        <div className="col-lg-4">
          <LoginDetails />
          <ContactDetails />
          <PersonalDocuments
            docType={"personal_doc"}
            heading={"Your Personal Documents"}
          />
        </div>
        <div className="col-lg-4">
          <div className="card card-profile-info-card">
            <div className="card-header">Email Account Details</div>
            <div className="card-body">
              <div className="card-body-inr card-body-info">
                <div className="form-group">
                  <label>Email</label>
                  <div className="label-selected form-control">
                    {details != undefined ? details.userPrincipalName : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="label-selected form-control">
                    {/* {details!=undefined?details.password:''} */}
                    Your password is the same as your Student Portal
                    Login Password
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddressDetails />
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
