import React from "react";
import BackgroundImage from "../assets/images/login-bg.jpg";
import LoginImage from "../assets/images/card-header.png";
import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function LoginPage() {
  
  const brandConfig = useSelector((state) => state.brand);
  
  return (
    <div className="login-box-main portal-login d-flex align-items-center" >
      <div className="login-box-w100">
        <div className="login-left" >
          <div className="login-bg-mobile">
            <img
              src={
                brandConfig.configuration &&
                brandConfig.configuration.background_logo
                  ? brandConfig.configuration.background_logo
                  : LoginImage
              }
              alt="Background"
            />
          </div>
          <LoginForm></LoginForm>
        </div>
        <div className="login-right">
          <div className="login-right-img">
            <img
              src={brandConfig.configuration && brandConfig.configuration.background_logo ? brandConfig.configuration.background_logo : LoginImage}
              alt="Background"
            />
          </div>
        </div>
      </div>
    </div>
  );

}

export default LoginPage;


// import React from "react";
// import BackgroundImage from "../assets/images/loginbg.png";
// import LoginForm from "../components/LoginForm";
// import { Link } from "react-router-dom";


// const BackgroundStyle = {
//   backgroundImage: `url(${BackgroundImage})`,
// };

// class LoginPage extends React.Component {
//   componentDidMount() {
//     document.body.classList.add('overflow-inherit')
//   }
//   componentWillUnmount() {
//     document.body.classList.remove('overflow-inherit')
//   }
//   render() {
//     return (
//       <div className="login-box-main portal-login d-flex align-items-center">
//         <div class="login-box-w100" style={BackgroundStyle}>
//           <div class="login-left">
//             <LoginForm></LoginForm>
//           </div>
//           <div class="login-right">
//             <div className="login-right-img">
//               <img
//                 src={require("../assets/images/logright.png")}
//                 alt="Background"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default LoginPage;
