import React from "react";
import $ from "jquery";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import * as Cards from "../components/profile/index";
import {
  GetStatementStatus,
  ProfileCoverDetailService,
  ProfileImageDetailService,
  RemoveProfileCover,
  RemoveProfileImage,
} from "../services/ProfileService";
import { connect } from "react-redux";
import { setUser } from "../store/actions";
import Swal from "sweetalert2";
import TempCoverImage from "../assets/images/default-banner.png";
import TempProfileImage from "../assets/images/default-profile.jpeg";
import { InitalRender, originalValues } from "../components/common/Helper";
import { download } from "../utils/FileDownload";
import { BASE_URL } from "../utils/Constants"
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUser: (payload) => dispatch(setUser(payload)),
  };
}

/** map state */

const mapStateToProps = (state) => ({ user: state.user });
const userStatus = JSON.parse(localStorage.getItem("user"));
// console.log(mapStateToProps())

class ProfilePage extends React.Component {
  mount = false;
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      currentComponent: "PersonalInformation",
      disableStatement : false
    };
    this.inputFileRef = React.createRef();
    this.inputProfileFileRef = React.createRef();
  }




  Tabs = [
    {
      name: "Personal Information",
      component: "PersonalInformation",
      class: "fa-user",
      id: 1,
    },
    {
      name: "Educational Details",
      component: "EducationalDetails",
      class: "fa-graduation-cap",
      id: 2,
    },
    {
      name: "Sponsor/Account Payer",
      component: "SponsorPayer",
      class: "fa-piggy-bank",
      id: 3,
    },
    {
      name: "Certificates",
      component: "Certificates",
      class: "fa-file-alt",
      id: 4,
    },
    {
      name: "Licenses",
      component: "Licenses",
      class: "fa-address-card",
      id: 5,
    },
    {
      name: "Account Statements",
      component: "AccountStatement",
      class: "fa-file-invoice-dollar",
      id: 6,
    },
  ];

  handleTab = (tab) => {
    this.setState({ currentTab: tab.id, currentComponent: tab.component });
    $("body").removeClass("hide-subject-sidebar");
  };

  

  handleTabContent = (component, key) => {
    const Card = Cards[component];
    return <Card key={key} />;
  };

  handleCover = () => {
    this.inputFileRef.current.click();
  };
  handleCoverImage = (e) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("cover", e.target.files[0]);

      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileCoverDetailService(formInputs)
        .then((res) => {
          Swal.close();
          this.props.setUser(res.data);

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {});
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => {});
        });
    }
  };
  removeCoverImage = () => {
    RemoveProfileCover()
      .then((res) => {
        Swal.close();
        this.props.setUser(res.data);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Removed successfully",
        }).then((result) => {});
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {});
      });
  };
  handleImage = () => {
    this.inputProfileFileRef.current.click();
  };
  handleProfileImage = (e) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("image", e.target.files[0]);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileImageDetailService(formInputs)
        .then((res) => {
          Swal.close();
          this.props.setUser(res.data);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {});
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => {});
        });
    }
  };
  removeProfileImage = () => {
    RemoveProfileImage()
      .then((res) => {
        Swal.close();
        this.props.setUser(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Removed successfully",
        }).then((result) => {});
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {});
      });
  };
  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };
  componentDidMount() {  
    if(this.props.user.netsuite_id && this.props.user.netsuite_id!=""){
      GetStatementStatus({id:this.props.user.netsuite_id}).then((res)=>{
        if(res.status == 200){
          console.log(res.data.data);
          const respData = res.data.data;
          if(respData?.BadDebt === true || respData?.HandedOver === true){
            this.setState({disableStatement : true})
          }          
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  }
  // componentWillUnmount() { 
  //   console.log("calling 2")

  //  }




  render() {
    const temp_cover_photo = TempCoverImage;
    const temp_profile_photo = TempProfileImage;
    const {
      cover_photo,
      photo,
      first_name,
      last_name,
      status,
      status_message,
      student_crm_id,
      electronic_student_card,
      electronic_student_card_image,
      netsuite_id,
      student_brand_url,
      student_brand_name
    } = this.props.user;
    let brand;
    if (localStorage.getItem("brandVariables") != null) {
      brand = JSON.parse(localStorage.getItem("brandVariables"));
      //console.log("details--- ", details.email);
    }
    const downloadFile = async (absolutePath) => {
      let fileName = "my_card.pdf";
      absolutePath = BASE_URL+'/'+absolutePath.replace("/home/myaie/public_html/","");
      let value = await download(absolutePath, fileName);
    };
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="profile-wrapper">
              <div className="profile-left bg-white">
                <button
                  className="close-btn-sidebar"
                  onClick={this.handleSideBar}
                >
                  <i className="close-bar close-bar-1"></i>
                  <i className="close-bar close-bar-2"></i>
                  <i className="close-bar close-bar-3"></i>
                </button>

                <ul className="nav nav-pills flex-column profile-tab-pills">
                  {this.Tabs.map((tab, index) => {                    
                    if(((netsuite_id==null || netsuite_id == "" || netsuite_id == undefined) && tab.id===6) || (tab.id == 6 && (brand?.domain_url.includes("learnfast") || brand?.domain_url.includes("cadco") || (student_brand_url && student_brand_url.includes("learnfast")) || (student_brand_url && student_brand_url.includes("cadco"))))){
                      return null
                    }
                    return (
                      <li className="nav-item" title={tab.name} key={tab.id}>
                        {tab.id === 6 && (this.state.disableStatement === true || status == "On Hold") ? 
                        <a
                          className={
                            "nav-link disabled"
                          }
                          data-toggle="tab"
                          href={"#profile-tab-" + tab.id}
                        >
                          <i className={"fa " + tab.class}></i>
                          {tab.name}
                        </a>
                        :
                        <a
                          className={
                            "nav-link " + (tab.id == this.state.currentTab ? "active" : "")
                          }
                          data-toggle="tab"
                          href={"#profile-tab-" + tab.id}
                          onClick={() => this.handleTab(tab)}
                        >
                          <i className={"fa " + tab.class}></i>
                          {tab.name}
                        </a>
                        }
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="profile-right">
                <div className="profile-header">
                  {/* <div className="profile-cover-top">
                    <figure className="m-0">
                      <img
                        src={
                          cover_photo !== "https://myaie.acundefined"
                            ? cover_photo
                            : temp_cover_photo
                        }
                        alt="Your AIE Profile Banner"
                      />
                    </figure>
                    <div className="upload-icon upload-icon-cover">
                      <input
                        type="file"
                        name="cover"
                        ref={this.inputFileRef}
                        onChange={(e) => this.handleCoverImage(e)}
                      />
                      <i
                        className="fal fa-edit"
                        onClick={() => this.handleCover()}
                      ></i>
                    </div>
                  </div> */}
                 {status ? status == "Results Witheld" && this.state.currentTab == 4 ? <></> : <> <div className="profile-cover-top">
                    <figure className="m-0">
                      {" "}
                      <img
                        src={
                          cover_photo !== null ? cover_photo : temp_cover_photo
                        }
                        alt=""
                      />
                    </figure>
                    <div className="upload-drop-down-wrap">
                      <div className="dropdown">
                        <button
                          title="Profile Picture"
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fal fa-ellipsis-v"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <ul className="list-unstyled">
                            <li title="Upload Cover Picture">
                              <div className="upload-image-option">
                                <input
                                  type="file"
                                  name="cover"
                                  ref={this.inputFileRef}
                                  onChange={(e) => this.handleCoverImage(e)}
                                />
                                <i
                                  className="fal fa-camera"
                                  onClick={() => this.handleCover()}
                                ></i>
                                Upload Cover Picture
                              </div>
                            </li>
                            {cover_photo !== null ? (
                              <li title="Remove Cover Picture">
                                <button onClick={() => this.removeCoverImage()}>
                                  <i className="fal fa-trash-alt"></i>Remove
                                  Cover Picture
                                </button>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="avtar-bottom avtar-card-block">
                    {/* <div className="avtar-bot-lft">
                      <div className="profile-img">
                        <img
                          src={
                            photo !== "https://myaie.acundefined"
                              ? photo
                              : temp_profile_photo
                          }
                          alt="Your AIE Profile Photo"
                        />
                      </div>
                      <div className="upload-icon upload-icon-avtar">
                        <input
                          type="file"
                          name="avtar"
                          accept="image/*"
                          ref={this.inputProfileFileRef}
                          onChange={(e) => this.handleProfileImage(e)}
                        />
                        <i
                          className="fal fa-camera"
                          onClick={() => this.handleImage()}
                        ></i>
                      </div>
                    </div> */}
                    <div className="avtar-card-left">
                      <div className="avtar-bot-lft">
                      <div className="profile-img">                      
                        {/* <img                        
                          src={photo==null?TempProfileImage:photo}
                          alt="Your AIE Profile Photo"
                        /> */}
                        {photo === null ? InitalRender(originalValues(first_name + " " + last_name, 23)) : <img
                          src={photo}
                          alt="" onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.closest('.profile-img').append(InitalRender(originalValues(first_name + " " + last_name, 23)));
                            currentTarget.remove();
                          }}
                        />}
                      </div>
                        <div className="upload-drop-down-wrap">
                          <div className="dropdown">
                            <button
                              title="Profile Picture"
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fal fa-ellipsis-v"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <ul className="list-unstyled">
                                <li title="Upload Profile Picture">
                                  <div className="upload-image-option">
                                    <input
                                      type="file"
                                      name="avtar"
                                      accept="image/*"
                                      ref={this.inputProfileFileRef}
                                      onChange={(e) =>
                                        this.handleProfileImage(e)
                                      }
                                    />
                                    <i
                                      className="fal fa-camera"
                                      onClick={() => this.handleImage()}
                                    ></i>
                                    Upload Profile Picture
                                  </div>
                                </li>
                                {photo !== "https://myaie.acundefined" ? (
                                  <li title="Remove Profile Picture">
                                    <button
                                      onClick={() => this.removeProfileImage()}
                                    >
                                      <i className="fal fa-trash-alt"></i>Remove
                                      Profile Picture
                                    </button>
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="avtar-bot-rgt">
                        <h4>
                          {first_name} {last_name}
                        </h4>
                        <label className="d-block">#{student_crm_id}</label>
                        <label>
                          Student Status:{" "}
                          <span className="green">{status ? status : "NA"}</span>
                        </label>
                        <p>
                          {/* Your myAIE Student Portal account has been set to{" "} */}
                          {status_message}
                        </p>
                      </div>
                    </div>
                    {(electronic_student_card !== undefined &&
                      electronic_student_card !== "") ||
                    (electronic_student_card_image !== undefined &&
                      electronic_student_card_image !== "") ? (
                      <div className="avtar-card-right">
                        <div className="avtar-card-item">
                          <i class="fal fa-id-card"></i>
                          <div className="avtar-card-actions">
                            {electronic_student_card === undefined ||
                            electronic_student_card === "" ? null : (
                              <a
                                target="_blank"
                                onClick={() =>
                                  downloadFile(electronic_student_card)
                                }
                                title="Download"
                              >
                                <i className="fal fa-download"></i>
                              </a>
                            )}
                            {electronic_student_card === undefined ||
                            electronic_student_card === "" ? null : (
                              <a
                                href={
                                  BASE_URL +
                                  "/" +
                                  electronic_student_card.replace(
                                    "/home/myaie/public_html/",
                                    ""
                                  ) + "#zoom=75"
                                }
                                target="_blank"
                                title="View"
                              >
                                <i class="fal fa-eye"></i>
                              </a>
                            )}
                          </div>
                        </div>
                        <h4>Your Student Card</h4>
                      </div>
                    ) : null}
                  </div> </> : <></>}
                </div> 

                <div className="tab-content  profile-tab-content">
                  <div
                    id={"profile-tab-" + this.state.currentTab}
                    className="tab-pane active"
                  >
                    {this.handleTabContent(
                      this.state.currentComponent,
                      this.state.currentTab
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
