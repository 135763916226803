import React from "react";
import Player from "../components/common/Player";

class VideoAdmin extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="content-wrapper d-flex flex-wrap without-header">
        <Player videoType={this.props.match.params.videoType} videoId={this.props.match.params.videoId}/>
        </div>
      </>
    );
  }
}

export default VideoAdmin;
