import React, { useEffect, useState } from 'react';
import downloadIcon from "../../assets/images/download.png";
import {DownloadFileFeedService} from "../../services/FeedService";
import axios from 'axios';

export default function FeedAttachments({ attachments }) {

const [images ,setImages] = useState([]);
const [downloads ,setDownloads] = useState([]);

useEffect(()=>{

    if(attachments.length) {
        const imageArr = attachments.filter(item => {
            return item.fileType === "render"
        })
        const downloadArr = attachments.filter(item => {
            return item.fileType === "download"
        })
        setDownloads(downloadArr);
        setImages(imageArr);
    }


},[attachments]);
const handleDownload = ( e,file ) => {
    e.preventDefault();
    DownloadFileFeedService(file.attachment)
    .then(response => {
        forceFileDownload(response, file)

    })
    .catch((err) => console.log(err))
    // axios({
    //     method: 'get',
    //     url: 'http://127.0.0.1:3333/api/v1/download',
    //     params:{ url : file.attachment},
    //     responseType: 'blob'
    //     })
    //     .then(response => {
    //         console.log(response);
    //         forceFileDownload(response, file)

    //     })
    //     .catch(() => console.log('error occured'))
    //     console.log( file );

}
const forceFileDownload = (response ,item)=> {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', item.originalName) //or any other extension
    document.body.appendChild(link)
    link.click()
}

  return (
    <>
    <div className="attachment-container">
        <div className="image-container">
        <ul className="post-image-ul">
        {images.map((item)=>{
            return (
            <li className="post-image" key={item.id}>
                <a   onClick={(e)=>handleDownload(e,item)}>
                <img src={item.attachment} />
                </a>
            </li>
            )
        })}
        </ul>
        </div>
    </div>
        <div className="files-container">
            <ul className="files-container-ul">
            {downloads.map((item)=>{
            return (
            <li className="files-container-li" key={item.id}>
                 <a
                           
                            onClick={(e)=>handleDownload(e,item)}
                            className="file-link"
                            >
                            <img src={downloadIcon} className="file-download-icon" />
                           
                            <span className="file-original-name">{item.originalName}</span>
                            </a>
            </li>
            )
        })}
            </ul>

        </div>
    </>
  );
}
