import React, { useState } from 'react'
import ExamAvatar from './ExamAvatar'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Select from "react-select";
import { ExamRegistrationRemindMeLater } from '../../services/ExamService';
import Swal from "sweetalert2";

export default function ExamRegistrationForm({ data, reasonDropdownData, update, setUpdate }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemindMeLoading, setIsRemindMeLoading] = useState(false);

  const initialValue = {
    examReg: true,
    reasonType: "",
    reason: "",
  }

  const ExamRegLaunchSchema = Yup.object({
    examReg: Yup.boolean().required("Please check Yes/No"),
    reasonType: Yup.string().when("examReg", {
      is: false,
      then: Yup.string().required("Please select any reason type"),
    }),
    reason: Yup.string().when(["examReg", "reasonType"], {
      is: (examReg, reasonType) => {
        return (examReg === false && reasonType === "10")
      },
      then: Yup.string().required("Reason is required"),
    }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: ExamRegLaunchSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      if (values.examReg === true) {
        history.push(`/myexamdetails/${data.exam_id}`);
        setIsLoading(false);
      } else {
        const params = {
          exam_id: data.exam_id,
          remind: 0,
          submit_reason: 1,
          not_submit_reason: values.reasonType,
          not_submit_reason_description: values.reason,
          
        };
        console.log(params)
        ExamRegistrationRemindMeLater(params).then(res=>{
            formik.resetForm();
            // console.log("after sumbit response",res);
            setIsLoading(false);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Exam Registration reason set successfully",
            }).then((result)=>{
                if(result.isConfirmed){
                    setUpdate(!update)
                    // window.location.href = "/reregStatus";
                    // history.push('/home');
                    // if(!isReReg.isReReg){
                        // history.push('/reregStatus');

                }
            });
        }).catch(error=>{
            console.error(error);
            setIsLoading(false);
        });
      }
    }
  });

  const handleRemindMeLater = () => {
    setIsRemindMeLoading(true);
    let params = {
      exam_id: data.exam_id,
      remind: 1,
      submit_reason: 0,
    };
    if (data?.reregistration_id) {
      params = { ...params, rereg_id: data.reregistration_id }
    }

    console.log(params)
    ExamRegistrationRemindMeLater(params).then(res=>{
        formik.resetForm();
        // console.log("after sumbit response",res);
        setIsRemindMeLoading(false);
        Swal.fire({
            icon: "success",
            title: "Success",
            text: "Remind me later set successfully",
        }).then((result)=>{
            if(result.isConfirmed){
                setUpdate(!update)
                // window.location.href = "/reregStatus";
                // history.push('/home');
                // if(!isReReg.isReReg){
                    // history.push('/reregStatus');

            }
        });
    }).catch(error=>{
        console.error(error);
        setIsRemindMeLoading(false);
    });
  }
  const { handleBlur, handleSubmit, values, errors, touched, setFieldValue } = formik;

  return (
    <div className='launch__form'>
      <div className='card card-profile-info-card mb-4'>
        <div className='card-body re-reg-card'>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4">
              <figure>
                <ExamAvatar />
              </figure>
            </div>
            <div className="col-md-6">
              <form className='d-flex flex-column' noValidate onSubmit={handleSubmit}>
                <h3 className='mt-0 exam-reg-message'>
                  <i className='fal fa-calendar-exclamation mr-1'></i>
                  Exam Registrations close {moment(data?.regCloseDate,"DD MMM YYYY").format("DD MMMM YYYY")}
                  {/* {`moment.unix(data?.early_bird_end_date).format("DD MMM YY")`} */}
                </h3>
                <h4 className='exam-info-message'>Exam Registration is open for {data?.period}</h4>

                <span className='d-flex align-items-center mb-2'>
                  <input id={"examReg1" + data?.id} type='radio' name='examReg' checked={formik.values.examReg} onChange={(e) => setFieldValue(`examReg`, true)} />
                  <label htmlFor={"examReg1" + data?.id} className='ml-2 mb-0'>Yes, I want to register for exams</label>
                </span>
                <span className='d-flex align-items-center'>
                  <input id={"examReg2" + data?.id} type='radio' name='examReg' checked={!formik.values.examReg} onChange={(e) => setFieldValue(`examReg`, false)} />
                  <label htmlFor={"examReg2" + data?.id} className='ml-2 mb-0'>No, I don’t want to register for exams</label>
                </span>
                {values.examReg === false && <div className="form-icon-group mb-2">
                  <Select
                    className={"form-control custom-select-box" + (errors.reasonType && touched.reasonType ? " is-invalid" : "")
                    }
                    name="reasonType"
                    value={reasonDropdownData.filter(item => item.value === values.reasonType)}
                    onChange={(value) => { setFieldValue("reasonType", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder="Select Reason for not registering for exams"
                    options={reasonDropdownData}
                  />
                </div>}
                {(values.reasonType === "10" && values.examReg === false) && <div className="form-group-blk mb-2">
                  <label>Reason *</label>
                  <textarea
                    rows="2"
                    name="reason"
                    value={values.reason}
                    onChange={(e) => setFieldValue("reason", e.target.value)}
                    className={"form-control" + (errors.reason && touched.reason ? " is-invalid" : "")}
                    placeholder={"Explain your other reason why you chose not to re-register."}
                  ></textarea>
                </div>}
                <div className='d-flex align-items-center mt-3'>
                  <button type="submit" className='btn btn-primary' disabled={isLoading}>{isLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>} Submit</button>
                  {(!data?.hard_launch_date || moment.unix(data?.hard_launch_date).format("YY-MM-DD") > moment().format("YY-MM-DD")) &&
                    <button type="button" className='btn btn-primary additional-btn ml-2' onClick={handleRemindMeLater} disabled={isRemindMeLoading}>
                      {isRemindMeLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-bell-slash"></i>}
                      Remind Me Later
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
