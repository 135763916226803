import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/images/loginbg.png";
import LoginImage from "../assets/images/card-header.png";
import ResetPasswordForm from "../components/ResetPasswordForm";
import { useSelector } from "react-redux";
const ResetPassword = () => {
  const brandConfig = useSelector((state) => state.brand);

  return (
    <>
      <div className="portal-login portal-forgot-password d-flex align-items-center">
        <div
          class="login-box-w100"
        >
          <div class="login-left">
            <div className="login-bg-mobile">
              <img
                src={
                  brandConfig.configuration &&
                  brandConfig.configuration.background_logo
                    ? brandConfig.configuration.background_logo
                    : LoginImage
                }
                alt="Background"
              />
            </div>
            <div className="card portal-login-card text-center">
              <div className="card-header-new">
                <div className="card-header-img">

                  <img
                    src={brandConfig.configuration && brandConfig.configuration.login_header ? brandConfig.configuration.login_header : LoginImage}
                    alt="Background"
                  />

                </div>
              </div>
              <div className="card-body-new">
                <div className="card-body-inr">
                  <div className="portal-login-info">
                    {/* {/ <!-- <h1>COMMUNICATOR</h1> --> /} */}
                    <h3>Set your Password</h3>
                    <p>
                      Enter your registered email address
                    </p>
                  </div>
                  <div className="portal-login-form">
                    <ResetPasswordForm />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Link title="Back to Login" to="/">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>

          <div class="login-right">
            <div className="login-right-img">
              <img
                src={brandConfig.configuration && brandConfig.configuration.background_logo ? brandConfig.configuration.background_logo : LoginImage}
                alt="Background"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;