import axiosInstance from "../utils/axios";

export const GetMyTicketsService = async (history, status, page) => {
  return await axiosInstance(history).get("/getMyTickets", {
    params: { status: status, page: page },
  });
};

export const GetSingleTicketsService = async (_id) => {
  return await axiosInstance().get("/getSingleTicket", {
    params: { id_ticket: _id },
  });
};

export const GetDepartmentsService = async (history) => {
  return await axiosInstance(history).get("/getSupportDepartments");
};
export const GetCategoriesService = async (department) => {
  return await axiosInstance().get("/getSupportCategories", {
    params: { depId: department },
  });
};
export const AddTicketService = async (values) => {
  return await axiosInstance().post("/postSupportTicket", values);
};

export const AddTicketThreadService = async (values) => {
  return await axiosInstance().post("/addTicketThread", values);
};

export const CloseTicketService = async (values) => {
  return await axiosInstance().post("/closeMyTickets", { id_ticket: values });
};

export const GetMyUnreadTicketNotificationCount = async () => {
  return await axiosInstance().get("/getMyUnreadTicketNotificationCount");
};

export const readTicketIndicator = async (values) => {
  return await axiosInstance().post("/markAsReadTicket", values);
};

export const GetMyTicketsNewService = async (history, status, page) => {
  return await axiosInstance(history).get("/getMyTicketsNew", {
    params: { status: status, page: page },
  });
};