import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExamSubjectAppendReadOnly from "./ExamSubjectAppendReadOnly";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Link from "react-router-dom";
import ExamCentersReadOnly from "./ExamCentersReadOnly";
import StudentDateBirthReadOnly from "./StudentDateBirthReadOnly";
import {
  submitExamRegistration,
  getSubmitedExamRegistration,
} from "./../../services/ExamService";
import Swal from "sweetalert2";

const ExamRightBox = (props) => {
  const user = useSelector((state) => state.user);

  const [rsaId, setRsaId] = useState(false);
  const [passport, setPassport] = useState(false);
  const [examId, setExamId] = useState(false);
  const [examStudentReg, setExamStudentReg] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [firstList, setFirstList] = useState([]);
  const [examCenterId, setExamCenterId] = useState();

  let subjects = [];
  let firsts = [];

  const { examid, examListsRow } = props;
  const handleStudentType = (e) => {
    const subjectType = e.target.value;

    if (subjectType === "south-african-citizen") {
      setRsaId(true);
      setPassport(false);
      setExamId(false);
    } else {
      setRsaId(false);
      setPassport(true);
      setExamId(true);
    }
  };

  useState(() => {
    getSubmitedExamRegistration(examid).then((res) => {
      setExamStudentReg(res.data.exam_details[0]);
      setExamCenterId(res.data.exam_details[0].centre);
      if (res.data.exam_details[0].student_type != "south-african-citizen") {
        setExamId(true);
      }

      res.data.examSubjectList.forEach((subject) =>
        subjects.push(subject.subject)
      );
      setSubjectList(subjects);
      res.data.examSubjectList.forEach((first) => firsts.push(first.first));
      setFirstList(firsts);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      exam: examid,
      last_name: examStudentReg.last_name,
      first_name: examStudentReg.first_name,
      second_name: examStudentReg.second_name,
      third_name: examStudentReg.third_name,
      gender: examStudentReg.gender,
      bday_year: examStudentReg.bday_year,
      bday_month: examStudentReg.bday_month,
      bday_day: examStudentReg.bday_day,
      race: examStudentReg.race,
      student_type: examStudentReg.student_type,
      centre: examStudentReg.centre,
      subject_: subjectList,
      first_: firstList,
      rsa_identity: examStudentReg.rsa_identity,
      exam_id_number: examStudentReg.exam_id_number,
      term_condn: true,
      is_exam_id: (examStudentReg?.is_exam_id && examStudentReg?.is_exam_id==1) ? true : false,
    },
    onSubmit: (values) => {
      submitExamRegistration(values).then((res) => {

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => {
          if (result.value) {
          }
        });
      });
    },
  });

  return (
    <div className="exam-detail-rgt">
      <div className="exam-detail-top d-flex justify-content-between">
        <div className="exam-detail-top-left">
          <div className="exam-bx-info  d-flex">
            <div className="icon">ME</div>
            <div className="exam-bx-info-rgt">
              <h3>External Exam Registration Form</h3>
              <p>
                Register here for your DoE National Exams. Please ensure that
                you have completed all the required fields
              </p>
            </div>
          </div>
        </div>
        {/* <div className="exam-detail-top-right">
					<a href="exam-list.html">Back</a>
				</div>		 */}
      </div>

      <div className="exam-register-bx">
        <div className="card card-form-bx">
          <div className="card-header">Application Comments</div>

          <form onSubmit={formik.handleSubmit}>
            <div className="card-body">
              <div className="theme-form application-form">
                <div className="row">
                  <div className="form-group col-sm-6">
                    <label>Surname as per Your Official ID*</label>
                    <input
                      type="hidden"
                      name="exam"
                      value={formik.values.last_name}
                      disabled="disabled"
                    />
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.errors.last_name && formik.touched.last_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder=""
                      name="last_name"
                      value={formik.values.last_name}
                      disabled="disabled"
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>First Names as per Your Official ID*</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.first_name && formik.errors.first_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="First Name"
                      value={formik.values.first_name}
                      onBlur={formik.handleBlur}
                      name="first_name"
                      disabled="disabled"
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Second Name as per Your Official ID</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.second_name && formik.errors.second_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Second Name"
                      value={formik.values.second_name}
                      onBlur={formik.handleBlur}
                      name="second_name"
                      disabled="disabled"
                    />
                    {formik.touched.second_name && formik.errors.second_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.second_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Third Name as per Your Official ID</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.third_name && formik.errors.third_name
                          ? "is-invalid"
                          : null)
                      }
                      placeholder="Third Name"
                      name="third_name"
                      value={formik.values.third_name}
                      onBlur={formik.handleBlur}
                      disabled="disabled"
                    />
                    {formik.touched.third_name && formik.errors.third_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.third_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Student Type*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.student_type &&
                        formik.errors.student_type
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.student_type}
                      name="student_type"
                      onBlur={formik.handleBlur}
                      disabled="disabled"
                    >
                      <option value="">-------</option>
                      <option value="south-african-citizen">
                        South African Citizen
                      </option>
                      <option value="international-student">
                        International Student
                      </option>
                    </select>
                    {formik.touched.student_type &&
                    formik.errors.student_type ? (
                      <div className="invalid-feedback">
                        {formik.errors.student_type}
                      </div>
                    ) : null}
                  </div>
                  {formik.values.student_type === "south-african-citizen" ? (
                    <div className="form-group col-sm-6">
                      <label>RSA ID Number</label>
                      <input
                        type="text"
                        className={
                          "form-control " +
                          (formik.touched.rsa_identity &&
                          formik.errors.rsa_identity
                            ? " is-invalid"
                            : "")
                        }
                        placeholder=""
                        value={formik.values.rsa_identity}
                        name="rsa_identity"
                        onBlur={formik.handleBlur}
                        disabled="disabled"
                      />
                      {formik.touched.rsa_identity &&
                      formik.errors.rsa_identity ? (
                        <div className="invalid-feedback">
                          {formik.errors.rsa_identity}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {formik.values.student_type === "international-student" ? (
                    <div className="form-group col-sm-6">
                      <label>Passport Number</label>
                      <input
                        type="text"
                        className={
                          "form-control " +
                          (formik.touched.rsa_identity &&
                          formik.errors.rsa_identity
                            ? " is-invalid"
                            : "")
                        }
                        placeholder=""
                        name="rsa_identity"
                        value={formik.values.rsa_identity}
                        onBlur={formik.handleBlur}
                        disabled="disabled"
                      />
                      {formik.touched.rsa_identity &&
                      formik.errors.rsa_identity ? (
                        <div className="invalid-feedback">
                          {formik.errors.rsa_identity}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {examId === true ? (
                    <>
                      <div className="form-group col-sm-6 d-flex align-items-center">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className={
                              "custom-control-input " +
                              (formik.touched.is_exam_id && formik.errors.is_exam_id
                                ? " is-invalid"
                                : "")
                            }
                            name="is_exam_id"
                            onBlur={formik.handleBlur}
                            checked={formik.values.is_exam_id}
                            id="customCheck2"
                            disabled
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            I do not have an Exam ID.
                          </label>
                          {formik.touched.is_exam_id && formik.errors.is_exam_id ? (
                            <div className="invalid-feedback">
                              {formik.errors.is_exam_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <label>Exam ID Number (from previous exams)</label>
                        <input
                          type="text"
                          value={formik.values.exam_id_number}
                          className={
                            "form-control " +
                            (formik.touched.exam_id_number &&
                            formik.errors.exam_id_number
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          name="exam_id_number"
                          onBlur={formik.handleBlur}
                          disabled="disabled"
                        />
                        {formik.touched.rsa_identity &&
                        formik.errors.rsa_identity ? (
                          <div className="invalid-feedback">
                            {formik.errors.rsa_identity}
                          </div>
                        ) : null}
                        <div className="helper-text">
                        Previous 13 digit exam ID assigned to you by the DHET.
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="form-group col-sm-6">
                    <label>Gender*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.gender && formik.errors.gender
                          ? " is-invalid"
                          : "")
                      }
                      name="gender"
                      value={formik.values.gender}
                      onBlur={formik.handleBlur}
                      disabled="disabled"
                    >
                      <option value="">-------</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className="invalid-feedback">
                        {formik.errors.gender}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Race*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.race && formik.errors.race
                          ? " is-invalid"
                          : "")
                      }
                      name="race"
                      value={formik.values.race}
                      onBlur={formik.handleBlur}
                      disabled="disabled"
                    >
                      <option value="">-------</option>
                      <option value="asian">Asian</option>
                      <option value="african">African</option>
                      <option value="coloured">Coloured</option>
                      <option value="indian">Indian</option>
                      <option value="white">White</option>
                      <option value="other">Other</option>
                    </select>
                    {formik.touched.race && formik.errors.race ? (
                      <div className="invalid-feedback">
                        {formik.errors.race}
                      </div>
                    ) : null}
                  </div>
                  <StudentDateBirthReadOnly
                    bday_day={user.bday_day}
                    bday_month={user.bday_month}
                    bday_year={user.bday_year}
                    formik={formik}
                  />
                  <div className="form-group col-12">
                    <label>Detailed description and instructions:</label>
                  </div>
                  <ExamSubjectAppendReadOnly formik={formik} examId={examid} />
                </div>
              </div>

              <ExamCentersReadOnly
                formik={formik}
                examCenterId={examCenterId}
                examStudentReg={examStudentReg}
                examdetails={examListsRow}
              />

              <div className="terms-condition-wrap">
                <h5>Terms & Conditions</h5>
                <p>
                  By submitting this examination registration, I hereby declare
                  that I have read through all the information on the Exam Info
                  page and I accept the rules and regulations as set out by the
                  Academic Institute of Excellence, in accordance with
                  requirements from the DHET, regarding qualifying for
                  eligibility to enter the external exams. I also confirm that
                  the details provided by me are correct.
                </p>
                <div className="custom-control custom-checkbox text-left">
                  <input
                    type="checkbox"
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    value={formik.values.term_condn}
                    name="term_condn"
                    checked="true"
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I agree to the terms and conditions.
                  </label>
                </div>
              </div>

              <button
                disabled="disabled"
                type="submit"
                className="btn btn-submit btn-primary"
              >
                Submitted Application
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExamRightBox;
