import React, { useEffect, useState, useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
import {
   ViewNotes,
   AddNote,
 } from "../../services/MentoredSubjectService";
 import { useFormik } from "formik";
 import * as Yup from 'yup';
 import Swal from "sweetalert2";
 import IconStarted from '../../assets/images/self-managed-subjects-icon/started.svg';
 import IconUpdated from '../../assets/images/self-managed-subjects-icon/date-updated.svg'
 import IconCompleted from '../../assets/images/self-managed-subjects-icon/stoped.svg';
 import IconApproved from '../../assets/images/self-managed-subjects-icon/thumb-up.svg';
 import IconDeclined from '../../assets/images/self-managed-subjects-icon/thumb-down.svg';
 import IconAddHoc from '../../assets/images/self-managed-subjects-icon/edit.svg';
import { toggleNotes } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import UserHover from "../common/UserHover";
import { InitalRender, originalValues, rollsWidget } from "../common/Helper";
import { BASE_URL } from "../../utils/Constants";
import { useParams } from "react-router-dom";
import { shortenText } from "../../utils/commonFunction";

const MentoredSubjectNotes = ({subject_id}) => {
   const {password} = useParams();
   const [showNote, setShowNote] = useState({show:false, data:{}});
   const [notes, setNotes] = useState([]);
   const dispatch = useDispatch();
   const noteButton = useSelector(state => state.buttonStates.notes);
   const Activity = ({activity}) =>{
      switch (activity) {
         case "subject_started":
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconStarted} alt="" /></span>
               Module Started
               </div>
               </>
            );
            break;
         case "subject_updated":
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconUpdated} alt="" /></span>
               Module Date Updated
               </div>
               </>
            );
            break;
         case "subject_completed":
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconCompleted} alt="" /></span>
               Module Finished
               </div>
               </>
            );
            break;
         case "subject_approved":
            return (
            <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconApproved} alt="" /></span>
               Sign Off Approved
               </div>
            </>
            );
            break;
         case "subject_declined":
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconDeclined} alt="" /></span>
               Sign Off Declined
               </div>
               </>
            );
            break;
         case "ad_hoc":
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconAddHoc} alt="" /></span>
               Ad-hoc Note
               </div>
               </>
            );
            break;
         default:
            return (
               <>
               <div className="subject-notes-icon">
               <span className="mr-2"><img src={IconAddHoc} alt="" /></span>
               Ad-hoc Note
               </div>
               </>
            );
            break;
      }
    } 
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
      note: "",
      subject_id:subject_id
      },
      validationSchema: Yup.object({
         note: Yup.string()
           .required('Required'),
     }),
      onSubmit: (values,{resetForm}) => {
      AddNote(values)
      .then((res) => {
          Swal.close();
          Swal.fire({
          icon: "success",
          title: 'Successful',
          text:'Note saved',
          showConfirmButton: true
          }).then((result) => {
              setNotes(res.data);
              resetForm({})
          });
      })
      .catch((error) => {
          Swal.fire({
          icon: "error",
          title: "Unknown Error while saving",
          });
      });
      },
   });
   useEffect(() => {
      let mounted = true
      if(password==undefined || password==null || password=="" || !password){
         if(noteButton==true){
            ViewNotes({subject_id:subject_id})
            .then((res) => {
               if (mounted) {
               setNotes(res.data);
               }
            })
            .catch((error) => {});
         }
      }      
      return function cleanup() {
         mounted = false
     }
    }, [noteButton]);

    const columns = useMemo(() => [
      {
        name: "Date",
        selector: "note_date",
        wrap: true,
        sortable: true,
      },
      {
        name: "Submitted By",
        selector: "submitted_by",
        sortable: true,
        cell: (row) =>(         
         row.submitted_by == "student" ? 
            <div className="notes-user-profile user-profile assigned-title-blk">
               <div className="assigned-title-itm">
                  <span className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                  {row?.stu_photo ? (
                     <img
                        src={
                        BASE_URL +
                        "/" +
                        row?.stu_photo.replace("/home/myaie/public_html/", "").replace("public/","")
                        }
                        onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.closest('span').append(InitalRender(`${row.stu_firstname} ${row.stu_last_name}`));
                           currentTarget.remove();
                        }}
                     />
                     
                  ) : (
                     InitalRender(`${row.stu_firstname} ${row.stu_last_name}`)
                  )}
                  {row?.stu_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                  </span>
                  
                  <span> {shortenText(`${row.stu_firstname} ${row.stu_last_name}`, 15)} <small>{row.submitted_by == "student" ? rollsWidget("student") : rollsWidget(row.admin_role)}</small></span>
               </div>
              <UserHover 
              user={{
               photo:row?.stu_photo, 
               fullname:`${row.stu_firstname} ${row.stu_last_name}`, 
               activity_status:row.stu_activity_status,
               email :row.stu_email
               }}/>              
            </div>
         :<div className="notes-user-profile user-profile assigned-title-blk">
            <div className="assigned-title-itm">
               <span
               className="name-icon cat-dark-red bgBlue  user-drop-down-img">
               {row?.admin_profile ? (
                  <img
                     src={
                     BASE_URL +
                     "/" +
                     row?.admin_profile.replace("/home/myaie/public_html/", "").replace("public/","")
                     }                     
                     onError={({ currentTarget }) => {
                     currentTarget.onerror = null; // prevents looping
                     currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${row.admin_firstname} ${row.admin_lastname}`));
                     currentTarget.remove();
                     }}
                  />
                  
               ) : (
                  InitalRender(`${row.admin_firstname} ${row.admin_lastname}`)
               )}
               {row?.admin_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
               </span>               
               <span>{`${row.admin_firstname} ${row.admin_lastname}`}<small>{row.submitted_by == "student" ? rollsWidget("student") : rollsWidget(row.admin_role)}</small></span>
            </div>
            <UserHover 
              user={{
               photo:row?.admin_profile, 
               fullname:`${row.admin_firstname} ${row.admin_lastname}`, 
               activity_status:row.admin_activity_status==1 ? "online" : "offline",
               email :row.admin_email
            }}/>           
         </div>
        )
      },
      {
        name: "Details",
        selector: "note_content",
        sortable:false,
        cell: (row) =>(row.note_content ?  
         <span className="as-text-blue plink overflow-ellipsis2 curser" onClick={()=>{handleShow(row)}}>View Note</span>  
         : "-")
      },
      {
        name: "Activity",
        cell: (row) => (
         <Activity  activity= {row.activity} />
       ),
      },
    ]);
    
    const tableData = {
      columns,
      data:notes
    };

    const handleClose = () => {setShowNote({...showNote, show:false})};
    const handleShow = (data) => {
      setShowNote({...showNote, show:true, data:data})
    };

    return (
        <>
         <div className={"card course-card-custom course-notes-card-custom hide collapse mb-3 " + (noteButton === true ? "show" : "")} id="notes" data-parent="#accordionSubject">
            <div className="card-header upper-heading">Mentor Notes & Feedback</div>            
            <div className="card-body ">
               <div className="card-table-custom p-0 custom-table-div ">
                  <DataTableExtensions {...tableData}
                  export={false}
                  print={false} > 
                  <DataTable 
                     columns={columns} 
                     className="notes-custom-table"
                     data={notes} 
                     pagination={true}
                     defaultSortField='note_date'
                     defaultSortAsc={false}
                  />
                  </DataTableExtensions> 
               </div>
            
               <div className={`theme-form application-form ${
                                notes.length <=0
                                  ? "mt-4"
                                  : ""
                              }`}>
                  <form onSubmit={formik.handleSubmit}>
                     <div className="row">
                        <div className="form-group col-12">
                           <label><strong>Your Feedback</strong></label>
                           <textarea  
                            className={'form-control' + (formik.errors.note && formik.touched.note ? ' is-invalid' : '')}  
                            onChange={formik.handleChange}
                            value={formik.values.note} 
                            name="note"
                            id="note" 
                            placeholder="Please provide extra notes to your lecturer..."></textarea>
                            {formik.touched.note && formik.errors.note ? (
                            <div className="color-red mt-2">{formik.errors.note}</div>
                            ) : null}
                        </div>
                        <div className="form-group col-12">                              
                           <button type="submit" className="btn btn-submit btn-primary"><i className="fal fa-clipboard-list"></i>Add Note</button>
                           <a href="#notes" onClick={() => dispatch(toggleNotes())} data-toggle="collapse" className="btn btn-submit btn-main btn-danger ml-2">Cancel</a>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            {/* View Notes popup */}
            <Modal show={showNote.show} onHide={()=>{handleClose()}} className="notes-modal modal able_modal01 fade" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton className="modal-header modal-header-notes">
               <h5 class="modal-title d-flex">
                  <Activity  activity= {showNote.data.activity} />
                  <span className="ml-2 as-text-blue">{showNote.data.note_date}</span>
               </h5>
            </Modal.Header>
            <Modal.Body className="">
               <div className="notes-user-info">
                  {showNote?.data?.submitted_by == "student" ? 
                     <div className="notes-user-profile user-profile assigned-title-blk">
                        <div className="assigned-title-itm">
                           <span className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                           {showNote.data.stu_photo ? (
                              <img
                                 src={
                                 BASE_URL +
                                 "/" +
                                 showNote.data.stu_photo.replace("/home/myaie/public_html/", "").replace("public/","")
                                 }
                                 onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.closest('span').append(InitalRender(`${showNote.data.stu_firstname} ${showNote.data.stu_last_name}`));
                                    currentTarget.remove();
                                 }}
                              />
                              
                           ) : (
                              InitalRender(`${showNote.data.stu_firstname} ${showNote.data.stu_last_name}`)
                           )}
                           {showNote.data.stu_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                           </span>
                           
                           <span> {`${showNote.data.stu_firstname} ${showNote.data.stu_last_name}`} <small>{showNote.data.userRole ? rollsWidget("student") : ""}</small></span>
                        </div>
                     <UserHover 
                     user={{
                        photo:showNote.data.stu_photo, 
                        fullname:`${showNote.data.stu_firstname} ${showNote.data.stu_last_name}`, 
                        activity_status:showNote.data.stu_activity_status,
                        email :showNote.data.stu_email
                        }}/>              
                     </div>
                  :<div className="notes-user-profile user-profile assigned-title-blk">
                     <div className="assigned-title-itm">
                        <span
                        className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                        {showNote?.data?.admin_profile ? (
                           <img
                              src={
                              BASE_URL +
                              "/" +
                              showNote.data.admin_profile.replace("/home/myaie/public_html/", "").replace("public/","")
                              }                     
                              onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${showNote.data.admin_firstname} ${showNote.data.admin_lastname}`));
                              currentTarget.remove();
                              }}
                           />
                           
                        ) : (
                           InitalRender(`${showNote.data.admin_firstname} ${showNote.data.admin_lastname}`)
                        )}
                        {showNote.data.admin_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                        </span>
                        
                        <span>{`${showNote.data.admin_firstname} ${showNote.data.admin_lastname}`}<small>{showNote.data.userRole ? rollsWidget("student") : ""}</small></span>
                     </div>
                     <UserHover 
                     user={{
                        photo:showNote.data.admin_profile, 
                        fullname:`${showNote.data.admin_firstname} ${showNote.data.admin_lastname}`, 
                        activity_status:showNote.data.admin_activity_status==1 ? "online" : "offline",
                        email :showNote.data.admin_email
                     }}/>           
                  </div>}
                  <small>{showNote.data.submitted_by == "student" ? rollsWidget("student") : rollsWidget(showNote.data.admin_role)}</small>
               </div>
               <span>{showNote.data.note_content}</span>
            </Modal.Body>
            </Modal>
         </div>
      </>
    );
}
export default MentoredSubjectNotes;
