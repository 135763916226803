import React, { useEffect, useMemo, useState } from 'react'
import { getStudentLibraryDropdown } from '../services/ResourcesService';
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../loaders/SkeletonTicketList';
import Str from '../components/common/Str';
import { Link, useLocation, useParams } from 'react-router-dom';
import { availability, campus, campusWidget, resource, resourceWidget, topicsWidget } from '../components/common/Helper';
import { addToMyLibrary, getAssignStudentResource, removeFromFavorite } from '../services/StudentHubService';
import moment from 'moment';
import ResourceDetail from './Library/ResourceDetail';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setFavouritesCount } from '../store/actions';

export default function StudentHubMyLibrary({ tabState, paramObj }) {
    const location = useLocation();
    const { tab, page, id } = useParams();
    const [totalRows, setTotalRows] = useState("");
    const [listData, setListData] = useState([])
    const [renderTable, setRenderTable] = useState(false);
    const [dropdownList, setDropdownList] = useState({});
    const [pages, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortKey, setSortKey] = useState("id");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [loadingId, setLoadingId] = useState('');
    const dispatch = useDispatch();
    const favCount = useSelector((state) => state.favourites.favouritesCount);
    const brandConfig = useSelector((state) => state.brand);
    const [param, setParam] = useState({
        viaFormat: [],
        viaCampus: [],
        page: pages,
        limit: perPage,
        key: sortKey,
        sorting: sortOrder,
        exportStatus: false,
        viaSearch: '',
        viaTopics: [],
    })


    const handlePageChange = (page) => {
        // setParam({ ...param, page: page })
        setPage(page);
    };
    const wordSpliterTopics = (str, type) => {
        const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
        const newStrArr = strArr.length ? strArr?.map(item => {
        const topic = dropdownList?.topic?.find(val => val.value == item);
          return item ? <small className="as-widget-resource" style={{color: '#fff',background: topic?.color}}>{topic?.label}</small> : 'NA';      
        }) : "NA";
        return newStrArr;
      }

    const handlePerRowsChange = (newPerPage, page) => {
        // setParam({ ...param, limit: newPerPage });
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.selector);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC")
        // setParam({
        //   ...param,
        //   key: column.selector,
        //   sort: sortDirection === "asc" ? "ASC" : "DESC"
        // })
    };

    const getData = () => {

        // setRenderTable(false);
        const sendData = {
            ...param,
            page: pages,
            limit: perPage,
            key: sortKey,
            sorting: sortOrder,
            brand_id: brandConfig?.configuration?.id
        }
        getAssignStudentResource(sendData).then((res) => {
            if (res.status === 200) {
                const resp = res.data.resource;
                setListData(resp.data);
                setTotalRows(resp.total);
                setRenderTable(true);
            }
        }).catch((err) => { console.log(err) });

    }

    useEffect(() => {
        getData();
    }, [param, pages, perPage, sortKey, sortOrder])

    const getStudentLibraryDropdownData = async () => {
        const result = await getStudentLibraryDropdown();
        setDropdownList(result?.data);
    }

    useEffect(() => {
        getStudentLibraryDropdownData();
    }, [])


    const columns = useMemo(() => [
        // {
        //     name: "Nr",
        //     selector: "id",
        //     sortable: true,
        //     // maxWidth: "110px",
        //     cell: (row) => (
        //         <div className="ticket-id" title={row.id}>
        //             <p>
        //                 <span className="overflow-ellipsis2"><b>{row.id}</b></span>
        //             </p>
        //         </div>
        //     ),
        // },
        {
            name: "Title",
            selector: "title",
            wrap: true,
            sortable: true,
            cell: (row) => (
                <Link
                    to={{ pathname: row.type === "Book" ? `/student-hub/my-library/detail/physical/${(row.id)}` : `/student-hub/my-library/detail/digital/${(row.id)}`, state: { prevPath: location.pathname } }}
                    className={"as-text-blue plink"}
                    title={row.title}
                >
                    {row.title}
                </Link>
            ),
        },
        // {
        //     name: "Author",
        //     selector: "author",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => <p className="oneline-ellipsis">{row.author ? row.author : "NA"}</p>
        //     //   cell: (row) => <StatusRender status={row.Author} />,
        // },
        // {
        //   name: "Edition",
        //   selector: "edition",
        //   wrap: true,
        //   sortable: true,
        //   cell: (row) => <p className="oneline-ellipsis">{row.edition ? <b>#{row.edition}</b> : "NA"}</p>
        // },
        {
            name: "Type",
            selector: "type",
            wrap: true,
            sortable: true,
            cell: (row) => (
                <p className="oneline-ellipsis">
                    {resourceWidget(row.type)}
                </p>
            ),
        },
        {
            name: "Topics",
            selector: "topic",
            wrap: true,
            sortable: true,
            cell: (row) => (
             
                row?.topic ? wordSpliterTopics(row?.topic.toString(), "topics") : 'NA'
      
            ),
          },
        // {
        //     name: "Campus",
        //     selector: "campus",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => (
        //         <p className="oneline-ellipsis">
        //             {row.campus ? campusWidget(row.campus) : "NA"}
        //             {/* {row.department
        //         ? <DepartmentRender status={row.Resource} />
        //         : "NA"} */}
        //         </p>
        //     ),
        // },
        // {
        //     name: "Issue Date",
        //     selector: "issue_date",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => <p className="oneline-ellipsis">{row.issued_date && row.issued_date != "0" ? moment.unix(Number(row.issued_date)).format("D MMM 'YY") : "NA"}</p>
        // },
        // {
        //     name: "Due Date",
        //     selector: "due_date",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => (<p className="oneline-ellipsis">{row.due_date && row.due_date != "0" ? moment.unix(Number(row.due_date)).format("D MMM 'YY") : "NA"}</p>),
        // },
        // {
        //     name: "Barcode",
        //     selector: "barcode",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => <p className="oneline-ellipsis">{row.barcode ? row.barcode : "NA"}</p>
        // },
        // {
        //     name: "Availability",
        //     selector: "availability",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => (availability(row.availability)),
        // },
        // {
        //     name: "Location",
        //     selector: "place",
        //     wrap: true,
        //     sortable: true,
        //     cell: (row) => <p className="oneline-ellipsis">{row.place}</p>
        // },
        {
            name: "Action",
            // width: "100%",
            // maxWidth: "90px",
            // minWidth: "90px",
            cell: (row) => (
                <div className="assessment-08 advSearch-action-con">
                    <div className="as-buttons">
                        <Link to={{ pathname: row.type === "Book" ? `/student-hub/my-library/detail/physical/${(row.id)}` : `/student-hub/my-library/detail/digital/${(row.id)}`, state: { prevPath: location.pathname } }}>
                            <button
                                title="Open"
                                className="btn btn-primary rounded-circle"
                            >
                                <i className="fal fa-folder-open"></i>
                            </button>
                        </Link>
                    </div>
                    <div className="as-buttons">
                        <button
                            title={`${row.favorite ? 'Remove from' : 'Add to'} Favourites`}
                            className="btn btn-primary rounded-circle"
                            onClick={() => { handleFavourties(row.id, row.favorite, row.sp_resource_issued_id); setLoadingId(row.id) }}
                        >
                            <i className={`${row.id == loadingId ? 'fas fa-cog fa-spin' : row.favorite ? 'fas fa-star' : 'fal fa-star'} `}></i>
                        </button>
                    </div>
                </div>
            ),

        },
    ]);

    const handleFavourties = (id, favorite, sp_resource_issued_id) => {
        const data = { resource_id: id, sp_resource_issued_id: sp_resource_issued_id }
        if (!favorite) {
            addToMyLibrary(data).then((res) => {
                dispatch(setFavouritesCount(favCount+1))
                updateFavIcon(id, favorite)
                setLoadingId('')
            })
                .catch((err) => {
                    setLoadingId('')
                })
        }
        else {
            removeFromFavorite(data).then((res) => {
                dispatch(setFavouritesCount(favCount-1))
                updateFavIcon(id, favorite)
                setLoadingId('')
            })
                .catch((err) => {
                    setLoadingId('')
                })
        }
    }

    const updateFavIcon = (id, favorite) => {

        let tempData = listData
        tempData.map(i => {
            if (i.id == id) {
                if (i.favorite) {
                    i.favorite = 0
                }
                else {
                    i.favorite = 1
                }
            }
        })
        setListData(pre => [...tempData])
    }


    // ** Function to handle type filter  
    const handleSearchFilter = e => {
        const value = e.target.value
        setParam({ ...param, viaSearch: value });
    }

    const handleUpdateFilter = e => {
        const value = e.target.value;

        let arr = [...param.viaFormat];
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setParam({
            ...param,
            viaFormat: [...arr]
        })
    }

    const handleCampusFilter = e => {
        const value = e.target.value;
        let arr = [...param.viaCampus];
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setParam({
            ...param,
            viaCampus: [...arr]
        })
    }

    const handleTopicsFilter = e => {
        const value = e.target.value;
        let arr = [...param.viaTopics];
        if (arr.includes(value)) {
          arr.splice(arr.indexOf(value), 1);
        } else {
          arr.push(value);
        }
        setParam({
          ...param,
          viaTopics: [...arr]
        })
      }


    const resetFilter = () => {
        setParam({
            ...param,
            viaCampus: [],
            viaFormat: [],
            viaSearch: "",
            viaTopics: []
        });
        // setUpdate([]);
        // setSearchUpdateCheck({});
        // setStatus([]);
        // setSearchStatusCheck({});
        // setSearchDepartment([]);
        // setSearchDepartmentCheck({});
        // setSearchCat([])
        // setSearchCatCheck({})
        // setSearch('')
    };

    return (
        <>
            {tab === "my-library" && page === "table" &&
                <div className="card card-profile-info-card">
                    {/* <div className="header-info-box">
                        <h4>Favourites</h4>
                    </div> */}
                    <div className="card-body ">
                        {/* <div className="card-header pt-0">My Library</div> */}
                        <div className="card-body-inr">
                            <div className="card-table-new custom-table-div01 filter-search-icon01 card card-table-custom custom-table-div">
                                <div className="filter-search-bar-blk">
                                    <div className="filter-button-group">
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                        {/* <div className="assessment-table-filter">
                                            <div className="btn-type-filter dropdown-comman">
                                                <div className={`dropdown multiselect`}>
                                                    <button
                                                        className={`btn btn-default dropdown-toggle ${param.viaFormat.length ? 'btn-selected' : ''}`}
                                                        type="button"
                                                        id="dropdownMenu1"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="true"
                                                        title="Format"
                                                    >
                                                        <span>
                                                            Format <i className="fal fa-angle-down"></i>
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu remove-list-style">
                                                        <ul aria-labelledby="dropdownMenu1">
                                                            {
                                                                dropdownList?.LibraryResourceType?.map((item, index) => {
                                                                    return (
                                                                        <li className="item-text-green" key={index}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={item?.value}
                                                                                value={item?.value}
                                                                                onClick={handleUpdateFilter}
                                                                                checked={param.viaFormat.includes(item.value)}
                                                                            />
                                                                            <label htmlFor={item?.value}>
                                                                                {
                                                                                    resourceWidget(item?.value)
                                                                                }
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                         <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${param.viaFormat.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Campus"
                  >
                    <span>
                      Format <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {
                         dropdownList?.LibraryResourceType?.map((item, index) => {
                          return (
                            <li className="item-text-green" key={index}>
                              <input
                                type="checkbox"
                                id={item?.value}
                                value={item?.value}
                                onClick={handleUpdateFilter}
                                checked={param.viaFormat.includes(item.value.toString())? true : false}
                              />
                              <label htmlFor={item?.value}>
                                {
                                  resourceWidget(item?.value)
                                }
                              </label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </form>
                </div>
              </div>
            </div>

                                        <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${param.viaTopics.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Topics"
                  >
                    <span>
                      Topics <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {
                        dropdownList?.topic?.map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={item?.value}
                                value={item?.value}
                                onClick={handleTopicsFilter}
                                checked={param.viaTopics.includes(item.value.toString())? true : false}
                              />
                              <label htmlFor={item?.value}>
                                {
                                  topicsWidget(item?.value,item?.color,item?.label)
                                }
                              </label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </form>
                </div>
              </div>
            </div>

                                        {/* <div className="assessment-table-filter">
                                            <div className="btn-type-filter dropdown-comman">
                                                <div className={`dropdown multiselect`}>
                                                    <button
                                                        className={`btn btn-default dropdown-toggle ${param.viaCampus.length ? 'btn-selected' : ''}`}
                                                        type="button"
                                                        id="dropdownMenu1"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="true"
                                                        title="Campus"
                                                    >
                                                        <span>
                                                            Campus <i className="fal fa-angle-down"></i>
                                                        </span>
                                                    </button>
                                                    <form className="dropdown-menu remove-list-style">
                                                        <ul aria-labelledby="dropdownMenu1">
                                                            {
                                                                dropdownList?.campus?.map((item, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={item?.value}
                                                                                value={item?.value}
                                                                                onClick={handleCampusFilter}
                                                                                checked={param.viaCampus.includes(item.value)}
                                                                            />
                                                                            <label htmlFor={item?.value}>
                                                                                {
                                                                                    campusWidget(item?.value)
                                                                                }
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="button-reset">
                                            <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                                <i className="fal fa-redo"></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div >
                                <div className="data-table-extensions">
                                    <div className="data-table-extensions-filter">
                                        <label htmlFor="filterDataTable" className="icon"></label>
                                        <input
                                            type="text"
                                            name="filterDataTable"
                                            className="filter-text"
                                            placeholder="Search"
                                            onChange={handleSearchFilter}
                                            value={param.viaSearch}
                                        />
                                    </div>
                                    <div className="data-table-extensions-action"></div>
                                </div>

                                {
                                    renderTable ?
                                        <DataTable
                                            data={listData}
                                            // defaultSortField={param.key}
                                            // defaultSortAsc={false}
                                            columns={columns}
                                            pagination={true}
                                            noDataComponent={Str.noRecord}
                                            paginationDefaultPage={param.page}
                                            progressPending={!renderTable}
                                            progressComponent={<SkeletonTicketList />}
                                            onSort={handleSort}
                                            paginationServer
                                            sortServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            highlightOnHover={false}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                        />
                                        : <SkeletonTicketList />
                                }
                            </div >
                        </div>
                    </div>
                </div>
            }
            {tab === "my-library" && page === "detail" &&
                <ResourceDetail dropdownList={dropdownList}/>
            }
        </>
    )
}
