const initialState = {
    currentCount: 0,
}

const courseCount = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COUNT':
            state.currentCount = action.payload
            return {...state};
        default:
            return state;
    }

}

export default courseCount;