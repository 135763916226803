import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { RenderQuestionLevel, RenderQuestionType, RenderQuizResult } from "../../ticket/StatusRender";
import { API_BASE, STUDENT_FILE_DOWNLOAD } from "../../../utils/Constants";
import Swal from "sweetalert2";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import HtmlParser from "react-html-parser";
import ShowPopupQues from "./ShowPopupQues";
import { Modal } from "react-bootstrap";
import { MathJax } from "better-react-mathjax";

const ReviewQuiz = (props)=> {
  const { data } = props;
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalQues, setModalQues] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const secToMin = (value) => {
    var timestamp = value;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    // hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    var formatted =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formatted;
  };

  const handleDocumentDownload = async (submittedFiles) =>{
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const files = submittedFiles.map(f=> STUDENT_FILE_DOWNLOAD + f.replaceAll("/home/myaie/public_html/","").replace("public/",""))
    // console.log("___________ files",files);
    const zip = new JSZip();

    const downloadPromises = files.map(async (fileUrl) => {
      const response = await axios.get(fileUrl, { responseType: 'blob' });
      const fileData = response.data;
      // console.log("___________________ file res",response,fileData);

      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      zip.file(fileName, fileData);
    });

    await Promise.all(downloadPromises);

    const zipFile = await zip.generateAsync({ type: 'blob' });
    Swal.close();
    saveAs(zipFile, 'files.zip');
  };

  
  const columns = useMemo(() =>[
    {
      name:'Name',
      selector:"question_name",
      sortable:true,
      cell : (row) =>(row.question_name ? 
        <div class="assessment-01 curser">
          <p class="as-text-blue" onClick={()=>{setModalQues(row); handleShow()}}>
            <span className="overflow-ellipsis2" title={row.question_name}>{row.question_name}</span> 
          </p>
        </div>
      : '-')
    },
    {
      name:'Question',
      selector:"question",
      sortable:false,
      cell : (row) =>(row.question ? <span className="overflow-ellipsis2" title={row?.question}><MathJax inline dynamic>{HtmlParser(row.question)}</MathJax></span> : "-")
    },
    {
      name:'Question Type',
      selector:"question_type",
      sortable:true,
      cell : (row) =>(row.question_type ? <RenderQuestionType type={row.question_type} /> : '-')
    },
    {
      name:'Question Level',
      selector:"level_name",
      sortable:true,
      cell : (row) =>(row.level_name ? <RenderQuestionLevel level={row.level_name} /> : "-")
    },
    {
      name:'Submitted Answer',
      sortable:false,
      selector:"UserAnswer",
      cell : (row) =>(row.UserAnswer ? row.question_type === "Document Upload" ? <a href="javascript:void(0)" onClick={()=>handleDocumentDownload(row.UserAnswer)}>Download Submission</a> : <span className="overflow-ellipsis2" title={row?.UserAnswer}><MathJax inline dynamic>{HtmlParser(row.UserAnswer)}</MathJax></span> : "-")
    },
    {
      name:'Correct Answer',
      selector:"correctAnswer",
      sortable:false,
      cell : (row) =>(row.correctAnswer ? <span className="overflow-ellipsis2" title={row.correctAnswer}><MathJax inline dynamic>{HtmlParser(row.correctAnswer)}</MathJax></span> : "-")
    },
    {
      name:'Score',
      selector:"result",
      sortable:false,
      cell: (row) => row.question_type === "Document Upload" ? "-" : `${Math.floor(row?.result * 100) / 100}/${Math.floor(row?.totalScoreIndividual * 100) / 100}`
      // cell : (row) =>(row.score ? `${Math.floor(row?.result * 100) / 100}/${row?.totalScoreIndividual ? 
      //   Math.floor(row?.totalScoreIndividual * 100) / 100 : 
      //   Math.floor(row?.score * 100) / 100}` : "-")
    },
    {
      name:'Time Spent',
      selector:"time",
      sortable:false,
      cell : (row) =>(row.time ? secToMin(row.time) : "-")
    },
    {
      name:'Result',
      sortable:false,
      selector:"result",
      cell : (row) => (row.question_type === "Document Upload" || row.question_type === "Long Answer") ? <div className="max__content">{RenderQuizResult("document").html}</div> : ((row?.totalScoreIndividual ? row?.totalScoreIndividual == 0 : row?.score == 0)  ? 
      <div className="max__content">{RenderQuizResult("0").html}</div> : row?.score == 0 ? <div className="max__content">{RenderQuizResult("0").html}</div> :  
      (row?.totalScoreIndividual ? row.result/row?.totalScoreIndividual != 0 : row.result/row?.score != 0 ) ? 
      <div className="max__content">{RenderQuizResult((row.result/row?.totalScoreIndividual).toString()).html}</div> : 
      parseInt(row.result) == (row?.totalScoreIndividual ? row?.totalScoreIndividual : row?.score) ? 
      <div className="max__content">{RenderQuizResult("1").html}</div> : <div className="max__content">{RenderQuizResult("0").html}</div>)
    },
    {
      name: 'Action',
      selector: "time",
      sortable: false,
      cell: (row) => <button
        type="button"
        title="View Question"
        onClick={()=>{setModalQues(row); handleShow()}}
        className="btn btn-primary rounded-circle"><i className="fal fa-eye"></i></button>
    },
  ],[])

  const handleSearchFilter = e => {
    const value = e.target.value
    setSearch(value);
  }

  const dataToRender = () =>{
    let allData = data;
    if(search.length){
      let filterData = allData.filter(item => {
        let includes = item.question_name.toLowerCase().includes(search.toLowerCase())        
        return includes
      })
      allData = [...filterData]
    }

    return allData
  }
  
  const resetFilter = () =>{
    setSearch("");
  }
  
  return (
    <div className="mb-30">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="filter-search-bar-blk">
          <div className="filter-button-group">
            <div className="filter-eff filter-data-btn">
              <button className="filter-buttons">
                <i className="fal fa-filter"></i>
              </button>
            </div>            
            <div className="button-reset">
              <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>
          </div>
        </div>
        <div className="data-table-extensions">
          <div className="data-table-extensions-filter">
            <label for="filterDataTable" className="icon"></label>
            <input
              type="text"
              name="filterDataTable"
              className="filter-text"
              placeholder="Search"
              onChange={handleSearchFilter}
              value={search}
            />
          </div>
        </div>
        {loading ? <SkeletonTicketList />
        :<DataTable 
          defaultSortAsc
          defaultSortField="question_name"
          data={dataToRender()}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}            
        />}        
      </div>
      {/* Quiz Preview view question popup */}
      <Modal show={show} onHide={handleClose} className="topic-add-modal modal able_modal01 fade" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="modal-header modal-header-custom">
          <h5 class="modal-title">View Question</h5>
        </Modal.Header>
        <Modal.Body className="p-30px">
          <ShowPopupQues data={modalQues} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default React.memo(ReviewQuiz)