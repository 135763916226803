import React, { useState, useEffect } from "react";
import $ from 'jquery';
import {
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  GetLearningMaterial,
  GetLearningMaterialAdmin
} from "../../services/AssembliesService";

import AssembliesItems from "./AssembliesItems";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";

let obj = {};
export default function SubjectAssemblies(props) {
  const {
    setQuizId,
    setshow,
    setrId,
    rId,
    getSubContentMethod,
  } = props;
  const [loading, setLoading] = useState(false);
  const [assembliesItems, setAssembliesItems] = useState([]);

  //const [player,setPlayer]=useState({});

  const [moduleId, setModuleId] = useState(0);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const [documentId, setDocumentId] = useState([]);
  const [assemblies,setAssemblies] =useState([])
  const setCustomeCurrentVideoId = (videoId) => {
    setCurrentVideoId(videoId);
  };

  useEffect(() => {
    setLoading(true);
    getLearningMaterialData(props.subjectId);      
  }, [props.subjectId]);

  // useEffect(() => {}, [assembliesItems]);

  const getLearningMaterialData = (sid) => {
    let mounted = true
    if(props.isAdmin==1){
      GetLearningMaterialAdmin({ sid: sid })
      .then((res) => {
        if (mounted && res.data.assemblies) {
          setAssemblies(res.data.assemblies)
        }
        setLoading(false);
      })
      .catch((error) => {});
    }else{
      GetLearningMaterial({ sid: sid })
      .then((res) => {
        if (mounted && res.data.assemblies) {
          setAssemblies(res.data.assemblies)
        }
        setLoading(false);
      })
      .catch((error) => {});

    }


    return function cleanup() {
      mounted = false
    }
  };

  const handleItem = (item) => {
    setTimeout(
      function() {
        if($('#accordionBodyOne'+item).hasClass('show')) {
          if(props.isAdmin==1){
            getAssembliesItemAdmin(item).then((res) => {
              obj[item] = res.data;
              setAssembliesItems(obj);
              setModuleId(item);
            });
          }else{
            getAssembliesItem(item).then((res) => {
              obj[item] = res.data;
              setAssembliesItems(obj);
              setModuleId(item);
            });
      
          }

        }
      }.bind(this),
    500);
  };

  const handleFileTracking = (itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus(true);
      setDocumentId([...documentId, itemId]);
      getLearningMaterialData(props.subjectId);
    });
  };



  // const ref = (player) => {
  //   player = player;
  // };
  return (
    <>
      {/* <ReactPlayer
        url="https://player.vimeo.com/video/215151720#t=4m"
        ref={player}
        // light={false}
        onPlay={() => handleCustomeButton()}
        className="react-player"
        playing
        width="100%"
        height="100%"
        controls={true}
      /> */}
       {loading && <SekletonLearningMaterial />}
       {assemblies.length > 0 && !loading ? ( 
        assemblies.map((assembly) => {
        return (
          <div
            className="learning-material-listing"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
            key={assembly.id}
          >
            {assemblies.length > 1 && ( 
            <div className="subject-header">
              <div className="subject-sub-header">
                <h3>
                  <i className="fal fa-tv-retro"></i> {assembly.name}
                </h3>
                <p>{assembly.item_description}</p>
              </div>
            </div>)}
           
    
            {assembly && assembly.lessons
              ? assembly.lessons.map((lesson, lessonindex) => {
                  return (
                    <div
                      className="learning-material-card"
                      key={"learning-material-card" + lesson.module_id}
                    >
                      <div
                        className="hd-main d-flex flex-wrap"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        onClick={() => handleItem(lesson.module_id)}
                        href={"#accordionBodyOne" + lesson.module_id}
                        key={lesson.module_id}
                      >
                        <div className="learning-material-head borderprimary">
                          <div className="learning-left ">
                            <h3>
                              {assembly.isNumbered == 1 && lessonindex + 1}{" "}
                              {lesson.module_name}
                            </h3>
                            {/* <span className="txt-smll">Use these resources to familiarize yourself with the objectives covered in this topic. Each resource has been carefully compiled to build your knowledge on a modular basis.</span> */}
                          </div>
                          <div className="learning-right ml-auto">
                            <ul className="learningright-inner d-flex align-items-center">
                              {/* <li className="bar-line">
                                <div className="barImg">
                                <i className="fal fa-tasks"></i>
                                </div>
                              </li> */}

                              <li className="material-info deep-text">
                                <i title="Videos" className="fal fa-video" />  {lesson.assembliesVidoesFile} 
                                <i title="Documents" className="fal fa-file ml-2" />  {lesson.assembliesDocumentFile} 
                                <i title="Quiz" className="fal fa-clipboard-list ml-2" />  {lesson.assembliesQuiz}                                
                                <i title="Link" className="fal fa-external-link ml-2" />  {lesson.assembliesLinkFile}                                
                              </li>
                              {lesson.progressPercentag &&
                              lesson.progressPercentag != null ? (
                                <li className="item-progress">
                                  <div className="progress">
                                    <div
                                      className="progress-bar progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      style={{
                                        width: lesson.progressPercentag + "%",
                                      }}
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {lesson.progressPercentag}%
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="header-status">
                          <div className="status-gol card-blue-1">
                            <i className="fal fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className="learning-material-body collapse"
                        id={"accordionBodyOne" + lesson.module_id}
                      >
                        <ul className="sub-ul">
                          {assembliesItems && assembliesItems[lesson.module_id]
                            ? assembliesItems[lesson.module_id].map(
                                (assemblyItem, index) => {
                                  return (
                                    <AssembliesItems
                                      key={index}
                                      isAdmin={props.isAdmin}
                                      parentSubId={props.subjectId}
                                      parentGetLearningMaterialData={
                                        getLearningMaterialData
                                      }
                                      assemblyItem={assemblyItem}
                                      setQuizId={setQuizId}
                                      setshow={setshow}
                                      setrId={setrId}
                                      lesson={lesson}
                                      parentSetCurrentVideoId={
                                        setCustomeCurrentVideoId
                                      }
                                      lessonindex={
                                        assembly.isNumbered == 1
                                          ? parseInt(lessonindex + 1) +
                                            "." +
                                            parseInt(index + 1)
                                          : ""
                                      }
                                      parentFileTracking={handleFileTracking}
                                      currentVideoId={currentVideoId}
                                      getSubContentMethod={getSubContentMethod}
                                      buttonCheckedStatus={buttonCheckedStatus}
                                      documentId={documentId}
                                      refreshProgress={(id)=>{handleItem(id)}}
                                    />
                                  );
                                }
                              )
                            : null}
                        </ul>
                      </div>
                    </div>
                  );
                })
              : null}
              
          </div>
        );
      })):
        (
          !loading ? (
            <div
              className="learning-material-listing"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="subject-header">
                <div className="subject-sub-header">
                  <h3>
                    No Content Available.
                  </h3>
                </div>
              </div>
            </div>
          ) : null
        )
      }
    </>
  );
}
