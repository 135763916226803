import React, { useEffect, useState } from "react";
import $ from "jquery";
import AsyncSelect, { components } from "react-select/async";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Avatar from "../assets/images/avtar.jpg";
import {
  getAvailableUsersService,
  createChatGroupService,
  getMyGroupsService,
} from "../services/FeedService";
import FeedMessageForm from "../components/feed/FeedMessageForm";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ChatGroupList from "../components/chat/ChatGroupList";
import { setChatGroups, unsetChatGroups } from "../store/actions";
import ChatMessageForm from "../components/chat/ChatMessageForm";

function NewChat() {
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [search, setSearch] = useState(false);
  const [mygroups, setMyGroups] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chat.groups);

  useEffect(() => {
    if (!chats.length) {
      getMyGroupsService()
        .then((res) => {
          dispatch(setChatGroups(res.data));
          setMyGroups(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMyGroups(chats);
    }
  }, []);

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // load options using API call
  const loadOptions = (inputValue, callback) => {
    let users = [];
    return getAvailableUsersService(inputValue).then((res) => res.data);
    //return users;
    //return fetch(`http://jsonplaceholder.typicode.com/users?id=${inputValue}`).then(res => res.json());
  };

  const Option = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div ref={innerRef} {...innerProps} className={"custom-option"}>
        <div className={"sub"}>
          <img
            src={Avatar}
            style={{ width: "50px", height: "50px" }}
            className="rounded-circle"
          />
          {props.data.first_name} {props.data.last_name}
        </div>
      </div>
    );
  };

  const MultiValue = (props) => {
    const {
      children,
      className,
      components,
      cx,
      data,
      getStyles,
      innerProps,
      isDisabled,
      removeProps,
      selectProps,
    } = props;
    const { Container, Label, Remove } = components;

    return (
      <div>
        <img src={Avatar} style={{ width: "20px", height: "20px" }} />
        {props.data.first_name} {props.data.last_name}
        <Remove
          data={data}
          innerProps={{
            className: "remove-icon",
            ...removeProps,
          }}
          selectProps={selectProps}
        />
      </div>
    );
  };

  //   const MultiValueRemove = props => {
  // 	console.log(props);
  //    const { innerProps, innerRef } = props;
  //    return (

  // 	   <p>X</p>

  //    );
  //  }

  const makeGroup = () => {
    if (selectedValue) {
      createChatGroupService(JSON.stringify(selectedValue))
        .then((res) => {
          history.push(`/mychats/${res.data.group}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleGroupClick = (g) => {
    dispatch(unsetChatGroups([]));
    history.push(`/mychats/${g.group_encrypted_id}`);
  };

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="Chat-main-wrap">
            <div className="Chat-left-wrap bg-white">
              <button
                className="close-btn-sidebar"
                onClick={() => handleSideBar()}
              >
                <i className="close-bar close-bar-1"></i>
                <i className="close-bar close-bar-2"></i>
                <i className="close-bar close-bar-3"></i>
              </button>
              <div className="Chat-panel">
                <div className="Chat-panel-head d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Chat</h5>

                  <div className="search-chat" style={{ display: "block" }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={selectedValue}
                      getOptionLabel={(e) => e.title}
                      getOptionValue={(e) => e.id}
                      loadOptions={loadOptions}
                      onInputChange={handleInputChange}
                      onChange={handleChange}
                      isMulti={true}
                      placeholder="Search People"
                      components={{ Option, MultiValue }}
                      isSearchable={true}
                    />
                    {/* <input type="text" className="form-control" placeholder="People, groups & messages" /> */}
                    {/* <button className="close-search-chat" onClick={()=>setSearch(false)}><i className="fal fa-times"></i></button> */}
                  </div>
                </div>
                <div className="Chat-body">
                  {mygroups ? (
                    <ChatGroupList
                      mygroups={mygroups}
                      handleGroupClick={handleGroupClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="Chat-right-wrap">
              <div className="Chat-detail">
                <div className="Chat-detail-head d-flex">
                  <div className="Chat-head-lft">
                    <div className="icon">
                      <div className="icon-img">
                        <img src="images/user.jpg" />
                      </div>
                      <span className="portal-status-icon">
                        <i className="fas fa-clock"></i>
                      </span>
                    </div>
                  </div>
                  <div className="Chat-head-rgt">
                    <h3>Leon LW. Smalberger</h3>
                  </div>
                </div>
                <div className="Chat-detail-body">
                  <ChatMessageForm
                    group={null}
                    subscription={null}
                    initial={selectedValue}
                  />
                  {/* <input placeholder="Type new message" onFocus={makeGroup}  />  */}
                  {/* <FeedMessageForm /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewChat;
