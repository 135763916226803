const initialState = {
    isWithHeld: false,
  };
  
  const isWitheldReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_IS_WITHHELD":
        state.isWithHeld = action.payload;
        return { isWithHeld: state.isWithHeld };
      default:
        return state;
    }
  };
  
  export default isWitheldReducer;