import React, { useEffect, useState } from 'react'
import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'
import HomeWishes from '../components/common/HomeWishes'
import ExamRegistrationForm from '../components/ExamRegistration/ExamRegistrationForm'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setIsExamReg, setIsReReg } from '../store/actions'
import { GetExamNotSubmitReasonsList, GetMyOpenCloseExam } from '../services/ExamService'

export default function ExamRegistrationPage() {
  const [examList, setExamsList] = useState([])
  const [reasonDropdownData, setReasonDropdownData] = useState([]);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(()=>{
    GetMyOpenCloseExam({exam_type:"in_progress", exam:0}).then((res)=>{
      const respData = res?.data?.open.filter(item => item.registrationStudentStatus === "Not Registered" && (item.remind !==0));
      if(respData.length){
        dispatch(setIsExamReg(true));
        setExamsList(respData);
      }else{
        dispatch(setIsExamReg(false));
        history.push('/home');
      }
    }).catch((err)=>{console.log(err)});

    GetExamNotSubmitReasonsList()
    .then(res => {
        setReasonDropdownData(res?.data?.data.map(item=>({value:item?.id.toString(), label: item?.reason})));
    })
    .catch(error => {
        console.error(error);
    });
  },[update])

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap on-hold-height">
        <SideBar />
        <div className="sidebar-right">
          <div className="home-list-wrap">
            <HomeWishes />
            <div className="launch__wrappper border-0">
              {examList.length ? examList.map((exam, idx)=>(<ExamRegistrationForm update={update} setUpdate={setUpdate} key={idx} data={exam} reasonDropdownData={reasonDropdownData}/>)) : null}            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
