const initialState = {
    isReReg: false,
  };
  
  const isReRegReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_IS_RE_REG":
        state.isReReg = action.payload;
        return { isReReg: state.isReReg };
      default:
        return state;
    }
  };
  
  export default isReRegReducer;
  