import React, { useEffect, useState, useRef } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import TicketForm from "../components/ticket/TicketForm";
import TicketList from "../components/ticket/TicketList";
import NewTicketView from "../components/ticket/NewTicketView";
import { Link, useHistory } from "react-router-dom";

const NewTicketPage = () => {
  const [selectedTicket, setSelectedTicket] = useState('');
  const [ticketDetails, setTicketDetails] = useState({});
  const [view, setView] = useState("All");
  const [newTicket, setNewTicket] = useState(false);
  const scroll_down_bottom_parent = useRef();
  const [openForm, setOpenForm] = useState(false);

  const history = useHistory();

  useEffect(() => {
  }, [openForm, selectedTicket])

  useEffect(() => {
    const path = window.location.href.split('/').slice().reverse()
    // console.log
    setSelectedTicket(path[0])
  }, [])
  
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="wrap-box-right">
            <div className="Tickets-main-wrap">
              {/* <TicketList
                            selectedTicket={selectedTicket}
                            setSelectedTicket={setSelectedTicket}
                            ticketDetails={ticketDetails}
                            setTicketDetails={setTicketDetails}
                            view={view}
                            setView={setView}
                            newTicket={newTicket}
                            setNewTicket={setNewTicket}
                        /> */}
              {selectedTicket && (
                <React.Fragment>
                  <NewTicketView
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    scroll_down_bottom_parent={scroll_down_bottom_parent}
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    onBack={() => history.push("/mytickets")}
                  />
                  <div ref={scroll_down_bottom_parent}></div>
                </React.Fragment>
              )}
              {/* {selectedTicket == undefined && (
                            <TicketForm
                                setNewTicket={setNewTicket}
                                setSelectedTicket={setSelectedTicket}
                                openForm={openForm}
                                setOpenForm={setOpenForm}
                            />
                        )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTicketPage;
