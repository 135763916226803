import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

/** HOC */
import PublicRoute from "./pages/PublicRoute";
import PrivateRoute from "./pages/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import Calendar from "./pages/Calendar";
import ProfilePage from "./pages/ProfilePage";
import MyCourses from "./pages/MyCourses";
import MyCourse from "./pages/MyCourse";
import Course from "./pages/Course";
import ExamSummeryPage from "./pages/ExamSummeryPage";
import TicketPage from "./pages/TicketPage";
import NewTicketPage from "./pages/TicketPageNew";
import AddNewTicketPage from "./pages/AddNewTicketPage";
import NewMyTicketsID from "./pages/NewTicketPage";
import NotificationPage from "./pages/NotificationPage";
import AssignmentPage from "./pages/AssignmentPage";
import ReRegistrationPage from "./pages/ReRegistrationPage";
import ReRegistrationSchool from "./pages/ReRegistrationSchool";
import ExamDetails from "./pages/ExamDetails";
import Test from "./pages/Test";
import MyChats from "./pages/MyChats";
import NewChat from "./pages/NewChat";
import ComplimentCommentsPage from "./pages/ComplimentCommentsPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentVerify from "./pages/StudentVerify";
import ViewStudent from "./pages/ViewStudent";
import Video from "./pages/Video";
import VideoAdmin from "./pages/VideoAdmin";
import Settings from "./pages/Settings";
import StudentHub from "./pages/StudentHub";
import StudentStatus from "./pages/StudentStatus";
import ClassAttendance from "./pages/ClassAttendance";
import Article from "./pages/Article";
import ResetPasswordSet from "./pages/ResetPasswordSet";
import CourseOverview from "./pages/CourseOverview";
import ForceAboutDetails from "./pages/ForceAboutDetails";
import ReRegStatus from "./pages/ReRegStatus";
import QuizPreview from "./pages/QuizPreview";
import ResultWithheld from "./pages/ResultWithheld";
import ExamRegistrationPage from "./pages/ExamRegistrationPage";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expired: false,
    };
    console.warn = () => { };
  }
  componentDidMount() { }

  render() {
    return (
      <Router basename="/">
        <ToastContainer />

        <Switch>
          <PublicRoute exact path="/" component={LoginPage} />
          <PublicRoute exact path="/resetPassword" component={ResetPassword} />
          <PublicRoute exact path="/resetPasswordSet" component={ResetPasswordSet} />
          <PublicRoute
            exact
            path="/verify/:studentId"
            component={StudentVerify}
          />
          <Route
            exact
            path="/viewStudent"
            component={ViewStudent}
          />
          <Route
            exact
            path="/viewStudent/:email/:password"
            component={ViewStudent}
          />
          <PublicRoute exact path="/courseinfo/:qid/:password" component={Course} />
          <PublicRoute exact path="/courseinfo/shortcourse/:sid/:password" component={Course} />
          <PublicRoute exact path="/courseinfo/:qid/subject/:sid/:password" component={Course} />
          <PublicRoute exact path="/courseinfo/quizinfo/:qid/:password" component={QuizPreview} />
          <PublicRoute exact path="/videoAdmin/:videoType/:videoId" component={VideoAdmin} />
          <PrivateRoute exact path="/video/:videoType/:videoId" component={Video} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute path="/student-hub/:tab?/:page?/:type?/:id?" component={StudentHub} />
          
          <PrivateRoute exact path="/test" component={Test} />
          <PrivateRoute exact path="/home" component={HomePage} />
          <PrivateRoute exact path="/gdc" component={HomePage} />
          <PrivateRoute exact path="/calendar" component={Calendar} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/mycourses" component={MyCourses} />
          <PrivateRoute exact path="/course/:qid" component={MyCourse} />
          <PrivateRoute exact path="/shortCourse/:sid" component={MyCourse} />
          <PrivateRoute exact path="/course/:qid/subject/:sid" component={MyCourse} />
          <PrivateRoute exact path="/course/:qid/subject/:sid/assessment/:aid" component={MyCourse} />
          {/* <PrivateRoute exact path="/mytickets" component={TicketPage} /> */}
          {/* <PrivateRoute exact path="/mytickets/:ticketId" component={TicketPage} /> */}
          <PrivateRoute exact path="/myticketsdetail/:ticketId" component={NewMyTicketsID} />
          <PrivateRoute exact path="/mytickets" component={NewTicketPage} />
          <PrivateRoute exact path="/addnewticket" component={AddNewTicketPage} />
          <PrivateRoute exact path="/myticketsnew/:ticketId" component={NewTicketPage} />
          <PrivateRoute exact path="/myexam" component={ExamSummeryPage} />
          <PrivateRoute exact path="/status" component={StudentStatus} />
          <PrivateRoute exact path="/reregStatus" component={ReRegStatus} />
          <PrivateRoute exact path="/examregistration" component={ExamRegistrationPage} />
          <PrivateRoute exact path="/resultWithheld" component={ResultWithheld} />
          <PrivateRoute exact path="/course/:qid/subject/:sid/attendance" component={ClassAttendance} />
          <PrivateRoute exact path="/course/:qid/attendance" component={ClassAttendance} />
          <PrivateRoute exact path="/article/:id" component={Article} />
          <PrivateRoute exact path="/complimentcomments" component={ComplimentCommentsPage} />
          <PrivateRoute exact path="/reregistration/:id?" component={ReRegistrationPage} />
          <PrivateRoute exact path="/reregistrationSchool/:regid" component={ReRegistrationSchool} />
          <PrivateRoute
            // exact
            path="/mynotifications/:id?"
            component={NotificationPage}
          />
          <PrivateRoute exact path="/myexamdetails/:examid" component={ExamDetails} />
          <PrivateRoute exact path="/myassignment/:aid" component={AssignmentPage} />
          <PrivateRoute path="/mychats/:id?" component={MyChats} />
          <PrivateRoute exact path="/newchat" component={NewChat} />
          <PrivateRoute exact path="/forceAboutDetails" component={ForceAboutDetails} />
          <Route exact path="/courseOverview/:qid/subject/:sid/:password?" component={CourseOverview} />
          <Route exact path="/courseOverviews/:qid/subject/:sid/:stdId/:password?" component={CourseOverview} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

export default App;
