import React, { useState } from "react";
import AssignmentStatus from "../course/AssignmentStatus";
import { download } from "../../utils/FileDownload";
import {
    StoreTime,
} from "../../services/QualificationService";
import {getRandomInt}  from '../../utils/commonFunction.js'
import Swal from "sweetalert2";
import AppealStatus from "./AppealStatus";
import moment from "moment";

const downloadFile = async (absolutePath, fileName, index, timeLimitActive,setAssignmentReload,startTime) => {
    if(timeLimitActive==1){
       let msg = "You are about to launch your assessment which will start the countdown for submission. Please take note of the time you will have to submit your assessment once launched!";
       if (startTime===null) {
        Swal.close();
        Swal.fire({
        title: 'Are you sure?',
        text: msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Launch'
        }).then(function(result) {
        if (result.value) {
          StoreTime({assId:index})
          .then((res) => {
            setAssignmentReload(getRandomInt(6));
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Assessment Launched',
              showConfirmButton: true
            }).then((result) => {
              let value = download(absolutePath, fileName);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Assessment Could not be Started.",
              text: error,
            }).then((result) => {});
          });
        }
        });
      }else {
         let value = await download(absolutePath, fileName);
       }
    }else{
      let value = await download(absolutePath, fileName);
    }
  };
const getDownloadText = (status) =>{
    if(status=='Open'){
        return "File not available";
    }else if(status=='Pending'){
        return "Assessment will be available for download when it opens";
    }else{
        return "Assessment no longer available for download";
    }
}

const ShowResult = ({ data }) => (
    data.grade == 1 ? (
      data.assessmentMarkPercentage == 0  ? 
      (<div className='assessments-result-box'><i className="fas fa-circle red"></i>Incomplete</div>):
      data.assessmentMarkPercentage >= 50 ? 
      (<div className='assessments-result-box'><i className="fas fa-circle green"></i>Competent</div>): 
      (<div className='assessments-result-box'><i className="fas fa-circle yellow" ></i>Not yet Competent</div>)
      
      ) : (<p>{data.assessmentMarkPercentage}</p>)
);

const AssessmentHeader = ({assignmentDetails,setAssignmentReload, viewAssignment}) => {
    const [showMore, setShowMore] = useState(false);

  return (
    <>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Assessment Name:</b>
            </div>
            <div>
                <p>{assignmentDetails.name}</p>
            </div>
            </div>
        </div>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Code:</b>
            </div>
            <div>
                <p>{assignmentDetails.code}</p>
            </div>
            </div>
        </div>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Opening Date:</b>
            </div>
            <div>
                <p>{assignmentDetails.open_date_time}</p>
            </div>
            </div>
        </div>
        { assignmentDetails.status=='Closed-Submitted' ?
            (<div className="col-5 assessment-col">
                <div className="d-flex flex-wrap assMentbox">
                <div>
                    <b>Return Date:</b>
                </div>
                <div>
                <p>{assignmentDetails.marking_deadline_date}</p>
                </div>
                </div>
            </div>
            ):('')
        }
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Due Date:</b>
            </div>
            <div>
                <p>{assignmentDetails.close_date_time}</p>
            </div>
            </div>
        </div>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Your Result:</b>
            </div>
            <div>
            { assignmentDetails.status=='Closed-Marked' ?
                (<ShowResult data={assignmentDetails} />):('-')
            }
            </div>
            </div>
        </div>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Assessment Downloads:</b>
            </div>
            <div>
                { assignmentDetails.status=='Open' && assignmentDetails.assFile!='' ?
                (
                <p>
                    <a href
                    target="_blank" onClick={() =>
                    downloadFile(
                        assignmentDetails.assFile,
                        assignmentDetails.assFileName,
                        assignmentDetails.id,
                        assignmentDetails.timeLimitActive,
                        setAssignmentReload,
                        assignmentDetails.startTime
                    )
                    } className="btn btn-white-bordered">
                    <i className="fal fa-arrow-to-bottom"></i>
                    { assignmentDetails.timeLimitActive==1 ?
                    ( "Launch & Download" ): (  "Download" )
                    }
                    </a>
                </p>
                ): (
                <p>
                {getDownloadText(assignmentDetails.status)}
                </p>)
                }
            </div>
            </div>
        </div>
          {assignmentDetails?.assigendSickAssessment > 0 && assignmentDetails?.appeal_outcome==1 ? <div className="col-5 assessment-col">
              <div className="d-flex flex-wrap assMentbox">
                  <div>
                      <b>Sick Test Link:</b>
                  </div>
                  <div>
                      <p>
                          <button
                              onClick={()=>{viewAssignment(assignmentDetails?.assigendSickAssessment, "view")}}
                              className="btn btn-primary">
                              <i class="fas fa-eye"></i>
                              View
                          </button>
                      </p>
                  </div>
              </div>
          </div> : ""}
        { assignmentDetails.appeal_status=='Approved' && assignmentDetails?.appeal_outcome==0 ? (
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Extended Due Date:</b>
            </div>
            <div>
                <p>{moment.unix(assignmentDetails.assessment_close_date).format("DD MMM YYYY HH:mm")}</p>
            </div>
            </div>
        </div>
        ): null }
        { assignmentDetails.timeLimitActive==1 ? (
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Assessment Duration:</b>
            </div>
            <div>
            <div>
                <p className="as-red">{assignmentDetails.timeHour} hours {assignmentDetails.timeMin} min</p>
            </div>
            </div>
            </div>
        </div>
        ): null }
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Assessment Status:</b>
            </div>
            <div>
                <AssignmentStatus status= {assignmentDetails.status}/>
            </div>
            </div>
        </div>
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Appeal Status:</b>
            </div>
            <div>
                <AppealStatus status= {assignmentDetails.appeal_status}/>
            </div>
            </div>
        </div>        
        <div className="col-5 assessment-col">
            <div className="d-flex flex-wrap assMentbox">
            <div>
                <b>Appeal Message:</b>
            </div>
            <div>                
                {assignmentDetails?.student_message ? 
                (assignmentDetails?.student_message).length > 95 ?
                <>
                <p className={"appeal-message " + (!showMore ? "show-less" : "")} title={assignmentDetails?.student_message}>
                    {assignmentDetails?.student_message.trim()}
                </p>
                <span className="as-text-blue curser" onClick={()=>{setShowMore(!showMore)}}>{showMore ? "See Less" : "See More"}</span>
                </>
                : 
                <p className={"appeal-message "}>
                    {assignmentDetails?.student_message}
                </p>
                : 
                <p>
                {"-"}
                </p>}
                
            </div>
            </div>
        </div>
    </>
  );
}
export default AssessmentHeader;
