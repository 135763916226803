import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
const SkeletonQaulificationBox = () => {
  return (
    <div className="sub-lft-head">
      <div className="all-courses">
        <Link to="/mycourses">
          <i className="fal fa-angle-left"></i>All Courses
        </Link>
      </div>
      <div className="couses-bx-info-top d-flex">
        <div className="icon">
          <Skeleton height={50} />
        </div>
        <div className="couses-bx-info-right">
          <h3>
            <Skeleton height={25} width={200} />
          </h3>
          <p>
            <Skeleton height={25} width={200} />
          </p>
        </div>
      </div>
      <Skeleton height={25} />
    </div>
  );
};
export default SkeletonQaulificationBox;
