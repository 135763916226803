const initialState = {
    favouritesCount: 0,
  };
  
  const favouritesCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FAVOURITES_COUNT":
        state.favouritesCount = action.payload;
        return {
            favouritesCount: state.favouritesCount,
        };
    
      default:
        return state;
    }
  };
  
  export default favouritesCountReducer;