import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonLeftBoxNotification = () => {
    return (
        <div className="notfication-body">
            <div className="tab-content">
                <div className="tab-pane active" id="all">
                    <div className="card border-0 bg-white rounded-0">
                        <ul className="notification-list-ul list-unstyled m-0">
                            {Array(3)
                                .fill()
                                .map((item, index) => (                       
                                <li key={index}>
                                   <Skeleton width={317} height={30} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonLeftBoxNotification;
