import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Str from "../../common/Str";
import ObjectDetection from '../../../pages/object-detection/object-detection';
import '../../../pages/object-detection/styles.css';
import { SystemIcons } from "../../common/SystemIcons";
import { Loader } from "semantic-ui-react";


const Step2 = (props) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 10000);

  }, [props]);

  return (
    <>
      <div class="tab-pane fade show active" id="Assessment-Detail">
        <div class="test-series-main-block">
          <div class="test-series-main-left-blk">
            <div class="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span class="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>

            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li onClick={() => props.stepUpdate(1)} class="custom-breadcrumb-item active">
                    <a href="javascript:void(0);">
                      <i class="fal fa-home"></i>
                    </a>
                  </li>
                  <li class={`custom-breadcrumb-item ${(
                    (props.data.camera ? props.hasCamera : true) &&
                    (props.data.audio ? props.hasMicrophone : true) &&
                    (props.data.screen_share ? props.isRecord : true)
                  ) ? 'active' : 'inactive'}`}>
                    <a href="javascript:void(0);">{Str.sysCheck}</a>
                  </li>
                  {props.showIdentification && (
                    <li class="custom-breadcrumb-item">
                      <a href="javascript:void(0);">{Str.stuIdentify}</a>
                    </li>
                  )}
                  <li class="custom-breadcrumb-item">
                    <a href="javascript:void(0);">{Str.Test}</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={props.backBtn} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  <button
                    onClick={props.repeatBtn}
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fal fa-repeat"></i>&nbsp; Repeat Check
                  </button>

                  {(props.data.camera ? props.hasCamera : true) &&
                    (props.data.audio ? props.hasMicrophone : true) &&
                    (props.data.screen_share ? props.isRecord : true) ? (
                    <button
                      onClick={props.onNext}
                      type="button"
                      class="btn btn-primary"
                    >
                      {loader? <i className="fas fa-cog fa-spin"></i>: ''} Next &nbsp;<i class="fal fa-chevron-right"></i>{" "}
                      {props.showIdentification ? Str.Identification : Str.testAss}
                    </button>
                  ) : (
                    
                    <button type="button" class="btn btn-primary disabled">
                      {loader? <i className="fas fa-cog fa-spin"></i>: ''} Next &nbsp;<i class="fal fa-chevron-right"></i>{" "}
                      {props.showIdentification ? Str.Identification : Str.testAss}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div class="test-series-main-heading mb-15">
              <h4>System Check</h4>
            </div>
            <div class="system-check-items mb-4">
              <ul>
                <li
                  class={
                    // `${props.data.audio && props.data.camera
                    //   ? props.hasMicrophone && props.hasCamera
                    //     ? "active"
                    //     : "non-active"
                    //   : props.data.audio && !props.data.camera
                    //     ? props.hasMicrophone
                    //       ? "active"
                    //       : "non-active"
                    //     : !props.data.audio && props.data.camera
                    //       ? props.hasCamera
                    //         ? "active"
                    //         : "non-active"
                    //       : "system-check-opacity "
                    // }`
                    `${props.data.camera
                      ? props.hasCamera
                        ? "active"
                        : "non-active"
                      : "system-check-opacity "
                    }`
                  }
                >
                  <i class="fal fa-webcam"></i>
                  <p>
                    Webcam
                  </p>
                </li>
                {/* system-check-opacity */}
                <li
                  class={`${props.data.audio
                    ? props.hasMicrophone
                      ? "active"
                      : "non-active"
                    : "system-check-opacity "
                    }`}
                >
                  <i class="fal fa-microphone"></i>
                  <p>Microphone</p>
                </li>
                {/* <li
                  class={`${props.data.camera
                    ? props.hasCamera
                      ? "active"
                      : "non-active"
                    : "system-check-opacity "
                    }`}
                >
                  <i class="fal fa-image"></i>
                  <p>Webcam Image</p>
                </li> */}
                <li
                  class={`${props.data.screen_share
                    ? props.isRecord
                      ? "active"
                      : "non-active"
                    : "system-check-opacity "
                    }`}
                >
                  <i class="fal fa-desktop"></i>
                  <p>
                    Screen Sharing & <br /> Recording
                  </p>
                </li>
              </ul>
            </div>

          </div>

          <div class="test-series-main-right-blk">
            <div className="step-sidebar-space">
              {/* <div class="btn-action-block text-center">
                <button
                  onClick={props.backBtn}
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fal fa-chevron-left"></i> Back to your Assessments{" "}
                </button>
              </div> */}
            </div>
            <div class="test-series-steps">
              <ul>
                <li class="test-series-steps-item active done">
                  <a href="javascript:void(0);">
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                <li className={`test-series-steps-item active ${(

                  (props.data.camera ? props.hasCamera : true) &&
                  (props.data.audio ? props.hasMicrophone : true) &&
                  (props.data.screen_share ? props.isRecord : true)

                ) ? 'done' : ''}`}>
                  <a href="javascript:void(0);">
                    <span>2</span> <b>System Check</b>
                  </a>
                </li>
                {props.showIdentification ? (
                  <li class="test-series-steps-item">
                    <a href="javascript:void(0);">
                      <span>3</span> Identification
                    </a>
                  </li>
                ) : null}
                <li class="test-series-steps-item">
                  <a href="javascript:void(0);">
                    <span>{props.showIdentification ? 4 : 3}</span> Launch Exam
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step2;

