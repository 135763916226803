import React, { useState, useEffect } from "react";
import {
  GetDepartmentsService,
  AddTicketService,
} from "../../services/TicketService";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import NoSelectionIcon from "../../assets/images/notification_right_img.svg";
import Swal from "sweetalert2";
import { getFormattedTime, getStatusIcon, getCurrentTimeHomePage } from "../common/Helper";
import Select from "react-select";
import { useSelector } from "react-redux";

const StyledDiv = styled.div`
  .place-right {
    float: right;
  }
  .icon {
    width: 25%;
    position: absolute;
    top: 30%;
    left: 35%;
  }
  .wrap-text {
    margin: 15px 0 0 35px;
    .highlighttext {
      font-weight: 700;
    }
    .label {
      font-size: 12px;
      margin: 5px 0 0 15px;
    }
  }
`;

export default function NewTicketForm(props) {
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [stopSubmit, setStopSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const {user, isReReg, isWithHeld} = useSelector((state) => state);
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;
    GetDepartmentsService(history)
      .then((res) => {
        if (!unmounted) {
          setDepartments(res.data.departments);
          setSubjects(res.data.subjects);
        }
      })
      .catch((error) => { });

    return () => (unmounted = true);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ticket_subject: props.user.status== "On Hold" ? "Portal On Hold" : isWithHeldStatus == "true" ? "Results Withheld" : "",
      ticket_department: props.user.status== "On Hold" || isWithHeldStatus == "true" ? 7 : "",
      ticket_category: props.user.status == "On Hold" ? 22 : isWithHeldStatus == "true" ? 39 : "",
      ticket_intake: "",
      ticket_message: "",
    },
    validationSchema: Yup.object({
      ticket_subject: Yup.string().required("Ticket subject is required"),
      ticket_department: Yup.string().required("Department is required"),
      ticket_category: Yup.string().required("Category is required"),
      ticket_intake: Yup.string().required("Subject is required"),
      ticket_message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (!stopSubmit) {
        const formInputs = new FormData();
        selectedFile.map((item, index) => {
          formInputs.append(`file_${index + 1}`, item);
        });
        Object.keys(values).map((item) => {
          formInputs.append(item, values[item]);
        });
        AddTicketService(formInputs)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Submittted",
              timer: 3000,
              onOpen: function () {
                Swal.showLoading();
              },
            }).then(function (result) {
              setLoading(false);
              // if (result.dismiss === "timer") {
              //   let token = res.data.token;
              //   let user = res.data.user;
              //   dispatch(setUser(user));
              //   setUserSession(token, user);
              //   dispatch(setTab()); //side bar tab value set default to 1
              //   setLoading(false);
              //   history.push("/home");
              // }

              props.setSelectedTicket(res.data.ticket.id);

              props.setNewTicket(true);
              history.push("/myticketsdetail/" + res.data.ticket.id);
            });
          })
          .catch((error) => { });
      }
    },
  });

  const handleDepartment = async (e) => {
    const { value } = e;
    let valueExists = e.value;

    formik.setFieldValue("ticket_department", e.value);

    if (valueExists == "") {
      setCategories([]);
    } else {
      const cates = await departments.filter((item) => {
        return item.id == value;
      });
      formik.setFieldValue("ticket_category_label", '');
      formik.setFieldValue("ticket_category", '');
      setCategories(cates[0].categories);
    }
  };

  const getSelectedFile = (e) => {
    let newFile = Object.values(e.target.files);
    setSelectedFile((files) => [...files, ...newFile]);
  };

  const deleteSelectedFile = (index) => {
    const arr = selectedFile;
    arr.splice(index, 1);
    setSelectedFile(arr);
    setStopSubmit(true);
    setTimeout(() => setStopSubmit(false), 1000);
  };

  return (
    <>
      <div className="my-tickets-heading-blk flex-direction mb-4">
          <h4 className="text-left-align landing-heading">
            New <span>Tickets</span>
          </h4>
          <div className="text-md-right">
            <p className="">
              {getCurrentTimeHomePage(Date().toLocaleString())}
            </p>
          </div>
        </div>
      <div className="my-tickets-detail-block">
        {!props.openForm ? (
          <>
            <div className="Tickets-detail">
              <div className="Tickets-detail-head d-flex"></div>
            </div>
            <div className="no-notification no-Tickets">
              <div className="no-img">
                <img src={NoSelectionIcon} />
              </div>
              <div className="no-content">
                <div
                  className="add-new-ticket-bx"
                  onClick={() => props.setOpenForm(true)}
                >
                  <a title="New Ticket" className="btn btn-primary">
                    <i className="fal fa-plus"></i>New Ticket
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="Tickets-detail">
            <div className="Tickets-detail-body">
              <div className="card card-add-new-ticket align-center">
                

                <div className="card-body col-11">
                  <form onSubmit={formik.handleSubmit}>
                    {/* <div> */}
                    <div className="new-ticket-form row">
                      <div className="form-group col-md-4">
                        <div className=" form-group" title="Ticket Subject">
                          <label>Ticket Subject*</label>
                          <input
                            type="text"
                            name="ticket_subject"
                            className={
                              "form-control" +
                              (formik.errors.ticket_subject &&
                                formik.touched.ticket_subject
                                ? " is-invalid"
                                : "")
                            }
                            disabled={props.user.status == "On Hold" ? true : false}
                            placeholder="Ticket Subject"
                            value={formik.values.ticket_subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div className="form-group" title="Subject">
                          <label>Subject*</label>
                          <div className="form-icon-group">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.ticket_intake && formik.touched.ticket_intake
                                  ? " is-invalid"
                                  : "")
                              }
                              name="ticket_intake"
                              value={formik.values.ticket_intake}
                              onChange={(value) => {
                                formik.setFieldValue("ticket_intake", value.value)
                                formik.setFieldValue("ticket_intake_label", value.label);
                              }}
                              onBlur={formik.handleBlur}
                              maxMenuHeight={175}
                              placeholder={formik.values.ticket_intake_label ? formik.values.ticket_intake_label : "Choose Subject"}
                              options={subjects.map((sub, indexValue) => {
                                return (
                                  { value: sub.id, label: sub.name }
                                );
                              })}
                            />
                            {formik.errors.ticket_intake &&
                              formik.touched.ticket_intake ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group" title="Department">
                          <label>Department*</label>
                          <div className="form-icon-group">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.ticket_department && formik.touched.ticket_department
                                  ? " is-invalid"
                                  : "")
                              }
                              name="ticket_department"
                              isDisabled={props.user.status == "On Hold" ? true : false}
                              value={formik.values.ticket_department}
                              onChange={(value) => {
                                formik.setFieldValue("ticket_department", props.user.status == "On Hold" ? 7 : value.value)
                                formik.setFieldValue("ticket_department_label", props.user.status == "On Hold" ? "Finance" : value.label);
                                handleDepartment(value)
                              }}
                              onBlur={formik.handleBlur}
                              maxMenuHeight={175}
                              placeholder={formik.values.ticket_department_label ? formik.values.ticket_department_label : props.user.status== "On Hold" || isWithHeldStatus == "true" ? "Finance" : "Choose Department"}
                              options={departments.map((dep) => {
                                return (
                                  { value: dep.id, label: dep.title }
                                );
                              })}
                            />
                            {formik.errors.ticket_department &&
                              formik.touched.ticket_department ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-group" title="Category">
                          <label>Category*</label>
                          <div className="form-icon-group">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.ticket_category && formik.touched.ticket_category
                                  ? " is-invalid"
                                  : "")
                              }
                              name="ticket_category"
                              value={formik.values.ticket_category}
                              onChange={(value) => {
                                formik.setFieldValue("ticket_category", props.user.status == "On Hold" ? 22 : value.value)
                                formik.setFieldValue("ticket_category_label", props.user.status == "On Hold" ? "Portal on Hold" : value.label);
                              }}
                              isDisabled={props.user.status == "On Hold" ? true : false}
                              onBlur={formik.handleBlur}
                              maxMenuHeight={175}
                              placeholder={formik.values.ticket_category_label ? formik.values.ticket_category_label : props.user.status== "On Hold" ? "Portal on Hold" : isWithHeldStatus == "true" ? "Results Withheld" : "Choose Category"}
                              options={categories.length != 0 ? categories.map((cate) => {
                                return (
                                  { value: cate.id, label: cate.title }
                                );
                              }) : []}
                            />

                            {formik.errors.ticket_category &&
                              formik.touched.ticket_category ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-8  " title="Message">
                        <label>Message*</label>
                        <textarea
                          className={
                            "form-control" +
                            (formik.errors.ticket_message &&
                              formik.touched.ticket_message
                              ? " is-invalid"
                              : "")
                          }
                          name="ticket_message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ticket_message}
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-group atttach-file m-0 col-12 d-flex p-0">
                      <div className="">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading}
                          title="Create"
                        >
                          {loading && <i className="fas fa-cog fa-spin"></i>}{" "}
                          <i class="fal fa-check"></i>
                          Create
                        </button>
                      </div>
                      <div className="">
                        <label>
                          <i className="fal fa-paperclip"></i>
                          <span>Add Attachment</span>
                          <input
                            type="file"
                            id="input"
                            multiple
                            className="form-control  form-control-aatch-file"
                            name="file[]"
                            onChange={getSelectedFile}
                          />
                        </label>
                      </div>
                      <div className="" title="Cancel">
                        <a className="btn btn-danger" onClick={props.onCancel}>
                          <i class="fal fa-times"></i>
                          Cancel
                        </a>
                      </div>
                      <span className="ticketAttachments">
                        {selectedFile.length > 0 &&
                          selectedFile.map((file, index) => {
                            return (
                              <div key={file.name}>
                                {file.name}{" "}
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteSelectedFile(index)}
                                >
                                  <i className="fal fa-trash-alt"></i>
                                </button>
                              </div>
                            );
                          })}
                      </span>
                      {formik.touched.ticket_subject &&
                        formik.errors.ticket_subject && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.ticket_subject}
                          </div>
                        )}
                      {formik.touched.ticket_intake &&
                        formik.errors.ticket_intake ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.ticket_intake}
                        </div>
                      ) : null}
                      {formik.touched.ticket_department &&
                        formik.errors.ticket_department ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.ticket_department}
                        </div>
                      ) : null}
                      {formik.touched.ticket_category &&
                        formik.errors.ticket_category ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.ticket_category}
                        </div>
                      ) : null}
                      {formik.touched.ticket_message &&
                        formik.errors.ticket_message ? (
                        <div className="invalid-feedback d-block">
                          {formik.errors.ticket_message}
                        </div>
                      ) : null}
                    </div>
                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
