import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import VeryGoodImage from "../assets/images/very-good.png";
import GoodImage from "../assets/images/good.png";
import AverageImage from "../assets/images/average.png";
import PoorImage from "../assets/images/poor.png";
import VeryPoorImage from "../assets/images/very-poor.png";
import VeryGood from "../components/ComplimentComment/VeryGood";
import Good from "../components/ComplimentComment/Good";
import Average from "../components/ComplimentComment/Average";
import Poor from "../components/ComplimentComment/Poor";
import VeryPoor from "../components/ComplimentComment/VeryPoor";
class ComplimentCommentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rating_type: "VERY GOOD",
    };
  }

  handleRating = (rating) => {
    this.setState({ rating_type: rating });
  };
  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="compliment-wrpe-main">
              <div className="sec-compliment">
                <div className="compliment-header">
                  <h3>Rate our service, let our CEO know</h3>
                  <p>
                    At The Academic Institute of Excellence, we place our
                    client's interests at the forefront of our business. We like
                    to hear what our customers have to say in order to help
                    shape the future of our business and improve levels of
                    service. That is what 'Let our CEO know' is all about, an
                    opportunity for you to pose a question, lay a complaint or
                    compliment AIE directly to our CEO.
                  </p>
                </div>

                <div className="compliment-area">
                  <ul
                    className="nav nav-pills nav-pills-custom justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item"
                      onClick={() => this.handleRating("VERY GOOD")}
                    >
                      <a
                        title="Very good"
                        className="active"
                        id="verygood-tab"
                        data-toggle="pill"
                        href="#verygood"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <img src={VeryGoodImage} alt="" />
                        <p>very good</p>
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => this.handleRating("GOOD")}
                    >
                      <a
                        title="Good"
                        id="good-tab"
                        data-toggle="pill"
                        href="#good"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <img src={GoodImage} alt="" />
                        <p>good</p>
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => this.handleRating("AVERAGE")}
                    >
                      <a
                        title="Average"
                        id="average-tab"
                        data-toggle="pill"
                        href="#average"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        <img src={AverageImage} alt="" />
                        <p>AVERAGE</p>
                      </a>
                    </li>

                    <li
                      className="nav-item"
                      onClick={() => this.handleRating("POOR")}
                    >
                      <a
                        title="Poor"
                        id="poor-tab"
                        data-toggle="pill"
                        href="#poor"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        <img src={PoorImage} alt="" />
                        <p>POOR</p>
                      </a>
                    </li>

                    <li
                      className="nav-item"
                      onClick={() => this.handleRating("VERY POOR")}
                    >
                      <a
                        title="Very poor"
                        id="very-poor-tab"
                        data-toggle="pill"
                        href="#very-poor"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        <img src={VeryPoorImage} alt="" />
                        <p>VERY POOR</p>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content tbs-main" id="pills-tabContent">
                    <VeryGood rating_type={this.state.rating_type} />
                    <Good rating_type={this.state.rating_type} />
                    <Average rating_type={this.state.rating_type} />
                    <Poor rating_type={this.state.rating_type} />
                    <VeryPoor rating_type={this.state.rating_type} />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </>
    );
  }
}

export default ComplimentCommentsPage;
