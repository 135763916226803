import React from "react";
import moment from "moment";
import ClosedIcon from "./../../assets/images/closed.png";
import InProgressIcon from "./../../assets/images/In-Progress.png";
import OpenIcon from "./../../assets/images/Open.png";

import PendingAcademy from "./../../assets/images/TicketStatusIcon/Pending-Academy.png";
import PendingInvestigation from "./../../assets/images/TicketStatusIcon/Pending-Investigation.png";
import PendingStudent from "./../../assets/images/TicketStatusIcon/Pending-student.png";
import HoldIcon from "./../../assets/images/TicketStatusIcon/hold.png";
import New from "./../../assets/images/TicketStatusIcon/new.png";


export const originalValues = (s) => {
  try {
    return decodeURIComponent(escape(s));
  } catch (error) {
    return s;
  }
}

export const getCurrentTimeHomePage = (time) => {
  moment.suppressDeprecationWarnings = true;
  let formattedTime = moment(time).format("dddd, MMMM Do YYYY | h:mm:ss a");
  return formattedTime;
};

export const convertArray = (value) => {
  let v = value.replace(new RegExp(",", "g"), "<br/>");
  return v.replace(new RegExp("___", "g"), "  ");
};

export const wishTiming = (time) => {
  let todayHrs = time.getHours();

  if (todayHrs >= 0 && todayHrs <= 12) {
    return "Good Morning";
  } else if (todayHrs > 12 && todayHrs <= 17) {
    return "Good Afternoon";
  } else if (todayHrs > 17 && todayHrs <= 24) {
    return "Good Evening";
  }
};

export const getMenuItem = (item) => {
  let home = ["", "home"];
  let course = ["mycourses", "course", "shortCourse"];
  let myexam = ["myexam", "myexamdetails"];
  let chat = ["newchat", "mychats"];
  // let complimentcomments = ["complimentcomments"];
  let reregistration = ["reregistration", "reregistrationSchool"];
  let addNewTicket = ["addnewticket", "myticketsnew", "newmytickets"]

  if (home.includes(item)) return "home";
  if (course.includes(item)) return "mycourses";
  if (myexam.includes(item)) return "myexam";
  if (chat.includes(item)) return "mychats";
  if (reregistration.includes(item)) return "reregistration";
  if (addNewTicket.includes(item)) return "myticketsnew";

};

export const getFormattedTime = (time) => {
  let formattedTime = moment(time).format("DD MMM 'YY hh:mm a");
  return formattedTime;
};

export const dateTimeComparision = (current, update) => {
  var a = new Date(current);
  var b = new Date(update);

  var msDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate());
  var msDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate());

  if (parseFloat(msDateA) < parseFloat(msDateB)) return -1;
  // lt
  else if (parseFloat(msDateA) == parseFloat(msDateB)) return 0;
  // eq
  else if (parseFloat(msDateA) > parseFloat(msDateB)) return 1;
  // gt
  else return null; // error
};

export const getCurrentTime = () => {
  let formattedTime = moment().format("DD MMM 'YY hh:mm a");
  return formattedTime;
};

export const examPdfDateFormate = () => {
  let formattedTime = moment().format("DD-MM-YYYY");
  return formattedTime;
};

export const getFormattedTimeForList = (time) => {
  let formattedTime = moment(time).format("Do MMM YY hh:mm A");
  return formattedTime;
};

export const slashDateFormate = () => {
  let formattedTime = moment().format("DD/MM/YYYY");
  return formattedTime;
};

export const getStatusIcon = (status) => {
  if (status == "Closed") return ClosedIcon;
  if (status == "On-Hold") return HoldIcon;
  if (status == "Open") return OpenIcon;
  if (status == "Pending-Academy") return PendingAcademy;
  if (status == "Pending-Investigation") return PendingInvestigation;
  if (status == "Pending-Student") return PendingStudent;
  if (status == "New") return New;
};

export const getStatusDetails = (status) => {
  if (status == "Closed")
    return (
      <p className="as-red">
        <i className="fal fa-times"></i>Closed-Not Submitted
      </p>
    );
  if (status == "On-Hold")
    return (
      <p>
        <img src={InProgressIcon} />
        On - Hold
      </p>
    );
  if (status == "Open")
    return (
      <p>
        <img src={OpenIcon} />
        {status}
      </p>
    );
  if (status == "New")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
  if (status == "Pending-Academy")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
  if (status == "Pending-Student")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
};

export const getFileIcon = (extenstion) => {
  extenstion = extenstion.split(".")[extenstion.split(".").length - 1];
  const images = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "ico"];

  const pdfFile = ["pdf"];

  const docFile = ["doc", "docx"];

  const pptFile = ["ppt", "pptx"];

  const files = [
    "rtf",
    "xls",
    "xlsx",
    "txt",
    "csv",
    "html",
    "xhtml",
    "psd",
    "sql",
    "log",
    "fla",
    "xml",
    "ade",
    "adp",
    "mdb",
    "accdb",
    "odt",
    "ots",
    "ott",
    "odb",
    "odg",
    "otp",
    "otg",
    "odf",
    "ods",
    "odp",
    "css",
    "ai",
    "kmz",
    "dwg",
    "dxf",
    "hpgl",
    "plt",
    "spl",
    "step",
    "stp",
    "iges",
    "igs",
    "sat",
    "cgm",
    "tiff",
    "",
  ];

  const archives = ["zip", "rar", "gz", "tar", "iso", "dmg"];

  if (images.includes(extenstion)) return "fa-image";

  if (files.includes(extenstion)) return "fa-file";

  if (pdfFile.includes(extenstion)) return "fa-file-pdf";
  if (docFile.includes(extenstion)) return "fa-file-pdf";
  if (pptFile.includes(extenstion)) return "fa-file-powerpoint";

  if (archives.includes(extenstion)) return "fa-file-archive";
};

export const InitalRender = (data) => {
  if (data != null && data != undefined && data) {
    let tempArr = data.split(" ")
    let initials = ''
    tempArr.map(i => {
      if (i) {
        initials = initials + i[0].toUpperCase();
      }
    })
    initials = initials.length > 2 ? initials.slice(0, 2) : initials
    return initials
  }
  return 'NA'

}
const ResourceUi2 = ({ color, text, icon }) => {
  return (
    <span className={"cat " + color} title={text}>
      <i className={icon + " mr-1"}></i>
      {text}
    </span>
  );
};

const ResourceUi = ({ color, text, icon }) => {
  return (
    <p className={"as-widget " + color} title={text}>
      <i className={icon + " mr-1"}></i>
      {text}
    </p>
  );
};

const ResourceTopicsUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget"} style={{background : color , color: "#fff"}} title={text}>
    {text} </small>
  );
};

const campusResourceUi = ({ color, text, icon }) => {
  return (
    <p className={"as-widget"} style={{background : color , color: "#fff"}} title={text}>
      <i className={icon + " mr-1"}></i>
      {text}
    </p>
  );
};

const RollsUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget " + color} title={text}>
      {text} </small>
  );
};

const classStatusUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget-class-status " + color} title={text}>
      {text} </small>
  );
};


export const onlyCampus = (status) => {
  let str = status ? status.toLowerCase() : status;
  var replaced = str;
  switch (replaced) {
    case "cape-town":
      return "Cape Town";

    case "cent-pre":
      return "Centurion, Pretoria";

    case "correspondence":
      return "Correspondence";

    case "gdc":
      return "Greenside";

    case "mid-john":
      return "Midrand";

    case "online":
      return "Online";

    default:
      return str ? str : "NA";
  }
};


export const campusWidget = (status) => {
  let str = status ? status.toLowerCase() : status;
  var replaced = str;
  switch (replaced) {
    case "cape-town":
      return ResourceUi({ color: "as-blue-bg", text: "Cape Town", icon: "fal fa-university" });

    case "cent-pre":
      return ResourceUi({ color: "as-green-bg", text: "Centurion, Pretoria", icon: "fal fa-university" });

    case "correspondence":
      return ResourceUi({ color: "as-red-bg", text: "Correspondence", icon: "fal fa-university" });

    case "gdc":
      return ResourceUi({ color: "as-skyblue-bg", text: "Greenside", icon: "fal fa-university" });

    case "mid-john":
      return ResourceUi({ color: "as-orange-bg", text: "Midrand", icon: "fal fa-university" });

    case "online":
      return ResourceUi({ color: "as-green-bg", text: "Online", icon: "far fa-wifi" });

    default:
      return ResourceUi({ color: "as-blue-bg", text: str ? str : "NA" });
  }
};
export const campusesWidget = (color,text) => {
  return campusResourceUi({ color: color, text: text, icon: `${text == "Online" ? "far fa-wifi" : "fal fa-university"}` })  
};

export const topicsWidget = (status,color,text) => {
  return ResourceTopicsUi({ color: color, text: text }) 
 
};
export const campus = (status) => {
  let str = status ? status.toLowerCase() : status;
  var replaced = str;
  switch (replaced) {
    case "cape-town":
      return ResourceUi2({ color: "cat-blue", text: "Cape Town", icon: "fal fa-university" });

    case "cent-pre":
      return ResourceUi2({ color: "cat-greenhex", text: "Centurion, Pretoria", icon: "fal fa-university" });

    case "correspondence":
      return ResourceUi2({ color: "cat-dark-red", text: "Correspondence", icon: "fal fa-university" });

    case "gdc":
      return ResourceUi2({ color: "cat-skyblue", text: "Greenside", icon: "fal fa-university" });

    case "mid-john":
      return ResourceUi2({ color: "cat-orange", text: "Midrand", icon: "fal fa-university" });

    case "online":
      return ResourceUi2({ color: "cat-green", text: "Online", icon: "far fa-wifi" });

    default:
      return ResourceUi2({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};

export const availability = (availability) => {
  switch (availability ? availability.toLowerCase() : availability) {

    case "issued":
      return <p className="as-red-bg as-widget">Issued</p>;

    case "missing":
      return <p className="as-blue-bg as-widget">Missing</p>;

    case "found":
      return <p className="as-yellowshade-bg as-widget">Found</p>;

    case "reserved":
      return <p className="as-yellow-bg as-widget">Reserved</p>;

    case "returned":
      return <p className="as-green-bg as-widget">Returned</p>;

    case "is_available":
      return <p className="as-green-bg as-widget">Available</p>;

    default:
      return "-";
  }
};


export const availabilityText = (availability) => {
  switch (availability ? availability.toLowerCase() : availability) {

    case "issued":
      return "Issued";

    case "missing":
      return "Missing";

    case "found":
      return "Found";

    case "reserved":
      return "Reserved";

    case "returned":
      return "Returned";

    case "is_available":
      return "Available";

    default:
      return "-";
  }
};


export const resourceWidget = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "video":
      return ResourceUi({ color: "as-green-bg", text: "Video", icon: "fas fa-video" });
    case "vimeo":
      return ResourceUi({ color: "as-green-bg", text: "Video", icon: "fas fa-video" });
    case "youtube":
      return ResourceUi({ color: "as-green-bg", text: "Video", icon: "fas fa-video" });
  
    case "book":
      return ResourceUi({ color: "as-blue-bg", text: "Book", icon: "fas fa-book" });
    case "ebook":
      return ResourceUi({ color: "as-orange-bg", text: "E-Book", icon: "fas fa-wifi" });
  
    case "link":
      return ResourceUi({ color: "as-skyblue-bg", text: "Link", icon: "fas fa-link" });

    case "document":
      return ResourceUi({ color: "as-red-bg", text: "Document", icon: "fas fa-file" });

    case "pdf":
      return ResourceUi({ color: "as-red-bg", text: "Document", icon: "fas fa-file" });

    case "article":
      return ResourceUi({ color: "as-yellowshade-bg", text: "Article", icon: "fas fa-newspaper" });
    
    case "label":
      return ResourceUi({ color: "as-yellowshade-bg", text: "Label", icon: "fas fa-tag" });

    case "white_paper":
    case "white_pape":
      return ResourceUi({ color: "as-orange-bg", text: "White Paper", icon: "fas fa-link" });

    default:
      return ResourceUi({ color: "as-orange-bg", text: str ? str : "NA" });
  }
};

export const rollsWidget = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  switch (str) {
    case "lecturer":
      return RollsUi({ color: "rolls-tag-small", text: "Subject Lecturer" });
    case "guest lecturer":
    case "guest_lecturer":
      return RollsUi({ color: "rolls-tag-small", text: "Guest Lecturer" });
    case "coach":
      return RollsUi({ color: "rolls-tag-small", text: "Programme Lead" });
    case "support":
      return RollsUi({ color: "rolls-tag-small", text: "Programme Support" });
    case "product_manager":
    case "product manager":
      return RollsUi({ color: "rolls-tag-small", text: "Product Manager" });
    case "student":
      return RollsUi({ color: "student-rolls-tag-small", text: "Student" });
    case "student_support":
    case "student support":
      return RollsUi({ color: "rolls-tag-small", text: "Student Support" });
    default:
      return RollsUi({ color: "rolls-tag-small", text: str ? str : "NA" });
  }
};

const classUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget-class-status " + color} title={text}>
      {text} </small>
  );
};

export const classStatusWidgets = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  switch (str) {
    case "past":
      return classUi({ color: "as-red-bg-status", text: "Past" });
    case "upcoming":
      return classUi({ color: "as-orange-bg-status", text: "Upcoming" });
    case "live":
      return classUi({ color: "as-green-bg-status", text: "Live" });
    case "ended":
      return classUi({ color: "as-red-bg-status", text: "Ended" });
    case "cancelled":
      return classUi({ color: "as-red-bg-status-cancelled", text: "Cancelled" });
      case "launched":
        return classUi({ color: "as-blue-bg-status", text: "Launched" });
      case "not launched":
        return classUi({ color: "as-blue-bg-not-launch-status", text: "Not Launched" });
    default:
      return classUi({ color: "", text: str ? str : "" });
  }
};


export const resource = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "video":
      return ResourceUi({ color: "as-green-bg", text: "Video", icon: "fas fa-video" });
    case "vimeo":
      return ResourceUi2({ color: "as-green-bg", text: "VIdeo", icon: "fas fa-video" });
    case "youtube":
        return ResourceUi2({ color: "as-green-bg", text: "Video", icon: "fas fa-video" });
  
    case "book":
      return ResourceUi2({ color: "cat-blue", text: "Book", icon: "fas fa-book" });

    case "link":
      return ResourceUi2({ color: "cat-skyblue", text: "Link", icon: "fas fa-link" });

    case "document":
      return ResourceUi2({ color: "cat-dark-red", text: "Document", icon: "fas fa-file" });

    case "article":
      return ResourceUi2({ color: "cat-darkyellow", text: "Article", icon: "fas fa-newspaper" });

    case "pdf":
      return ResourceUi2({ color: "cat-dark-red", text: "Document", icon: "fas fa-file" });
    
    case "label":
      return ResourceUi2({ color: "cat-greenshade", text: "Label", icon: "fas fa-tag" });
    
    case "white_paper":
        return ResourceUi({ color: "as-orange-bg", text: "White Paper", icon: "fas fa-link" });
  
    default:
      return ResourceUi2({ color: "cat-dark-red", text: str ? str : "NA" });
  }
};
