import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import SubmitAppeal from "../course/SubmitAppeal";
import ViewAppeal from "../course/ViewAppeal";
import AssessmentHeader from "../course/AssessmentHeader";
import AssignmentStatus from "../course/AssignmentStatus";
import {
  GetAssignmentDetail, 
} from "../../services/QualificationService";

const Appeal = (props) => {
  const {assessmentId} = props;
  const [assignmentDetails, setassignmentDetails] = useState({});
  const [assignmentReload, setAssignmentReload] = useState(false);
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (props.assessmentId) {
      let aid=props.assessmentId
      GetAssignmentDetail({aid})
        .then((res) => {
          setassignmentDetails(res.data);
        })
        .catch((error) => {});
    }
}, [assessmentId,assignmentReload]);

const viewMe = (id,view) => {
  props.viewAssignmentCall(id,view);
};
  const DownloadButton='';
  return (
    <>
    <button title="Back to Assessment List" onClick={() => props.parentCall()} class="btn btn-white-bordered btn-back"><i class="fal fa-angle-left"></i>Back to Assessment List</button>
      <div class="assessments-card">
        <div class="ac-card ac-ass-col-adjust mb-4">
          <h3 class="header-title">Assessment Detail</h3>
            <div class="ac-white-card">
            <div class="row rowAssessment">
            <AssessmentHeader assignmentDetails= {assignmentDetails} setAssignmentReload={setAssignmentReload} viewAssignment={viewMe} />
               {assignmentDetails.submit_date > 0 ? (
                <div class="col-5 assessment-col">
                  <div class="d-flex flex-wrap assMentbox">
                    <div>
                        <b>Appeal Status:</b>
                    </div>
                    <div>
                    {assignmentDetails.appeal_status=='Approved' ? (                  
                      <p class="as-green">{assignmentDetails.appeal_status}</p>
                    ):(<p class="as-red">{assignmentDetails.appeal_status}</p>
                    )}
                    </div>
                  </div>
                </div>
                ): null}
                {assignmentDetails.submit_date > 0 ? ( 
                <div class="col-5 assessment-col">
                  <div class="d-flex flex-wrap assMentbox">
                    <div>
                        <b>Appeal Comment</b>
                    </div>
                    <div>
                      {assignmentDetails?.student_message ? 
                      (assignmentDetails?.student_message).length > 95 ?
                        <>
                        <p className={"appeal-message " + (!showMore ? "show-less" : "")} title={assignmentDetails?.student_message}>
                            {assignmentDetails?.student_message.trim()}
                        </p>
                        <span className="as-text-blue curser" onClick={()=>{setShowMore(!showMore)}}>{showMore ? "See Less" : "See More"}</span>
                        </>
                      :<p className={"appeal-message "}>
                        {assignmentDetails?.student_message}
                      </p>
                      :<p>{"-"}</p>}
                    </div>
                  </div>
                </div>
              ): null}
              {assignmentDetails.appeal_status=='Approved' && assignmentDetails.appeal_outcome==0 ? ( 
                <div class="col-5 assessment-col">
                  <div class="align-items-center d-flex flex-wrap assMentbox">
                    <div>
                        <b>Assessment Link</b>
                    </div>
                    <div>
                    <button title="View" onClick={() => viewMe(assignmentDetails.id,"view")} className="btn appeal_assessment_btn btn-primary">     
                    <i class="fa fa-eye mr-1"></i> View
                    </button>
                    </div>
                  </div>
                </div>
              ): null}
            </div>
          </div>
        </div>
        {assignmentDetails.appeal_status === null ? (
        <SubmitAppeal row= {assignmentDetails} setAssignmentReload={setAssignmentReload}/>
        ):(
        <ViewAppeal row= {assignmentDetails} setAssignmentReload={setAssignmentReload}/>
        )}
      </div>
    </>
  );
}
export default Appeal;
