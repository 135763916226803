import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { connect } from "react-redux";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { useHistory, Link } from "react-router-dom";
import SoftAndHardLaunch from "../components/SoftAndHardLaunch/SoftAndHardLaunch";
// import dummyImg from "../assets/images/dummy-on-hold.png";

const mapStateToProps = (state) => ({
  user: state.user,
});

class StudentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},
    };
    // console.log("user status >>>>",this.props.user);
    // const reRegStatus = false;
    const reRegStatus = JSON.parse(localStorage.getItem('reRegStatus'));
    if (
      (this.props.user.status !== "On Hold")
      && !reRegStatus
    ) {
      window.location.href = "/home";
    }
  }

  render() {
    const user = this.props.user;
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap on-hold-height">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="home-list-wrap">
              <div className="wlcome-user-info">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h4 className="my-md-2 my-0">
                      Dear{" "}
                      <span className="heading-text-on-hold">
                        {user.first_name} {user.second_name} {user.third_name}{" "}
                        {user.last_name}
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-6 text-md-right">
                    <p className="my-2">
                      {getCurrentTimeHomePage(Date().toLocaleString())}
                    </p>
                  </div>
                </div>
                <div className="on-hold-box card mt-3">
                  {/* <br/><br/>
                      Your student account status has been set to, {user.status}. You will have limited access to your account. Please submit a ticket or contact student support for further information. 
                      <br/><br/><br/>
                      Kind regards, 
                      <br/><br/>
                      AIE */}

                  <div className="row align-items-center">
                    <div className="col-md-5 ml-auto on-hold-box-L">
                      {/* <figure><img src={dummyImg}/></figure> */}
                      <figure>
                        <svg
                          version="1.2"
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 286 175"
                          width="463"
                          height="309"
                        >
                          <path
                            id="Layer"
                            className="s0"
                            d="m125.2 136.9h1v27.8h-1z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m153.6 123.9h0.9v40.8h-0.9z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m182 132.4h1v32.3h-1z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m152.1 122.5c0.5-0.5 1.3-0.8 2-0.8 0.8 0 1.5 0.3 2.1 0.8 0.5 0.6 0.8 1.3 0.8 2.1 0 0.7-0.3 1.5-0.8 2-0.6 0.6-1.3 0.9-2.1 0.9-0.7 0-1.5-0.3-2-0.9-0.6-0.5-0.9-1.3-0.9-2 0-0.8 0.3-1.5 0.9-2.1z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m180.5 130.6c0.6-0.6 1.3-0.9 2.1-0.9 0.7 0 1.5 0.3 2 0.9 0.6 0.5 0.9 1.3 0.9 2 0 0.8-0.3 1.5-0.9 2.1-0.5 0.5-1.3 0.8-2 0.8-0.8 0-1.5-0.3-2.1-0.8-0.5-0.6-0.8-1.3-0.8-2.1 0-0.7 0.3-1.5 0.8-2z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m123.1 132.5c0.6-0.5 1.3-0.9 2.1-0.9 0.7 0 1.5 0.4 2 0.9 0.6 0.5 0.9 1.3 0.9 2.1 0 0.7-0.3 1.5-0.9 2-0.5 0.5-1.3 0.9-2 0.9-0.8 0-1.5-0.4-2.1-0.9-0.5-0.5-0.8-1.3-0.8-2 0-0.8 0.3-1.6 0.8-2.1z"
                          />
                          <path
                            id="Layer"
                            className="s1"
                            d="m41.9 165.6c26.8-1 63.2-1.6 101.1-1.6 37.9 0 74.3 0.6 101.1 1.6 26.8 1 41.9 2.4 41.9 3.9 0 1.5-15.1 2.9-41.9 3.9-26.8 1-63.2 1.6-101.1 1.6-37.9 0-74.3-0.6-101.1-1.6-26.8-1-41.9-2.4-41.9-3.9 0-1.5 15.1-2.9 41.9-3.9z"
                          />
                          <path
                            id="Layer"
                            className="s-blue"
                            d="m222 0c2.5 0 4.5 3.1 4.5 6.9v72.6c0 3.8-2 6.9-4.5 6.9h-137.8c-2.5 0-4.6-3.1-4.6-6.9v-72.6c0-3.8 2.1-6.9 4.6-6.9z"
                          />
                          <path
                            id="Layer"
                            className="s3"
                            d="m28.1 114.8q1.1-1.9 2.3-3.7 1.2-1.9 2.6-3.6 1.3-1.7 2.8-3.3 1.5-1.7 3.1-3.2 0.2-0.1 0.4-0.2 0.3-0.1 0.5-0.2 0.3 0 0.5 0.1 0.3 0.1 0.5 0.3l10.8 8.4q0.1 0.1 0.2 0.2 0.1 0.2 0.2 0.3 0 0.1 0.1 0.3 0 0.2 0 0.3 0 0.2 0 0.4-0.1 0.1-0.1 0.3-0.1 0.1-0.2 0.3-0.1 0.1-0.3 0.2-1.6 1.3-3 2.8-1.5 1.4-2.7 3-1.3 1.7-2.4 3.4-1.1 1.8-1.9 3.7-0.1 0.1-0.2 0.2-0.1 0.2-0.2 0.3-0.1 0.1-0.3 0.2-0.1 0.1-0.3 0.1-0.2 0-0.3 0-0.2 0-0.4 0-0.1 0-0.3-0.1-0.1-0.1-0.3-0.2l-10.7-8.4q-0.2-0.2-0.4-0.4-0.1-0.2-0.2-0.5 0-0.2 0-0.5 0.1-0.3 0.2-0.5z"
                          />
                          <path
                            id="Layer"
                            className="s4"
                            d="m43 113.5l-5.6-4.5q-0.2-0.1-0.3-0.3-0.1-0.2-0.1-0.4 0-0.2 0-0.4 0.1-0.2 0.2-0.3l0.3-0.3q0.1-0.2 0.3-0.3 0.1-0.1 0.3-0.1 0.2 0 0.4 0 0.2 0.1 0.4 0.2l5.7 4.4q0.1 0.1 0.2 0.3 0.1 0.2 0.1 0.4 0.1 0.2 0 0.4-0.1 0.2-0.2 0.4l-0.2 0.3q-0.2 0.2-0.3 0.3-0.2 0.1-0.4 0.1-0.2 0-0.4-0.1-0.2 0-0.4-0.1z"
                          />
                          <path
                            id="Layer"
                            className="s4"
                            d="m40.4 116.8l-5.6-4.4q-0.2-0.2-0.3-0.3-0.1-0.2-0.1-0.4-0.1-0.2 0-0.4 0.1-0.2 0.2-0.4l0.2-0.3q0.2-0.2 0.3-0.3 0.2-0.1 0.4-0.1 0.2 0 0.4 0 0.2 0.1 0.4 0.2l5.6 4.5q0.2 0.1 0.3 0.3 0.1 0.1 0.1 0.3 0 0.3 0 0.5-0.1 0.1-0.2 0.3l-0.2 0.3q-0.2 0.2-0.4 0.3-0.1 0.1-0.3 0.1-0.2 0-0.4 0-0.2-0.1-0.4-0.2z"
                          />
                          <path
                            id="Layer"
                            className="s3"
                            d="m252.9 116.3q-0.3-2.1-0.5-4.3-0.1-2.2-0.1-4.4 0-2.2 0.1-4.4 0.2-2.2 0.5-4.4 0.1-0.2 0.2-0.4 0.1-0.3 0.3-0.4 0.2-0.2 0.5-0.3 0.2-0.1 0.5-0.1h13.7q0.1 0 0.3 0 0.2 0.1 0.3 0.2 0.2 0 0.3 0.1 0.1 0.1 0.2 0.3 0.1 0.1 0.2 0.3 0.1 0.1 0.1 0.3 0 0.1 0 0.3 0 0.2 0 0.3-0.5 2-0.7 4.1-0.3 2-0.3 4.1 0 2.1 0.3 4.1 0.2 2.1 0.7 4.1 0 0.1 0 0.3 0 0.2 0 0.3 0 0.2-0.1 0.3-0.1 0.2-0.2 0.3-0.1 0.2-0.2 0.3-0.1 0.1-0.3 0.1-0.1 0.1-0.3 0.2-0.2 0-0.3 0h-13.7q-0.3 0-0.5-0.1-0.3-0.1-0.5-0.3-0.2-0.1-0.3-0.4-0.1-0.2-0.2-0.5z"
                          />
                          <path
                            id="Layer"
                            className="s4"
                            d="m264.1 106.5h-7.2q-0.2 0-0.4-0.1-0.2-0.1-0.3-0.2-0.2-0.1-0.2-0.3-0.1-0.2-0.1-0.4v-0.4q0-0.2 0.1-0.4 0-0.2 0.2-0.4 0.1-0.1 0.3-0.2 0.2-0.1 0.4-0.1h7.2q0.2 0 0.4 0.1 0.2 0.1 0.3 0.2 0.2 0.2 0.3 0.4 0 0.2 0 0.4v0.4q0 0.2 0 0.4-0.1 0.2-0.3 0.3-0.1 0.1-0.3 0.2-0.2 0.1-0.4 0.1z"
                          />
                          <path
                            id="Layer"
                            className="s5"
                            d="m256.2 101.4q-0.5 0.4-1.2 0.6-0.6 0.2-1.3 0.2-0.6-0.1-1.2-0.4-0.5-0.3-0.9-0.7-0.4-0.5-0.6-1-0.2-0.6-0.3-1.1 0-0.6 0.1-1.2l-1-11.3 5-3.6 0.2 12.4q0.1 0.1 0.2 0.1 0 0 0.1 0 0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0.6 0.3 1.1 0.9 0.4 0.5 0.6 1.2 0.2 0.6 0.1 1.3 0 0.7-0.4 1.3-0.2 0.6-0.7 1.1z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m254.8 94.8l-3.5 0.2q-0.3 0-0.6-0.1-0.2-0.1-0.5-0.3-0.2-0.2-0.3-0.4-0.1-0.3-0.2-0.6l-2.9-22.3-4.8-13.6q-0.2-0.4-0.2-0.8-0.1-0.5-0.1-0.9 0-0.5 0.1-0.9 0.1-0.4 0.3-0.8 0.2-0.4 0.4-0.8 0.3-0.4 0.6-0.6 0.3-0.3 0.6-0.6 0.4-0.2 0.8-0.4 0.8-0.2 1.7-0.2 0.8 0.1 1.6 0.5 0.7 0.4 1.3 1.1 0.5 0.6 0.7 1.4l4.9 15.6 1.5 22.8q0 0.2 0 0.3 0 0.2-0.1 0.3 0 0.2-0.1 0.3-0.1 0.1-0.2 0.2 0 0.1-0.1 0.2-0.1 0-0.1 0.1-0.1 0.1-0.2 0.1-0.1 0.1-0.2 0.1 0 0-0.1 0 0 0-0.1 0 0 0.1-0.1 0.1 0 0-0.1 0z"
                          />
                          <path
                            id="Layer"
                            className="s5"
                            d="m234.1 163.4h-4.3l-2-16.3h6.3z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m235.1 167.4h-13.5v-0.1q0-1.1 0.4-2.1 0.4-0.9 1.1-1.7 0.7-0.7 1.7-1.1 1-0.4 2-0.4h8.3z"
                          />
                          <path
                            id="Layer"
                            className="s5"
                            d="m246.4 163.4h-4.2l-2-16.3h6.2z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m247.5 167.4h-13.6v-0.1q0-1.1 0.4-2.1 0.4-0.9 1.2-1.7 0.7-0.7 1.7-1.1 0.9-0.4 2-0.4h8.3z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m249.2 97.8l-1 59.6q0 0.3-0.1 0.5-0.1 0.3-0.3 0.5-0.2 0.2-0.5 0.3-0.3 0.1-0.6 0l-4.9-0.4q-0.2 0-0.5-0.1-0.2-0.1-0.4-0.3-0.1-0.2-0.2-0.4-0.1-0.2-0.2-0.5l-2.8-37.2q0-0.1-0.1-0.2-0.1-0.1-0.3-0.1-0.1 0-0.2 0.1-0.1 0.1-0.1 0.2l-1.4 38.1q-0.1 0.3-0.2 0.5-0.1 0.2-0.3 0.4-0.2 0.2-0.4 0.3-0.3 0.1-0.5 0.1h-5.3q-0.3 0-0.5-0.1-0.3-0.1-0.4-0.3-0.2-0.1-0.3-0.4-0.2-0.2-0.2-0.5l-3.7-60.5 22-5.5z"
                          />
                          <path
                            id="Layer"
                            className="s5"
                            d="m224.9 33.2c0-2.2 0.8-4.3 2.4-5.9 1.6-1.6 3.8-2.5 6-2.5 2.2 0 4.4 0.9 6 2.5 1.5 1.6 2.4 3.7 2.4 5.9 0 2.3-0.9 4.4-2.4 6-1.6 1.6-3.8 2.5-6 2.5-2.2 0-4.4-0.9-6-2.5-1.6-1.6-2.4-3.7-2.4-6z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m223.1 99.5q-0.1-0.1-0.2-0.3-0.1-0.1-0.2-0.2 0-0.2-0.1-0.3 0-0.2 0-0.3l-0.6-40.7q0-1.5 0.5-3 0.4-1.5 1.3-2.8 0.9-1.2 2.2-2.2 1.2-0.9 2.7-1.4c0.8-3.6 5-4 7.4-4q0.5 0 0.9 0.1 0.5 0.2 0.9 0.4 0.4 0.2 0.7 0.5 0.3 0.3 0.6 0.7l1.8 2.6 3.8 2.3q1.4 0.8 2.4 2 1 1.1 1.7 2.5 0.6 1.5 0.7 3 0.2 1.6-0.2 3.1l-0.6 2.7q-0.7 3.6-1.1 7.2-0.4 3.7-0.3 7.4 0.1 3.6 0.6 7.3 0.5 3.6 1.4 7.2l1.2 4.5q0.1 0.4 0 0.8 0 0.3-0.3 0.6-0.2 0.3-0.6 0.4-0.3 0.2-0.7 0.2l-1.4-0.1q-0.3-0.1-0.5-0.2-0.2-0.1-0.4-0.3-0.2-0.2-0.4-0.4-0.1-0.3-0.1-0.5l-0.2-2.3q0-0.1-0.1-0.2 0 0-0.1 0-0.1 0-0.1 0-0.1 0.1-0.1 0.2l-0.2 2.2q0 0.3-0.2 0.6-0.1 0.2-0.3 0.4-0.2 0.2-0.5 0.3-0.2 0.1-0.5 0.1l-19.7 0.3h-0.1q-0.1 0-0.3 0-0.1-0.1-0.2-0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.2z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m233.9 38.1q0-0.3-0.1-0.6-0.1-0.3-0.1-0.6-0.1-0.3-0.1-0.6-0.1-0.2-0.1-0.5c-0.1-0.8-0.4-1.7-1.1-2q-0.2-0.1-0.5-0.1-0.2 0-0.4 0.1-0.2 0-0.4 0.1-0.2 0.1-0.4 0.3-0.2 0.1-0.4 0.2-0.1 0.2-0.3 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.2-0.4 0.2-0.9 0.1-0.5 0-0.9-0.2-0.4-0.2-0.7-0.6-0.3-0.3-0.4-0.8c-0.2-0.8 0-1.8-0.6-2.4q-0.1-0.2-0.3-0.3-0.2-0.2-0.5-0.2-0.2-0.1-0.5-0.1-0.2 0-0.5 0c-0.6 0-1.4 0.6-1.9 0.2-2.9-2.3 8.8-9.5 11.4-6.3 2.1-1.4 5.2-0.7 7 1.1q0.7 0.8 1.2 1.6 0.4 0.9 0.7 1.8 0.3 0.9 0.4 1.9 0 1-0.1 1.9-0.1 0.6-0.1 1.1-0.1 0.5-0.2 0.9-0.1 0.5-0.3 1-0.1 0.5-0.2 1l-1.1 4.1q0 0.2-0.1 0.5-0.2 0.2-0.4 0.3-0.2 0.2-0.5 0.2-0.3 0-0.5-0.1-0.4-0.1-0.9-0.2-0.4-0.1-0.8-0.3-0.5-0.1-0.9-0.3-0.4-0.2-0.8-0.4c-1-0.6-1.8-1.3-1.9-2.5z"
                          />
                          <path
                            id="Layer"
                            className="s7"
                            d="m234 77.9l-10.6 17.2 7.2-17.5 1.4-16.9z"
                          />
                          <path
                            id="Layer"
                            className="s5"
                            d="m219.4 102.4c-0.9-0.1-1.7-0.4-2.3-1-0.6-0.6-1-1.4-1-2.2-0.1-0.9 0.2-1.7 0.7-2.4l3.4-10.6 6.2-1.3-4.6 11.4q0.6 0.5 0.9 1.2 0.3 0.7 0.3 1.5 0 0.7-0.3 1.4-0.4 0.7-1 1.2c-0.6 0.5-1.5 0.8-2.3 0.8z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m221.6 96.6l-3.5-1.3q-0.3-0.1-0.5-0.3-0.3-0.2-0.4-0.4-0.1-0.3-0.2-0.6 0-0.3 0.1-0.6l5.8-21.3 0.9-19.3c0-1.2 0.6-2.3 1.4-3.1 0.9-0.8 2.1-1.3 3.3-1.2 1.2 0.1 2.3 0.6 3 1.5 0.8 0.9 1.2 2.1 1.1 3.3l-1.6 21.2-7.4 21.1q-0.1 0.3-0.3 0.6-0.2 0.2-0.5 0.3-0.3 0.2-0.6 0.2-0.3 0-0.6-0.1z"
                          />
                          <path
                            id="Layer"
                            className="s8"
                            d="m40.2 146.8l5.6 2.8-9 13.6-3.8-1.8z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m32.7 159.7l7.4 3.7q1 0.4 1.7 1.2 0.7 0.8 1 1.8 0.4 1 0.3 2-0.1 1.1-0.5 2l-0.1 0.2-12.2-6z"
                          />
                          <path
                            id="Layer"
                            className="s8"
                            d="m70.5 151.6h6.2l-2 16.3h-4.2z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m69.4 165.1h8.3q1.1 0 2 0.4 1 0.4 1.8 1.2 0.7 0.7 1.1 1.7 0.4 0.9 0.4 2v0.2h-13.6z"
                          />
                          <path
                            id="Layer"
                            className="s8"
                            d="m53 47.3c0-2.2 0.9-4.3 2.5-5.9 1.6-1.6 3.7-2.5 6-2.5 2.2 0 4.3 0.9 5.9 2.5 1.6 1.6 2.5 3.7 2.5 5.9 0 2.3-0.9 4.4-2.5 6-1.6 1.6-3.7 2.5-5.9 2.5-2.3 0-4.4-0.9-6-2.5-1.6-1.6-2.5-3.7-2.5-6z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m69.9 162.5q-0.3 0-0.6-0.1-0.3-0.1-0.5-0.3-0.2-0.2-0.3-0.5-0.2-0.3-0.2-0.6l-3.2-43.3q0-0.2-0.2-0.4-0.1-0.1-0.2-0.1-0.2-0.1-0.4 0-0.1 0.1-0.2 0.2l-20.9 39.8q-0.1 0.3-0.3 0.5-0.3 0.2-0.6 0.3-0.2 0.1-0.5 0.1-0.3-0.1-0.6-0.2l-5.8-2.9q-0.3-0.1-0.5-0.4-0.2-0.2-0.3-0.5-0.1-0.3-0.1-0.6 0-0.3 0.2-0.6l12.4-25.2 8.3-18.9c3.1-6.2 6.5-9.5 10.3-9.8 3.8-0.4 8 2.2 12.6 8.1v0.1l-0.7 53q0.1 0.3-0.1 0.5-0.1 0.3-0.3 0.5-0.1 0.2-0.4 0.4-0.3 0.1-0.6 0.1l-6.2 0.8q-0.1 0-0.1 0z"
                          />
                          <path
                            id="Layer"
                            className="s8"
                            d="m50 112.4c-0.9-0.2-1.7-0.7-2.3-1.4-0.6-0.7-0.8-1.6-0.8-2.5 0-0.9 0.4-1.8 1.1-2.4l0.5-32.7 6.2-0.7-1.3 33.5q0.5 0.7 0.8 1.4 0.2 0.8 0.1 1.6-0.1 0.8-0.6 1.5-0.4 0.7-1.1 1.1c-0.8 0.5-1.7 0.7-2.6 0.6z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m56.5 78.6l-8.3-1q-0.4 0-0.7-0.2-0.3-0.2-0.5-0.5-0.2-0.3-0.3-0.6 0-0.4 0.1-0.7l2.1-8.1c0.1-1.2 0.8-2.3 1.7-3 1-0.8 2.2-1.1 3.4-1 1.2 0.1 2.3 0.7 3.1 1.7 0.7 1 1.1 2.2 0.9 3.4l0.3 8.3q0 0.3-0.1 0.7-0.2 0.3-0.4 0.5-0.3 0.3-0.6 0.4-0.4 0.1-0.7 0.1z"
                          />
                          <path
                            id="Layer"
                            className="s8"
                            d="m92.2 57.4c0.4-1.4 1.6-2.5 3.1-2.7 1.4-0.2 2.9 0.5 3.6 1.8 0.7 1.4 0.5 3-0.4 4.1-1 1.1-2.6 1.5-4 1l-15.2 12q-0.4 0.3-0.8 0.5-0.4 0.1-0.9 0.1-0.4 0-0.8-0.1-0.5-0.1-0.8-0.4l-6-4.3 3.8-5 3.6 2.1 14.7-8.5q0-0.1 0.1-0.2 0 0 0-0.1 0-0.1 0-0.1 0-0.1 0-0.2z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m76.9 66.9l-4.8 6.2q-0.2 0.2-0.5 0.4-0.3 0.2-0.6 0.2-0.3 0-0.7-0.1-0.3-0.1-0.5-0.4l-5.4-5.6c-0.9-0.7-1.5-1.7-1.6-2.8-0.2-1.2 0.1-2.3 0.8-3.2 0.7-0.9 1.8-1.5 2.9-1.6 1.1-0.2 2.3 0.1 3.1 0.8l6.8 3.8q0.3 0.2 0.5 0.4 0.2 0.3 0.3 0.6 0 0.4 0 0.7-0.1 0.3-0.3 0.6z"
                          />
                          <path
                            id="Layer"
                            className="s-blue"
                            d="m80.8 109.7q0.1 0.3-0.1 0.7-0.1 0.3-0.3 0.5-0.3 0.3-0.6 0.4-0.4 0.1-0.7 0-1.6-0.4-3.2-0.6-1.7-0.3-3.3-0.4-1.6-0.1-3.3-0.2-1.6 0-3.2 0.1l-0.8-7.3-1.9 7.4c-3.6 0.2-6.7 0.6-8.6 0.9q-0.3 0-0.6-0.1-0.3-0.1-0.5-0.4-0.3-0.2-0.4-0.5-0.1-0.3 0-0.7l3.8-19.3-5.1-18.1q-0.3-1.2-0.4-2.5-0.1-1.3 0.2-2.6 0.2-1.2 0.7-2.4 0.5-1.2 1.2-2.2 0.5-0.7 1.1-1.2 0.6-0.6 1.2-1 0.7-0.4 1.5-0.7 0.7-0.3 1.5-0.5l3.1-0.5q1.6-0.2 3.2 0.1 1.5 0.4 2.9 1.3 1.3 0.9 2.2 2.3 0.9 1.3 1.2 2.9l9.2 44.6z"
                          />
                          <path
                            id="Layer"
                            className="s6"
                            d="m71.2 42.4q-0.2 1.1-0.7 2.1-0.6 1-1.4 1.7-0.8 0.8-1.8 1.2c-2 0.8-4.6 0.5-5.9 2.2-1.3 1.7-0.4 4.2 0.8 6v0.1c-4 0-8.2-0.7-10.9-3.6q-0.6-0.6-1.1-1.4-0.4-0.7-0.8-1.6-0.3-0.8-0.5-1.7-0.1-0.8-0.1-1.7-0.5-0.1-1-0.2-0.5-0.1-1-0.2-0.5-0.1-1-0.2-0.5-0.1-1-0.3c-2.2-0.5-4.5-1-6.7-0.4-3.9 1.2-5.8 5.6-9.3 7.8-1.5 0.9-3.3 1.4-5.2 1.2-1.8-0.1-3.5-0.8-4.9-1.9-1.4-1.1-2.5-2.7-3-4.4-0.5-1.8-0.4-3.7 0.2-5.4 2.4 2.5 6.4 2.4 9.6 1.1 3.2-1.3 5.9-3.6 8.9-5.2 3-1.7 6.7-2.7 9.8-1.2 1.8 0.9 3.1 2.4 4.8 3.5q0.1 0.1 0.2 0.2 0.1 0 0.2 0.1 0.2 0.1 0.3 0.1 0.1 0.1 0.3 0.2 0.5-1.1 1.3-2 0.7-0.9 1.6-1.7 0.8-0.8 1.8-1.4 1-0.6 2.1-1.1 1-0.4 2.1-0.7 1.1-0.3 2.2-0.3 1.1-0.1 2.2 0.1 1.2 0.1 2.2 0.5 1.1 0.3 2 0.9 0.9 0.6 1.7 1.5 0.7 0.8 1.2 1.8 0.5 1 0.8 2.1 0.2 1.1 0 2.2z"
                          />
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="s4"
                            d="m142 13.3c7.1 0 10.6 4.3 10.6 11.7 0 7.3-3.4 11.8-10.9 11.8-7.3 0-10.8-4.2-10.8-11.4 0-7.5 3.9-12.1 11.1-12.1zm3.9 10.7c0-6.7-0.2-10-4.1-10-4 0-4.2 3.4-4.2 10.1v2.4c0 6.1 0.4 9.6 4.2 9.6 3.6 0 4.1-3.1 4.1-9.7zm27.8 12.5h-4.6l-11.2-21.1v20.5h1.8v0.6h-4.4v-0.6h1.8v-21.6h-1.8v-0.6h9l8.5 16.4v-15.8h-1.8v-0.6h4.5v0.6h-1.8zm-57.1 5.3v0.6h-1.9v10.1h6.5v-10.1h-1.9v-0.6h10.2v0.6h-2v21.6h2v0.7h-10.2v-0.7h1.9v-10.6h-6.5v10.6h1.9v0.7h-10.2v-0.7h1.9v-21.6h-1.9v-0.6zm26.6-0.4c7 0 10.5 4.3 10.5 11.8 0 7.2-3.4 11.8-10.9 11.8-7.3 0-10.8-4.2-10.8-11.4 0-7.5 3.9-12.2 11.2-12.2zm3.8 10.7c0-6.7-0.2-10-4.1-10-3.9 0-4.2 3.5-4.2 10.2v2.3c0 6.2 0.4 9.7 4.2 9.7 3.7 0 4.1-3.2 4.1-9.7zm21-10.4v0.7h-3.4v21.4h1.1c4.1 0 5.9-2.9 7.8-6.9h0.7l-0.6 7.7h-17.3v-0.8h1.9v-21.4h-1.9v-0.7zm17.4 22.9h-9.2v-0.7h2.1v-21.6h-2.1v-0.6h10.1c8 0 12.5 2.9 12.5 11 0 9.2-5.6 11.9-13.4 11.9zm0.5-0.7c4.4 0 6.2-2.8 6.2-10.1v-1.9c0-7.9-2.1-9.6-6.6-9.6h-0.8v21.6z"
                          />
                        </svg>
                      </figure>
                    </div>

                    <div className="col-md-4 mr-auto on-hold-box-R">
                      <h4>
                        Your student account status has been <br />
                        set to, <b>{user.status}</b>
                      </h4>
                      <p>
                      Your portal has been locked due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket below.
                      </p>
                      <Link className="btn btn-primary mt-2" to="/addnewticket" title="Log A Ticket">
                        <i class="far fa-ticket-alt"></i> Log A Ticket
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(StudentStatus);
