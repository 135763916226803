import React, { useEffect, useState } from "react";
import {
  campusWidget,
  resource,
  resourceWidget,
  onlyCampus,
  availability,
  availabilityText,
} from "../../components/common/Helper";
import {
  addToMyLibrary,
  removeFromFavorite,
} from "../../services/StudentHubService";
import Swal from "sweetalert2";
import { modalOpen, setFavouritesCount } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ViewTextResource from "./ViewTextResource";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import { BASE_URL } from "../../utils/Constants";
import { getStudentLibraryDropdown } from "../../services/ResourcesService";
import { campusesWidget } from "../../components/common/Helper";
// import { setFavouritesCount } from "../../store/actions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import defaultImage from '../../assets/images/default-banner.png'

export default function DigitalResourceDetail({ ResourceData, refreshData ,dropdownList }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [resourceIcon, setResourceIcon] = useState("");
  const [favIcon, setFavIcon] = useState("");
  const [campusData, setCampusData] = useState([]);
  const ResourceDetail = ResourceData.resourceDetails;
  const ResourceInformationDetails = ResourceData.ResourceInformationDetails;
  const favCount = useSelector((state) => state.favourites.favouritesCount);
    
  const wordSpliterTopics = (str, type) => {
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
    const topic = dropdownList && dropdownList?.topic && dropdownList?.topic.find(val => val.value == item);
      return item ? <small className="as-widget-resource" style={{color: '#fff',background: topic?.color}}>{topic?.label}</small> : '';      
    }) : "";
    return newStrArr;
  }
 
  useEffect(()=>{
    if(dropdownList.campus && dropdownList.campus.length){
      const campus = dropdownList.campus.find(item=>item.value==ResourceDetail?.campus)
      setCampusData(campus)
    }
  },[dropdownList])
  const addToLibrary = () => {
    if (!ResourceDetail?.favorite) {
      const data = { resource_id: ResourceDetail?.id };
      addToMyLibrary(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then((result) => {
            dispatch(setFavouritesCount(favCount+1))
            refreshData();
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    } else {
      const data = { resource_id: ResourceDetail?.id };
      removeFromFavorite(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then((result) => {
            dispatch(setFavouritesCount(favCount-1))
            refreshData();
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    }
  };

  const viewResource = () => {
    if (
      ResourceDetail?.type.toString().toLowerCase() === "vimeo" ||
      ResourceDetail?.type.toString().toLowerCase() === "youtube"
    ) {
      let link = `https://player.vimeo.com/video/${ResourceDetail?.video_id}`;
      if (ResourceDetail?.type.toString().toLowerCase() == "youtube") {
        link = ResourceDetail?.link;
      }
      dispatch(modalOpen(link));
    } else if (
      ResourceDetail?.type.toString().toLowerCase() === "article" ||
      ResourceDetail?.type.toString().toLowerCase() === "label"
    ) {
      setShow(true);
    }
  };

  useEffect(() => {
    let icon = "";
    switch (ResourceDetail?.type ? ResourceDetail.type.toLowerCase() : "") {
      case "link":
      case "white_paper":
        icon = "fad fa-link";
        break;
      case "video":
      case "vimeo":
      case "youtube":
        icon = "fad fa-video";
        break;
      case "book":
        icon = "fad fa-book";
        break;
      case "ebook":
        icon = "fad fa-wifi";
        break;
      case "document":
      case "pdf":
        icon = "fad fa-file";
        break;
      case "article":
        icon = "fad fa-newspaper";
        break;
      case "label":
        icon = "fad fa-tag";
        break;
    }
    setResourceIcon(icon);
    setFavIcon(ResourceDetail?.favorite ? "fas fa-star" : "fal fa-star");
  }, [ResourceDetail]);

  return (
    <>
      {show && (
        <ViewTextResource
          show={show}
          close={() => setShow(false)}
          data={ResourceDetail}
        />
      )}
      <div className="card card-profile-info-card">
        <div className="card-body advanced_serach d-flex mb-4">
          <div className="advanced_serach_left">
            {/* <figure><img src={img} /></figure> */}

            {/*<figure>
               <img
                src={
                  ResourceDetail.thumbnail.includes("http") ? ResourceDetail.thumbnail : (BASE_URL +
                  "/" +
                  ResourceDetail.thumbnail.replace(
                    "/home/myaie/public_html/",
                    ""
                  ).replace("public/",""))
                }
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.remove();
                }}
              /> 
            </figure>*/}
            {ResourceDetail?.thumbnail && ResourceDetail?.thumbnail != null ? (
              <figure>
                <LazyLoadImage
                    alt="Image Not Available"
                    effect="blur"
                    height={"100%"}
                    // placeholderSrc={(basicInfo?.thumbnail.includes("http") ? "" : (IMAGE_URL + "/")) + basicInfo?.thumbnail?.replace("public/", "").replace("home/myaie/public_html/", "")}
                    placeholderSrc={ResourceDetail.thumbnail.includes("http") ? ResourceDetail.thumbnail : (BASE_URL + "/" + ResourceDetail.thumbnail.replace("/home/myaie/public_html/","").replace("public/",""))}
                    // placeholder={<span><i className="fal fa-cog" /></span>}
                    src={ResourceDetail.thumbnail.includes("http") ? ResourceDetail.thumbnail : (BASE_URL + "/" + ResourceDetail.thumbnail.replace("/home/myaie/public_html/","").replace("public/",""))} 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.remove();
                    }}
                />
              </figure>
            ) : (
              <div className="advanced_serach-img-box-2">
                {/* <span className='serch-icon-bg mb-0'>
                                    <i class="fal fa-globe"></i></span> */}

                <span className="serch-icon-upper">
                  <i class={resourceIcon}></i>
                </span>
              </div>
            )}
            <div
              className={`serach-btn-bottom d-flex justify-content-center ${
                ResourceDetail?.type.toString().toLowerCase() !== "book"
                  ? "mt-3"
                  : ""
              }`}
            >
              {ResourceDetail?.type === "link" ||
              ResourceDetail?.type === "white_paper" ||
              ResourceDetail?.type === "pdf" ||
              ResourceDetail?.type === "ebook" ? (
                <>
                  <a
                    href={
                      (ResourceDetail.link != null && ResourceDetail?.type === "link" )||
                     ( ResourceDetail?.type === "white_paper" && ResourceDetail.link != null)
                        ? ResourceDetail?.link :  ResourceDetail?.type === "pdf" && ResourceDetail.link != null ? ResourceDetail.link != null && ResourceDetail.link.includes("http") ? ResourceDetail.link : BASE_URL +
                        "/" +
                        ResourceDetail.link.replace(
                          "/home/myaie/public_html/",
                          ""
                        ).replace("public/","")
                        : ResourceDetail?.url
                    }
                    target="_blank"
                    className="btn btn-primary w-100"
                    title="View Resource"
                  >
                    <i className="fal fa-eye"></i> View Resource
                  </a>
                </>
              ) : ResourceDetail?.type.toString().toLowerCase() === "vimeo" ||
                ResourceDetail?.type.toString().toLowerCase() === "youtube" ? (
                <Link to={location.pathname + `/${ResourceDetail.type}/video`}>
                  <div
                    onClick={() => {
                      viewResource();
                    }}
                    className="btn btn-primary w-100"
                    title="View Resource"
                  >
                    <i className="fal fa-eye"></i> View Resource
                  </div>
                </Link>
              ) : ResourceDetail?.type.toString().toLowerCase() ===
                "book" ? null : (
                <button
                  type="button"
                  onClick={() => {
                    viewResource();
                  }}
                  className="btn btn-primary w-100"
                  title="View Resource"
                >
                  <i className="fal fa-eye"></i> View Resource
                </button>
              )}
            </div>
          </div>
          <div className="advanced_serach_right">
            <div className="advanced_serach_r-head d-flex justify-content-between mb-1">
              <div className="advanced_serach_r-head-left">
                {ResourceDetail?.type
                  ? resourceWidget(ResourceDetail?.type)
                  : "NA"}
              </div>
              <div className="advanced_serach_r-head-right d-flex">
                {ResourceDetail?.type === "pdf" && (
                  <button
                    onClick={() => {
                      download( ResourceDetail.link.includes("http") ? ResourceDetail.link :
                        BASE_URL +
                        "/" +
                        ResourceDetail.link.replace(
                          "/home/myaie/public_html/",
                          ""
                        ).replace("public/","")
                        ,
                        `${ResourceDetail?.title}.pdf`
                      );
                    }}
                    className="btn btn-primary rounded-circle"
                    title="Download Resource"
                  >
                    <i className="fal fa-download"></i>
                  </button>
                )}
                {/* {ResourceDetail?.added === false && */}
                <div className="as-buttons">
                  <button
                    onClick={() => {
                      addToLibrary();
                    }}
                    title="Favourites"
                    className="btn btn-primary rounded-circle"
                  >
                    <i className={favIcon}></i>
                  </button>
                </div>
                {/* } */}
              </div>
            </div>
            <div className="advanced_serach_r-title_head">
            <h3 className="advanced_serach_r-title mb-3">
              {ResourceDetail?.title}
            </h3>
            <div className="advanced_serach_topic_r-head">
                {ResourceDetail?.topic
                  ? <div className="d-flex flex-wrap">
                    {wordSpliterTopics(ResourceDetail?.topic ? ResourceDetail?.topic.toString() : "")}
                  </div> 
                  : ""}
              </div>
            </div>
            {(ResourceDetail?.type === "link" ||
              ResourceDetail?.type === "white_paper") &&
              ResourceDetail?.link && (
                <a className="search-link-view mb-3">
                  <i class="fal fa-link"></i>{" "}
                  {(ResourceDetail?.type === "link" ||
                    ResourceDetail?.type === "white_paper") && (
                    <a
                      href={ResourceDetail?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ResourceDetail?.link ? ResourceDetail?.link : "NA"}
                    </a>
                  )}
                </a>
              )}

            <>
              {ResourceDetail?.sub_title && (
                <h4 className="advanced_serach_r-sub mb-3">
                  {ResourceDetail?.sub_title &&
                  (ResourceDetail?.sub_title).trim() !== ""
                    ? ResourceDetail?.sub_title
                    : "NA"}
                </h4>
              )}
              <ul className="seacrh_list-info">
                {ResourceDetail?.author && (
                  <li>
                    {" "}
                    <i class="fal fa-user"></i>
                    {ResourceDetail?.author}
                  </li>
                )}
                {(ResourceDetail?.type == "book" ||
                  ResourceDetail?.type == "Book" || ResourceDetail?.type == "ebook") && ResourceDetail?.language && (                  
                  <li className="normal-font-weight">
                    <i class="fal fa-globe"></i>
                    {ResourceDetail?.language}
                  </li>
                )}
              </ul>
            </>
            {ResourceDetail?.description &&
              (ResourceDetail?.description).trim() !== "" && (
                <h4 className="advanced_serach_r-sub mb-3">Description</h4>
              )}
            {ResourceDetail?.description &&
              (ResourceDetail?.description).trim() !== "" && (
                <p className="search_descripton">
                  {ResourceDetail?.description &&
                  (ResourceDetail?.description).trim() !== ""
                    ? ResourceDetail?.description
                    : "NA"}
                </p>
              )}
          </div>
        </div>
        {/* this section comment start */}
        {/* <div className="card-body advanced_serach d-flex mb-4">
                    <div className="search-advance-filters-head d-flex justify-content-between align-items-center mb-4">
                        <div className="card-header p-0">
                            {ResourceDetail?.title}
                        </div>
                        <div className="d-block search-advance-filters-head-r">
                            {(ResourceDetail?.type === 'link' || ResourceDetail?.type === 'white_paper') || ResourceDetail?.type === 'pdf' ?
                                <>
                                    <a href={(ResourceDetail?.type === 'link' || ResourceDetail?.type === 'white_paper') ? ResourceDetail?.link : ResourceDetail?.url} target="_blank" className="btn btn-primary mr-2" title="View Resource">
                                        <i className="fal fa-eye"></i> View Resource
                                    </a>
                                    {ResourceDetail.type === "pdf" &&
                                        <button onClick={() => { download(ResourceDetail?.url, `${ResourceDetail?.title}.pdf`) }} className="btn btn-primary" title="Download Resource">
                                            <i className="fal fa-download"></i> Download
                                        </button>}
                                </>

                                : (ResourceDetail?.type.toString().toLowerCase() === 'vimeo' || ResourceDetail?.type.toString().toLowerCase() === 'youtube') ?
                                    <Link to={location.pathname + `/${ResourceDetail.type}/video`}>
                                        <div onClick={() => { viewResource() }} className="btn btn-primary" title="View Resource">
                                            <i className="fal fa-eye"></i> View Resource
                                        </div>
                                    </Link>
                                    :
                                    <button type="button" onClick={() => { viewResource() }} className="btn btn-primary" title="View Resource">
                                        <i className="fal fa-eye"></i> View Resource
                                    </button>}
                            {ResourceDetail?.added === false &&
                                // <button type="button" onClick={() => { addToLibrary() }} className="btn btn-primary ml-2" title="Add To Favorites"><i className="fal fa-plus"></i> Add To Favorites</button>
                                <div className="as-buttons">

                                    <button
                                        onClick={() => { addToLibrary() }}
                                        title="favourites"
                                        className="btn btn-primary rounded-circle"
                                    >
                                        <i className="fal fa-star"></i>
                                    </button>
                                </div>

                            }
                        </div>
                    </div>
                    <div className="card-body-inr">
                        <div className="box-list-style-2">
                            <div className="box-list-style-box">
                                <ul className='d-block'>
                                    <li><span>Location: </span> {ResourceDetail?.location ? ResourceDetail?.location : "NA"}</li>
                                    <li><span>Type: </span> {ResourceDetail?.type ? resourceWidget(ResourceDetail?.type) : "NA"}</li>
                                    <li><span>Campus: </span> {ResourceDetail?.campus ? campusWidget(ResourceDetail?.campus) : "NA"}</li>
                                    <li className="mw-100 f-100 flex-fill"><span>Description: </span> {ResourceDetail?.description && (ResourceDetail?.description).trim() !== "" ? ResourceDetail?.description : "NA"}</li>
                                    {(ResourceDetail?.type === 'link' || ResourceDetail?.type === 'white_paper') && <li><span>URL: </span><a href={ResourceDetail?.link} target="_blank" rel="noopener noreferrer">{ResourceDetail?.link ? ResourceDetail?.link : "NA"}</a> </li>}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* this section comment end */}
        {(ResourceDetail?.type == "book" || ResourceDetail?.type == "Book" || ResourceDetail?.type == "ebook") && (
            <div className="card card-profile-info-card">
              <div className="card-body ">
                <div className="card-header pt-0">Information</div>
                <div className="card-body-inr">
                  <div className="box-list-style-box">
                    <ul>
                      {ResourceDetail.publisher && <li>
                        {/* <span>Publisher:</span> <p title={ResourceDetail.publisher}> {ResourceDetail.publisher.length > 20 ? (ResourceDetail.publisher).slice(0, 20) + "..." : ResourceDetail.publisher}</p> */}
                        <span>Publisher:</span> {ResourceDetail.publisher}
                      </li>}                                            
                      {ResourceDetail?.dewey && <li>
                        <span>Dewey Nr:</span> {ResourceDetail?.dewey}
                      </li>}                                            
                      {ResourceDetail.barcode && <li>
                        <span>Barcode:</span> {ResourceDetail.barcode}
                      </li>}
                      {ResourceDetail?.isbn && <li>
                        <span>ISBN:</span> {ResourceDetail?.isbn}
                      </li>}
                      {ResourceDetail.series && <li>
                        <span>Series:</span> {ResourceDetail.series}
                      </li>}
                      {ResourceDetail?.type != "ebook" && campusData?.color && campusData?.label && <>
                      <li>
                        <span>Campus:</span>{" "}
                        {campusesWidget(campusData?.color,campusData?.label)}
                      </li>
                      {ResourceDetail.availability && <li>
                        <span>Availability:</span>{" "}
                        <span
                        title={availabilityText(ResourceDetail.availability)}
                        >
                          {availability(ResourceDetail.availability)}
                        </span>
                      </li>}
                      </>}
                      {ResourceDetail.availability == "issued" && ResourceInformationDetails?.due_date && <li>
                        <span>Return Date:</span> {moment.unix(ResourceInformationDetails?.due_date).format("DD MMM YY")}
                      </li>}
                      
                      {/* <li><span>Issued To:</span> {ResourceInformationDetails?.issuedToname}</li>
                                    <li><span>Issued Date:</span> {moment.unix(Number(ResourceInformationDetails?.issued_date)).format("D MMM 'YY")}</li>
                                    <li><span>Due Date:</span> {moment.unix(Number(ResourceInformationDetails?.due_date)).format("D MMM 'YY")}</li>
                                    <li><span>Days Due:</span> <font className="text-danger">{ResourceInformationDetails?.due_days > 0 ? ResourceInformationDetails?.due_days : 0}</font></li>
                                    <li><span>Issued By:</span> {ResourceInformationDetails?.issuedByname}</li>
                                    <li className="mw-100 f-100 flex-fill"><span>Comments:</span> {ResourceInformationDetails?.comment}</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}
