import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginDetailService } from "../../../services/ProfileService";
import Swal from "sweetalert2";

const LoginDetails = () => {
  const [edit, setEdit] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  user.password = "";
  user.password_confirmation = "";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      LoginDetailService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setUser(res.data));
              setEdit(true);
            }
          });
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Email is not valid",
            text: "You cannot use an email that already exists",
          }).then((result) => {});
        });
    },
  });

  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header">Login Details</div>
        <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit?"Edit":"Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>
          {edit ? (
            <div className="card-body-inr card-body-info">
              <div className="form-group">
                <label>Email</label>
                <div className="label-selected form-control">{user.email}</div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="label-selected form-control">**********</div>
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <div className="label-selected form-control">**********</div>
              </div>
            </div>
          ) : (
            <div className="card-body-inr">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <label>Email *</label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (formik.errors.email && formik.touched.email
                        ? " is-invalid"
                        : "")
                    }
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Password *</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (formik.errors.password && formik.touched.password
                        ? " is-invalid"
                        : "")
                    }
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Confirm Password *</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (formik.errors.password_confirmation &&
                      formik.touched.password_confirmation
                        ? " is-invalid"
                        : "")
                    }
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.password_confirmation &&
                  formik.touched.password_confirmation ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>

                <div className="password-criteria">
                  <h5>Password Criteria!</h5>
                  <ul>
                    <li>Minimum 8 characters.</li>
                    <li>At least 1 uppercase letter.</li>
                    <li>At least 1 lowercase letter.</li>
                    <li>At least 1 number.</li>
                    <li>At least 1 special character.</li>
                  </ul>
                </div>

                <div className="form-group form-group-save-cancel">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                    Save
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    onClick={() => setEdit(true)}
                    title="Cancel"
                  >
                  <i className="fal fa-times"></i>
                    Cancel
                  </button>

                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.email}
                    </div>
                  ) : null}

                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.password}
                    </div>
                  ) : null}

                  {formik.touched.password_confirmation &&
                  formik.errors.password_confirmation ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.password_confirmation}
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginDetails;
