import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default function ViewBookingModal({ show, onClose, bookingDetails }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      {/* <div className="modal-content"> */}

      <div className="booking-modal-content">
        <div className="modal-header modal-header-booking">
          <h5 className="modal-title">
            <i className="fal fa-calendar"></i>&nbsp; Schedule Booking
          </h5>
          <button className="close" onClick={onClose}>
            <i className="fal fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="t p-10px">
            <div className="topic-add-modal-content">
              <div className="row center-label">
                <div className="col-md-3">
                  <div className="form-group-blk mb-3">
                    <label>Name *</label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="Name"
                        id="Name"
                        name="name"
                        placeholder="Enter Name"
                        className={'form-control'}
                        value={bookingDetails[0].name || ''}
                        title="Name"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Date *</label>
                  <div className="form-icon-group mb-4">
                    <div className="datepicker-wrapper-class">
                      <DatePicker
                        id="booking_date"
                        name="booking_date"
                        dateFormat="yyyy/MM/dd"
                        value={moment(
                          bookingDetails[0].booking_date || ''
                        ).format('YYYY-MM-DD')}
                        disabled
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <label>Start Time *</label>
                  <div className="form-icon-group mb-4">
                    <div className={'datepicker-wrapper-class'}>
                      <DatePicker
                        id="booking_start_time"
                        name="booking_start_time"
                        value={moment(
                          bookingDetails[0].booking_start_time || '',
                          'HH:mm:ss'
                        ).format('HH:mm')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        disabled
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <label>End Time *</label>
                  <div className="form-icon-group mb-4">
                    <div className={'datepicker-wrapper-class'}>
                      <DatePicker
                        id="booking_end_time"
                        name="booking_end_time"
                        value={moment(
                          bookingDetails[0].booking_end_time || '',
                          'HH:mm:ss'
                        ).format('HH:mm')}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        disabled
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group-blk mb-3">
                    <label>Linked Course</label>
                    <div className="form-icon-group mb-4">
                      {/* <Select
                        className={'form-control custom-select-box'}
                        name="linked_course"
                        value={{
                          label: bookingDetails[0].linked_course || '',
                          value: bookingDetails[0].linked_course || '',
                        }}
                        isClearable
                        maxMenuHeight={175}
                        isDisabled
                      /> */}
                      <input
                        type="Linked_Course"
                        id="linked_course"
                        name="linked_course"
                        placeholder="Enter Linked Course"
                        className={'form-control'}
                        value={bookingDetails[0].linked_course || ''}
                        title="Linked Course"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>Campus *</label>
                  <div className="form-icon-group mb-4">
                    {/* <Select
                      className={'form-control custom-select-box'}
                      name="campus"
                      value={{
                        label: bookingDetails[0].campus || '',
                        value: bookingDetails[0].campus || '',
                      }}
                      isClearable
                      maxMenuHeight={175}
                      isDisabled
                    /> */}
                    <input
                        type="Campus"
                        id="campus"
                        name="campus"
                        placeholder="Enter Campus"
                        className={'form-control'}
                        value={bookingDetails[0].campus || ''}
                        title="Campus"
                        disabled
                      />
                  </div>
                </div>

                <div className="col-md-3">
                  <label>Room *</label>
                  <div className="form-icon-group mb-4">
                    {/* <Select
                      className={'form-control custom-select-box'}
                      name="room_no"
                      value={{
                        label: bookingDetails[0].room_no || '',
                        value: bookingDetails[0].room_no || '',
                      }}
                      isDisabled
                      maxMenuHeight={175}
                    /> */}
                    <input
                        type="Room_no"
                        id="room_no"
                        name="room_no"
                        placeholder="Enter Room No."
                        className={'form-control'}
                        value={bookingDetails[0].room_no || ''}
                        title="Room Number"
                        disabled
                      />
                  </div>
                </div>

                <div className="col-md-3">
                  <label>Booking Reason</label>
                  <div className="form-icon-group mb-4">
                    {/* <Select
                      className={'form-control custom-select-box'}
                      name="booking_reason"
                      value={{
                        label: bookingDetails[0].booking_reason || '',
                        value: bookingDetails[0].booking_reason || '',
                      }}
                      isClearable
                      maxMenuHeight={175}
                      isDisabled
                      options={[]}
                    /> */}
                    <input
                        type="Booking_reason"
                        id="booking_reason"
                        name="booking_reason"
                        placeholder="Enter Booking Reason"
                        className={'form-control'}
                        value={bookingDetails[0].booking_reason || ''}
                        title="Booking Reason"
                        disabled
                      />
                  </div>
                </div>

                <div className="col-md-6 col-lg-12">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="link">Additional Information </label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="AdditionalInformation"
                        id="AdditionalInformation"
                        className={'form-control'}
                        name="additional_information"
                        placeholder="Enter Additional Information"
                        value={bookingDetails[0].additional_information || ''}
                        disabled
                        title="AdditionalInformation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
