import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPayer } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PayerContactDetailService } from "../../../services/ProfileService";
import Swal from "sweetalert2";
import { MOBILE_REGEX } from "../../../utils/Constants";

const PayerContactDetails = () => {
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const payer = useSelector((state) => state.payer);
  const dispatch = useDispatch();

  const tempPayer = {
    email: "",
    mobile: "",
    phone: "",
    a_phone: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(payer).length > 0 ? payer : tempPayer,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .matches(
          MOBILE_REGEX,
          "Phone number should be 0-9 of 10 digits with country code"
        )
        .required("Mobile is required"),
      // phone: Yup.string()
      //   .matches(
      //     MOBILE_REGEX,
      //     "Phone number should be 0-9 of 10 digits with country code"
      //   )
      //   .required("Required"),
      // a_phone: Yup.string()
      //   .matches(
      //     MOBILE_REGEX,
      //     "Phone number should be 0-9 of 10 digits with country code"
      //   )
      //   .required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      PayerContactDetailService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setPayer(res.data));
              setEdit(true);
            }
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="card card-profile-info-card">
      <div className="card-header">Contact Details</div>
      <div className={`card-body ${edit ? "" : "edit-profile-icon-active"}`}>
        {!user.payer_same ? (
          <div className="edit-icon">
            <button
              className="edit-profile-icon"
              onClick={() => setEdit(!edit)}
              title={edit?"Edit":"Cancel"}
            >
              <i className="fal fa-edit"></i>
            </button>
          </div>
        ) : null}
        {edit ? (
          <div className="card-body-inr card-body-info">
            <div className="form-group">
              <label>Email</label>
              <div className="label-selected form-control">{payer.email}</div>
            </div>
            <div className="form-group">
              <label>Mobile</label>
              <div className="label-selected form-control">{payer.mobile}</div>
            </div>
            <div className="form-group">
              <label>Landline </label>
              <div className="label-selected form-control">{payer.phone}</div>
            </div>
            <div className="form-group">
              <label>Alternative Contact Number</label>
              <div className="label-selected form-control">{payer.a_phone}</div>
            </div>
          </div>
        ) : (
          <div className="card-body-inr">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label>Email *</label>
                <input
                  type="email"
                  className={
                    "form-control" +
                    (formik.errors.email && formik.touched.email
                      ? " is-invalid"
                      : "")
                  }
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label>Mobile *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.mobile && formik.touched.mobile
                      ? " is-invalid"
                      : "")
                  }
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label>Landline </label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.phone && formik.touched.phone
                      ? " is-invalid"
                      : "")
                  }
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.phone && formik.errors.phone ? (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label>Alternative Contact Number</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.a_phone && formik.touched.a_phone
                      ? " is-invalid"
                      : "")
                  }
                  name="a_phone"
                  value={formik.values.a_phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.a_phone && formik.errors.a_phone ? (
                  <div className="invalid-feedback">
                    {formik.errors.a_phone}
                  </div>
                ) : null}
              </div>

              <div className="form-group form-group-save-cancel">
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  disabled={loading}
                  title="Save"
                >
                  {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>} Save
                </button>

                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  onClick={() => setEdit(true)}
                  title="Cancel"
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>

                {formik.errors.email ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.email}
                  </div>
                ) : null}

                {formik.errors.mobile ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.mobile}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayerContactDetails;
