const initialState = {
    notes: false,
    completeModule : false,
    startModule : false
};

const buttonState = (state = initialState ,action) => {

    switch(action.type) {

        case 'CLEAR_TOGGLES':
            return {
                ...state, notes: false,
                completeModule : false,
                startModule : false
            }
        case 'TOGGLE_NOTES' : 
            return {...state,notes : !state.notes}
        case 'TOGGLE_COMPLETE_MODULE' : 
            return {...state,completeModule : !state.completeModule}
        case 'TOGGLE_START_MODULE' : 
            return {...state,startModule : !state.startModule}
        default :
        return state;
    }

}

export default buttonState;