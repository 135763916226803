import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import SoftAndHardLaunch from "../components/SoftAndHardLaunch/SoftAndHardLaunch";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTimeHomePage, wishTiming } from "../components/common/Helper";
import { useHistory } from "react-router-dom";
import HomeWishes from "../components/common/HomeWishes";
import Articulation from "../components/Articulation/Articulation";
import { ArticulationRegistrationList } from "../services/ReRegistrationService";
import { setIsArticulation, setIsReReg } from "../store/actions";
import SkeletonMessage from "../loaders/SkeletonMessage";

function ReRegStatus() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [articulation, setArticulation] = useState("");
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const registrationsLoading = useSelector((state) => state.registrations.loading);
  const [loading, setLoading] = useState(false);
  const [ownArticulateData, setOwnArticulateData] = useState({});
  // const reRegStatus = false;
  const reRegStatus = JSON.parse(localStorage.getItem('reRegStatus'));

  if (user.status === "On Hold" ) {
    history.push("/status");
  }else if ((user.status !== "On Hold" ) && !reRegStatus) {
    history.push("/home");
  }

  const getData = ()=>{
    setLoading(true);
    ArticulationRegistrationList().then(res=>{
      // console.log("______Articulation______",res);
      setOwnArticulateData({
        learning_method_list: res?.data?.learning_method_list, 
        school_type_list: res?.data?.school_type_list,
      });
      const artiStatus = res?.data?.open.filter(item=>(item?.nonRegData?.status !== "Declined" && (item?.remind !== 0)));
      // console.log(">>>>>>>>>>>>>>>>",artiStatus);
      if(artiStatus.length){
        setArticulation(artiStatus[0]);
        dispatch(setIsReReg(true));
      }else{
        setArticulation("");
        dispatch(setIsReReg(true));
        dispatch(setIsArticulation(false));
      }
      setLoading(false);
    }).catch(error=>{
      setLoading(false);
      console.error(error);
    });
  }

  useEffect(()=>{
    getData();
  },[update])


  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap on-hold-height">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="home-list-wrap">
            <HomeWishes />
            {(loading || registrationsLoading) ? <SkeletonMessage />
            : articulation ? <Articulation articulation={articulation} ownArticulateData={ownArticulateData} data={articulation?.articulation_programmes} update={update} setUpdate={setUpdate} /> : <SoftAndHardLaunch />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReRegStatus;
