import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { FormikProvider, useFormik, Form } from "formik";
import { LANGUAGES } from "../../utils/Constants";
import ResourceList from "./ResourceTable";
import { getStudentLibraryDropdown } from "../../services/ResourcesService";
import ResourceDetail from "./ResourceDetail";

function AdvanceSearch(props) {
  // const [inputValue, setValue] = useState("");
  const { tab, page, id } = useParams();
  const { globalSearch, resetGlobalSearch, searchBtn } = props;
  const history = useHistory();
  const [digital, setDigital] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const [resource, setResource] = useState([]);
  const [topicsValue, setTopicsValue] = useState([]);
  const [dropdownList, setDropdownList] = useState({});
  const [reInitValues, setReInitValues] = useState({})
  const [titleValue, setTitleValue] = useState("")
  const condition = [
    { value: "and", label: "And" },
    { value: "or", label: "Or" }
  ]

  // console.log('the value of currentId in the table is ', tab, page, id);

  // years array from 1901 to today's year ----
  let YEARS = [];
  let todayYear = new Date().getFullYear();
  for (let i = todayYear; i >= 1901; i--) {
    YEARS.push({ value: i, label: i });
  }

  const handleResourceSelect = (e) => {
    switch (e.value) {
      case "Book":
        setDigital(false);
        setValues({
          ...values,
          viaFormat: [e.value],
          resourceDrop: e
        })
        break;
      default:
        setDigital(true);
        setValues({
          ...values,
          viaFormat: [e.value],
          resourceDrop: e,
          place: "",
          author: "",
          language: "",
          from: "",
          to: "",
        })
        break;
    }

  }

  // keywords spliting and removing ----
  const handleKeyDown = (event) => {
    let hash = keywords;
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log("do validate", event.target.value);
      if (event.target.value) {
        hash.push(event.target.value);
        event.target.value = "";
        setKeywords((hash) => [...hash]);
        setReInitValues(values)
      }
    }
  };
  const removeKeywords = (index) => {
    let keyword = keywords;
    keyword.splice(index, 1);
    // console.log("keywords from remove", keyword);
    setKeywords((keyword) => [...keyword]);
  };
  // keywords spliting and removing ----

  useEffect(() => {
    getStudentLibraryDropdown().then((res) => {
      setDropdownList(res?.data);
      setResource(res.data?.LibraryResourceType)
      let topicsArr = []
      res.data.topic.map((item)=>{
          topicsArr.push(item?.value)  
      })
      setTopicsValue(topicsArr)
     
      // setCampus(res.data?.campus)
      resetForm();
    }).catch((err) => { console.log(err) });
  }, [])

  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      viaFormat: [],
      resourceDrop: reInitValues.resourceDrop ? reInitValues.resourceDrop : {},
      condition1: reInitValues.condition1 ? reInitValues.condition1 : "",
      title: reInitValues.title ? reInitValues.title : "",
      condition2: reInitValues.condition2 ? reInitValues.condition2 : "",
      keywords: keywords,
      condition3: reInitValues.condition3 ? reInitValues.condition3 : "",
      description: reInitValues.description ? reInitValues.description : "",
      place: reInitValues.place ? reInitValues.place : "",
      author: reInitValues.author ? reInitValues.author : "",
      language: reInitValues.language ? reInitValues.language : "",
      from: reInitValues.from ? reInitValues.from : "",
      to: reInitValues.to ? reInitValues.to : "",
      viaAvailability: "",
      viaCampus: [],
      page: 1,
      limit: 10,
      key: "title",
      sort: "ASC",
      exportStatus: false,
      viaSearch: ""
    },
    onSubmit: (values) => {
      history.push("/student-hub/advance-search/table")
    }
  });

  const { values, setValues, handleSubmit, handleChange, resetForm } = formik;

  const handleResetForm = () => {
    setKeywords([]);
    setDigital(true);
    resetForm();
    setReInitValues({})
  }
  return (
    <div className="card card-profile-info-card book_con">
      {/* <div className="header-info-box adv-search-table-head">
        <h4>Advanced Search</h4>
        {tab === 'advance-search' && page === "table" &&
          <>
            <div className='separator-line' />
            <button type="button" onClick={() => history.push(`/student-hub/advance-search/query`)} className="btn btn-primary" title="Back"><i className="fal fa-chevron-left"></i> Back</button>
          </>
        }
      </div> */}
      {tab === 'advance-search' && page === "query" &&
        <div className="card-body ">
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="false" onSubmit={handleSubmit}>
              <div className="card-body-inr">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group-blk mb-3">
                      <label>Resource</label>
                      <div className="input-group-2">
                        <div title="Resource Type" className="form-control custom-select-box">
                          <Select
                            name="viaFormat"
                            value={values.resourceDrop.value ? values.resourceDrop.value : ""}
                            placeholder={values.resourceDrop.label ? values.resourceDrop.label : "Select..."}
                            options={resource}
                            title="Resource Type"
                            onChange={(e) => handleResourceSelect(e)}
                          />
                        </div>
                        <div title="And/Or" className="form-control custom-select-box">
                          <Select
                            name="condition1"
                            value={values.condition1}
                            placeholder={values.condition1 ? condition.find((item) => item.value === values.condition1).label : "Select..."}
                            options={condition}
                            onChange={(val) => { setValues({ ...values, condition1: val.value }) }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group-blk mb-3">
                      <label>Title</label>
                      <div className="input-group-2">
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          placeholder="Title"
                          title="Title"
                        />
                        <div title="And/Or" className="form-control custom-select-box">
                          <Select
                            // className=""
                            name="condition2"
                            value={values.condition2}
                            placeholder={values.condition2 ? condition.find((item) => item.value === values.condition2).label : "Select..."}
                            options={condition}
                            onChange={(val) => { setValues({ ...values, condition2: val.value }) }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group-blk mb-3">
                      <label>Keywords</label>
                      <div className="input-group-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Keywords"
                          // onKeyDown={handleKeyDown}
                          value={values.keywords}
                          onChange={handleChange}
                          name="keywords"
                          title="Keywords"
                        />
                        <div title="And/Or" className="form-control custom-select-box">
                          <Select
                            className="form-control custom-select-box"
                            name="condition3"
                            value={values.condition3}
                            placeholder={values.condition3 ? condition.find((item) => item.value === values.condition3).label : "Select..."}
                            options={condition}
                            onChange={(val) => { setValues({ ...values, condition3: val.value }) }}
                          />
                        </div>
                      </div>
                      {keywords.length > 0 && (
                        <ul className="tag-list">
                          {keywords?.map((ele, index) => (
                            <li key={index}>
                              {ele}
                              <span onClick={() => removeKeywords(index)}>
                                <i className="fal fa-times"></i>
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group-blk mb-3">
                      <label>Description</label>
                      <div className="input-group-2">
                        <input
                          className="form-control"
                          type="text"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          placeholder="Description"
                          title="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ad-search-main">
                  <div className="col">
                    <div className={"form-group-blk mb-3" + (digital ? " disabled-input" : "")}>
                      <label>Published Location</label>
                      <div className="input-group-2">
                        <input
                          className="form-control"
                          type="text"
                          name="place"
                          onChange={handleChange}
                          placeholder="Published Location"
                          title="Place"
                          value={values.place}
                          disabled={digital}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={"form-group-blk mb-3" + (digital ? " disabled-input" : "")}>
                      <label>Author</label>
                      <div className="input-group-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Author"
                          title="Author"
                          name={"author"}
                          value={values.author}
                          onChange={handleChange}
                          disabled={digital}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={"form-group-blk mb-3" + (digital ? " disabled-input" : "")}>
                      <label>Language</label>
                      <div className="input-group-2" title="Language">
                        <Select
                          className="form-control custom-select-box"
                          name="language"
                          value={values.language}
                          onChange={(val) => { setValues({ ...values, language: val.value }) }}
                          placeholder={values.language ? LANGUAGES.find((item) => item.value === values.language).label : "Select..."}
                          isDisabled={digital}
                          options={LANGUAGES}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={"form-group-blk mb-3" + (digital ? " disabled-input" : "")}>
                      <label>From (Year of publication)</label>
                      <div className="input-group-2" title="From (Year of publication)">
                        <Select
                          className="form-control custom-select-box"
                          name="from"
                          value={values.from}
                          placeholder={values.from && YEARS.length ? YEARS.find((item) => item.value == values.from).label : "Year"}
                          isDisabled={digital}
                          options={YEARS}
                          onChange={(e) => { setValues({ ...values, from: e.value }) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={"form-group-blk mb-3" + (digital ? " disabled-input" : "")}>
                      <label>To (Year of publication)</label>
                      <div className="input-group-2" title="To (Year of publication)">
                        <Select
                          className="form-control custom-select-box"
                          name="to"
                          value={values.to}
                          placeholder={values.to && YEARS.length ? YEARS.find((item) => item.value === values.to).label : "Year"}
                          isDisabled={digital}
                          options={YEARS}
                          onChange={(e) => { setValues({ ...values, to: e.value }) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-advance-search-form">
                {/* <div className="card-header p-0"></div> */}
                <button type="submit" className="btn btn-primary mr-2" title="Search">
                  <i className="fal fa-search"></i> Search
                </button>
                <button type="button" onClick={() => handleResetForm()} className="btn btn-primary" title="Reset">
                  <i className="fal fa-redo"></i> Reset
                </button>
              </div>

            </Form>
          </FormikProvider>
        </div>
      }
      {tab === 'advance-search' && page === "table" &&
        <div className="card card-profile-info-card">
          <div className="card-body ">
            {/* <div className="card-header pt-0">Search Results</div> */}
            <div className="card-body-inr">
              <div className="">
                <div className="">
                  <ResourceList searchBtn={searchBtn} globalSearch={globalSearch} resetGlobalSearch={() => { resetGlobalSearch() }} searchValue={values} topicsValue={topicsValue}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {(tab === 'advance-search') && page === "detail" &&
        <ResourceDetail dropdownList={dropdownList}/>
      }
    </div>
  );
}

export default AdvanceSearch;
