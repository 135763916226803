import React, { useState, useEffect } from "react";
import ProgressFormReadOnly from "./re_registration_sub_read_only/ProgressFormReadOnly";
import ReSubmissionReadOnly from "./re_registration_sub_read_only/ReSubmissionReadOnly";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../../services/ReRegistrationService";

function SchoolOfBusiness(props) {
  const {
    reRegSubject,
    registration,
    subjectProgressReadOnly,
    subjectReWriteReadOnly,
    subjectReDoReadOnly,
    subjectReSubReadOnly,
  } = props;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([
    { subject: "" },
  ]);

  const [parentSubjectReSubLength, setparentSubjectReSubLength] = useState([
    { subject: "" },
  ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectProgress_: subjectProgressReadOnly,
      subjectReSub_: subjectReDoReadOnly,
    },
    validationSchema: Yup.object({
      subjectProgress_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      subjectReSub_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
    }),
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You'r Registration have been submited succefully",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
              {/* <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Re-submission:</h5>
                <label>
                  Modules you were not found competent in, thus a final mark of
                  65% was not achieved, and student would like to re-submit.
                </label>

                <ReSubmissionReadOnly
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLength}
                  parentSubjectReSubLength={parentSubjectReSubLength}
                  reRegSubject={reRegSubject}
                />
              </div> */}
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Progress From:</h5>
                <label>
                  To be eligible to progress to the second year of Project
                  Management NQF5 (PM5), the student needed to be found
                  competent (65%) for the first two subjects: Project Planning
                  and Cost Management & Manage Stakeholder Relationships. (The
                  year mark per subject can be found on your Statement of
                  Results. Kindly request an SOR via the ticketing system should
                  you not have this already.)
                </label>
                <ProgressFormReadOnly
                  formik={formik}
                  setparentSubjectProgLength={setparentSubjectProgLength}
                  parentSubjectProgLength={parentSubjectProgLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck2"
                    checked="checked"
                    disabled="disabled"
                  />
                  <label
                    className="custom-control-label color-red"
                    htmlFor="customCheck2"
                  >
                    {" "}
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button
              disabled="disabled"
              className="btn btn-submit btn-main btn-danger ml-2"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfBusiness;
