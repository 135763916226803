import React, { useEffect, useState, useRef } from "react";
import 'react-html5-camera-photo/build/css/index.css';
import { SystemIcons } from "../../common/SystemIcons";
import { CameraFeed } from "../../common/TakePhoto";
import { SysCheckSideIcon } from "../../common/SysCheckSideIcon";
const Step3 = (props) => {

  // const videoRef = useRef(null);
  // const canvasRef = useRef(null);

  // const processDevices = (devices) => {
  //   devices.forEach(device => {
  //     console.log(device.label);
  //     setDevice(device);
  //   });
  // }

  // const setDevice = async (device) => {
  //   const { deviceId } = device;
  //   const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
  //   videoRef.current.srcObject = stream;
  //   videoRef.current.play();
  // }

  // useEffect(async () => {
  //   const cameras = await navigator.mediaDevices.enumerateDevices();
  //   processDevices(cameras);
  // }, [])

  // const takePhoto = () => {
  //   console.log('canvasRef.current is ', canvasRef.current)
  //   const context = canvasRef.current.getContext('2d');
  //   context.drawImage(videoRef.current, 0, 0, 680, 360);
  //   props.handleTakePhoto(canvasRef.current.toDataURL())
  // };


  return (
    <>
      <div class="tab-pane fade show active" id="Assessment-Detail">
        <div class="test-series-main-block">
          <div class="test-series-main-left-blk">
            <div class="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span class="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>

            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li onClick={() => props.stepUpdate(1)} class="custom-breadcrumb-item active">
                    <a href="javascript:void(0);">
                      <i class="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li onClick={() => props.stepUpdate(2)} class="custom-breadcrumb-item active">
                      <a href="javascript:void(0);">System Check</a>
                    </li>
                  }
                  <li class={`custom-breadcrumb-item ${(props.photoId != "") ? 'active' : 'inactive'}`}>
                    <a href="javascript:void(0);">Student Identification</a>
                  </li>
                  <li class="custom-breadcrumb-item">
                    <a href="javascript:void(0);">Test</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={props.backBtn} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  {props.photoId != "" &&
                    <button
                      onClick={props.photoId == "" ? props.handleClick : props.retake}
                      type="button"
                      class="btn btn-primary"
                    >
                      <i class="fal fa-camera"></i>&nbsp;{" "}
                      Reset
                    </button>
                  }

                  {props.photoId != "" ? (
                    <button onClick={props.onNext} type="button" class="btn btn-primary">
                      Next &nbsp;<i class="fal fa-chevron-right"></i>{" "} Test/Assessment
                    </button>
                  ) : (
                    <button type="button" class="btn btn-primary disabled">
                      Next &nbsp;<i class="fal fa-chevron-right"></i>{" "}
                      Test/Assessment
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div class="test-series-main-heading mb-15">
              <h4>Student Identification</h4>
            </div>

            <div class="std-identify-blk mb-15">
              <p>
                Please take a photo of your face and your student card/ID, as soon as you are ready to take the photo please select the Camera icon in the center of the photo box
              </p>
              <figure>
                {/* <i onClick={takePhoto} class="fal fa-camera"></i> */}
                {props.photoId != "" &&
                  <div className="output-img-border">
                    <div className="output-img">
                      <img src={props.photoId} />
                    </div>
                  </div>
                }

                {(props.hasCamera) ?
                  <>
                    <CameraFeed
                      sendFile={props.handleTakePhoto}
                      // canvasRef={canvasRef}
                      // videoRef={videoRef}
                      // onClick={() => takePhoto()}
                      // noCameraPermission={props.noCameraPermission}
                      // showActive={(props.noCameraPermission == '') ? true : false}
                      showCamFeed={props.photoId != "" ? false : true}
                    />
                  </>
                  :
                  <h1 style={{ textAlign: 'center' }}>{'Camera not found'}</h1>
                }
              </figure>
            </div>
          </div>

          <div class="test-series-main-right-blk">
            <div className="step-sidebar-space">
              <SysCheckSideIcon props={props} />
            </div>

            <div class="test-series-steps">
              <ul>
                <li class="test-series-steps-item active done">
                  <a href="javascript:void(0);">
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li class="test-series-steps-item active done">
                    <a href="javascript:void(0);">
                      <span>2</span> <b>System Check</b>
                    </a>
                  </li>
                  : null}
                <li className={`test-series-steps-item active ${(props.photoId != "") ? 'done' : ''}`}>
                  <a href="javascript:void(0);">
                    <span>{props.showSystemCheck ? 3 : 2}</span> <b>Identification</b>
                  </a>
                </li>
                <li class="test-series-steps-item">
                  <a href="javascript:void(0);">
                    <span>{(props.showSystemCheck && props.showIdentification) ? 4 : (props.showSystemCheck || props.showIdentification) ? 3 : 2}</span> Launch Exam
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step3;
