import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import ReactPlayer from "react-player/lazy";
import Loader from "react-loader-spinner";
import screenfull from "screenfull";
import { APP_LINK } from "../../utils/Constants";
import Swal from "sweetalert2";

export default function VideoPlayer(props) {
  const {
    ParentResumeBtn,
    playing,
    videoId,
    loopId,
    assemblyName,
    assemblyDescription,
    parentRefId,
    watchedVideo,
    url,
    item_url,
    videoType,
    video_duration,
    watch_in_m_s,
    currentVideoId,
    playerSeek,
    alreadyWatched,
    watchPercentage,
    isWatchedVideo,
    lessonindex,
    ParentPlayerPipMode,
    isAdmin
  } = props;

  let { parentRef } = props;
  let playerRef = {};
  let player = {};

  const handleVideoStart = (e, player, assemblyItem, videoId) => {
      e.preventDefault();
      props.ParentHandleVideoStart(player, assemblyItem, videoId);   
  };

  const handleVideoResume = (e, player, assemblyItem, videoId, action = false) => {
      e.preventDefault();
      props.ParentHandleVideoResume(player, assemblyItem, videoId, action);
  };

  const handleVideoResumeRP = (player, assemblyItem, videoId, action) => {
      props.ParentHandleVideoResume(player, assemblyItem, videoId, action);
  };

  const handleVideoRewind = (player, assemblyItem, videoId, rewindSec = 0) => {
      if(rewindSec > 0) {
        let setSeek = parseFloat(playerSeek) - parseFloat(rewindSec);
        if(setSeek > 0) {
          props.ParentHandleVideoRewind(player, assemblyItem, videoId);
          player.seekTo(parseFloat(setSeek));
        } else {
          props.ParentHandleVideoStart(player, assemblyItem, videoId);
        }
      }
  };

  const handleVideoReady = (e, player, assemblyItem, videoId) => {
      e.preventDefault();
     props.ParentHandleVideoReady(player, assemblyItem, videoId);
  };

  const handleVideoFullScreen = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    screenfull.request(ReactDOM.findDOMNode(player));   
  };

  const handleVideoTogglePIP = (player, assemblyItem, videoId, action) => {
      props.ParentHandleVideoTogglePIP(player, assemblyItem, videoId, action);
  };

  const handlePlayPause = () => {
    //this.setState({ playing: !this.state.playing })
    //console.log("play pause");
  };

  const handleStop = () => {
    // this.setState({ url: null, playing: false })
    // console.log("stop");
    // console.log("Current time",player.current.getCurrentTime())
    // console.log("duration time",player.current.getDuration())

    // console.log(player);
  };

  const handlePlay = () => {
    // console.log("onPlay");
    // this.setState({ playing: true })
  };

  const handlePause = () => {
    // console.log("onPause");
    // this.setState({ playing: false })
  };

  const handleProgress = (e, player, assemblyItem, videoId) => {
    props.ParentHandleVideoInProgress(player, assemblyItem, videoId);
    // We only want to update time slider if we are not currently seeking
    // if (!this.state.seeking) {
    //   this.setState(state)
    // }
  };

  const handleEnded = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    // console.log("onEnded");
    // this.setState({ playing: this.state.loop })
  };

  const handleDuration = (duration) => {
    // console.log("onDuration", duration);
    // this.setState({ duration })
  };

  //  const handleClickFullscreen = () => {
  //    screenfull.request(findDOMNode(player))
  //  }

  //  const ref = (ply) => {
  //    player = ply;
  //  }

  return (
    <>
      <div className="card-list-inner d-flex">
        <div className="card-list-left">
          <div className="card-list-ic d-flex">
            <div className="card-media card-blue-1">
              <i className="fal fa-video"></i>
            </div>
            <div className="card-media-text">
              <p>
                {lessonindex} {assemblyName}
              </p>
              <span>
                <i className="fal fa-clock"></i> {video_duration}
              </span>
            </div>
          </div>
        </div>
        <div className="card-list-right ml-auto">
          <ul className="learningright-inner d-flex align-items-center">
            <li className="border-left item-progress">
              {watchPercentage != 0 && (
                <div className="progress border-left">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: "" + watchPercentage + "%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {watchPercentage}%
                  </div>
                </div>
              )}
            </li>
            <li className="border-left item-action">
              <div className="media-gp">                
                <a
                  title={(loopId == videoId && playing == true) ||
                    watchedVideo.includes(loopId) == true ||
                    watch_in_m_s != "" ? (
                      (loopId == currentVideoId) == true ? (
                        null
                      ) : "Resume Video"  
                    ) : "Watch Video"}
                  className="btn btn-white-bordered"
                  onClick={(e) =>
                    handleVideoReady(
                      e,
                      playerRef[parentRefId],
                      parentRefId,
                      loopId
                    )
                  }
                >
                  {(loopId == videoId && playing == true) ||
                  watchedVideo.includes(loopId) == true ||
                  watch_in_m_s != "" ? (
                    (loopId == currentVideoId) == true ? (
                      <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={20}
                        width={20}
                      />
                    ) : (
                      <React.Fragment>
                        <i className="fal fa-play"></i>{" "}
                        <React.Fragment title="Resume Video">Resume Video</React.Fragment>
                      </React.Fragment>
                    )
                  ) : (
                    <React.Fragment>
                      <i className="fal fa-play"></i>{" "}
                      <React.Fragment>Watch Video</React.Fragment>
                    </React.Fragment>
                  )}
                </a>
              </div>
            </li>
            {watchPercentage >= 95 || isWatchedVideo === true ? (
              <li className="item-status">
                  <div className="header-status ">
                    <div className="status-gol bg-success">
                      <i className="fal fa-check"></i>
                    </div>
                  </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>

      {loopId == currentVideoId ? (
        <div className="card-list-inner d-flex">
          <div className="row rowlec">
            <div className="col-xl-8 lec-sec-left">
              <div className="vidbox">
                {videoType == 0 ? (
                  //   Vimeo
                  <ReactPlayer
                    url={`https://player.vimeo.com/video/${item_url}#t=${watch_in_m_s}`}
                    // url={`https://vimeo.com/${item_url}`}
                    onError={(err)=>{
                      console.log(err.message)
                      if(err.message.toString().toLowerCase().includes("not found")){
                        Swal.fire({
                          icon:"error",
                          title:"Video Not Found",
                          text:"This Video Doesn't Exists anymore."
                        })
                      }
                    }}
                    ref={(ref) => (playerRef[parentRefId] = ref)}
                    onReady={() => playerRef[parentRefId].seekTo(playerSeek)}
                    // onReady={() => console.log("onReady")}
                    // onStart={() => console.log("onStart")}
                    onPlay={() =>
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        true
                      )
                    }
                    onPause={() =>
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        false
                      )
                    }
                    onEnded={() => {
                      // console.log("onEnd");
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        null
                      );
                    }}
                    // light={
                    //   (loopId == currentVideoId && playing == true) ||
                    //   watchedVideo.includes(loopId) == true
                    //     ? false
                    //     : true
                    // }
                    // light={
                    //   loopId == currentVideoId && playing == true ? false : true
                    // }
                    pip={ParentPlayerPipMode}
                    stopOnUnmount={false}
                    className="react-player"
                    playing={
                      loopId == currentVideoId && playing == true ? true : false
                    }
                    width='100%'
                    height='100%'
                    progressInterval={2000}
                    onProgress={(e) => {
                      // console.log("onEnd");
                      handleProgress(
                        e,
                        playerRef[parentRefId],
                        parentRefId,
                        loopId
                      );
                    }}
                    controls={true}
                  />
                  ) : (
                  // Youtube
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${url}#t=${watch_in_m_s}`}
                    ref={(ref) => (playerRef[parentRefId] = ref)}
                    onReady={() => playerRef[parentRefId].seekTo(playerSeek)}
                    // onReady={() => console.log("onReady")}
                    // onStart={() => console.log("onStart")}
                    onPlay={() =>
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        true
                      )
                    }
                    onPause={() =>
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        false
                      )
                    }
                    onEnded={() => {
                      // console.log("onEnd");
                      handleVideoResumeRP(
                        playerRef[parentRefId],
                        parentRefId,
                        loopId,
                        null
                      );
                    }}
                    // light={
                    //   (loopId == currentVideoId && playing == true) ||
                    //   watchedVideo.includes(loopId) == true
                    //     ? ""
                    //     : `https://img.youtube.com/vi/${url}/0.jpg`
                    // }

                    // light={
                    //   loopId == currentVideoId && playing == true
                    //     ? ""
                    //     : `https://img.youtube.com/vi/${url}/0.jpg`
                    // }
                    pip={ParentPlayerPipMode}
                    stopOnUnmount={false}
                    className="react-player"
                    playing={
                      loopId == videoId && playing == true ? true : false
                    }
                    width="100%"
                    height="100%"
                    progressInterval={2000}
                    onProgress={(e) => {
                      // console.log("onEnd");
                      handleProgress(
                        e,
                        playerRef[parentRefId],
                        parentRefId,
                        loopId
                      );
                    }}
                    controls={true}
                    youtube="youtube"
                  />
                )}
                
              </div>
            </div>
            <div className="col-xl-4 lec-sec-right">
               <div className="lec-sec-text">
                  <div className="lec-sec-text-body">
                    <p><b>{assemblyName}</b></p>
                    <p>Length: {video_duration}</p>
                    <p><b>Description</b> </p>
                    <p>
                      {assemblyDescription}
                    </p>
                  </div>

                  {(loopId == videoId && playing == true) ||
                  watchedVideo.includes(loopId) == true ||
                  watch_in_m_s != "" ? (
                   <div className="lec-sec-footer">
                     {watchPercentage != 0 && (
                        <div className="custom-vi-player">
                          <div className="progress">
                             <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: "" + watchPercentage + "%" }}
                             >
                              {watchPercentage}%
                             </div>
                          </div>
                        </div>
                     )}
                     <div className="video-item-group">
                        <div className="video-item b-start">
                           <a
                            className="btn btn-white-bordered"
                            onClick={(e) =>
                              handleVideoStart(
                                e,
                                playerRef[parentRefId],
                                parentRefId,
                                loopId
                              )
                            }
                           >
                              <i className="fal fa-step-backward"></i>
                           </a>
                           <p>Restart</p>
                        </div>

                        <div className="video-item b-history">
                           <a
                            className="btn btn-white-bordered"
                            onClick={() =>
                              handleVideoRewind(
                                playerRef[parentRefId],
                                parentRefId,
                                loopId,
                                15
                              )
                            }
                           >
                              <i className="fal fa-history"></i>
                           </a>
                           <p>15 Sec</p>
                        </div>

                        {(ParentResumeBtn == true) && (
                          <div className="video-item b-play">
                            <a
                              className="btn btn-white-bordered"
                              onClick={(e) =>
                                handleVideoResume(
                                  e,
                                  playerRef[parentRefId],
                                  parentRefId,
                                  loopId,
                                  loopId == videoId && playing == true ? false : true
                                )
                              }
                            >
                              {loopId == videoId && playing == true ? (
                                <i className="fal fa-pause"></i>
                              ) : (
                                <i className="fal fa-play"></i>
                              )}{" "}
                            </a>
                            <p>Play / Pause</p>
                          </div>
                        )}

                        <div className="video-item b-full-screen">
                           <a
                              className="btn btn-white-bordered"
                              onClick={(e) =>
                                handleVideoFullScreen(
                                  e,
                                  playerRef[parentRefId],
                                  parentRefId,
                                  loopId
                                )
                              }
                           >
                            <i className="fal fa-expand-wide"></i>
                           </a>
                           <p>Full Screen</p>
                        </div>

                        {(ReactPlayer.canEnablePIP(playerRef[parentRefId])) && (
                          <div className="video-item b-thumb-pop">
                             <a
                                className="btn btn-white-bordered"
                                onClick={(e) =>
                                  handleVideoTogglePIP(
                                    e,
                                    playerRef[parentRefId],
                                    parentRefId,
                                    loopId,
                                    ParentPlayerPipMode ? false : true
                                  )
                                }
                             >
                                <i className="fal fa-image"></i>
                             </a>
                             <p>{ ParentPlayerPipMode ? 'Pop-out' : 'Pop-in' }</p>
                          </div>
                        )}
                     </div>
                   </div>
                  ) : (
                    <div className="lec-sec-footer">
                      <a
                        className="btn btn-white-bordered"
                        onClick={(e) =>
                          handleVideoReady(
                            e,
                            playerRef[parentRefId],
                            parentRefId,
                            loopId
                          )
                        }
                      >
                        <i className="fal fa-play"></i> Watch Video
                      </a>
                    </div>
                  )}
               </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
