import axiosInstance from "../utils/axios";
  // Mentored Subject Starts here
  export const GetSubjectDetails = async (values) => {
    return await axiosInstance().get(`/getSubjectDetails`, { params: values });
  };
  
  export const StartModule = async (values) => {
    return await axiosInstance().get(`/startModule`, { params: values });
  };
  
  export const ViewNotes = async (values) => {
    return await axiosInstance().get(`/viewNotes`, { params: values });
  };

  export const AddNote = async (values) => {
    return await axiosInstance().get(`/addNote`, { params: values });
  };

  export const MarkComplete = async (values) => {
    return await axiosInstance().get(`/markComplete`, { params: values });
  };
  // Mentored Subject Ends here