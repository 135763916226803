import React from "react";
import moment from 'moment';
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./styles.css";

var streams = {}
var sound = 0
var callDraw = true

class AudioDetection extends React.Component {
  videoRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      stream: "",
      topCol: '',
      resetCount: 0
    };
  }

  componentDidMount() {
    callDraw = true
    sound = 0
    var topCol = this.props?.brandInfo?.topbar_color
    let data = this.props
    var constraints = { audio: true, video: false };

    navigator.mediaDevices.getUserMedia(constraints).then(
      function (stream) {
        streams = stream
        // console.log('the value of stream is ', streams)
        var max_level_L = 0;
        var old_level_L = 0;
        var cnvs = document.getElementById("test");
        var canvasCtx = cnvs.getContext("2d");

        var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        var analyser = audioCtx.createAnalyser();

        var source = audioCtx.createMediaStreamSource(stream);
        source.connect(analyser);
        var distortion = audioCtx.createScriptProcessor(1024, 1, 1);
        analyser.connect(distortion);
        distortion.connect(audioCtx.destination);

        analyser.fftSize = 2048;
        var bufferLength = analyser.frequencyBinCount;
        var dataArray = new Uint8Array(bufferLength);

        // analyser.getByteTimeDomainData(dataArray);
        // canvasCtx.clearRect(0, 0, cnvs.height, cnvs.height);
        let makeCount = true

        function draw() {
          if (streams.active) {
            var average = getAverageVolume(dataArray)
            if (makeCount) {
              if (average >= 127.8) {
                makeCount = false
                sound = sound + 1
                data.soundCount(sound)
                setTimeout(() => {
                  makeCount = true
                }, 100);
              }
            }
          }
          if (callDraw) {
            var drawVisual = requestAnimationFrame(draw);
          }

          analyser.getByteTimeDomainData(dataArray);
          canvasCtx.fillStyle = "rgba(0,0,0,0)";
          canvasCtx.clearRect(0, 0, cnvs.height, cnvs.height)

          canvasCtx.lineWidth = 1;
          canvasCtx.strokeStyle = topCol
          canvasCtx.beginPath();

          var sliceWidth = cnvs.height * 1.0 / bufferLength;
          var x = 0;

          for (var i = 0; i < bufferLength; i++) {
            var v = dataArray[i] / 128.0;
            var y = v * cnvs.height / 2;
            if (i === 0) {
              canvasCtx.moveTo(x, y);
            } else {
              canvasCtx.lineTo(x, y);
            }
            x += sliceWidth;
          }
          canvasCtx.lineTo(cnvs.width, cnvs.height / 2);
          canvasCtx.stroke();
        };

        function getAverageVolume(array) {
          var values = 0;
          let average;
          var length = array.length;
          // get all the frequency amplitudes
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          average = values / length;
          return average;
        }

        draw();

      }).catch(function (err) {
        console.log('the error in audio-detection is ', err);
      });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.resetCount != state.resetCount) {
      state.resetCount = props.resetCount
      sound = 0
    }
    return null;
  }

  componentWillUnmount() {
    callDraw = false
    sound = 0
    const stream = (streams && streams.id) ? streams : undefined;
    const tracks = stream && stream.getTracks();
    tracks && tracks.forEach(function (track) {
      track.stop();
    });
  }

  render() {
    return (
      <div className="audio-detect-div">
        <canvas width="100% !important" height="100px !important" id="test" className="audio-canvas"></canvas>
      </div>
    );
  }
}

export default AudioDetection;
