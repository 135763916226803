import { useEffect, createRef, useRef } from "react";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, CompositeDecorator, AtomicBlockUtils, convertFromHTML, SelectionState, RichUtils, DefaultDraftBlockRenderMap, getSafeBodyFromHTML } from "draft-js";
import embed from "embed-video";
import { set } from "date-fns";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from "../../utils/Constants";
import { getToken } from "../../utils/Auth";
import draftToHtml from "draftjs-to-html";
import HtmlParser from "react-html-parser";
import createMentionPlugin, { defaultSuggestionsFilter } from "draft-js-mention-plugin";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// import Editor from "draft-js-plugins-editor";
import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import defaultMentionStyle from '../../utils/defaultMentionStyle'
import defaultStyle from '../../utils/defaultStyle'
import { MentionsInput, Mention } from 'react-mentions'
import { ShowMoreHide } from "./ShowMoreHide";
import FeedAttachments from "../feed/FeedAttachments";
import htmlToDraft from 'html-to-draftjs';

/** mention users in editor */

// registerPlugin(FilePondPluginImagePreview);

// const FileMentionComponent =
//     ({ mention: { name, link, value },
//         children
//     }) => {
//         console.log("consooglgle")
//         return (
//             <>
//                 <span className="new">{value}11111</span>
//                 <span className="check">{children}1111222</span>
//             </>

//         );
//     }
// const mentionPlugin = createMentionPlugin({
//     mentionTrigger: "@",
//     separator: " ",
//     mentionComponent: FileMentionComponent
// });
// const { MentionSuggestions } = mentionPlugin;
// const plugins = [mentionPlugin];
function HtmlInputEditorShowComment({ data, tagClass, setCurrentVideo, editableArr, allData, attachmentClick, sendClick, placeholder, isImportant, setIsImportant, importantArr, userData, deletedFileHandle }) {

    const [users, setUsers] = useState('');
    const isInvalid = false;
    const [videoArr, setVideoArr] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [editable, setEditable] = useState(false);
    const [showLess, setShowLess] = useState(null);
    const [inputError, setInputError] = useState('');
    const [sendActive, setSendActive] = useState(false);
    const [files, setFiles] = useState([]);
    const [showFilePond, setShowFilePond] = useState(false);
    const [removeFile, setRemoveFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [suggestions, setSuggestions] = useState(userData)

    const btnAttachment = createRef();
    const filePondRef = createRef();
    const editorReference = useRef();

    // useEffect(() => {
    //     let finalUserData = []
    //     userData.map(item => {
    //         let tempVal = {
    //             name: item.value,
    //             link: item.url,
    //             userId: item.id,
    //             avatar: item.value
    //         }
    //         finalUserData.push(tempVal)
    //     })
    //     setSuggestions(userData)
    // }, [])

    const HANDLE_REGEX = /\@[\w]+/g;
    const styles = {
        editor: { border: "1px solid #ddd", cursor: "text", fontSize: 16, minHeight: "40px", padding: 10, background: "#000" },
        handle: { color: "rgba(98, 177, 254, 1.0)", direction: "ltr", unicodeBidi: "bidi-override" },
    };

    const HandleSpan = (props) => {
        return (
            <span className="check">
                <span
                    style={styles.handle}
                    data-offset-key={props.offsetKey}
                    className="checkkkk"
                >
                    {props.children}
                </span>
            </span>
        );
    };

    function handleStrategy(contentBlock, callback, contentState) {
        findWithRegex(HANDLE_REGEX, contentBlock, callback);
    }

    const compositeDecorator = new CompositeDecorator([{ strategy: handleStrategy, component: HandleSpan }]);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(compositeDecorator));

    useEffect(() => {

        let isBlock = isJsonString(data)
        if (isBlock) {
            try{
                let entitymap = JSON.parse(data).entityMap ? JSON.parse(data).entityMap : {}
                let embedArr = []
                let otherEntityArr = []            
                let finalData = Object.keys(JSON.parse(data)).length ? JSON.parse(data) : ""
                Object.values(entitymap).map((entity, index) => {
                    if (entity.type === 'EMBEDDED_LINK') {
                        let url = (entity.data.src).substring(2, (entity.data.src).length)
                        let thumbId = ''
                        let thumbImg = ''
                        if ((url.substring(0, 7) == 'youtube') || (url.substring(0, 7) == 'www.you')) {
                            thumbId = youtube_parser(url)
                            thumbImg = `https://img.youtube.com/vi/${thumbId}/sddefault.jpg`
                        }
                        else if ((url.substring(0, 7) == 'player.') || (url.substring(0, 7) == 'www.pla')) {
                            thumbId = vimeo_parser(url)
                            thumbImg = `https://vumbnail.com/${thumbId}.jpg`
                        }
                        embedArr.push({ src: (entity.data.src).substring(2, (entity.data.src).length), thumbnail: thumbImg });
                        finalData.entityMap[index] = {}
                    }
                    else {
                        // otherEntityArr.push(entity)
                    }
                });
    
                let blocks = JSON.parse(data).blocks ? JSON.parse(data).blocks : []
                let tempTxt = ''
                blocks.map(i => {
                    tempTxt = tempTxt + '' + (i.text)
                })
                if ((blocks && (blocks.length > 3)) || (tempTxt.length > 200) || (blocks.length > 0 && tempTxt.includes("\n\n"))) {
                    setShowMore(true)
                }
    
                if (embedArr.length > 0) {
                    setVideoArr(embedArr)
                }
                const blocksFromHTML = convertFromRaw(finalData);
    
                setEditorState(EditorState.createWithContent(blocksFromHTML));
            }catch(err){
                console.log(err)
            }
        }
        else {
            // const blocksFromHTML = convertFromHTML(data);
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap,
            // )
            // setEditorState(EditorState.createWithContent(state));

            const blocksFromHtml = htmlToDraft(data);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, []);

    useEffect(() => {
        let isBlock = isJsonString(data)
        if (isBlock) {
            const messageValue = convertToRaw(editorState.getCurrentContent());


            let blocks = messageValue.blocks
            let tempTxt = ''
            blocks.map(i => {
                tempTxt = tempTxt + '' + (i.text).trim()
            })
            // let entitymap = messageValue.entityMap
            // let embedArr = []
            // Object.values(entitymap).forEach(entity => {
            //     if (entity.type === 'EMBEDDED_LINK') {
            //         embedArr.push(entity.data.src);
            //     }
            // });
            if (videoArr.length != 0) {

                if (tempTxt == '') {
                    let contentState = editorState.getCurrentContent();
                    const block = contentState.getBlockMap().first();
                    const next = contentState.getBlockAfter(block.getKey());

                    if (block && next) {
                        const removeSelection = new SelectionState({
                            anchorKey: block.getKey(),
                            anchorOffset: block.getText().length,
                            focusKey: next.getKey(),
                            focusOffset: 0
                        });

                        let newContentState = Modifier.removeRange(
                            contentState,
                            removeSelection,
                            "forward"
                        );

                        let newEditorState = EditorState.push(
                            editorState,
                            newContentState,
                            "remove-range"
                        );
                        setEditorState(newEditorState);
                    }
                }
            }
        }
    }, [editorState])

    useEffect(() => {
        if (editableArr.includes(allData.id)) {
            // console.log('the ids for comment is ', editableArr, allData.id)
            editorReference.current.focusEditor()
            setShowMore(false)
            setShowLess(false)
            setEditable(true)
        }
        else {
            setEditable(false)
        }
    }, [editableArr])

    const youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const vimeo_parser = (url) => {
        let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
        let match = url.match(regEx);
        if (match && match.length == 7) {
            let videoId = match[6];
            return videoId
        }
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function findWithRegex(regex, contentBlock, callback) {
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    const onEditorStateChange = (editorState) => setEditorState(editorState);

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    function uploadImageCallBack(file) {
        return new Promise((resolve, reject) => {
            getBase64(file)
                .then((data) => {
                    resolve({ data: { link: data } });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const handlePastedFiles = async (files) => {
        getBase64(files[0])
            .then((data) => {
                setEditorState(insertImage(data));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    };

    const renderVideos = () => {
        if (videoArr.length > 0) {
            return videoArr.map(i => {
                return (
                    <div onClick={() => setCurrentVideo(i.src)} className="modal-thumb" data-toggle="modal" data-target="#staticBackdrop">
                        <div className="modal-thumb-play">
                            <span><i class="fas fa-play"></i></span>
                        </div>
                        <img width='100%' src={i.thumbnail} />
                    </div>
                )
            })
        }
        else {
            return null
        }
    }

    const feedStateChange = (e) => {
        let blocks = e.blocks
        let tempTxt = ''
        blocks.map(i => {
            tempTxt = tempTxt + '' + (i.text).trim()
        })
        let entitymap = e.entityMap
        let embedArr = []
        Object.values(entitymap).forEach(entity => {
            if (entity.type === 'EMBEDDED_LINK') {
                embedArr.push(entity.data.src);
            }
        });

        if ((tempTxt != '') || (embedArr.length != 0) || (attachments?.length != 0)) {
            setSendActive(true)
        }
        else {
            setSendActive(false)
        }
    }

    const handleSendClick = () => {
        // console.log("handleSendClickhandleSendClickhandleSendClickhandleSendClick");
        // const messageValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        const forFormik = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );
        const messageValue = forFormik
        sendClick(messageValue, allData.id, attachments, sizes, allData.message_id)
        setAttachments([])
        setSizes([])
        setFiles([]);
        setShowFilePond(false);
        // setIsImportant(false)
    }

    const handleFiles = (event) => {
        const ufiles = event.target.files;
        if (ufiles.length > 0) {
            for (var i = 0; i < ufiles.length; i++) {
                if (ufiles[i]) {
                    if (filePondRef.current) {
                        filePondRef.current
                            .addFile(ufiles[i])
                            .then((file) => {})
                            .catch((error) => console.log(error));
                    }
                }
            }
        }
    };

    const handleAttachment = () => {
        btnAttachment.current.click();
    };

    const attachmentsArrUpdate = (file) => {
        let tempAttach = attachments
        for (var i = 0; i < tempAttach.length; i++) {
            if (tempAttach[i].includes(file.split('.')[0])) {
                tempAttach.splice(i, 1);
                break;
            }
        }
        if (tempAttach.length < 1) {
            setTimeout(() => {
                setShowFilePond(false)
                // setShowCurser(!showcursure)
            }, 500);
        }
    }

    const handleUploadedFiles = (response) => {
        // setAttachments((attachments) => [...attachments, response]);
        // console.log('the uploaded file is like this ', response)
        let newObj = {}
        let blocks = isJsonString(response)
        if (blocks) {
            newObj = JSON.parse(response)
            setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
            setSizes((pre) => [...pre, newObj.size]);
        }
        // console.log('the new res is here ', newObj)
    };

    const handleDragEnter = event => {
        setShowFilePond(true);
    };

    const expand = () => {
        if (showMore) {
            setShowMore(false);
            setShowLess(true)
        }
    }

    const imgAndText = (data) => {
        // console.log("data", data);
        // console.log("convertToRaw(data.getCurrentContent())",convertToRaw(data.getCurrentContent()) );
        // let tempState = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
        const forFormik = draftToHtml(convertToRaw(data.getCurrentContent()));
        // console.log("forFormik", forFormik);

        //         const blockRenderMap = DefaultDraftBlockRenderMap.set('p', { element: 'p' });
        //         const blocksFromHTML = convertFromHTML(forFormik, getSafeBodyFromHTML, blockRenderMap)
        //         .map(block => (block.get('type') === 'p' ? block.set('type', 'unstyled') : block));
        //         const contentState = ContentState.createFromBlockArray(blocksFromHTML);

        // console.log("contentState", contentState);
        return forFormik
    }

    const onSearchChange = ({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, suggestions))
    };

    const StyleButton = (props) => {
        let onClickButton = (e) => {
            e.preventDefault();
            props.onToggle(props.style);
        };
        return <button className="fontStyle draft-editor-btn" onMouseDown={onClickButton}>{props.label}</button>;
    };

    const InlineStyleControls = (props) => {
        return (
            <div>
                {INLINE_STYLES.map((type) => (
                    <StyleButton
                        key={type.label}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                ))}
            </div>
        );
    };

    const onInlineClick = (e) => {
        let nextState = RichUtils.toggleInlineStyle(editorState, e);
        setEditorState(nextState);
    };

    const INLINE_STYLES = [
        { label: <i>I</i>, style: "ITALIC" },
        { label: <b>B</b>, style: "BOLD" },
        { label: <u>U</u>, style: "UNDERLINE" },
    ];
    const editorRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            editorRef.current
            && !editorRef.current.contains(event.target)
            && event.target.classList.contains('rdw-embedded-modal-btn') != true
            && event.target.classList.contains('rdw-suggestion-option') != true
            && event.target.classList.contains('hold-show') != true
            && event.target.classList.contains('swal2-cancel') != true
            && event.target.classList.contains('swal2-confirm') != true
        ) {
            setAttachments([])
            setEditable(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <div
            className={`new-discussion-box ${!isInvalid ? (`post-input-content ${editable ? '' : 'readonly'} mb-0 ` + tagClass) : "file-req is-invalid mb-4"}`}
            onClick={expand}
            ref={editorRef}
        >
            <div className={`${showMore ? "" : ''} rdw-editor-position`} onDragEnter={editable ? handleDragEnter : null}>
                <div className={!editable ? "d-none" : ""}>
                    <Editor
                        readOnly={editable ? false : true}
                        editorState={editorState}
                        // editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        handlePastedFiles={handlePastedFiles}
                        onChange={e => feedStateChange(e)}
                        ref={editorReference}
                        // plugins={plugins}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: suggestions,
                        }}
                        toolbar={{
                            options: ['inline', 'textAlign', 'list', 'embedded'
                                //  'blockType', 'fontSize', 'fontFamily',   'colorPicker',  'image', 'link', 'emoji',  'remove', 'history'
                            ],
                            inline: {
                                options: ['bold', 'italic', 'underline'],
                                italic: { className: 'i-italic-icon' },
                                bold: { className: 'i-bold-icon' },
                                underline: { className: 'i-underline-icon' },
                            },
                            list: {
                                options: ['unordered', 'ordered'],
                                unordered: { className: 'i-unordered-icon' },
                                ordered: { className: 'i-ordered-icon' },
                                // indent: { className: 'i-indent-icon' },
                                // outdent: { className: 'i-outdent-icon' },
                            },
                            textAlign: {
                                options: ['left', 'center', 'right', 'justify'],
                                left: { className: 'i-left-icon' },
                                center: { className: 'i-center-icon' },
                                right: { className: 'i-right-icon' },
                                justify: { className: 'i-justify-icon' },
                            },
                            blockType: {
                                inDropdown: false,
                                options: ['H1', 'Blockquote'],
                                className: 'i-bloctype-icon',
                            },
                            embedded: {
                                className: 'i-embedded-icon',
                                embedCallback: link => {
                                    const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                    return (detectedSrc && detectedSrc[1]) || link;
                                }
                            }
                        }}
                    />
                </div>
                {editable ? <>
                    <div className="action-input-content edit-buttons-new">
                        <button onClick={() => { setIsImportant(allData, !isImportant) }} className={`btn btn-input-action hold-show ${isImportant ? 'active' : ''}`} title="Important"><i className="fal fa-exclamation hold-show"></i></button>
                        <button onClick={handleAttachment} className="btn btn-input-action hold-show" title="Add Attachment"><i className="fal fa-paperclip hold-show"></i></button>
                        {sendActive ?
                            <button onClick={handleSendClick} className="btn btn-input-action hold-show" title="Send"><i className="fal fa-paper-plane hold-show"></i></button>
                            :
                            <button onClick={() => { }} className="btn btn-input-action hold-show" title="Send" disabled><i className="fal fa-paper-plane hold-show"></i></button>
                        }
                    </div>

                </>
                    : null
                }
                {!editable &&
                    <ShowMoreHide
                        show={allData.comment ? allData.comment : ""}
                        attachInfo={(allData.attachments && allData.attachments.length) ?
                            <FeedAttachments attachments={allData.attachments} isEditable={editableArr.includes(allData.id)} allData={allData} deletedFile={deletedFileHandle} />
                            : null}
                    />
                }

            </div>

            {editable && (allData.attachments && allData.attachments.length) ?
                <div className="attach-comments">
                    <FeedAttachments attachments={allData.attachments} isEditable={editableArr.includes(allData.id)} allData={allData} deletedFile={deletedFileHandle} /></div>
                : null}

            {inputError != '' ? <div className="invalid-feedback d-block">{inputError}</div> : null}
            {showMore ? <div onClick={() => { setShowMore(false); setShowLess(true) }} className="show-more-txt">See more</div> : null}
            {showLess ? <div onClick={() => { setShowLess(false); setShowMore(true) }} className="show-more-txt">See less</div> : null}

            {renderVideos()}
            {editable ? <>
                <input
                    type="file"
                    ref={btnAttachment}
                    style={{ display: "none" }}
                    className='hold-show'
                    onChange={(e) => handleFiles(e)}
                />

                <div
                    className={
                        !showFilePond ? "hideFilePond" : "showFilePond"
                    }
                >
                    <FilePond
                        name="post"
                        allowMultiple={true}
                        maxFiles={3}
                        ref={filePondRef}
                        files={files}
                        onupdatefiles={setFiles}
                        beforeRemoveFile={(f) => { setRemoveFile(f.file.name); attachmentsArrUpdate(f.file.name) }}
                        // onremovefile={(err, file)=>{console.log('the removed file is ', file)}}
                        onaddfile={(err, fileItem) => {
                            setShowFilePond(true);
                        }}
                        server={{
                            url: API_BASE,
                            process: {
                                url: "/addFeedAttachments",
                                headers: {
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                    handleUploadedFiles(response);
                                },
                                ondata: (formData) => {
                                    formData.append('datasize', true);
                                    return formData;
                                },
                            },
                            revert: {
                                url: `/removeFeedAttachments/${removeFile}`,
                                headers: {
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                    setRemoveFile(null);
                                },
                            },
                        }}
                    />
                </div>
            </>
                :
                null}

        </div>
    );
}

export default React.memo(HtmlInputEditorShowComment);
