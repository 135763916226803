const initialState = {
    examLaunched: {},
}

const examLaunched = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_EXAM_LAUNCHED':
            state.examLaunched = action.payload
            return {...state};
        default:
            return state;
    }
}

export default examLaunched;