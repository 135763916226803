import React, { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResizableTextarea from "../common/ResizableTextarea";
import TextareaAutosize from "react-autosize-textarea";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from "../../utils/Constants";
import { getToken, removeUserSession } from "../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../assets/css/filePond.css";
import {
  getAvailableUsersService,
  createChatGroupService,
  getMyGroupsService,
} from "../../services/FeedService";
import { useHistory } from "react-router-dom";
import FeedComments from "../feed/FeedMessage";
import FeedAttachments from "../feed/FeedComments";
/** mention users in editor */
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../utils/defaultStyle";
import defaultMentionStyle from "../../utils/defaultMentionStyle";
import ReactHtmlParser from "react-html-parser";
import { setChatGroups, unSetSelectedGroups } from "../../store/actions";
/** emoji plugin */
//import 'emoji-mart/css/emoji-mart.css'
//import { Picker } from 'emoji-mart'

registerPlugin(FilePondPluginImagePreview);

const ChatMessageForm = (props) => {
  const user = useSelector((state) => state.user);
  const selectedGroup = useSelector((state) => state.selectedGroup);
  const [InptMessage, setInptMessage] = useState("");
  const postTxt = createRef();
  const btnAttachment = createRef();
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const [comments, setComments] = useState([{ message: "message" }]);
  const [comment, setComment] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();

  const { groupFeed } = props;

  useEffect(() => {}, []);

  const handleInptMessage = (e) => {
    setInptMessage(e.target.value);
    //props.TypingMessage();
  };
  const handleBtnMessage = () => {
    let messagpost = {
      message: InptMessage,
      files: attachments,
      taggedUsers,
      user_id: user.id,
      role: 'student'
    };

    if (selectedGroup.hasOwnProperty("id")) {
      messagpost.groupId = selectedGroup.id;
    }
    setInptMessage("");
    setFiles([]);
    setAttachments([]);
    setShowFilePond(false);

    if (props.subscription) {
      //props.setGroupFeed((groupFeed) => [...groupFeed, messagpost]);

      props.subscription.emit("message", messagpost);
    } else {
      if (props.initial) {
        createChatGroupService(JSON.stringify({ ...props.initial }), {
          ...messagpost,
        })
          .then((res) => {
            history.push(`/mychats/${res.data.group}`);
          })
          .catch((error) => {});
      }
    }
  };
  const handleAttahment = () => {
    btnAttachment.current.click();
  };
  const handleFiles = async (event) => {
    const ufiles = await event.target.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        // if(filePondRef.current) {
        filePondRef.current
          .addFile(ufiles[0])
          .then((file) => {
          })
          .catch((error) => console.log(error));
        // }
      }
    }
  };
  const handleUploadedFiles = (response) => {
    setAttachments((attachments) => [...attachments, response]);
  };
  const handlePaste = (e) => {
    if (e.clipboardData.types[0] == "Files") {
      if (e.clipboardData.items == undefined) {
        return;
      }

      for (var i = 0; i < e.clipboardData.items.length; i++) {
        // Skip content if not image
        if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = e.clipboardData.items[i].getAsFile();
      }

      setTimeout(() => {
        filePondRef.current.addFile(blob);
      }, 1000);

      setShowFilePond(true);
      handleFilePondInit(e);
    }
  };
  const handleFilePondInit = () => {};
  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  };
  const addEmoji = (emoji) => {
    const appendText = InptMessage + emoji.native;
    setInptMessage(appendText);
  };

  return (
    <>
      <div className="ps-fd-card-head">
        <div className="post-area">
          <div className="post-area-left">
            <div className="post-area-img">
              <div className="post-user-img">
                <img src={user.photo} alt={user.first_name} />
              </div>
              <div className="active-status">
                <i className="fas fa-check"></i>
              </div>
            </div>
          </div>
          <div className="post-area-right">
            <div className="card">
              {/* <div
             className={
               "ps-by-card-header" +
               (props.feed.user.id === user.id ? " mypost" : "")
             }
           >
             <h5>
               <a>{props.feed.user.fullname}</a>
               <span className="post-by-time">{props.feed.created_at}</span>
             </h5>
             <p> {ReactHtmlParser(props.feed.message)}</p>
             {(props.feed.attachments && props.feed.attachments.length) ? <FeedAttachments attachments={props.feed.attachments} /> : null}
           </div> */}
              {/* <div className="ps-by-card-body">
             {comments && comments.length ? (
               <FeedComments
                 comments={null}
                 user={null}
                 online={null}
               />
             ) : null}
           </div> */}

              <div className="textarea-btn-submit">
                <MentionsInput
                  value={InptMessage}
                  onChange={(e) => handleInptMessage(e)}
                  style={defaultStyle}
                  placeholder={"Share something with your class..."}
                  className="mentions"
                >
                  <Mention
                    className="mentions__mention"
                    trigger="@"
                    displayTransform={(username) => `@${username}`}
                    data={props.taggingUsers}
                    style={defaultMentionStyle}
                    appendSpaceOnAdd
                    onAdd={handleUserTagged}
                    markup={"@__display__#"}
                    renderSuggestion={(
                      entry,
                      search,
                      highlightedDisplay,
                      index,
                      focused
                    ) => {
                      return <span className="test">{entry.display}</span>;
                    }}
                  />
                </MentionsInput>

                {/* {props.isTyping ? <div>Someone is typing...</div> : null} */}
                {/* <Picker onSelect={ (emoji)=>addEmoji(emoji) } /> */}

                <button
                  className="btn btn-submit-arrow"
                  onClick={handleAttahment}
                >
                  <i className="fal fa-paperclip"></i>
                </button>
                <div>
                  <input
                    type="file"
                    ref={btnAttachment}
                    style={{ display: "none" }}
                    onChange={(e) => handleFiles(e)}
                  />
                </div>
                {!InptMessage && !files.length ? (
                  <button
                    className="btn btn-submit-arrow"
                    onClick={() => handleBtnMessage()}
                    disabled={true}
                  >
                    <i className="fal fa-paper-plane"></i>
                  </button>
                ) : (
                  <button
                    className="btn btn-submit-arrow"
                    onClick={() => handleBtnMessage()}
                  >
                    <i className="fal fa-paper-plane"></i>
                  </button>
                )}
              </div>
              <div className={!showFilePond ? "hideFilePond" : "showFilePond"}>
                <FilePond
                  name="post"
                  allowMultiple={true}
                  maxFiles={3}
                  ref={filePondRef}
                  files={files}
                  onupdatefiles={setFiles}
                  beforeRemoveFile={(f) => setRemoveFile(f.file.name)}
                  init={handleFilePondInit}
                  onaddfile={(err, fileItem) => {
                    setShowFilePond(true);
                  }}
                  server={{
                    url: API_BASE,
                    process: {
                      url: "/addGroupAttachments",
                      headers: {
                        Authorization: `Bearer ${getToken()}`,
                      },
                      onload: (response) => {
                        handleUploadedFiles(response);
                      },
                    },
                    revert: {
                      url: `/removeGroupAttachments/${removeFile}`,
                      headers: {
                        Authorization: `Bearer ${getToken()}`,
                      },
                      onload: (response) => {
                        setRemoveFile(null);
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ChatMessageForm);
