const Str = {
    detailDes: 'Detailed description and instructions',
    noInstruction: 'No instructions available',
    backAssment: 'Back',
    // backAssment: 'Back to your Assessments',
    Next: 'Next',
    sysCheck: 'System Check',
    Identification: 'Identification',
    launchExam: 'Launch Exam',
    assDetail: 'Assessment Detail',
    acceptSoftMonitor: 'I accept that these items will be monitored via software to ensure there are no additional parties, voices and browser windows present during examination.This will only be viewed by a moderator when/where issues were observed by the software.',
    stuIdentify: 'Student Identification',
    Test: 'Test',
    testAss: 'Test/Assessment',
    noRecord: 'There are no records to show',
    noCamPermission: 'Camera permission not given',
    camPermission: 'Cam permission given',
    quizPopup: 'You are about to launch your assessment which will start the countdown for submission. Please take note of the time you will have to submit your assessment once launched!',
    setFinalPopup: `<p>Do you want to change the submission to Final Status? You will not be able to edit the submission once you do this.</p>`,
    Submit: 'Submit',


    TABLE_DATE_FORMAT :"DD MMM YY",
    FORM_DATE_FORMAT :"d MMM yy",
    TABLE_DATE_TIME_FORMAT :"DD MMM YY, HH:mm",
    FORM_DATE_TIME_FORMAT :"dd MMM yyyy, HH:mm",
    TABLE_TIME_FORMAT :"HH:mm",
    FORM_TIME_FORMAT :"HH:mm",
    NETSUITE_DATE_TIME_FORMAT :"DD-MM-YYYY h:mm A",
};
export default Str;
