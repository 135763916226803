import React, { useEffect, useState } from "react";

export default function DownloadCertificate({
  item,
  canRemove,
  handleRemoveFile,
}) {
  const [fileName, setFileName] = useState(null);
  useEffect(() => {
    const fileSplitArray = item.url.split("/");
    const fileDisplayName = fileSplitArray[fileSplitArray.length - 1];
    setFileName(fileDisplayName);
  }, [item]);

  return (
    <>
     
      <a href={item.file} target="_blank">
      <i className="fal fa-file"></i>  {fileName}
      </a>
      {canRemove ? (
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => handleRemoveFile(item.id)}
          title="Delete"
        >
        <i className="fal fa-trash-alt"></i>
        </button>
      ) : null}
    </>
  );
}
