import React, { useState, useEffect } from "react";
import { listSupportTeamList } from "../../services/StudentHubService";


function StudentSupport(props) {
    const [copyText, setCopyText] = useState([0, 0, 0]);
    const [copyText1, setCopyText1] = useState([0, 0, 0]);
    const [data, setData] = useState([]);

    const copyEmailText = (email, i) => {
        navigator.clipboard.writeText(email);
        let a = [0, 0, 0]
        let b = [0, 0, 0]
        a[i] = 1;
        setCopyText([...a]);
        setTimeout(() => {
            setCopyText([...b])
        }, 1000);
    }

    const copyNumberText = (mobile, i) => {
        navigator.clipboard.writeText(mobile);
        let a = [0, 0, 0]
        let b = [0, 0, 0]
        a[i] = 1;

        setCopyText1([...a]);
        setTimeout(() => {
            setCopyText1([...b])
        }, 1000);
    }

    const getSupportList = () => {
        listSupportTeamList().then((res) => {
            if (res.status === 200) {
                setData(res.data);
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getSupportList()
    }, []);

    return (
        <>
            <div className="header-info-box">
                <h4>Have additional questions?</h4>
                <p>Our team is available to help you with any academic-related queries through the <b>Search Assistance</b> buttons on the sidebar.</p>

                <p>Alternatively, you can also ask for assistance in the relevant Programme/Course's Chatroom.</p>
            </div>
            {/* <div className="card-list-blk st-support">
                <div className="row">
                    {data.map((item,i)=>(<div className="col-md-4 mt-3">
                        <div className="home-link" title="">
                            <div className="card home-bx-card">
                                <div className="home-bx-card-info">
                                    <h3>{item?.Firstname} <span>{item?.Lastname}</span> </h3>
                                    <p>{item?.role_name}</p>
                                    
                                    {item?.Mobile && <p>{item?.Mobile} {copyText1[i] ? (
                                        <i class="fas fa-check-circle cat-greenhex"></i>
                                    ) : (<i
                                        className="fal fa-copy hover-color-blue"
                                        onClick={() => copyNumberText(item?.Mobile,i)}
                                    ></i>)}</p>}
                                    
                                    {item?.Email && <p>{item?.Email} {copyText[i] ? (
                                        <i class="fas fa-check-circle cat-greenhex"></i>
                                    ) : (<i
                                        className="fal fa-copy hover-color-blue"
                                        onClick={() => copyEmailText(item?.Email,i)}
                                    ></i>)}</p>}
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div> */}
        </>
    );
}

export default StudentSupport;
