import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import QualificationShortInfo from "./QualificationShortInfo";
//import randomColor from "randomcolor";
import { download } from "../../utils/FileDownload";
import ShortNameBox from "./ShortNameBox";
import { useDispatch, useSelector } from "react-redux";
import { setIsExamLaunched } from "../../store/actions";
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

const QualificationHeadBox = (props) => {
  const { row } = props;
  const [color, setColor] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { examLaunched } = useSelector(state=>state.examLaunched);
  useEffect(() => {
    //setColor(randomColor());
  }, [props]);
  return (
    <>
      <div className="sub-lft-head">
        <div className="all-courses">
        { history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched 
          ? <Link
            onClick={()=>dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))}
          ><i className="fal fa-angle-left"></i>All Courses
          </Link>
          : <Link to="/mycourses">
            <i className="fal fa-angle-left"></i>All Courses
          </Link>
        }
        </div>
        <div className="couses-bx-info-top d-flex">
          <ShortNameBox qualification={row}></ShortNameBox>
          {/* <div className="icon"  >
            ME
          </div> */}
          <div className="couses-bx-info-right">
            <QualificationShortInfo qualification={row} />
          </div>
        </div>
        {row.timeTable && row?.timeTable != "" && row?.timeTable != null ? (
            <button title="Download Timetable" onClick={() => downloadFile(row.file,row.fileName)} className="btn btn-white-bordered">
            <i className="fal fa-arrow-to-bottom"></i>Download Timetable
          </button>
          ) : null}
      </div>
    </>
  );
};
export default QualificationHeadBox;
