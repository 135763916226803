import React, { Component,createRef } from "react";
import $ from "jquery";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { getMyExam, getMyExamRegistrationForm } from "../services/ExamService";
import ExamLeftBox from "../components/exam/ExamLeftBox";
import ExamRightBox from "../components/exam/ExamRightBox";
import ExamRightBoxReadOnly from "../components/exam/ExamRightBoxReadOnly";
import ExamRightBoxInformation from "../components/exam/ExamRightBoxInformation";

class ExamDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      examListsRow: [],
      examid: this.props.match.params.examid,
      details: "",
      examRightBox: false,
      examRightBoxReadOnly: false,
      examRightBoxInf: true,
    };
    console.error=()=>{}
    console.warn=()=>{}
  }

  componentDidMount() {
    this.handleExamDetails();
    
  }

  handleExamDetails() {
    getMyExam(this.state.examid).then((res) => {
      this.setState({ details: res.data.examList[0] });
    });
  }

  handleExamStatus = (status) => {
    switch (status) {
      case "new_registration":
        this.setState({
          examRightBoxReadOnly: false,
          examRightBox: true,
          examRightBoxInf: false,
        });
        break;
      case "viewed":
        this.setState({
          examRightBoxReadOnly: true,
          examRightBox: false,
          examRightBoxInf: false,
          examid: this.props.match.params.examid,
        });
        this.handleExamDetails();
        break;

      default:
        this.setState({
          examRightBoxInf: true,
          examRightBox: false,
          examRightBoxReadOnly: false,
        });
        break;
    }
  };

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="exam-detail-wrap d-flex  h-100">
              <ExamLeftBox
                // ref={this.reff}
                examid={this.state.examid}
                examListsRow={this.state.details}
                handleParentExamStatus={this.handleExamStatus}
              />
              {this.state.examRightBox ? (
                <ExamRightBox
                  examid={this.state.examid}
                  examdetails={this.state.details}
                  handleParentExamStatus={this.handleExamStatus}
                />
              ) : null}
              {this.state.examRightBoxReadOnly ? (
                <ExamRightBoxReadOnly
                  examid={this.state.examid}
                  examListsRow={this.state.details}
                />
              ) : null}
              {this.state.examRightBoxInf ? (
                <ExamRightBoxInformation
                  content={this.state?.details?.content}
                  examListsRow={this.state?.details}
                />
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ExamDetails;
