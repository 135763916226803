import React from "react";
import FeedBackForm from "./FeedBackForm";

export default function Good(props) {
  return (
    <>
      <div
        className="tab-pane fade"
        id="good"
        role="tabpanel"
        aria-labelledby="good-tab"
      >
        <div className="tab-pane-head">
          <h3>
            <span>Thanks </span> for your rating.
          </h3>
          <p>
            {" "}
            Please let us know how we could have got 5 stars. Is there anything
            we could have improved on?
          </p>
        </div>
        <FeedBackForm rating_type={props.rating_type} />
      </div>
    </>
  );
}
