import axiosInstance from "../utils/axios";


export const GetCampusMgtList = async (data, cancelToken) => {
    return await axiosInstance().post("/getCampusClassesList",data,{cancelToken});
}

export const GetCampusRoomsListCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get("/getCampusRoomsList?campus_id=" + id, {cancelToken: cancelToken});
}

export const GetBookingLinkedCourses = async (id) => {
    return await axiosInstance().get(`/linked-courses/${id}`);
}

export const RequestBooking = async (values) => {
    return await axiosInstance().post(`/createBooking`, values);
};

export const ListBookings = async (
    userId = 0,
    date = 'month',
  ) => { 
    return await axiosInstance().get(`/listBookings?userId=${userId}?calendarView=${date}`);
  };


export const ListUpcomingBookings = async (
    userId = 0,
    status = 'upcoming'
  ) => { 
    return await axiosInstance().get(`/listUpcomingBookings?userId=${userId}&status=${status}`);
  };



