import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Swal from "sweetalert2";
import moment from "moment";
import { GetCampusMgtList, GetCampusRoomsListCancelToken, GetBookingLinkedCourses, RequestBooking } from '../services/BookingServices';

const validationSchema = Yup.object({
    name: Yup.string()
        .strict(true)
        .required('Name is required')
        .trim('Leading and ending trail not allowed in name'),
    booking_date: Yup.date().strict(true).required('Date is required'),
    booking_start_time: Yup.date()
        .strict(true)
        .required('Start Time is required'),
    booking_end_time: Yup.date()
        .strict(true)
        .required('End Time is required')
        .test('end-time-after-start-time', 'End Time must be after Start Time', function (value) {
            const { booking_start_time } = this.parent;
            return value && booking_start_time && new Date(value) > new Date(booking_start_time);
        }),
    campus: Yup.string()
        .min(1, 'Select at least one option')
        .required('Campus is required'),
    room_no: Yup.string()
        .min(1, 'Select at least one option')
        .required('Room is required'),
});

export default function BookingModal({ show, onClose}) {

    const user = useSelector((state) => state.user);
    const { id } = user;
    const user_role = 'Student';
    const history = useHistory();

    //console.log(user);

    //console.log("------", bookingDetails)

    const initialValues = {

        name: "",
        campus: "",
        campus_id: 0,
        room_no: "",
        booking_date: "",
        booking_start_time: "",
        booking_end_time: "",
        linked_course: "",
        linked_course_id: 0,
        booking_reason: "",
        request_type: "student",
        booking_for: [id],
        additional_information: ""
    }

    //console.log("------", initialValues);

    const [campusArr, setCampusArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [campusId, setCampusId] = useState(initialValues.campus_id ?? '');
    const [campusRooms, setCampusRooms] = useState([]);
    const [linkedCourses, setLinkedCourses] = useState([]);
    const [requestType, setRequestType] = useState(initialValues.request_type);
    const cancelTokenSources = useRef([]);

    useEffect(() => {
        getData();
        return () => {
            cancelTokenSources.current.forEach((source) => {
                source.cancel('Component unmounted');
            });
        };
    }, []);

    // const getData = useCallback(async () => {
    //     cancelTokenSources.current.forEach((source) => {
    //         source.cancel('New request made');
    //     });

    //     const source = axios.CancelToken.source();
    //     cancelTokenSources.current.push(source);

    //     try {
    //         let res = await GetCampusMgtList(source.token);
    //         if (res.status === 200) {
    //             let tempCampusArr = res.data.data_list.map((data) => ({
    //                 value: `${data.id}`,
    //                 label: `${data.campus_name}`,
    //             }));
    //             setCampusArr(tempCampusArr);
    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         if (!axios.isCancel(error)) {
    //             console.error(error);
    //         }
    //     }
    // }, []);

    const getData = async () => {
        const source = axios.CancelToken.source();
        cancelTokenSources.current.push(source);
    
        try {
            let res = await GetCampusMgtList(source.token);
            if (res.status === 200) {
                let tempCampusArr = res.data.data_list.map((data) => ({
                    value: data.id,
                    label: data.campus_name,
                }));
                setCampusArr(tempCampusArr);
                setIsLoading(false);
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error(error);
            }
        }
    };


    // useEffect(() => {
    //     const getRoomsByCampusId = async () => {
    //         try {
    //             if (campusId) {
    //                 const campusRoomsResponse = await GetCampusRoomsListCancelToken(
    //                     campusId
    //                 );
    //                 const rooms = campusRoomsResponse.data.data_list.map((room) => {
    //                     return {
    //                         value: room.room_name,
    //                         label: room.room_name,
    //                         id: room.id
    //                     };
    //                 });
    //                 setCampusRooms(rooms);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     getRoomsByCampusId();
    // }, [campusId]);

    useEffect(() => {
        const getRoomsByCampusId = async () => {
            try {
                if (campusId) {
                    const campusRoomsResponse = await GetCampusRoomsListCancelToken(campusId);
                    const rooms = campusRoomsResponse.data.data_list.map((room) => ({
                        value: room.room_name,
                        label: room.room_name,
                        id: room.id
                    }));
                    setCampusRooms(rooms);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getRoomsByCampusId();
    }, [campusId]);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        //onSubmit: (values) => createBooking(values)
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await createBooking(values);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    // useEffect(() => {
    //     const getLinkedCourses = async () => {
    //         try {
    //             if (id != 0) {
    //                 const response = await GetBookingLinkedCourses(id);
    //                 const courses_list = response.data?.data;
    //                 const linked_courses = courses_list.map((course) => {
    //                     return ({
    //                         label: course.course_name,
    //                         value: course.intake_id,
    //                     })
    //                 })
    //                 setLinkedCourses(prevCourses => [...prevCourses, ...linked_courses]);
    //             }
    //         }
    //         catch (error) {
    //             console.log('error', error);
    //         }
    //     }

    //     getLinkedCourses();

    // }, [id])

    useEffect(() => {
        const getLinkedCourses = async () => {
            try {
                if (id != 0) {
                    const response = await GetBookingLinkedCourses(id);
                    const courses_list = response.data?.data;
                    const linked_courses = courses_list.map((course) => ({
                        label: course.course_name,
                        value: course.intake_id,
                    }));
                    setLinkedCourses(linked_courses);
                }
            } catch (error) {
                console.log('error', error);
            }
        };
        getLinkedCourses();
    }, [id]);

    if (!show) {
        return null;
    }

    const bookingReason = [{
        label: 'Study Session', value: 'Study Session'
    }, {
        label: 'Class', value: 'Class'
    }, {
        label: 'Exam', value: 'Exam'
    }, {
        label: 'Assessment', value: 'Assessment'
    }, {
        label: 'Practical Task', value: 'Practical Task'
    }, {
        label: 'Practical Assessment', value: 'Practical Assessment'
    }, {
        label: 'Other', value: 'Other'
    }]

    const handleSelectedRoom = (selectedRooms) => {

        if (selectedRooms) {
            const room = campusRooms.find((room) => room.value === selectedRooms.value);
            formik.setFieldValue('room_no', room?.value);
            formik.setFieldValue('room_id', room?.id);
        }
    }


    const createBooking = async (values) => {
        try {
            const {
                name,
                campus,
                campus_id,
                room_no,
                booking_date,
                booking_start_time,
                booking_end_time,
                linked_course,
                linked_course_id,
                booking_reason,
                request_type,
                booking_for,
                additional_information,
                room_id
            } = values;

            const localTimeZone = moment.tz.guess();
            const serverTimeZone = 'Africa/Johannesburg'; // SA time zone
        
            const localDate = moment(booking_date).tz(localTimeZone).startOf('day');
            const formattedDate = localDate.format('YYYY-MM-DD');
        
            const startDateTime = moment.tz(`${localDate.format('YYYY-MM-DD')} ${moment(booking_start_time).format('HH:mm:ss')}`, localTimeZone);
            const endDateTime = moment.tz(`${localDate.format('YYYY-MM-DD')} ${moment(booking_end_time).format('HH:mm:ss')}`, localTimeZone);
        
            const formattedStartTime = startDateTime.tz(serverTimeZone).format('HH:mm:ss');
            const formattedEndTime = endDateTime.tz(serverTimeZone).format('HH:mm:ss');

            const formData = new FormData();

            formData.append('name', name);
            formData.append('campus', campus);
            formData.append('campus_id', campus_id);
            formData.append('room_no', room_no);
            formData.append('room_id', room_id);
            formData.append('booking_date', formattedDate);
            formData.append('booking_start_time', formattedStartTime);
            formData.append('booking_end_time', formattedEndTime);
            formData.append('linked_course', linked_course);
            formData.append('linked_course_id', linked_course_id);
            formData.append('booking_reason', booking_reason);
            formData.append('request_type', request_type);
            formData.append('booking_for', booking_for);
            formData.append('user_id', id);
            formData.append('role_name', user_role);
            formData.append('is_user_student', 1)
            formData.append('additional_information', additional_information);

            await RequestBooking(formData);
            Swal.fire({
                title: 'Success',
                text: 'Booking Request Submitted Successfully',
                icon: 'success'
              }).then(() => {
                window.location.reload();
              });
          
              document.addEventListener('click', () => {
                window.location.reload();
            }, { once: true });

        }
        catch (error) {

            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error'
            });
        }
    }

    return (
        <div className="modal-overlay">
            {/* <div className="modal-content"> */}

            <div className="booking-modal-content">
                <div className="modal-header modal-header-booking">
                    <h5 className="modal-title">
                        <i className="fal fa-calendar"></i>&nbsp; Schedule Booking
                    </h5>
                    <button className="close" onClick={onClose}>
                        <i className='fal fa-times'></i>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="t p-10px">
                            <div className="topic-add-modal-content">
                                <div className="row center-label">
                                    <div className="col-md-3">
                                        <div className="form-group-blk mb-3">
                                            <label>Name *</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="Name"
                                                    id="Name"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.name && formik.touched.name
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    name="name"
                                                    placeholder="Enter Name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    title="Name"
                                                />
                                                {formik.errors.Name && formik.touched.Name ? (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>

                                        <label>Date *</label>
                                        <div className="form-icon-group mb-4">
                                            <div className="datepicker-wrapper-class">
                                                <DatePicker
                                                    id="booking_date"
                                                    name="booking_date"
                                                    selected={formik.values.booking_date}
                                                    onChange={date => formik.setFieldValue('booking_date', date)}
                                                    onBlur={formik.handleBlur}
                                                    dateFormat="yyyy/MM/dd"
                                                    className={`form-control ${formik.touched.booking_date && formik.errors.booking_date ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            {formik.errors.booking_date && formik.touched.booking_date ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='col-md-3'>

                                        <label>Start Time *</label>
                                        <div className="form-icon-group mb-4">
                                            <div className={'datepicker-wrapper-class'}>
                                                <DatePicker
                                                    id="booking_start_time"
                                                    name="booking_start_time"
                                                    selected={formik.values.booking_start_time}
                                                    onChange={date => formik.setFieldValue('booking_start_time', date)}
                                                    onBlur={formik.handleBlur}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    className={`form-control ${formik.touched.booking_start_time && formik.errors.booking_start_time ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            {formik.errors.booking_start_time && formik.touched.booking_start_time ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='col-md-3'>

                                        <label>End Time *</label>
                                        <div className="form-icon-group mb-4">
                                            <div className={'datepicker-wrapper-class'}>
                                                <DatePicker
                                                    id="booking_end_time"
                                                    name="booking_end_time"
                                                    selected={formik.values.booking_end_time}
                                                    onChange={date => formik.setFieldValue('booking_end_time', date)}
                                                    onBlur={formik.handleBlur}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    className={`form-control ${formik.touched.booking_end_time && formik.errors.booking_end_time ? 'is-invalid' : ''}`}
                                                />
                                            </div>
                                            {formik.errors.booking_end_time && formik.touched.booking_end_time ? (
                                                <span className="exclamation">
                                                    <i className="fal fa-exclamation-circle"></i>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group-blk mb-3">
                                            <label>Linked Course</label>
                                            <div className="form-icon-group mb-4">
                                                <Select
                                                    className={
                                                        'form-control custom-select-box' +
                                                        (formik.errors.linked_course && formik.touched.linked_course
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    name="linked_course"
                                                    value={formik.values.linked_course}
                                                    onChange={(selectedCourse) => {
                                                        if (selectedCourse && requestType !== 'other') {
                                                            formik.setFieldValue('linked_course', selectedCourse.label);
                                                            formik.setFieldValue('linked_course_id', selectedCourse.value);
                                                        }
                                                        else {
                                                            formik.setFieldValue('linked_course', '');
                                                            formik.setFieldValue('linked_course_id', 0);

                                                        }
                                                    }}
                                                    isClearable
                                                    onBlur={formik.handleBlur}
                                                    options={linkedCourses}
                                                    maxMenuHeight={175}
                                                    placeholder={
                                                        formik.values.linked_course
                                                            ? formik.values.linked_course
                                                            : 'Select Course'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>

                                        <label>Campus *</label>
                                        <div className="form-icon-group mb-4">
                                            <Select
                                                className={
                                                    'form-control custom-select-box' +
                                                    (formik.errors.campus && formik.campus
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                                name="campus"
                                                value={formik.values.campus}
                                                onChange={(selectedCampus) => {
                                                    if (selectedCampus) {
                                                        setCampusId(selectedCampus.value);
                                                        formik.setFieldValue('campus', selectedCampus.label);
                                                        formik.setFieldValue('campus_id', selectedCampus.value);
                                                    }
                                                    else {
                                                        formik.setFieldValue('campus', '');
                                                        formik.setFieldValue('campus_id', 0);
                                                    }
                                                }}
                                                isClearable
                                                onBlur={formik.handleBlur}
                                                options={campusArr}
                                                maxMenuHeight={175}
                                                placeholder={
                                                    formik.values.campus
                                                        ? formik.values.campus
                                                        : 'Select Campus'
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-3'>

                                        <label>Room *</label>
                                        <div className="form-icon-group mb-4">
                                            <Select
                                                className={
                                                    'form-control custom-select-box' +
                                                    (formik.errors.room_no && formik.room_no ? ' is-invalid' : '')
                                                }
                                                name="room_no"
                                                value={formik.values.room_no}

                                                onChange={(selectedRoom) => {
                                                    handleSelectedRoom(selectedRoom)
                                                }}
                                                isClearable
                                                onBlur={formik.handleBlur}
                                                options={campusRooms}
                                                maxMenuHeight={175}
                                                placeholder={
                                                    formik.values.room_no ? formik.values.room_no : 'Select Room'
                                                }
                                            />
                                        </div>

                                    </div>

                                    <div className='col-md-3'>

                                        <label>Booking Reason</label>
                                        <div className="form-icon-group mb-4">
                                            <Select
                                                className={
                                                    'form-control custom-select-box' +
                                                    (formik.errors.booking_reason && formik.booking_reason
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                                name="booking_reason"
                                                value={formik.values.booking_reason || null}
                                                onChange={(reason) => {
                                                    formik.setFieldValue('booking_reason', reason.value);
                                                }}
                                                isClearable
                                                onBlur={formik.handleBlur}
                                                options={bookingReason}
                                                maxMenuHeight={175}
                                                placeholder={
                                                    formik.values.booking_reason
                                                        ? formik.values.booking_reason
                                                        : 'Select Reason'
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-12">
                                        <div className="form-group-blk mb-3">
                                            <label htmlFor="link">Additional Information </label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="AdditionalInformation"
                                                    id="AdditionalInformation"
                                                    className={
                                                        'form-control' +
                                                        (formik.errors.additional_information &&
                                                            formik.touched.additional_information
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                    name="additional_information"
                                                    placeholder="Enter Additional Information"
                                                    value={formik.values.additional_information}
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            'additional_information',
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    title="AdditionalInformation"
                                                />
                                                {formik.errors.additional_information &&
                                                    formik.touched.additional_information ? (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="form-group form-group-save-cancel mt-3">
                                <button
                                    className="btn btn-primary"
                                    title="Save"
                                    type='submit'
                                    // onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    {formik.isSubmitting ? (
                                        <i className="fas fa-cog fa-spin"></i>
                                    ) : (
                                        <i className="fal fa-calendar-plus"></i>
                                    )}{' '}
                                    Submit Request
                                </button>
                                <button
                                    className="btn btn-close btn-danger"
                                    type="button"
                                    title="Cancel"
                                    onClick={() => {
                                        formik.resetForm();
                                        onClose();
                                    }}
                                >
                                    <i className="fal fa-times"></i>Cancel Request
                                </button>
                                {Object.keys(formik.values).map(key => {
                                    if (formik.touched[key] && formik.errors[key]) {
                                        return (
                                            <div className="invalid-feedback d-block" key={key}>
                                                {formik.errors[key]}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
