import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player'
export default function Player(props) {
return (
<>
<div className="sidebar-right">
    <div className="vidbox">
        {
            props.videoType=='vimeo' ?(
            <ReactPlayer url={`https://player.vimeo.com/video/${props.videoId}`}  
            controls={true}
            className="react-player"
            width='100%'
            height='80%'/>  ):
            (
            <ReactPlayer url={`https://www.youtube.com/watch?v=${props.videoId}`}
            controls={true}
            className="react-player"
            width='100%'
            height='80%'
            youtube="youtube"/>)
        }
    </div>    
</div>   
</>
);
}
