import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import SkeletonTicketList from '../../loaders/SkeletonTicketList';
import Str from '../common/Str';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { GetAccountStatements, GetStatement } from '../../services/ProfileService';
import { BASE_URL } from '../../utils/Constants';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

export default function AccountStatement() {
    const [statements, setStatements] = useState([])
    const [search, setSearch] = useState("");
    const [renderTable, setRenderTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("statement_name");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [refresh, setRefresh] = useState(false);
    const user = useSelector(state => state.user);


    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };
    const handleSort = (column, sortDirection) => {        
        setSortKey(column.selector);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    useEffect(() =>{
        const payload = {
            key: sortkey,
            sort: sortOrder,
            search:search,
            page: page,
            limit: perPage, 
        }
        setRenderTable(false);
        GetAccountStatements(payload).then((res)=>{
            if(res.status === 200){
                console.log(res.data.data.data);
                const respData = res.data.data;
                setStatements(respData?.data);
                setTotalRows(respData?.total)

            }
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{
            setRenderTable(true);
        })
    },[page, perPage, search, sortkey, sortOrder, refresh])

    const columns = [
        {
            name: "Name",
            selector: "statement_name",
            sortable: true,
            wrap: true,
            cell: (row) => (row?.url && row?.statement_name ?
            <div class="assessment-01">
                <p class="as-text-blue">
                    <span className="overflow-ellipsis">
                        <a href={BASE_URL +  (row?.url).replace("/home/myaie/public_html","").replace("public", "")} target="_blank" title={row.statement_name}>
                            <i className="fal fa-file"></i>{row.statement_name}
                        </a>
                    </span>
                </p>
            </div>
            : "-")
        },
        {
            name: "Date Generated",
            selector: "generated_date",
            sortable: true,
            cell: (row) =>(row?.generated_date ? moment.unix(row?.generated_date).format("DD MMM YYYY hh:mm a") : "-")
        },
        {
            name: "Action",
            sortable: false,
            cell: (row) => (
                <div className="assessment-08 advSearch-action-con">
                    <div className="as-buttons">
                        <a
                            href={BASE_URL +  (row?.url).replace("/home/myaie/public_html","").replace("public", "")}
                            target="_blank"
                            className="btn btn-primary rounded-circle view-btn"
                            title="View"
                        >
                            <i className="fal fa-eye"></i>
                        </a>
                    </div>
                </div>
            ),
        },
    ];

    const resetFilter = () => {
        setSearch("")
    }

    const handleSearchFilter = (e) => {
        setSearch(e.target.value);
    }

    const handleGenerateStatement = () =>{
        setLoading(true)
        const today = new Date();
        const priorDate = new Date(new Date().setDate(today.getDate() - 90));

        const payload = {
            date : moment(priorDate).format("DD/MM/YYYY"),
            customerId: user.netsuite_id
        }
        
        GetStatement(payload).then((res)=>{
            if(res.status === 200){
                const fileURL = BASE_URL +  (res.data.url).replace("/home/myaie/public_html","").replace("public", "");
                window.open(fileURL, "_blank");
                setRefresh(!refresh)
            }
        }).catch((err)=>{            
            Swal.fire({
                icon: "error",
                title: "Statement can't be generated",
                text: err.response.data.message,
            });
        }).finally(() =>{
            setLoading(false);
        })
    }

    return (
        <div className='row'>
            <div className="col-12">
                <div className='account-statement-header'>
                    Account Statements
                </div>
                <div className="custom-table-div filter-search-icon card card-table-custom">
                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_filter" className="dataTables_filter">
                                            <label for="filterDataTable" className="icon"></label>
                                            <input
                                                type="text"
                                                name="filterDataTable"
                                                className="filter-text"
                                                placeholder="Search"
                                                onChange={handleSearchFilter}
                                                value={search}
                                            />
                                            <div className="filter-eff filter-data-btn">
                                                <button className="filter-buttons">
                                                    <i className="fal fa-filter"></i>
                                                </button>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group">
                                    <div className="reset-btn-group">
                                        <div className="button-reset">
                                            <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                                <i className="fal fa-redo"></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search-filter-div-right">
                            <div className="filter-search-bar-blk">
                                <div className="add-ticket-blk button-reset">
                                    <button disabled={loading} className="btn btn-primary" title="Generate Statement" onClick={() =>{handleGenerateStatement()}}>
                                        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-invoice"></i>} Generate Statement
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>                
                    <DataTable
                        columns={columns}
                        data={statements}
                        pagination
                        paginationServer
                        defaultSortField="statement_name"
                        defaultSortAsc={false}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        onSort={handleSort}
                        paginationTotalRows={totalRows}
                        progressPending={!renderTable}
                        progressComponent={<SkeletonTicketList />}
                    />
                </div> 
            </div>
        </div>
    )
}
