import React from 'react';
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function SkeletonExamRightBox(){
    return (
        <div className="exam-detail-rgt">
            <div className="exam-detail-top d-flex justify-content-between">
                    <div className="exam-detail-top-left">
                        <div className="exam-bx-info  d-flex">
                                <div className="icon">
                                        ME
                                </div>
                                <div className="exam-bx-info-rgt">
                                        <h3>External Exam Registration Form</h3>
                                        <p>Register here for your DoE National Exams. Please ensure that you have completed all the required fields</p>
                                </div>
                        </div>
                    </div>
                    {/* <div className="exam-detail-top-right">
                        <a href="exam-list.html">Back</a>
                    </div>		 */}
            </div>

            <div className="exam-register-bx">
            <div className="card card-form-bx">
				<div className="card-header">
					Application Comments
				</div>

                <Skeleton width={983} height={300} /> 

            </div>
            </div>

        </div>
    );
}