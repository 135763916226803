import React from "react";
import FeedBackForm from "./FeedBackForm";

export default function Average(props) {
  return (
    <>
      <div
        className="tab-pane fade"
        id="poor"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <div className="tab-pane-head">
          <h3>
            <span>Thanks </span> for your rating.
          </h3>
          <p>
            {" "}
            Your opinion matters to us. We would love to resolve the issue.
            Please give the CEO more details in order to resolve speedily.
          </p>
        </div>
        <FeedBackForm rating_type={props.rating_type} />
      </div>
    </>
  );
}
