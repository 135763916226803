import React, { useEffect, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from "jquery";

// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetBanner } from "../../services/HomeService";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import slide1 from "../../assets/images/slide-1.png";
import slide2 from "../../assets/images/slide-2.png";
import slide3 from "../../assets/images/slide-3.png";
import slide4 from "../../assets/images/slide-4.png";

SwiperCore.use([Navigation, Pagination]);
const HomeSlider = (props) => {
  // return (
  //   <div className="home-slider-wraper">
  //     <OwlCarousel
  //       className="owl-theme home-slider owl-carousel"
  //       loop
  //       margin={12}
  //       dots
  //       items={2}
  //     >
  //       <div className="item">
  //         <div className="home-slider-item">
  //           <div className="desktop-item">
  //             <img
  //               src={require("../../assets/images/slider-1-old.jpg")}
  //               alt=""
  //               className="desktop-img"
  //             />
  //           </div>
  //           <div className="mobile-item">
  //             <img
  //               src={require("../../assets/images/mobile-slide1.jpg")}
  //               alt=""
  //               className="mobile-img"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="item">
  //         <div className="home-slider-item">
  //           <div className="desktop-item">
  //             <img
  //               src={require("../../assets/images/slider-2-old.jpg")}
  //               alt=""
  //               className="desktop-img"
  //             />
  //           </div>
  //           <div className="mobile-item">
  //             <img
  //               src={require("../../assets/images/mobile-slide2.jpg")}
  //               alt=""
  //               className="mobile-img"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="item">
  //         <div className="desktop-item">
  //           <img
  //             src={require("../../assets/images/slider-1-old.jpg")}
  //             alt=""
  //             className="desktop-img"
  //           />
  //         </div>
  //         <div className="mobile-item">
  //           <img
  //             src={require("../../assets/images/mobile-slide1.jpg")}
  //             alt=""
  //             className="mobile-img"
  //           />
  //         </div>
  //       </div>
  //     </OwlCarousel>
  //   </div>
  // );

  const [banner, setBanner] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);

  let keyIndicator = 10;

  useEffect(() => {
    let domain ;
        if(window.location.hostname.includes("studentportal.devtechnosys.info") || window.location.host.includes("localhost:3000")){
          domain = "aie"
        }else if(window.location.hostname.includes("mygdc.ac") || window.location.host.includes("localhost:3001")){
          domain = "gdc"
        }else if(window.location.hostname.includes("myemendy.ac") || window.location.host.includes("localhost:3001")){
          domain = "emendy"
        }
    let mounted = true;
    GetBanner({domain:domain})
      .then((res) => {
        if (mounted) {
        setBanner(res.data);
        
          if (res.data.length > 0) {
            switch (res.data.length) {
              case 1:
                setBannerImg([100]);
                break;

              case 2:
                setBannerImg([50, 50]);
                break;

              case 3:
                setBannerImg([50, 25, 25]);
                break;

              case 4:
                setBannerImg([50, 25, 25, 50]);
                break;

              default:
                setBannerImg([100]);
                break;
            }
          }
        }
      })
      .catch(function (err) {
        return false;
      });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const bannerLength = banner.length;
  return (
    <div className="home-slider-wraper">
      
        <Swiper
          spaceBetween={10}
          slidesPerView={"auto"}
          observer={"true"}
          watchOverflow={"true"}
          onSlideChange={() => {}}
          //onSwiper={(swiper) => ()}
          centeredSlides={false}
          className="swiper-home"
          pagination={{ clickable: true }}
        >
          {bannerLength == 1 && (
            <SwiperSlide
              className="swiper-slide w-100"
              style={{
                backgroundImage: `url('${banner[0].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[0].banner_heading}</h4>
                  <p className="text-max-80">{banner[0].banner_subject}</p>
                  {banner[0].banner_url != "" ? (
                    <a
                      href={banner[0].banner_url}
                      target="_blank"
                      className="btn btn-white-bordered  btn-learn-more"
                    >
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
          )}
          {bannerLength == 2 && (
            <React.Fragment>
              <SwiperSlide
                className="swiper-slide w-50"
                style={{
                  backgroundImage: `url('${banner[0].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[0].banner_heading}</h4>
                    <p className="text-max-80">{banner[0].banner_subject}</p>
                    {banner[0].banner_url != "" ? (
                      <a
                        href={banner[0].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-50"
                style={{
                  backgroundImage: `url('${banner[1].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[1].banner_heading}</h4>
                    <p>{banner[1].banner_subject}</p>
                    {banner[1].banner_url != "" ? (
                      <a
                        href={banner[1].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
            </React.Fragment>
          )}
          {bannerLength == 3 && (
            <React.Fragment>
              <SwiperSlide
                className="swiper-slide w-50"
                style={{
                  backgroundImage: `url('${banner[0].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[0].banner_heading}</h4>
                    <p className="text-max-80">{banner[0].banner_subject}</p>
                    {banner[0].banner_url != "" ? (
                      <a
                        href={banner[0].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-25"
                style={{
                  backgroundImage: `url('${banner[1].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[1].banner_heading}</h4>
                    <p>{banner[1].banner_subject}</p>
                    {banner[1].banner_url != "" ? (
                      <a
                        href={banner[1].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-25"
                style={{
                  backgroundImage: `url('${banner[2].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[2].banner_heading}</h4>
                    <p>{banner[2].banner_subject}</p>
                    {banner[2].banner_url != "" ? (
                      <a
                        href={banner[2].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
            </React.Fragment>
          )}
          {bannerLength == 4 && (
            <React.Fragment>
              <SwiperSlide
                className="swiper-slide w-50"
                style={{
                  backgroundImage: `url('${banner[0].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[0].banner_heading}</h4>
                    <p className="text-max-80">{banner[0].banner_subject}</p>
                    {banner[0].banner_url != "" ? (
                      <a
                        href={banner[0].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-25"
                style={{
                  backgroundImage: `url('${banner[1].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[1].banner_heading}</h4>
                    <p>{banner[1].banner_subject}</p>
                    {banner[1].banner_url != "" ? (
                      <a
                        href={banner[1].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-25"
                style={{
                  backgroundImage: `url('${banner[2].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[2].banner_heading}</h4>
                    <p>{banner[2].banner_subject}</p>
                    {banner[2].banner_url != "" ? (
                      <a
                        href={banner[2].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide w-50"
                style={{
                  backgroundImage: `url('${banner[3].banner_img}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  minHeight: "190px",
                }}
              >
                <div className="home-slider-inr">
                  <div className="home-slider-content">
                    <h4 className="heading">{banner[3].banner_heading}</h4>
                    <p className="text-max-80">{banner[3].banner_subject}</p>
                    {banner[3].banner_url != "" ? (
                      <a
                        href={banner[3].banner_url}
                        target="_blank"
                        className="btn btn-white-bordered  btn-learn-more"
                      >
                        Learn More
                      </a>
                    ) : null}
                  </div>
                </div>
              </SwiperSlide>
            </React.Fragment>
          )}
        </Swiper>
      
    </div>
  );

  // return (
  //   <>
  //     {banner && (
  //       <div className="home-slider-wraper" key={keyIndicator}>
  //         <Swiper
  //           spaceBetween={10}
  //           slidesPerView="auto"
  //           slidesPerColumn={1}
  //           observer={'true'}
  //           watchOverflow={'true'}
  //           updateOnWindowResize={'true'}
  //           //onSlideChange={() => console.log("slide change")}
  //           onSwiper={(swiper) => $(window).resize()}
  //           centeredSlides={false}
  //           className="swiper-home"
  //           pagination={{ clickable: true }}
  //         >
  //           {banner.map((item, index) => {
  //             keyIndicator = index;
  //             return (
  //               <SwiperSlide
  //                 key={index}
  //                 className={`swiper-slide w-${bannerImg[index]}`}
  //                 style={{
  //                   backgroundImage: `url('${item.banner_img}')`,
  //                   backgroundRepeat: "no-repeat",
  //                   backgroundSize: "cover",
  //                   minHeight: "190px"
  //                 }}
  //               >
  //                 <div className="home-slider-inr">
  //                   <div className="home-slider-content">
  //                     <h4 className="heading">
  //                       {item.banner_heading}
  //                     </h4>
  //                     <p>{item.banner_subject}</p>
  //                     {item.banner_url != ''?
  //                     <a
  //                     href={item.banner_url}
  //                     target="_blank"
  //                     className="btn btn-white-bordered  btn-learn-more"
  //                   >
  //                     Learn More
  //                   </a>
  //                   : null }
  //                   </div>
  //                   {/* <div className="home-slider-image">
  //               <img src={item.banner_img} />
  //             </div> */}
  //                 </div>
  //               </SwiperSlide>
  //             );
  //           })}
  //         </Swiper>
  //       </div>
  //     )}
  //   </>
  // );
};

export default HomeSlider;
