import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import notification_right_img from "../../assets/images/notification_right_img.svg";
import { download } from "../../utils/FileDownload";
export default function NotificationRightBox(props) {
  const { notificationContent } = props;
  const { notificationAttachment } = props;
  const [downloadLoader, setDownloadLoader] = useState(0);

  const downloadFile = async (e,absolutePath, fileName, index) => {
    e.preventDefault();
    let value = await download(absolutePath, fileName);
    setDownloadLoader(index);

    setTimeout(() => {
      if (value) setDownloadLoader("0");
    }, 1000);
  };

  function removeHiddenChars(text) {
    try {
      var input = text;
      var output = "";
      for (var i = 0; i < input.length; i++) {
        if (input.charCodeAt(i) <= 127) {
          output += input.charAt(i);
        } else {
          output += " ";
        }
      }
      return output;
    } catch (error) {
      return "";
    }
  }

  return (
    <>
      {notificationContent && Object.keys(notificationContent).length ? (
        <div className="notfication-detail">
          <div className="notfication-detail-head d-flex">
            <div className="notfication-head-lft">
              <div className="icon">{notificationContent && notificationContent.subject && notificationContent.subject[0]}</div>
            </div>
            <div className="notfication-head-rgt">
              <h3>
                {removeHiddenChars(notificationContent && notificationContent.subject && notificationContent.subject)}
                <span className="attachment-detail">
                  {notificationContent && notificationContent.isFile && notificationContent.isFile ? (
                    <a href="" className="attachment">
                      <i className="fal fa-paperclip"></i>
                    </a>
                  ) : null}

                  {notificationContent.ish_importanceActive == 1 ? (
                    <a href="" className="alert-icon">
                      <i className="fal fa-exclamation-square"></i>
                    </a>
                  ) : (
                    ""
                  )}
                </span>
              </h3>
              <p>{notificationContent && notificationContent.expiredTime && notificationContent.expiredTime}</p>
              <p className="blue-text">{notificationContent && notificationContent.details && notificationContent.details}</p>
            </div>
          </div>
          <div className="notfication-detail-body">
            <div className="card">
              {notificationAttachment && notificationAttachment.length > 0 ? (
                <div className="attachment-wrap">
                  <strong>Attachment</strong>
                  <div className="attachment-row d-flex flex-wrap">
                    {notificationAttachment.map((attachment, index) => {
                      let keyValue = `key_${index}`;
                      return (
                        <div className="attachment-col" key={index}>
                          <div className="attach-bx">
                            <div className="attach-icon">
                              <i className={`fal ${attachment.icon}`}></i>
                            </div>
                            <div className="attach-detail" title="Download">
                              <p>{attachment.fileName}</p>
                              <a
                                key={index}
                                href=""
                                className="btn btn-white-bordered"
                                onClick={(e) =>
                                  downloadFile(
                                    e,
                                    attachment.absolutePath,
                                    attachment.fileName,
                                    keyValue
                                  )
                                }
                              >
                                <i className="fal fa-arrow-to-bottom"></i>
                                Download 
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <hr/>
                </div>
              ) : 
              notificationContent && notificationContent.fileName && notificationContent.fileName.trim() !='' && notificationContent.fileName!=0 ? (
                <div className="attachment-wrap">
                <strong>Attachment</strong>
                <div className="attachment-row d-flex flex-wrap">
                  <div className="attachment-col" key={notificationContent.id}>
                      <div className="attach-bx">
                        <div className="attach-icon">
                          <i className={`fal ${notificationContent.icon}`}></i>
                        </div>
                        <div className="attach-detail">
                          <p>{notificationContent.fileName}</p>
                          <a
                            key={notificationContent.id}
                            href=""
                            className="btn btn-white-bordered"
                            onClick={(e) =>
                              downloadFile(
                                e,
                                notificationContent.absolutePath,
                                notificationContent.fileName,
                                'key_'+notificationContent.id
                              )
                            }
                          >
                            <i className="fal fa-arrow-to-bottom"></i>
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                 </div>
                ):null}
              {ReactHtmlParser(removeHiddenChars(notificationContent && notificationContent.content && notificationContent.content))}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-notification">
          <div className="no-img">
            <img src={notification_right_img} alt="Select an item to read" />
          </div>
          <div className="no-content">
            <h4>Select an item to read</h4>
            <p>Nothing is selected</p>
          </div>
        </div>
      )}
    </>
  );
}
