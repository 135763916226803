import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsExamLaunched, setSubject } from '../../store/actions';
import { subjectDuration, getSubjectStatus } from "../../utils/commonFunction.js";
import { Link, useHistory } from "react-router-dom";
import { shortenText } from '../../utils/commonFunction.js';
import { ADMIN_PASSWORD } from "../../utils/Constants";
import Swal from 'sweetalert2';
import Str from '../common/Str';
import moment from 'moment';
const SubjectListBox = (props) => {
    const { rows, active, qid, isAdmin, changeSub } = props;
    const dispatch = useDispatch();
    const [subjectId, setSubjectId] = useState(0)
    const history = useHistory();
    const { examLaunched } = useSelector(state=>state.examLaunched); 

    const handleSelectSubject = (sid) => {
        // if (props.isExamLaunched) {
        //     if (props.isExamLaunched) {
        //         Swal.fire({
        //             title: 'Are you sure you want to exit?',
        //             text: "If you leave the tab, it will change to final!",
        //             type: 'warning',
        //             showCancelButton: true,
        //             confirmButtonColor: '#3085d6',
        //             cancelButtonColor: '#d33',
        //             confirmButtonText: 'Leave'
        //         }).then(function (result) {
        //             if (result.isConfirmed) {
        //                 props.setIsExamLaunchedToFalse()
        //                 props.parentGetSubContentMethod(sid);
        //                 if (subjectId !== sid) {
        //                     dispatch(setSubject({ sid }));
        //                     setSubjectId(sid);
        //                 }
        //             }
        //         })
        //     }
        // } else {
            props.parentGetSubContentMethod(sid);
            changeSub()
            if (subjectId !== sid) {
                dispatch(setSubject({ sid }));
                setSubjectId(sid);
            }
        // }
    }

    // console.log("row-----> ", rows);

    return (
        <>
            <div className="subject-list">
                {rows.map((row) => {
                    // switch(row.icon) {
                    //     case 'PROGRESS' :
                    //         var icon = IconProgress
                    //         break
                    //     case 'PUBLISHED' :
                    //         var icon = IconNotPublished
                    //         break
                    //     case 'FINISHED' :
                    //         var icon = IconFinished
                    //         break;
                    //     default :
                    //         var icon = IconNotPublished
                    //         break;
                    // }
                    var icon = getSubjectStatus(row.subjectStatus);
                    var subjectId = "id" + row.id;
                    return (

                        <div className={`subject-list-card card ${active == row.id ? "active" : ""} ${row.subject_type == 'orientation' ? "subject-list-card-orange" : ""} ${row.subjectStatus == "Not Yet Published" ? " disabled" : ""}`} key={row.id} 
                        onClick={() => {
                            if(row.subjectStatus != "Not Yet Published"){
                                if(history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched){
                                    dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))
                                }else{
                                    handleSelectSubject(row.id)
                                }
                            }                            
                        }}
                        >
                            {
                                isAdmin == 1 ? (<Link
                                    className="subject-list-card-link"
                                    to={`/courseinfo/${qid}/subject/${row.id}/${ADMIN_PASSWORD}`}>
                                </Link>) : (
                                    history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched 
                                    ? <span
                                        className="subject-list-card-link"
                                        onClick={()=>dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))}
                                    ></span>
                                    : <Link
                                        className="subject-list-card-link"
                                        to={row.subjectStatus != "Not Yet Published" ? `/course/${qid}/subject/${row.id}` : "#"}>
                                    </Link>)
                            }

                            <div className='sub_box_name'>
                                <h3 title={row.name}>{shortenText(row.name, 75)}</h3>
                                {row.unreadMessage > 0 ? <a title='You have unread messages' className='fa-comment-lines-icon' href='#'>
                                    <small className="notify-count">{row.unreadMessage > 9 ? '9+' : row.unreadMessage}</small>
                                    <i className="fal fa-comments" ></i></a> : null}
                            </div>
                            <ul className="subject-info-list list-unstyled d-flex mb-0">
                                <li>
                                    <div className="sub-info-lst-row d-flex">
                                        <div className="sub-info-lst-lft">
                                            <span className="sub-icon">
                                                {/* {row.subjectStatus != "Not Yet Published" ?  */}
                                                <i className="fal fa-calendar-alt"></i>
                                                {/* : (<i></i>)} */}
                                            </span>
                                        </div>
                                        {/* <div className="sub-info-lst-rgt">
                                                        <small>Your Completion Date</small>
                                                        <p>{row.showStart} to {row.showEnd} {subjectDuration(row.showStart,row.showEnd)}</p>
                                                    </div> */}
                                        {row.subject_type == "mentored" && isAdmin != 1 ?
                                            (() => {
                                                switch (row.student_intake_status) {
                                                    case 0:
                                                        return <div className="sub-info-lst-rgt">
                                                            <small>Recommended Date</small>
                                                            <p className="">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart, row.showEnd)}</p>
                                                        </div>;
                                                    case 1:
                                                    case 4:
                                                        return <div className="sub-info-lst-rgt">
                                                            <small>Committed Date</small>
                                                            <p className="">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart, row.showTargetEnd)}</p>
                                                        </div>;
                                                    case 2:
                                                    case 3:
                                                        return <div className="sub-info-lst-rgt">
                                                            <small>Completion Date</small>
                                                            <p className="">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart, row.showCompletion)}</p>
                                                        </div>;
                                                    default:
                                                        return <div className="sub-info-lst-rgt">
                                                            <small>Recommended Date</small>
                                                            <p className="">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart, row.showEnd)}</p>
                                                        </div>;
                                                }
                                            })()
                                            : (<div className="sub-info-lst-rgt">
                                                {/* {row.subjectStatus != "Not Yet Published" ?  */}
                                                {row.hide_dates && row.subjectStatus == "Not Yet Published"  ? "-" : 
                                                    <p className="">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart, row.showEnd)}</p>
                                                 }
                                            </div>)}
                                    </div>
                                </li>
                                <li className="status-detail-li">
                                    <div className="sub-info-lst-row d-flex  align-items-center">
                                        <div className="sub-info-lst-lft">
                                            <span className="sub-icon">
                                                <img src={icon} alt="" />
                                            </span>
                                        </div>
                                        <div className="sub-info-lst-rgt">
                                            <p>{row.subjectStatus}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )
                })}

            </div>


        </>


    )
}

export default SubjectListBox